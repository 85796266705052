import React from 'react';

import { Flex, Image, Text, Stack, Alert, AlertIcon } from '@chakra-ui/react';

import * as variables from '../../config/variables';

const Maintenance: React.FC = () => {
	return (
		<Flex w={`100%`} h={`100%`} justifyContent={`center`}>
			<Stack w={`40%`} spacing={3} mt={20}>
				<Flex minH={120} flexDir={`column`} alignItems={`center`}>
					<Image src={variables.logoUrl} w={130} />
				</Flex>
				<Flex flexDir={`column`}>					
					<Alert mt={10} status='error'>
						<AlertIcon />
						<Text>Estamos em manutenção, aguarde um momento</Text>
					</Alert>					
				</Flex>
			</Stack>
		</Flex>
	)
};

export default Maintenance;