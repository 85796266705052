import React from 'react';

import QRCode from 'react-qr-code';
import { useToasts } from 'react-toast-notifications';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Skeleton, Button, Icon } from '@chakra-ui/react';
import { Box, Text, Flex, HStack, Stack } from '@chakra-ui/layout';
import { CopyIcon } from '@chakra-ui/icons';

import {
	CreatePixPaymentDto,
	CreatePixPaymentResponseDTO,
	PaymentsApi,
	GetPaymentLinkSellerDto,
	CreatePixPaymentRequest,
	GetSimulationDto,
} from '../../../clients';
import { maskMoney, maskPorcent } from '../../../services/masks.service';
import { getApiDefaultConfig } from '../../../services/api.service';
import { CheckoutSaleInformationType } from '../types/checkout.type';

import ConfirmationBox from './ConfirmationBox';

type QrCodeProps = {
	seller: GetPaymentLinkSellerDto;
	saleInformation: CheckoutSaleInformationType | undefined;
	simulationValues: Array<GetSimulationDto>;
};

const QrCode: React.FC<QrCodeProps> = ({ seller, saleInformation, simulationValues }) => {
	const [qrCode, setQrCode] = React.useState<CreatePixPaymentResponseDTO | null>(null);
	const [value, setValue] = React.useState({
		simulated: 0,
		original: 0,
		cet: 0,
	});
	const [checked, setChecked] = React.useState(false);
	const customMessage: string = seller.custom_checkout?.pix?.custom_block || ''
	
	const { addToast } = useToasts();

	React.useEffect(() => {
		(async function () {
			if (qrCode || !checked) return;
			if (!value.simulated) return;

			const apiConfig = getApiDefaultConfig();

			const createPixPaymentDto: CreatePixPaymentDto = {
				amount_cents: Math.round(value.simulated),
				description: saleInformation!.description,
				customer: {
					first_name: saleInformation!.first_name,
					last_name: saleInformation!.last_name,
					document: saleInformation!.document,
				},
				sale_id: saleInformation!.id,
			};

			if (!createPixPaymentDto.customer.document) {
				delete createPixPaymentDto.customer.document;
			}

			const paymentRequest: CreatePixPaymentRequest = {
				sellerId: seller.id,
				createPixPaymentDto,
			};

			const paymentsApi = new PaymentsApi(apiConfig);
			const response = await paymentsApi.createPixPayment(paymentRequest);

			setQrCode(response);
		})();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [checked]);

	React.useEffect(() => {
		(async function () {
			const amount = simulationValues.find((payment) => payment.type === 'pix');

			if (!amount) return;

			setValue({
				simulated: amount.total_amount_cents,
				original: amount.original_amount_cents,
				cet: amount.cet,
			});
		})();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Flex flexDir='column'>
			<Flex direction='column' mt={`4`}>
				<Stack direction='column' spacing='4' fontSize='2sm' bgColor='gray.200' rounded='md' p='4'>
					<Flex justifyContent='space-between'>
						<Text>Valor {seller.custom_payment_link_checkout ? 'total a pagar' : 'original'} do serviço destinado ao cartório:</Text>

						<Text>{maskMoney(value.original / 100)}</Text>
					</Flex>
					<Flex justifyContent='space-between'>
						<Text>* Custo financeiro da operação:</Text>

						<Text>{maskMoney((value.simulated - value.original) / 100)}</Text>
					</Flex>
					<Flex justifyContent='space-between'>
						<Text>* Custo efetivo total:</Text>

						<Text>{maskPorcent(value.cet)}</Text>
					</Flex>

					{!seller.custom_payment_link_checkout  && (
						<>
							<Box bgColor='gray.400' height='0.063rem' />

							<Flex justifyContent='space-between'>
								<Text>Valor a ser pago com acréscimo</Text>

								<Text>{maskMoney(value.simulated / 100)}</Text>
							</Flex>
						</>
					)}
				</Stack>
			</Flex>

			<Flex direction='column' alignItems='center' my={`4`}>
			<ConfirmationBox
					isChecked={checked}
					onChange={() => {
						setChecked(!checked);
					}}
					customMessage={customMessage}
					message={
						'O pagamento realizado por QR Code será confirmado de forma instantânea no cartório. ' +
						'O pagamento por QR Code para Parcela Express será processado por Bradesco. ' +
						(!seller.custom_payment_link_checkout
							? 'Após a finalização do pagamento, o comprovante dessa transação será enviado para o e-mail cadastrado.'
							: '')
					}
				/>
			</Flex>

			{checked &&
				(qrCode ? (
					<>
						<Flex justifyContent='center' align='center' className='data__qrcode'>
							<Flex>
								<QRCode size={200} value={qrCode.qrCodeData} />
							</Flex>
						</Flex>
						<HStack align='center' justify='center' my={8}>
							<CopyToClipboard
								text={qrCode.qrCodeData}
								onCopy={() => {
									addToast('Link copiado com sucesso', {
										appearance: 'success',
										autoDismiss: true,
									});
								}}
							>
								<Button backgroundColor='primary' color='white' fontWeight='bold' className='button__copy-payment-code'>
									<HStack>
										<Icon w={`4`} as={CopyIcon} />
										<Text fontWeight={`smibold`}>Copiar código do pagamento</Text>
									</HStack>
								</Button>
							</CopyToClipboard>
						</HStack>
					</>
				) : (
					<>
						<Text color={`primary`}>A geração do código pode levar alguns segundos</Text>
						<Box pt={1} mb={4}>
							<Flex justifyContent='center' align='center'>
								<Flex>
									<Skeleton height={200} variant='rect' width={200} />
								</Flex>
							</Flex>
							<Skeleton height={25} variant='text' width='30%' mt={4} />
						</Box>
					</>
				))}
		</Flex>
	);
};

export default QrCode;
