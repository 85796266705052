import React from 'react';
import {
  FormControl,
  SimpleGrid,
  FormLabel,
  GridItem,
  Checkbox,
  Text,
  Flex,
  Tooltip,
  chakra,
} from '@chakra-ui/react';
import { IoInformationCircleOutline } from 'react-icons/io5';

import FormAutoComplete from 'components/Form/FormAutoComplete';
import FormErrorMessage from 'components/Form/FormErrorMessage';
import { maskMoney, maskPorcent } from 'services/masks.service';
import { GetSalePlanDto, GetSalePlanPaymentTypeDto, SalesPlansApi, GetSalePlanRequest } from '../../../clients';
import {
  paymentMethodEnum,
  salePlanType
} from '../../../services/enums.service';
import { getApiAuthConfig } from '../../../services/api.service';
import { useToasts } from 'react-toast-notifications';
import Loader from '../../../containers/Loader';

type Props = {
  salePlans: GetSalePlanDto[];
  initialSalePlan?: string;
  values: any;
  confirmSalePlan: (isConfirmed: boolean) => void;
};

type paymentTypesType = {
  id: string;
  description: string;
  code: string;
  piso: number;
  teto: number;
  fee: number;
  isChecked: boolean;
};

const SalePlanForm: React.FC<Props> = ({salePlans, initialSalePlan, values, confirmSalePlan}) => {
  const [salePlan, setSalePlan] = React.useState<GetSalePlanDto>();
  const [paymentTypes, setPaymentTypes] = React.useState<paymentTypesType[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const apiConfig = getApiAuthConfig();
  const salesPlansApi = new SalesPlansApi(apiConfig);

  const { addToast } = useToasts();

  const sortPaymentTypes = (paymentTypes: GetSalePlanPaymentTypeDto[]) => {
    const creditTypes = paymentTypes
      .filter((type) => type.operation_mode === 'credit')
      .sort((a, b) => a.installments - b.installments);

    const otherTypes = paymentTypes
      .filter((type) => type.operation_mode !== 'credit')
      .sort((a, b) => {
        if (a.code > b.code) {
          return 1;
        }
        if (a.code < b.code) {
          return -1;
        }
        return 0;
      });

    return [
      ...otherTypes,
      ...creditTypes
    ].map((paymentType) => ({...paymentType, isChecked: false}));
  }

  const setCheckbox = (isChecked: boolean, index: number) => {
    const paymentTypesUpdated = [...paymentTypes];

    paymentTypesUpdated[index].isChecked = isChecked;

    setPaymentTypes(paymentTypesUpdated);
  }

  const getSelectedSalePlan = async (salePlanId) => {
    try {
      setIsLoading(true);
      const getSalePlanRequest: GetSalePlanRequest = {
        salePlanId: salePlanId ? salePlanId : salePlan!.id
      }
			const response = await salesPlansApi.getSalePlan(getSalePlanRequest);

			setSalePlan(response);
		} catch (error) {
			addToast('Erro ao buscar plano de venda', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
      setIsLoading(false);
    }
  }

  React.useEffect(() => {
    if(values.sale_plan_id) {
      getSelectedSalePlan(values.sale_plan_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (salePlan) {
      setIsLoading(true);
      const { payment_types } = salePlan;

      const paymentTypes =
        values.sale_plan_id !== initialSalePlan
        ? sortPaymentTypes(payment_types)
        : sortPaymentTypes(payment_types)
          .map((paymentType) => ({...paymentType, isChecked: true}));

      setPaymentTypes(paymentTypes);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salePlan])

  React.useEffect(() => {
    if (paymentTypes.length && paymentTypes.every((paymentType)=> paymentType.isChecked)) {
      confirmSalePlan(true);
    } else {
      confirmSalePlan(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentTypes])

  return (
    <>
      <Loader isOpen={isLoading} />
      <SimpleGrid
        columns={{base: 1, md: 6, xl: 12}}
        color='gray.700'
        gap={4}
      >
        <GridItem colStart={{base: 1}} colSpan={{base: 4, md: 6, xl: 12}}>
          <FormControl id='sale_plan_id' isRequired>
            <FormLabel fontSize='md' fontWeight='bold'>
              Plano de venda
            </FormLabel>
            <FormAutoComplete
              name='sale_plan_id'
              placeholder='Selecione o plano de venda'
              options={
                salePlans.map(({id, name})=>({value: id, label: name}))
              }
              value={values.sale_plan_id}
              onChange={(item) => getSelectedSalePlan(item?.value)}
            />
            <FormErrorMessage name='sale_plan_id' />
          </FormControl>
        </GridItem>
      </SimpleGrid>
      {
        salePlan && (
          <SimpleGrid
            columns={{base: 1, md: 6, xl: 12}}
            color='gray.700'
            gap={4}
            mt={10}
          >
            <GridItem colSpan={{md: 3, xl: 3}}>
              <Text fontWeight='bold'>Nome</Text>
              <Text>{salePlan.name}</Text>
            </GridItem>
            <GridItem colSpan={{md: 3, xl: 4}}>
              <Text fontWeight='bold'>Descrição</Text>
              <Text>{salePlan.description}</Text>
            </GridItem>
            <GridItem colSpan={{md: 2, xl: 2}}>
              <Text fontWeight='bold'>Tipo</Text>
              <Text>{salePlanType[salePlan.type]}</Text>
            </GridItem>

            <GridItem colSpan={{md: 2, xl: 3}}>
              <Text fontWeight='bold'>Método de Pagamento</Text>
              <Text>{paymentMethodEnum[salePlan.payment_method]}</Text>
            </GridItem>

            <GridItem colSpan={{md: 2, xl: 3}}>
              <Text fontWeight='bold'>Merchant</Text>
              <Text>{salePlan.merchant?.description}</Text>
            </GridItem>
            <GridItem colSpan={{md: 2, xl: 3}}>
              <Text fontWeight='bold'>Adquirente</Text>
              <Text>{salePlan.acquirer?.name || '-'}</Text>
            </GridItem>
            <GridItem colSpan={{md: 2, xl: 3}}>
              <Text fontWeight='bold'>Plano de Liquidação</Text>
              <Text>{salePlan.settlement_plan?.name || '-'}</Text>
            </GridItem>
            <GridItem colSpan={{md: 2, xl: 3}}>
              <Text fontWeight='bold'>Plano de Custo</Text>
              <Text>{salePlan.cost_plan?.name || '-'}</Text>
            </GridItem>
            <GridItem colSpan={{md: 6, xl: 12}} mt={5}>
              <Flex>
                <Text mr={1} fontWeight='bold'>Taxas</Text>
                <Tooltip
                  label={'Selecione todas as taxas à critério de confirmação'}
                  placement='top'
                  hasArrow
                >
                  <chakra.span>
                    <IoInformationCircleOutline size='1.3rem' color='primary' />
                  </chakra.span>
                </Tooltip>
              </Flex>
            </GridItem>
            {
              paymentTypes.map((paymentType, index) => (
                <GridItem key={paymentType.id} colSpan={{md: 2, xl: 3}}>
                  <Flex wrap='wrap'>
                    <Checkbox
                      mr={2}
                      colorScheme={'green'}
                      isChecked={paymentType.isChecked}
                      onChange={(event) => setCheckbox(event.target.checked, index)}
                    />
                    <Text fontWeight='bold' mr={2}>{paymentType.description}:</Text>
                    <Text mr={2}>
                      {
                        paymentType.code === 'billet' || paymentType.code === 'pix'
                          ? `${
                              paymentType.teto && paymentType.piso
                                ? maskPorcent(paymentType.fee)
                                : `R$ ${maskMoney(paymentType.fee)}`
                            }`
                          : maskPorcent(paymentType.fee)
                      }
                    </Text>
                    {
                      paymentType.piso && paymentType.teto
                        && (
                          <Text fontSize='sm' alignSelf='center'>
                            (
                              {`R$ ${maskMoney(paymentType.piso)} - R$ ${maskMoney(paymentType.teto)}`}
                            )
                          </Text>
                        )
                    }
                  </Flex>
                </GridItem>
              ))
            }
          </SimpleGrid>
        )
      }
    </>
  )
}

export default SalePlanForm;