import React from 'react';

import { HeaderTitle, CellContent, SimpleTable, HeaderTitleProps, CellContentProps } from '../../../containers/SimpleTable';

import { Text, Icon, Button, Flex, Tooltip } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { FiEdit } from 'react-icons/fi';

const HeaderTitleStyled: React.FC<HeaderTitleProps> = (props) => {
	return (
		<HeaderTitle {...props} color='black' justifyContent='flex-start' fontSize='sm' fontWeight='bold'>
			{props.children}
		</HeaderTitle>
	);
};

const CellContentStyled: React.FC<CellContentProps> = (props) => {
	return (
		<CellContent {...props} color='primary' justifyContent='flex-start' fontSize='sm' fontWeight='semibold'>
			{props.children}
		</CellContent>
	);
};

export default ({ data }) => {
	const columns = React.useMemo(() => {
		const history = useHistory();

		let cols: any = [];

		const goToEditPage = (merchantId: string) => {
			history.push(`/admin/merchants/${merchantId}`);
		};

		return cols.concat([
			{
				Header: ({ column }) => <HeaderTitleStyled column={column}>ID</HeaderTitleStyled>,
				accessor: 'id',
				Cell: ({ cell: { value } }) => <CellContentStyled>{value}</CellContentStyled>,
			},
			{
				Header: ({ column }) => <HeaderTitle column={column}>Editar</HeaderTitle>,
				accessor: 'edit-merchant-button',
				Cell: ({ cell: { row } }) => (
					<CellContent>
						<Flex>
							<Tooltip label='Editar Merchant'>
								<Button
									background={'transparent'}
									onClick={() => {
										goToEditPage(row.values.id);
									}}
								>
									<Icon as={FiEdit} boxSize={5} />
								</Button>
							</Tooltip>
						</Flex>
					</CellContent>
				),
			},
			{
				Header: ({ column }) => <HeaderTitleStyled column={column}>Ativo</HeaderTitleStyled>,
				accessor: 'is_active',
				Cell: ({ cell: { value } }) => (
					<CellContentStyled>
						<Text>{value ? 'Sim' : 'Não'}</Text>
					</CellContentStyled>
				),
			},
			{
				Header: ({ column }) => <HeaderTitleStyled column={column}>Nome</HeaderTitleStyled>,
				accessor: 'name',
				Cell: ({ cell: { value } }) => <CellContentStyled>{value}</CellContentStyled>,
			},
			{
				Header: ({ column }) => <HeaderTitleStyled column={column}>Descrição</HeaderTitleStyled>,
				accessor: 'description',
				Cell: ({ cell: { value } }) => <CellContentStyled>{value}</CellContentStyled>,
			},
		]);
	}, []);

	return <SimpleTable columns={columns} data={data} pl={2} variant='unstyled' maxHeightTableWrapper='42vh' />;
};
