import React from 'react';

import {
	Flex,
	Text,
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	SimpleGrid,
	Center,
	Button,
	Icon,
	Collapse,
	Box,
} from '@chakra-ui/react';
import { FiEdit } from 'react-icons/fi';
import { ChevronRightIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { useToasts } from 'react-toast-notifications';

import { RolesApi } from 'clients';
import { useTheme } from 'contexts/ThemeProvider';
import { userType } from 'services/enums.service';
import RoleModal from './components/RoleModal';
import Loader from 'containers/Loader';
import { getApiAuthConfig } from '../../services/api.service';

const Roles: React.FC = () => {
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [openRoleModal, setOpenRoleModal] = React.useState(false);
	const [selectedRole, setSelectedRole] = React.useState(undefined);
	const [showTypes, setShowType] = React.useState<boolean[]>([]);

	const { addToast } = useToasts();
	const { theme } = useTheme();

	const [roles, setRoles] = React.useState<any>();

	const handleToggleType = (type) => {
		setShowType((prev) => Object.assign([], { ...prev, [type]: !showTypes[type] }));
	};

	async function fetchRoles() {
		setIsLoading(true);

		try {
			const apiConfig = getApiAuthConfig();

			const rolesApi = new RolesApi(apiConfig);

			const response = await rolesApi.listRoles();

			const rolesByType = response.reduce((r, a) => {
				r[a.type] = r[a.type] || [];
				r[a.type].push(a);
				return r;
			}, Object.create(null));

			setRoles(rolesByType);
		} catch (error) {
			addToast('Erro ao buscar perfis.', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	}

	const handleOpenRoleModal = (role?) => {
		setSelectedRole(role);
		setOpenRoleModal(!openRoleModal);
	};

	React.useEffect(() => {
		fetchRoles();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Loader isOpen={isLoading} />
			<Flex pb='8'>
				<Breadcrumb separator={<ChevronRightIcon />}>
					<BreadcrumbItem>
						<BreadcrumbLink href='#' fontSize='md' color={`darkGrey`}>
							Resumo
						</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem>
						<BreadcrumbLink href='#' fontSize='md' color={`darkGrey`}>
							Minha Conta
						</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink href='#' fontSize='md' color={`darkGrey`} fontWeight={`extrabold`}>
							Permissões
						</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>
			</Flex>
			<Flex mb='4' flexDirection='row' justifyContent='space-between'>
				<Text color={`darkGrey`} fontSize={`2xl`} fontWeight={`bold`}>
					Permissões
				</Text>
				<Button
					bgColor='transparent'
					borderWidth={1}
					borderColor={theme?.primary_color || 'black'}
					color={theme?.primary_color || 'black'}
					rounded='md'
					onClick={() => {
						handleOpenRoleModal();
					}}
				>
					Criar Novo Perfil
				</Button>
			</Flex>

			<Flex flexDir={`column`} p={10} bgColor='white' rounded={`md`} boxShadow={`sm`} mt='8' w='100%'>
				{roles &&
					Object.keys(roles)?.map((type) => {
						return (
							<Flex direction='column'>
								<Box
									backgroundColor='primary'
									padding='4'
									mb='4'
									rounded='md'
									_hover={{
										cursor: 'pointer',
										opacity: 0.8,
									}}
									onClick={() => handleToggleType(type)}
								>
									<Flex flexDirection='row' justifyContent='space-between'>
										<Text color='white' fontSize={`md`} fontWeight={`semibold`}>
											{userType[type]}
										</Text>
										{showTypes[type] ? <ChevronDownIcon color='white' h={6} w={6} /> : <ChevronRightIcon color='white' h={6} w={6} />}
									</Flex>
								</Box>
								<Collapse startingHeight={0} in={showTypes[type]}>
									<Flex flexDirection='column'>
										<SimpleGrid columns={2} spacing='4' w='100%' mt='4' mb='8'>
											<Center>
												<Text color={`black`} fontSize={`md`} fontWeight={`semibold`}>
													Nome
												</Text>
											</Center>
											<Center>
												<Text color={`black`} fontSize={`md`} fontWeight={`semibold`}>
													Permissões
												</Text>
											</Center>
											{roles[type].map((role) => (
												<>
													<Center>
														<Text color={`primary`} fontSize={`md`} fontWeight={`semibold`}>
															{role.name}
														</Text>
													</Center>
													<Center>
														<Text color={`primary`} fontSize={`md`} fontWeight={`semibold`}>
															<Button
																background={'transparent'}
																onClick={() => {
																	handleOpenRoleModal(role);
																}}
															>
																<Icon as={FiEdit} boxSize={5} />
																&nbsp;Editar
															</Button>
														</Text>
													</Center>
												</>
											))}
										</SimpleGrid>
									</Flex>
								</Collapse>
							</Flex>
						);
					})}
			</Flex>
			<RoleModal
				openRoleModal={openRoleModal}
				handleOpenRoleModal={handleOpenRoleModal}
				isLoading={isLoading}
				setIsLoading={setIsLoading}
				selectedRole={selectedRole}
				fetchRoles={fetchRoles}
			/>
		</>
	);
};

export default Roles;
