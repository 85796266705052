import { FormControl } from '@chakra-ui/react';
import React from 'react';

import { GetMerchantSellerDto } from 'clients/models';

import Button from 'components/Button';

import SplitRulesModal from './components/Modal';
import SplitsTable from './components/Table/SplitsTable';

type SplitRulesWrapperType = {
	splits: SplitSellerValue[];
	setSplits: React.Dispatch<React.SetStateAction<SplitSellerValue[]>>;
};

export type SplitSellerValue = {
	amount: number;
	seller: GetMerchantSellerDto;
	description?: string;
};

export type EditingSplitType = SplitSellerValue & {
	index: number;
};

const SplitRulesWrapper: React.FC<SplitRulesWrapperType> = ({ splits, setSplits }) => {
	const [isOpen, setIsOpen] = React.useState(false);
	const [editingSplit, setEditingSplit] = React.useState<EditingSplitType | undefined>();

	const removeFromSplits = (index: number) => {
		const parsedSplits = [...splits];
		parsedSplits.splice(index, 1);
		setSplits(parsedSplits);
	};

	const editSplit = (index: number) => {
		const split = splits[index];
		setEditingSplit({ ...split, index });
		setIsOpen(true);
	};

	return (
		<fieldset>
			<FormControl display='flex' flexDirection='column' alignItems='flex-start' justifyContent='center' mt='4'>
				{splits.length > 0 && <SplitsTable data={splits} variant='simple' removeFromSplits={removeFromSplits} editSplit={editSplit} />}

				<Button
					mt='2'
					_hover={{ backgroundColor: 'primary', opacity: 0.8 }}
					_focus={{ outline: 'none' }}
					backgroundColor='primary'
					onClick={() => setIsOpen(true)}
				>
					Adicionar Estabelecimento
				</Button>

				<SplitRulesModal
					setEditingSplit={setEditingSplit}
					editingSplit={editingSplit}
					setIsOpen={setIsOpen}
					isOpen={isOpen}
					onClose={() => setIsOpen(false)}
					splits={splits}
					setSplits={setSplits}
				/>
			</FormControl>
		</fieldset>
	);
};

export default SplitRulesWrapper;
