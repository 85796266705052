import yup from "services/yup.service";

const createEditSellerSchema = yup.object().shape({
	name: yup.string().required('Campo obrigatório'),
	description: yup.string().required('Campo obrigatório'),
	document: yup.string().required('Campo obrigatório').isCpfOrCnpjOptional('Documento inválido'),
	state_registration: yup.string().nullable(),
	cns: yup.string().required('Campo obrigatório')
		.matches(
			/^(([0-9]{6})|(([0-9]{2}\.[0-9]{3}-[0-9])))$/,
			'O valor deve ter exatamente 6 caracteres numéricos'
		),
	cnae: yup.string().required('Campo obrigatório')
		.matches(
			/^(([0-9]{7})|(([0-9]{2}\.[0-9]{2}-[0-9]\/[0-9]{2})))$/,
			'O valor deve ter exatamente 7 caracteres numéricos'
		),
	code: yup.string().required('Campo obrigatório'),
	assignment: yup.string().required('Campo obrigatório'),
	settlement_type: yup.string().required('Campo obrigatório'),
	merchant_id: yup.string().required('Campo obrigatório'),
	email: yup.string().email('Formato de e-mail inválido').nullable(),
	secondary_email: yup.string().email('Formato de e-mail inválido').nullable(),
	tertiary_email: yup.string().email('Formato de e-mail inválido').nullable(),
	address: yup.object().shape({
		zipcode: yup.string().required('Campo obrigatório'),
		street: yup.string().required('Campo obrigatório'),
		district: yup.string().required('Campo obrigatório'),
		city: yup.string().required('Campo obrigatório'),
		state: yup.string().required('Campo obrigatório'),
		complement: yup.string().nullable(),
		number: yup.string().required('Campo obrigatório'),
	}),
	bank_account: yup.object().shape({
		bank_code: yup.string().required('Campo obrigatório').matches(/^[0-9]+$/, 'O valor deve ser do tipo numérico').min(3, 'O valor deve ter exatamente 3 caracteres').max(3, 'O valor deve ter exatamente 3 caracteres'),
		agency_number: yup.number().required('Campo obrigatório').typeError('O valor deve ser do tipo numérico'),
		agency_digit: yup.number().nullable().typeError('O valor deve ser do tipo numérico'),
		account_number: yup.number().required('Campo obrigatório').typeError('O valor deve ser do tipo numérico'),
		account_digit: yup.number().nullable().typeError('O valor deve ser do tipo numérico'),
		type_account: yup.string().required('Campo obrigatório'),
		pix_key: yup.string().nullable(),
	}),
	monthly_billing: yup.number().required('Campo obrigatório'),
	erp: yup.string().required('Campo obrigatório'),
	covenant: yup.string().nullable(),
	is_split: yup.string(),
	phone: yup.string().nullable(),
	secondary_phone: yup.string().nullable(),
	tertiary_phone: yup.string().nullable(),
	status: yup.string().required('Campo obrigatório'),
	type: yup.string().required('Campo obrigatório'),
	responsible: yup.object().shape({
		support: yup.string().required('Campo obrigatório'),
		commercial: yup.string().required('Campo obrigatório'),
	}),
	owner_name: yup.string().required('Campo obrigatório'),
	owner_document: yup.string().isCpfOrCnpjOptional('Documento inválido').nullable(),
	three_ds_active: yup.string().nullable(),
	min_three_ds: yup.number().nullable().typeError('O valor deve ser do tipo numérico'),
	three_ds_value: yup.number().nullable().typeError('O valor deve ser do tipo numérico'),
	risk_classification: yup.string().required('Campo obrigatório'),
	sale_plan_id: yup.string().required('Campo obrigatório'),
	extract_name: yup.string().nullable().min(3, 'O campo deve haver pelo menos 3 dígitos').max(22, 'O campo não pode passar de 22 dígitos'),
});

export default createEditSellerSchema;
