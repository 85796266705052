import React from 'react';

import {
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
  GridItem,
  Heading,
  Divider
} from '@chakra-ui/react';

import FormInput from 'components/Form/FormInput';
import FormSelect from 'components/Form/FormSelect';
import FormErrorMessage from 'components/Form/FormErrorMessage';

import {
  status,
  seller_assignment,
  accreditationStatus,
} from 'config/constants';
import {
  GetMerchantDto,
  PermissionActionsEnum,
  PermissionSubjectEnum
} from '../../../clients';
import FormCpfCnpjInput from '../../../components/Form/FormCpfCnpjInput';
import FormCurrencyInput from '../../../components/Form/FormCurrencyInput';
import FormCnsInput from '../../../components/Form/FormCnsInput';
import FormCnaeInput from '../../../components/Form/FormCnaeInput';
import FormPhoneInput from '../../../components/Form/FormPhoneInput';
import Can from 'contexts/Can';
import CompanyComplianceForm from './CompanyComplianceForm';

type Props = {
  errors: any;
  values: any;
  setFieldValue: any;
  setSelectedPayer?: React.Dispatch<React.SetStateAction<any[]>>;
  merchants: GetMerchantDto[];
  salesPlans: any;
  isStatusDisabled: boolean;
  isCnsSearchEnabled: boolean;
};

const SellerForm: React.FC<Props> = ({
  setSelectedPayer,
  isStatusDisabled,
  setFieldValue,
  isCnsSearchEnabled
}) => {
  const completeDataByCns = ({
    assignment,
    phone,
    secondary_phone,
    email,
    zipcode,
    street,
    address_number,
    district,
    city,
    state,
    responsible,
    monthlyBilling
  }) => {
    setFieldValue('assignment', assignment);
    setFieldValue('phone', phone);
    setFieldValue('secondary_phone', secondary_phone);
    setFieldValue('email', email);
    setFieldValue('monthly_billing', monthlyBilling);
    setFieldValue('address.zipcode', zipcode);
    setFieldValue('address.street', street);
    setFieldValue('address.number', address_number);
    setFieldValue('address.district', district);
    setFieldValue('address.city', city);
    setFieldValue('address.state', state);
    setFieldValue('owner_name', responsible);
  }

  return (
    <SimpleGrid
      columns={{base: 1, md: 6, xl: 12}}
      color='gray.700'
      gap={4}
    >
      <GridItem colStart={{base: 1}} colSpan={{base: 4, xl: 5}}>
        <FormControl id='name' isRequired >
          <FormLabel fontSize='md' fontWeight='bold'>
            Nome
          </FormLabel>
          <FormInput name='name' />
          <FormErrorMessage name='name' />
        </FormControl>
      </GridItem>

      <GridItem colStart={{base: 1, md: 5 }} colSpan={{base: 4, md: 2, xl: 3}}>
        <FormControl id='document' isRequired>
          <FormLabel fontSize='md' fontWeight='bold'>
            CPF/CNPJ
          </FormLabel>
          <FormCpfCnpjInput
            name='document'
            isDisabled={false}
            isSearchPayer={false}
            setSelectedPayer={setSelectedPayer}
          />
          <FormErrorMessage name='document' />
        </FormControl>
      </GridItem>

      <GridItem colStart={{base: 1, md: 1}} colSpan={{base: 4, xl: 4}}>
        <FormControl id='description' isRequired>
          <FormLabel fontSize='md' fontWeight='bold'>
            Descrição
          </FormLabel>
          <FormInput as={Input} name='description' />
          <FormErrorMessage name='description' />
        </FormControl>
      </GridItem>

      <GridItem
        colStart={{base: 1, md: 5, xl: 1}}
        colSpan={{base: 4, md: 2, xl: 4}}
      >
        <FormControl id='state_registration'>
          <FormLabel fontSize='md' fontWeight='bold'>
            Registro estadual
          </FormLabel>
          <FormInput as={Input} name='state_registration' />
          <FormErrorMessage name='state_registration' />
        </FormControl>
      </GridItem>

      <GridItem colStart={{base: 1}} colSpan={{base: 4, md: 2, xl: 2}}>
        <FormControl id='cns' isRequired>
          <FormLabel fontSize='md' fontWeight='bold'>
              CNS
          </FormLabel>
          <FormCnsInput
            name='cns'
            search={
              isCnsSearchEnabled ? (data)=> {
                completeDataByCns(data);
              }
              : undefined
            }
          />
          <FormErrorMessage name='cns' />
        </FormControl>
      </GridItem>

      <GridItem colStart={{base: 1}} colSpan={{base: 4, md: 2, xl: 2}}>
        <FormControl id='cnae' isRequired>
          <FormLabel fontSize='md' fontWeight='bold'>
            CNAE
          </FormLabel>
          <FormCnaeInput name='cnae' />
          <FormErrorMessage name='cnae' />
        </FormControl>
      </GridItem>

      <GridItem colStart={{ base: 1 }} colSpan={{base: 4, md: 2, xl: 4}}>
        <FormControl id='assignment' isRequired>
          <FormLabel fontSize='md' fontWeight='bold'>
            Atribuição
          </FormLabel>
          <FormSelect name='assignment'>
            <option value='' />
            {seller_assignment.map((assignment) => (
              <option value={assignment.key} key={assignment.key}>
                {assignment.text}
              </option>
            ))}
          </FormSelect>
          <FormErrorMessage name='assignment' />
        </FormControl>
      </GridItem>

      <GridItem colStart={{base: 1}} colSpan={{base: 4, md: 4, xl: 4 }}>
        <FormControl id='code' isRequired>
          <FormLabel fontSize='md' fontWeight='bold'>
            Chave do Estabelecimento
          </FormLabel>
          <FormInput as={Input} name='code' />
          <FormErrorMessage name='code' />
        </FormControl>
      </GridItem>

      <GridItem colStart={{base: 1}} colSpan={{base: 4, md: 2, xl: 3}}>
        <FormControl id='monthly_billing' isRequired>
          <FormLabel fontSize='md' fontWeight='bold'>
            Faturamento Mensal
          </FormLabel>
          <FormCurrencyInput borderColor='darkGrey' name='monthly_billing' />
          <FormErrorMessage name='monthly_billing' />
        </FormControl>
      </GridItem>

      <GridItem colStart={{base: 1}} colSpan={{base: 4, md: 4, xl: 3}}>
        <FormControl id='covenant'>
          <FormLabel fontSize='md' fontWeight='bold'>
            Convênio
          </FormLabel>
          <FormInput id='covenant' as={Input} name='covenant' />
          <FormErrorMessage name='covenant' />
        </FormControl>
      </GridItem>

      <GridItem colStart={{base: 1}} colSpan={{base: 4, md: 2, xl: 2 }}>
        <Can
          do={PermissionActionsEnum.Read}
          on={PermissionSubjectEnum.ChangeSeller}
        >
          <FormControl id='status' isDisabled={isStatusDisabled} isRequired>
            <FormLabel fontSize='md' fontWeight='bold'>
              Status
            </FormLabel>
            <FormSelect name='status'>
              <option value='' />
              {status.map((type) => (
                <option
                  disabled={type.disabled}
                  value={type.value}
                  key={type.value}
                >
                  {type.text}
                </option>
              ))}
            </FormSelect>
            <FormErrorMessage name='status' />
          </FormControl>
        </Can>
      </GridItem>

      <GridItem colStart={{base: 1, xl: 1}} colSpan={{base: 4, md: 3, xl: 4 }}>
        <FormControl id='erp' isRequired>
          <FormLabel fontSize='md' fontWeight='bold'>
            Sistema de gestão (ERP)
          </FormLabel>
          <FormInput id='erp' as={Input} name='erp' />
          <FormErrorMessage name='erp' />
        </FormControl>
      </GridItem>

      <GridItem colStart={{base: 1}} colSpan={{base: 4, md: 3, xl: 4 }}>
        <FormControl id='accreditation_status'>
          <FormLabel fontSize='md' fontWeight='bold'>
            Status do credenciamento
          </FormLabel>
          <FormSelect id='accreditation_status' name='accreditation_status'>
            <option value='' />
            {accreditationStatus.map((type) => (
              <option
                value={type.value}
                key={type.value}
              >
                {type.text}
              </option>
            ))}
          </FormSelect>
          <FormErrorMessage name='accreditation_status' />
        </FormControl>
      </GridItem>

      <GridItem
        colStart={{base: 1}}
        colSpan={{base: 4, md: 2, xl: 4 }}
        order={{md: 2, xl: 1}}
      >
        <FormControl id='frontier'>
          <FormLabel fontSize='md' fontWeight='bold'>
            Fronteiriça
          </FormLabel>
          <FormSelect name='frontier'>
            <option value='' />
            <option value='true'>Sim</option>
            <option value='false'>Não</option>
          </FormSelect>
          <FormErrorMessage name='frontier' />
        </FormControl>
      </GridItem>

      <GridItem
        colStart={{base: 1}}
        colSpan={{base: 4, md: 6, xl: 12 }}
        order={{md: 1, xl: 2}}
      >
        <CompanyComplianceForm />
      </GridItem>

      <GridItem
        colStart={{base: 1}}
        colSpan={{base: 3, md: 6, xl: 12}}
        mt={5}
        order={{base: 10}}
      >
        <Heading color='gray.700' as='h6' size='sm' py='1%'>
          Contato
        </Heading>
        <Divider />
      </GridItem>

      <GridItem
        colStart={{base: 1}}
        colSpan={{base: 4, md: 2, xl: 4}}
        order={{base: 11, md: 12, xl: 11}}
      >
        <FormControl id='phone'>
          <FormLabel fontSize='md' fontWeight='bold'>
            Telefone
          </FormLabel>
          <FormPhoneInput name='phone'/>
          <FormErrorMessage name='phone' />
        </FormControl>
      </GridItem>

      <GridItem
        colSpan={{base: 4, md: 2, xl: 4}}
        order={{base: 12, md: 14, xl: 12}}
      >
        <FormControl id='secondary_phone'>
          <FormLabel fontSize='md' fontWeight='bold'>
            Telefone Secundário
          </FormLabel>
          <FormPhoneInput name='secondary_phone'/>
          <FormErrorMessage name='secondary_phone' />
        </FormControl>
      </GridItem>

      <GridItem
        colSpan={{base: 4, md: 2, xl: 4}}
        order={{base: 13, md: 16, xl: 13}}
      >
        <FormControl id='tertiary_phone'>
          <FormLabel fontSize='md' fontWeight='bold'>
            Telefone Terciário
          </FormLabel>
          <FormPhoneInput name='tertiary_phone'/>
          <FormErrorMessage name='tertiary_phone' />
        </FormControl>
      </GridItem>

      <GridItem
        colStart={{base: 1}}
        colSpan={{base: 4, md: 4, xl: 4 }}
        order={{base: 14, md: 13, xl: 14}}
      >
        <FormControl id='email'>
          <FormLabel fontSize='md' fontWeight='bold'>
            E-mail
          </FormLabel>
          <FormInput name='email' />
          <FormErrorMessage name='email' />
        </FormControl>
      </GridItem>

      <GridItem
        colStart={{base: 1}}
        colSpan={{base: 4, md: 4, xl: 4 }}
        order={{base: 15, md: 14, xl: 15}}
      >
        <FormControl id='secondary_email'>
          <FormLabel fontSize='md' fontWeight='bold'>
            E-mail Secundário
          </FormLabel>
          <FormInput name='secondary_email' />
          <FormErrorMessage name='secondary_email' />
        </FormControl>
      </GridItem>

      <GridItem
        colStart={{base: 1}}
        colSpan={{base: 4, md: 4, xl: 4 }}
        order={{base: 16, md: 16, xl: 16}}
      >
        <FormControl id='tertiary_email'>
          <FormLabel fontSize='md' fontWeight='bold'>
            E-mail Terciário
          </FormLabel>
          <FormInput name='tertiary_email' />
          <FormErrorMessage name='tertiary_email' />
        </FormControl>
      </GridItem>
    </SimpleGrid>
  );
};

export default SellerForm;
