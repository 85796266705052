import React from 'react';

import { HeaderTitle, CellContent, SimpleTable } from '../../../containers/SimpleTable';

import { Button, HStack, Text } from '@chakra-ui/react';

import { maskMoney } from 'services/masks.service';
import { saleStatus } from 'services/enums.service';
import { AiFillCheckCircle, AiFillClockCircle, AiFillCloseCircle } from 'react-icons/ai';

import RenderBilletButton from './RenderBilletButton';
import { getApiAuthConfig } from '../../../services/api.service';
import { SellersApi, GetSellerSaleRequest } from '../../../clients';
import { useCurrentSeller } from '../../../contexts/SellerProvider';
import { useToasts } from 'react-toast-notifications';

const iconStatusLiteral = {
	succeeded: <AiFillCheckCircle color='#47FF62' size={16} />,
	pending: <AiFillClockCircle color='#FFA547' size={16} />,
	pending_cancel: <AiFillClockCircle color='#FFA547' size={16} />,
	canceled: <AiFillCloseCircle color='tomato' size={16} />,
	failed: <AiFillCloseCircle color='tomato' size={16} />,
	expired: <AiFillCloseCircle color='tomato' size={16} />,
};

export default ({
	data,
	setPagination,
	pagination,
	totalPages,
	onRowClick,
	openReceiptModal,
	seller,
	setValues,
	setIsLoading,
	...rest
}) => {
	const { currentSeller } = useCurrentSeller();
	const { addToast } = useToasts();

	const getSaleById = async (saleId: string) => {
		try {
			setIsLoading(true);
			const apiConfig = getApiAuthConfig();
			const sellersApi = new SellersApi(apiConfig);

			const requestParams: GetSellerSaleRequest = {
				sellerId: currentSeller?.id!,
				saleId,
			};
			const response = await sellersApi.getSellerSale(requestParams);

			const { payer_address, payer_name, payer_document, payer_email, payer_phone, original_amount, description } = response;

			setValues({
				payer: {
					address: {
						street: payer_address?.street || '',
						district: payer_address?.district || '',
						city: payer_address?.city || '',
						zipcode: payer_address?.zipcode || '',
						number: payer_address?.number || '',
						state: payer_address?.state || '',
						complement: payer_address?.complement || 'N/A',
					},
					name: payer_name || '',
					document: payer_document || '',
					email: payer_email || '',
					phone: payer_phone || '',
				},
				amount: original_amount ? original_amount / 100 : 0,
				description: description || '',
			});
		} catch (error) {
			addToast('Erro ao buscar os dados do boleto', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	};

	const columns = React.useMemo(
		() => [
			{
				Header: ({ column }) => <HeaderTitle column={column}>Status</HeaderTitle>,
				accessor: 'status',
				Cell: ({ cell: { value } }) => (
					<CellContent>
						<HStack>
							<Text>{iconStatusLiteral[value]}</Text>
							<Text>{saleStatus[value] ?? 'N/A'}</Text>
						</HStack>
					</CellContent>
				),
			},
			{
				Header: ({ column }) => <HeaderTitle column={column}>Tipo de Pagamento</HeaderTitle>,
				accessor: 'formatted_type',
				Cell: ({ cell: { value } }) => <CellContent color={`darkGrey`}>{value}</CellContent>,
			},
			{
				Header: ({ column }) => <HeaderTitle column={column}>Descrição</HeaderTitle>,
				accessor: 'description',
				Cell: ({ cell: { value } }) => <CellContent color={`darkGrey`}>{value}</CellContent>,
			},
			{
				Header: ({ column }) => <HeaderTitle column={column}>Data de Criação</HeaderTitle>,
				accessor: 'created_at',
				Cell: ({ cell: { value } }) => <CellContent color={`darkGrey`}>{value}</CellContent>,
			},
			{
				Header: ({ column }) => <HeaderTitle column={column}>Data de Vencimento</HeaderTitle>,
				accessor: 'expires_at',
				Cell: ({ cell: { value } }) => <CellContent color={`darkGrey`}>{value}</CellContent>,
			},
			{
				Header: ({ column }) => <HeaderTitle column={column}>Total</HeaderTitle>,
				accessor: 'original_amount',
				Cell: ({ cell: { value } }) => <CellContent color={`darkGrey`}>R$ {maskMoney(value / 100)}</CellContent>,
			},
			{
				Header: ({ column }) => <HeaderTitle column={column}>Boleto</HeaderTitle>,
				accessor: 'reference',
				Cell: ({
					cell: {
						row: { values, original },
						value,
					},
				}) => {
					return (
						<CellContent onClick={(e) => e.stopPropagation()}>
							{values.status === 'pending' ? (
								<RenderBilletButton saleId={original.sale_id} seller={seller} />
							) : values.status !== 'expired' && (
								<Button
									px='4'
									height='auto'
									bgColor='teal.300'
									color='white'
									fontWeight='normal'
									onClick={() => {
										openReceiptModal(original.sale_id);
									}}
								>
									Recibo
								</Button>
							)}
						</CellContent>
					);
				},
			},
			{
				Header: ({ column }) => <HeaderTitle column={column}>Reemitir</HeaderTitle>,
				accessor: 'resend',
				Cell: ({
					cell: {
						row: { original },
					},
				}) => {
					return (
						<CellContent onClick={(e) => e.stopPropagation()}>
									<Button
										id='billets-table-resend-button'
										px='4'
										height='auto'
										bgColor='teal.300'
										color='white'
										fontWeight='normal'
										onClick={() => {
											getSaleById(original.sale_id);
										}}
									>
										Reemitir
									</Button>
						</CellContent>
					);
				},
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	return (
		<SimpleTable
			columns={columns}
			onRowClick={onRowClick}
			data={data}
			setPagination={setPagination}
			pagination={pagination}
			totalPages={totalPages}
			pl={2}
			maxHeightTableWrapper={250}
		/>
	);
};
