/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GetSimulationDto,
    GetSimulationDtoFromJSON,
    GetSimulationDtoToJSON,
    GetSimulationPayloadDto,
    GetSimulationPayloadDtoFromJSON,
    GetSimulationPayloadDtoToJSON,
} from '../models';

export interface ListCelcoinSimulationRequest {
    sellerId: string;
    amountCents: number;
    isPos?: boolean;
}

export interface ListSimulationRequest {
    sellerId: string;
    amountCents: number;
    fee?: number;
    isPos?: boolean;
}

export interface ListSimulationsFeeRequest {
    sellerId: string;
    getSimulationPayloadDto: GetSimulationPayloadDto;
}

/**
 * 
 */
export class SimulationApi extends runtime.BaseAPI {

    /**
     * Listar simulação de parcelas de Pagamento de Contas
     */
    async listCelcoinSimulationRaw(requestParameters: ListCelcoinSimulationRequest): Promise<runtime.ApiResponse<Array<GetSimulationDto>>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling listCelcoinSimulation.');
        }

        if (requestParameters.amountCents === null || requestParameters.amountCents === undefined) {
            throw new runtime.RequiredError('amountCents','Required parameter requestParameters.amountCents was null or undefined when calling listCelcoinSimulation.');
        }

        const queryParameters: any = {};

        if (requestParameters.amountCents !== undefined) {
            queryParameters['amount_cents'] = requestParameters.amountCents;
        }

        if (requestParameters.isPos !== undefined) {
            queryParameters['is_pos'] = requestParameters.isPos;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/simulation/sellers/{seller_id}/celcoin`.replace(`{${"seller_id"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetSimulationDtoFromJSON));
    }

    /**
     * Listar simulação de parcelas de Pagamento de Contas
     */
    async listCelcoinSimulation(requestParameters: ListCelcoinSimulationRequest): Promise<Array<GetSimulationDto>> {
        const response = await this.listCelcoinSimulationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Listar simulação de parcelas
     */
    async listSimulationRaw(requestParameters: ListSimulationRequest): Promise<runtime.ApiResponse<Array<GetSimulationDto>>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling listSimulation.');
        }

        if (requestParameters.amountCents === null || requestParameters.amountCents === undefined) {
            throw new runtime.RequiredError('amountCents','Required parameter requestParameters.amountCents was null or undefined when calling listSimulation.');
        }

        const queryParameters: any = {};

        if (requestParameters.amountCents !== undefined) {
            queryParameters['amount_cents'] = requestParameters.amountCents;
        }

        if (requestParameters.fee !== undefined) {
            queryParameters['fee'] = requestParameters.fee;
        }

        if (requestParameters.isPos !== undefined) {
            queryParameters['is_pos'] = requestParameters.isPos;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/simulation/sellers/{seller_id}`.replace(`{${"seller_id"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetSimulationDtoFromJSON));
    }

    /**
     * Listar simulação de parcelas
     */
    async listSimulation(requestParameters: ListSimulationRequest): Promise<Array<GetSimulationDto>> {
        const response = await this.listSimulationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Listar simulação de parcelas com ou sem split mostrando taxas
     */
    async listSimulationsFeeRaw(requestParameters: ListSimulationsFeeRequest): Promise<runtime.ApiResponse<Array<GetSimulationDto>>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling listSimulationsFee.');
        }

        if (requestParameters.getSimulationPayloadDto === null || requestParameters.getSimulationPayloadDto === undefined) {
            throw new runtime.RequiredError('getSimulationPayloadDto','Required parameter requestParameters.getSimulationPayloadDto was null or undefined when calling listSimulationsFee.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/simulation/sellers/{seller_id}`.replace(`{${"seller_id"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetSimulationPayloadDtoToJSON(requestParameters.getSimulationPayloadDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetSimulationDtoFromJSON));
    }

    /**
     * Listar simulação de parcelas com ou sem split mostrando taxas
     */
    async listSimulationsFee(requestParameters: ListSimulationsFeeRequest): Promise<Array<GetSimulationDto>> {
        const response = await this.listSimulationsFeeRaw(requestParameters);
        return await response.value();
    }

}
