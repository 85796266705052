import { Flex } from '@chakra-ui/react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { GetOrdersStatusEnum } from '../../../clients';
import { CellContent, CellContentProps, HeaderTitle, HeaderTitleProps, SimpleTable } from '../../../containers/SimpleTable';
import DetailModal from './DetailModal';
import { statusText } from './StatusText';
import { usePaperRollOrder } from '../context/PaperRollOrderContext';

const HeaderTitleStyled: React.FC<HeaderTitleProps> = (props) => {
	return (
		<HeaderTitle {...props} color='black' justifyContent='flex-start' fontSize='sm' fontWeight='bold'>
			{props.children}
		</HeaderTitle>
	);
};

const CellContentStyled: React.FC<CellContentProps> = (props) => {
	return (
		<CellContent {...props} color='primary' justifyContent='flex-start' fontSize='sm' fontWeight='semibold'>
			{props.children}
		</CellContent>
	);
};

export default () => {
	const { orders, pagination, setPagination, totalPages, isLoading } = usePaperRollOrder();
	const history = useHistory();
	const columns = React.useMemo(() => {
		let cols: any = [];

		return cols.concat([
			{
				Header: ({ column }) => <HeaderTitleStyled column={column}>ID do Pedido</HeaderTitleStyled>,
				accessor: 'id',
				id: 'id',
				Cell: ({ cell: { value } }) => <CellContentStyled textAlign='center'>{value}</CellContentStyled>,
			},
			{
				Header: ({ column }) => <HeaderTitleStyled column={column}>Cartório</HeaderTitleStyled>,
				accessor: 'seller_name',
				Cell: ({ cell: { value } }) => <CellContentStyled>{value}</CellContentStyled>,
			},
			{
				Header: ({ column }) => <HeaderTitleStyled column={column}>Quantidade</HeaderTitleStyled>,
				accessor: 'requesting_amount',
				Cell: ({ cell: { value } }) => <CellContentStyled textAlign='center'>{value}</CellContentStyled>,
			},
			{
				Header: ({ column }) => <HeaderTitleStyled column={column}>Status</HeaderTitleStyled>,
				accessor: 'status',
				Cell: ({ cell: { value } }) => (
					<CellContentStyled>
						<Flex flexDirection='row' alignItems='center'>
							{statusText[value as GetOrdersStatusEnum].icon} {statusText[value as GetOrdersStatusEnum].text}
						</Flex>
					</CellContentStyled>
				),
			},
			{
				Header: ({ column }) => <HeaderTitleStyled column={column}>Solicitante</HeaderTitleStyled>,
				accessor: 'requesting_user_name',
				Cell: ({ cell: { value } }) => <CellContentStyled>{value}</CellContentStyled>,
			},
			{
				Header: ({ column }) => <HeaderTitleStyled column={column}></HeaderTitleStyled>,
				accessor: 'details',
				Cell: ({ cell: { row } }) => {
					return (
						<Flex>
							<DetailModal paperRollOrder={row.original} />
						</Flex>
					);
				},
			},
		]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history]);

	return (
		<SimpleTable
			columns={columns}
			data={orders}
			setPagination={setPagination}
			pagination={pagination}
			totalPages={totalPages}
			pl={2}
			pointerEvents={isLoading ? 'none' : 'auto'}
			opacity={isLoading ? 0.5 : 1}
			variant='unstyled'
			maxHeightTableWrapper='42vh'
		/>
	);
};
