import React from 'react';
import {
	SimpleGrid,
	FormControl,
	FormLabel,
	Input,
	GridItem
} from '@chakra-ui/react';

import FormInput from 'components/Form/FormInput';
import FormSelect from 'components/Form/FormSelect';
import FormErrorMessage from 'components/Form/FormErrorMessage';
import { type_account } from 'config/constants';

type Props = {
	errors: any;
};

const BankForm: React.FC<Props> = (props) => (
	<SimpleGrid
		columns={{base: 1, md: 6, xl: 12}}
		color='gray.700'
		gap={4}
	>
		<GridItem
			colStart={{base: 1}}
			colSpan={{base: 4, md: 2, xl: 4, '2xl': 3}}
			order={{base: 1}}
		>
			<FormControl id='bank_account.bank_code' isRequired>
				<FormLabel fontSize='md' fontWeight='bold'>
					Código do Banco
				</FormLabel>
				<FormInput as={Input} name='bank_account.bank_code' />
				<FormErrorMessage name='bank_account.bank_code' />
			</FormControl>
		</GridItem>

		<GridItem
			colStart={{base: 1, xl: 1}}
			colSpan={{base: 4, md: 2, xl: 4}}
			order={{base: 2, xl: 3}}
		>
			<FormControl id='bank_account.agency_number' isRequired>
				<FormLabel fontSize='md' fontWeight='bold'>
					Número da agência
				</FormLabel>
				<FormInput as={Input} name='bank_account.agency_number' />
				<FormErrorMessage name='bank_account.agency_number' />
			</FormControl>
		</GridItem>

		<GridItem
			colStart={{base: 1}}
			colSpan={{base: 4, md: 2, xl: 2}}
			order={{base: 3, xl: 4}}
		>
			<FormControl id='bank_account.agency_digit'>
				<FormLabel fontSize='md' fontWeight='bold'>
					Digito
				</FormLabel>
				<FormInput as={Input} name='bank_account.agency_digit' />
				<FormErrorMessage name='bank_account.agency_digit' />
			</FormControl>
		</GridItem>

		<GridItem
			colStart={{base: 1}}
			colSpan={{base: 4, md: 2, xl: 4}}
			order={{base: 4, xl: 5}}
		>
			<FormControl id='bank_account.account_number' isRequired>
				<FormLabel fontSize='md' fontWeight='bold'>
					Número da conta
				</FormLabel>
				<FormInput as={Input} name='bank_account.account_number' />
				<FormErrorMessage name='bank_account.account_number' />
			</FormControl>
		</GridItem>

		<GridItem
			colStart={{base: 1}}
			colSpan={{base: 4, md: 2, xl: 2}}
			order={{base: 5, xl: 6}}
		>
			<FormControl id='bank_account.account_digit'>
				<FormLabel fontSize='md' fontWeight='bold'>
					Digito
				</FormLabel>
				<FormInput as={Input} name='bank_account.account_digit' />
				<FormErrorMessage name='bank_account.account_digit' />
			</FormControl>
		</GridItem>

		<GridItem
			colStart={{base: 1}}
			colSpan={{base: 4, md: 2, xl: 4}}
			order={{base: 6, xl: 1}}
		>
			<FormControl id='bank_account.type_account' isRequired>
				<FormLabel fontSize='md' fontWeight='bold'>
					Tipo de conta
				</FormLabel>
				<FormSelect name='bank_account.type_account'>
					<option value='' />
					{type_account.map((type) => (
						<option value={type.value} key={type.value}>
							{type.text}
						</option>
					))}
				</FormSelect>
				<FormErrorMessage name='bank_account.type_account' />
			</FormControl>
		</GridItem>

		<GridItem
			colStart={{base: 1}}
			colSpan={{base: 4, md: 4, xl: 6}}
			order={{base: 7}}
		>
			<FormControl id='bank_account.pix_key'>
				<FormLabel fontSize='md' fontWeight='bold'>
					Chave Pix
				</FormLabel>
				<FormInput as={Input} name='bank_account.pix_key' />
				<FormErrorMessage name='bank_account.pix_key' />
			</FormControl>
		</GridItem>
	</SimpleGrid>
);

export default BankForm;