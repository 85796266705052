/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetFileExportRequestSellerDto,
    GetFileExportRequestSellerDtoFromJSON,
    GetFileExportRequestSellerDtoFromJSONTyped,
    GetFileExportRequestSellerDtoToJSON,
    GetFileExportRequestUserDto,
    GetFileExportRequestUserDtoFromJSON,
    GetFileExportRequestUserDtoFromJSONTyped,
    GetFileExportRequestUserDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetFileExportRequestDto
 */
export interface GetFileExportRequestDto {
    /**
     * 
     * @type {string}
     * @memberof GetFileExportRequestDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetFileExportRequestDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof GetFileExportRequestDto
     */
    status: GetFileExportRequestDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GetFileExportRequestDto
     */
    formatted_status: string;
    /**
     * 
     * @type {string}
     * @memberof GetFileExportRequestDto
     */
    type: GetFileExportRequestDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetFileExportRequestDto
     */
    formatted_type: string;
    /**
     * 
     * @type {string}
     * @memberof GetFileExportRequestDto
     */
    extension: GetFileExportRequestDtoExtensionEnum;
    /**
     * 
     * @type {string}
     * @memberof GetFileExportRequestDto
     */
    start_date: string;
    /**
     * 
     * @type {string}
     * @memberof GetFileExportRequestDto
     */
    end_date: string;
    /**
     * 
     * @type {GetFileExportRequestUserDto}
     * @memberof GetFileExportRequestDto
     */
    user: GetFileExportRequestUserDto;
    /**
     * 
     * @type {GetFileExportRequestSellerDto}
     * @memberof GetFileExportRequestDto
     */
    seller: GetFileExportRequestSellerDto;
    /**
     * 
     * @type {string}
     * @memberof GetFileExportRequestDto
     */
    filename: string;
    /**
     * 
     * @type {string}
     * @memberof GetFileExportRequestDto
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof GetFileExportRequestDto
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof GetFileExportRequestDto
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof GetFileExportRequestDto
     */
    updated_at: string;
}

/**
* @export
* @enum {string}
*/
export enum GetFileExportRequestDtoStatusEnum {
    Pending = 'pending',
    Failed = 'failed',
    Finished = 'finished'
}/**
* @export
* @enum {string}
*/
export enum GetFileExportRequestDtoTypeEnum {
    Sales = 'sales',
    SalesConcil = 'sales_concil',
    Payments = 'payments',
    Settlement = 'settlement',
    SettlementTransactions = 'settlement_transactions',
    SettlementsBatchTransactions = 'settlements_batch_transactions',
    Protocols = 'protocols',
    SalesHistory = 'sales_history',
    MonthlyAlerts = 'monthly_alerts',
    Sellers = 'sellers',
    SalesBillPayment = 'sales_bill_payment',
    AssignmentTransactionAlerts = 'assignment_transaction_alerts',
    ConsolidateCostSales = 'consolidate_cost_sales'
}/**
* @export
* @enum {string}
*/
export enum GetFileExportRequestDtoExtensionEnum {
    Xls = 'xls',
    Csv = 'csv',
    Json = 'json',
    Pdf = 'pdf'
}

export function GetFileExportRequestDtoFromJSON(json: any): GetFileExportRequestDto {
    return GetFileExportRequestDtoFromJSONTyped(json, false);
}

export function GetFileExportRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetFileExportRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'description': json['description'],
        'status': json['status'],
        'formatted_status': json['formatted_status'],
        'type': json['type'],
        'formatted_type': json['formatted_type'],
        'extension': json['extension'],
        'start_date': json['start_date'],
        'end_date': json['end_date'],
        'user': GetFileExportRequestUserDtoFromJSON(json['user']),
        'seller': GetFileExportRequestSellerDtoFromJSON(json['seller']),
        'filename': json['filename'],
        'key': json['key'],
        'url': json['url'],
        'created_at': json['created_at'],
        'updated_at': json['updated_at'],
    };
}

export function GetFileExportRequestDtoToJSON(value?: GetFileExportRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'description': value.description,
        'status': value.status,
        'formatted_status': value.formatted_status,
        'type': value.type,
        'formatted_type': value.formatted_type,
        'extension': value.extension,
        'start_date': value.start_date,
        'end_date': value.end_date,
        'user': GetFileExportRequestUserDtoToJSON(value.user),
        'seller': GetFileExportRequestSellerDtoToJSON(value.seller),
        'filename': value.filename,
        'key': value.key,
        'url': value.url,
        'created_at': value.created_at,
        'updated_at': value.updated_at,
    };
}


