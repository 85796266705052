import React from 'react';
import { Field } from 'formik';
import Select from 'react-select';
import { SelectProps } from '@chakra-ui/react';

type Option = {
  value: string;
  label: string;
}

type FormAutoCompleteProps = SelectProps & {
  options: Option[];
  onChange: (option: Option) => any;
};

const FormAutoComplete: React.FC<FormAutoCompleteProps> = (props: FormAutoCompleteProps) => {
  return (
    <Field {...props}>
      {({ form, field }) => {
        return (
          <Select
            {...props}
            isClearable={true}
            isSearchable={true}
            placeholder={props.placeholder}
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                borderColor: '#4f4f4f',
                borderRadius: '0.5rem',
                fontSize: '0.75rem'
              }),
            }}
            value={props.options.find((option) => option.value === props.value)}
            onBlur={()=> {form.validateField(field.name)}}
            options={props.options}
            onChange={
              (option: Option) => {
                props.onChange && props.onChange(option);

                form.setFieldValue(field.name, option?.value);
              }
            }
          />
        );
      }}
    </Field>
  );
};

export default FormAutoComplete;
