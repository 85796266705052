/* eslint-disable @typescript-eslint/no-explicit-any */
import { Flex, FormControl, Stack } from '@chakra-ui/react';
import React from 'react';

import { Formik } from 'formik';

import Loader from 'containers/Loader';

import FormLabel from 'components/Form/FormLabel';
import Title from 'components/Title';

import { CelcoinApi, GetThemeDto, ListCelcoinSimulationRequest, SimulationApi } from '../../../clients';
import FormDebouncedInput from '../../../components/Form/FormDebouncedInput';
import { getApiAuthConfig } from '../../../services/api.service';
import { maskMoney } from '../../../services/masks.service';

export interface BillSaleInitialValuesInterface {
	description: string;
	createPayer: boolean;
	payer: {
		name: string;
		document: string;
		email: string;
		phone: string;
		address: { zipcode: string; street: string; district: string; city: string; state: string; complement: string; number: string };
	};
	search: {
		name: string;
		document: string;
	};
}

interface Props {
	billSaleInitialValues: BillSaleInitialValuesInterface;
	isLoading: boolean;
	setIsLoading: any;
	barCode: string;
	setBarCode: any;
	isMobile?: boolean;
	theme?: GetThemeDto | null;
	amount: string;
	setAmount: any;
	setShowAmount: any;
	addToast: any;
	setCelcoinResponse: any;
	barCodeDisable: boolean;
	setBarCodeDisable: any;
	setRadioValue: any;
	setSimulator: any;
	setInstallmentsSimulation: any;
	currentSeller: any;
	setPaymentType: any;
	setDueDate: any;
}

const defaultProps = {
	isMobile: false,
	theme: null,
};

const BillSaleBarcode: React.FC<Props> = ({
	billSaleInitialValues,
	isLoading,
	isMobile,
	theme,
	barCode,
	setBarCode,
	setIsLoading,
	setAmount,
	setShowAmount,
	addToast,
	setCelcoinResponse,
	setBarCodeDisable,
	setRadioValue,
	setSimulator,
	setInstallmentsSimulation,
	currentSeller,
	setPaymentType,
	setDueDate,
}: Props): JSX.Element => {
	const apiConfig = getApiAuthConfig();
	const celcoinApi = new CelcoinApi(apiConfig);
	const simulationApi = new SimulationApi(apiConfig);

	const handleSubmit = async () => {
		return true;
	};

	async function handleCelcoinBillet(): Promise<string | undefined> {
		setIsLoading(true);

		const authorizeTransactionRequest = {
			barCode: {},
		} as any;

		if (barCode.length > 44) {
			authorizeTransactionRequest.barCode.digitable = barCode;
		} else {
			authorizeTransactionRequest.barCode.barCode = barCode;
		}

		authorizeTransactionRequest.barCode.type = 0;

		try {
			const response = await celcoinApi.authorize({
				authorizeTransactionDTO: {
					...authorizeTransactionRequest,
				},
			});

			const value = (response['original_value'] * 100).toString();
			const valueString = `R$ ${maskMoney(response['original_value'])}`;

			setBarCodeDisable(false);
			setCelcoinResponse({
				celcoinId: response['id'],
				documentPayer: response['document_payer'],
			});

			setDueDate(response['due_date']);
			setAmount(value);
			setShowAmount(valueString);

			if(value){
				setPaymentType('pos');
				await simulation(value);
				setIsLoading(false);
			}

			return value;
		} catch (error) {
			setBarCodeDisable(true);
			addToast('Boleto inválido ou vencido', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}

		return undefined;
	}

	async function simulation(value) {
		if (!value) {
			return;
		}

		const amountCents = Number(value);

		if (Number.isNaN(amountCents)) {
			setRadioValue('');
			setSimulator({ installment: '', totalAmount: 0, installmentAmount: 0, type: '', cet: 0 });
			setInstallmentsSimulation([]);
			return;
		}

		const SimulationRequest: ListCelcoinSimulationRequest = {
			sellerId: currentSeller?.id!,
			amountCents: Number(amountCents),
			isPos: true,
		};

		setSimulator({ installment: '', totalAmount: 0, installmentAmount: 0, type: '', cet: 0 });

		const response = await simulationApi.listCelcoinSimulation(SimulationRequest);

		setInstallmentsSimulation(
			response
				.filter((installment) => installment.type !== 'billet' 
					&& installment.type !== 'pix' 
					&& installment.type !== 'pix_pos' 
					&& installment.type !== 'debit')
				.sort((a, b) => b.type.localeCompare(a.type))
		);

		setIsLoading(false);
		setRadioValue(
			JSON.stringify({
				installment: '',
				installmentAmount: 0,
				totalAmount: 0,
				type: '',
			})
		);
	}

	return (
		<>
			<Formik initialValues={billSaleInitialValues} onSubmit={handleSubmit}>
				{({ handleSubmit, setFieldValue, resetForm, values, errors, isValid }) => {
					return (
						<form>
							<Loader isOpen={isLoading} />

							<Flex mb='4'>
								<Title fontSize={isMobile ? 'lg' : '2xl'}>Pagamento de contas</Title>
							</Flex>

							<Flex flexDirection='column' pb='4'>
								<Flex flexDir='column' p={isMobile ? 4 : 10} bgColor='white' rounded='md' boxShadow='sm' mt='2' w='100%'>
									<Stack spacing={3} color={theme?.text_color || 'darkGrey'}>
										<FormControl isRequired>
											<FormLabel fontSize='md' fontWeight='medium'>
												Código de barras
											</FormLabel>

											<FormDebouncedInput
												isDisabled={isLoading}
												name='barcode'
												value={barCode}
												placeholder='Ex.: 1111111.11111.111...'
												onChange={(event) => {
													setBarCode(event.target.value.trim());
												}}
												afterDebounce={async () => {
													await handleCelcoinBillet();
												}}
											/>
										</FormControl>
									</Stack>
									<Stack>
									</Stack>
								</Flex>
							</Flex>
						</form>
					);
				}}
			</Formik>
		</>
	);
};

BillSaleBarcode.defaultProps = defaultProps;

export default BillSaleBarcode;
