import React from 'react';
import { Th, Table, Tr, Td } from '@chakra-ui/react';
import { maskMoney } from '../../../services/masks.service';

function ResponsibleRow({ responsibleInfos }) {
	return (
		<Tr fontSize='sm'>
			<Td textAlign='center'>{responsibleInfos?.name}</Td>
			<Td textAlign='center'>R$ {maskMoney(responsibleInfos?.goal / 100)}</Td>
		</Tr>
	);
}

function ResponsiblesTable({ data }) {
	return (
		<Table>
			<Tr>
				<Th textAlign='center'>Nome</Th>
				<Th textAlign='center'>Meta</Th>
			</Tr>
			{data?.subRows.map((responsible) => (
				<ResponsibleRow responsibleInfos={responsible} />
			))}
		</Table>
	);
}

export default ResponsiblesTable;
