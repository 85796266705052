import React from 'react';

import { FormControl } from '@chakra-ui/react';

import AddressForm from './AddressForm';
import PayerForm from './PayerForm';
import { requiredFields } from './types/requiredFields.type';
import Text from 'components/Text';

type CreatePayerWrapperType = {
	setFieldValue: any;
	errors: any;
	setSelectedPayer(param: any): void;
	selectedPayer: any[];
	requiredFields: requiredFields;
};

const RegisterPayerWrapper: React.FC<CreatePayerWrapperType> = ({ setSelectedPayer, setFieldValue, errors, requiredFields }) => {
	return (
		<fieldset>
			<FormControl display='flex' flexDirection='column' alignItems='flex-start' justifyContent='center' mt='4'>
				<Text fontSize={`lg`} fontWeight={`semibold`}>
					Inserir Dados do Pagador
				</Text>
				<PayerForm
					setSelectedPayer={setSelectedPayer}
					onSearchResults={(data) => {
						setFieldValue('payer.address.street', data.address.street);
						setFieldValue('payer.address.district', data.address.district);
						setFieldValue('payer.address.city', data.address.city);
						setFieldValue('payer.address.zipcode', data.address.zipcode);
						setFieldValue('payer.address.number', data.address.number);
						setFieldValue('payer.address.state', data.address.state);
						setFieldValue('payer.address.complement', data.address.complement);
						setFieldValue('payer.name', data.name);
						setFieldValue('payer.document', data.document);
						setFieldValue('payer.email', data.email);
						setFieldValue('payer.phone', data.phone);
					}}
					errors={errors}
					requiredFields={requiredFields}
					columnsNumber={2}
				/>
				<Text fontSize={`lg`} fontWeight={`semibold`} mt='4'>
					Endereço
				</Text>
				<AddressForm
					onSearchResults={(data) => {
						setFieldValue('payer.address.street', data.logradouro);
						setFieldValue('payer.address.district', data.bairro);
						setFieldValue('payer.address.city', data.localidade);
						setFieldValue('payer.address.state', data.uf);
						setFieldValue('payer.address.complement', data.complemento);
					}}
					errors={errors}
					requiredFields={requiredFields}
				/>
			</FormControl>
		</fieldset>
	);
};

export default RegisterPayerWrapper;
