import { Center, Flex, FormControl, Stack, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';

import React from 'react';

import { useLocation, useParams, useHistory, Link } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { format } from 'date-fns';
import { Formik } from 'formik';

import MultiSelect from 'components/MultiSelect';

import {
	GetSellerDto,
	GetSettlementResponseDto,
	SettlementsBatchesApi,
	CreateExportSettlementBatchSalesListDtoExtensionEnum,
	SettlementBatchControllerGetSettlementBatchRequest,
	GetUserDtoTypeEnum,
	ListSettlementResponseDTO,
	SellersApi,
} from '../../clients';

import Button from '../../components/Button';
import Loader from '../../containers/Loader';

import PaymentInfo from './components/PaymentInfo';
import SellerInfo from './components/SellerInfo';
import SalesTable from './containers/SalesTable';

import { maskMoney } from 'services/masks.service';

import { getApiAuthConfig } from 'services/api.service';
import { useAuth } from 'contexts/AuthProvider';
import { useCurrentSeller } from 'contexts/SellerProvider';

import ExportFileSuccessModal from 'containers/ExportFileSuccessModal';
import Breadcrumb from 'components/Breadcrumb';
import Text from 'components/Text';

const PaymentDetails: React.FC = () => {
	const history = useHistory();
	const [isLoading, setIsLoading] = React.useState(false);
	const [exportIsLoading, setExportIsLoading] = React.useState<boolean>(false);
	const [openExportSuccessModal, setOpenExportSuccessModal] = React.useState<boolean>(false);
	const [pagination, setPagination] = React.useState({ currentPage: 1, limit: 100 });
	const [batchDetails, setBatchDetails] = React.useState<GetSettlementResponseDto>();
	const [seller, setSeller] = React.useState<Partial<GetSellerDto>>();
	const { addToast } = useToasts();
	const [totalPages, setTotalPages] = React.useState(1);
	const [filters, setFilters] = React.useState({});
	const [previousFilters, setPreviousFilters] = React.useState({});
	const location = useLocation();
	const firstUpdate = React.useRef(true);

	const initialValues = {
		settlement_status: [],
		formPayment: [],
	};
	const { id } = useParams();
	const { user } = useAuth();
	const { currentSeller } = useCurrentSeller();

	const apiConfig = getApiAuthConfig();
	const settlementApi = new SettlementsBatchesApi(apiConfig);
	const sellersApi = new SellersApi(apiConfig);

	React.useEffect(() => {
		if (firstUpdate.current) {
			firstUpdate.current = false;
			return;
		}

		if (batchDetails?.settlement_batch.seller.id !== currentSeller?.id) {
			history.goBack();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSeller]);

	async function setBatchSeller(batch: ListSettlementResponseDTO) {
		const batchSeller = await sellersApi.getSeller({ sellerId: batch.seller.id });

		if (batchSeller) {
			setSeller(batchSeller);
		} else {
			setSeller(user.seller);
		}
	}

	async function fetchGetSettlementBatch(params) {
		const { settlement_status, formPayment, ...parsedParams } = params;

		if(user.type === GetUserDtoTypeEnum.Seller) {			
			setSeller(user.seller);
		}
		
		try {
			setIsLoading(true);

			const requestParams: SettlementBatchControllerGetSettlementBatchRequest = {
				settlementBatchId: id,
				currentPage: pagination.currentPage,
				limit: pagination.limit,
				settlementStatus: settlement_status?.map((v) => v.value),
				formPayment: formPayment?.map((v) => v.value),
				startDate: location?.state?.date || format(new Date(), 'yyyy-MM-dd'),
				endDate: location?.state?.date || format(new Date(), 'yyyy-MM-dd'),
				...parsedParams,
			};

			const response = await settlementApi.settlementBatchControllerGetSettlementBatch(requestParams);

			setBatchDetails(response);
			setTotalPages(response.total_pages);

			if(user.type !== GetUserDtoTypeEnum.Seller) {
				setBatchSeller(response.settlement_batch);
			}
		} catch (error) {
			addToast('Erro ao buscar os dados', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	}

	function navigateToDetails(id: string) {
		history.push(`/admin/sales-history/${id}`, {route: history.location.pathname});
	}

	const handleExportReportFile = async (props) => {
		setExportIsLoading(true);

		try {
			const { settlement_status, type, formPayment, ...parsedProps } = props;

			await settlementApi.createSettlementBatchSalesExportRequest({
				createExportSettlementBatchSalesListDto: {
					settlement_status: settlement_status?.map((v) => v.value),
					form_payment: formPayment?.map((v) => v.value),
					extension: CreateExportSettlementBatchSalesListDtoExtensionEnum.Xls,
					seller_id: currentSeller?.id,
					...parsedProps,
				},
				settlementBatchId: id,
			});

			setOpenExportSuccessModal(true);
		} catch (e) {
			addToast('Ocorreu um erro no processamento', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setExportIsLoading(false);
		}
	};

	const handleFormSubmit = async (values) => {
		setFilters(values);
		fetchGetSettlementBatch(values);
	};

	function setLastFilters(): void {
		const { location } = history || {};
		const { state } = location || {};
		const { filters } = state || {};

		setPreviousFilters(filters);
	}

	React.useEffect(() => {
		setLastFilters();
		fetchGetSettlementBatch(filters);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pagination]);

	return (
		<Flex w='100%' flexDirection='column'>
			<Loader isOpen={isLoading} />

			<Flex w='100%' flexDirection='column'>
				<Breadcrumb>
					<BreadcrumbItem>
						<BreadcrumbLink fontSize='md'>Resumo</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem>
						<BreadcrumbLink fontSize='md'>Gestão Financeira</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem>
						<BreadcrumbLink
							fontSize='md'
							as={Link}
							to={{pathname: '/admin/payments-history', state: {previousFilters}}}
						>
							Histórico de liquidação
						</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink fontSize='md' fontWeight='semibold'>
							Detalhes da Liquidação
						</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>

				<Stack spacing={5} mt={5}>
					<ExportFileSuccessModal
						showCloseButton
						isOpen={openExportSuccessModal}
						onOkClick={() => {
							setOpenExportSuccessModal(false);
						}}
						onClose={() => {
							setOpenExportSuccessModal(false);
						}}
					/>

					<Flex direction='column'>
						{batchDetails?.settlement_batch ? <PaymentInfo {...batchDetails.settlement_batch} /> : null}

						{seller ? <SellerInfo {...seller} /> : null}

						<Flex direction='column' p={6} bgColor='white' rounded='md' boxShadow='md' mt={5}>
							<Formik enableReinitialize initialValues={initialValues} onSubmit={handleFormSubmit}>
								{({ handleSubmit, setFieldValue, values, resetForm }) => {
									return (
										<form onSubmit={handleSubmit}>
											<Stack direction={['column', 'row']} justify='space-between' align='center' mb={4}>
												<Text fontSize={['md', 'md', 'xl']} fontWeight='bold'>
													Transações do Lote de Liquidação
												</Text>

												<Button
													{...(batchDetails?.sales?.length === 0 && { disabled: true })}
													bgColor='primary'
													isDisabled={exportIsLoading}
													isLoading={exportIsLoading}
													onClick={() => handleExportReportFile(values)}
													width={['100%', 'auto']}
												>
													Exportar para Excel
												</Button>
											</Stack>

											<Stack direction={['column', 'column', 'row']} justifyContent='space-between'>
												<Stack direction={['column', 'column', 'row']} spacing={4}>
													<Center pt={0} minWidth='150px' mr='2'>
														<FormControl>
															<MultiSelect
																id='settlement-status'
																placeholder='Status'
																options={[
																	{ value: 'pending', label: 'Pendente' },
																	{ value: 'paid', label: 'Pago' },
																	{ value: 'sent', label: 'Pagamento Enviado' },
																	{ value: 'canceled', label: 'Cancelado' },
																	{ value: 'pending_cancel', label: 'Cancelamento Pendente' },
																]}
																value={values.settlement_status}
																onChange={(value) => {
																	setFieldValue('settlement_status', value);
																	handleSubmit();
																}}
															/>
														</FormControl>
													</Center>

													<Center pt={0} minWidth='200px' mr='2'>
														<FormControl>
															<MultiSelect
																id='form-payment'
																placeholder='Forma de Liquidação'
																options={[
																	{ value: 'credit', label: 'Crédito' },
																	{ value: 'debit', label: 'Débito' },
																	{ value: 'billet', label: 'Boleto' },
																	{ value: 'pix', label: 'Pix' },
																	{ value: 'pix_pos', label: 'Pix Pos' },
																]}
																value={values.formPayment}
																onChange={(value) => {
																	setFieldValue('formPayment', value);
																	handleSubmit();
																}}
															/>
														</FormControl>
													</Center>

													<Center>
														{(values.formPayment.length > 0 || values.settlement_status.length > 0) && (
															<Button
																onClick={() => {
																	resetForm();
																	handleSubmit();
																}}
																paddingInline='10px'
																_focus={{ outline: 'none' }}
																color='secondary'
																bgColor='transparent'
															>
																Limpar Filtros
															</Button>
														)}
													</Center>
												</Stack>

												{batchDetails?.sales && (
													<Flex fontSize={['md', 'md', 'lg']} justify='center'>
														<Text fontWeight='bold' mr='4' textAlign={['center', 'left']}>
															Total Bruto R${maskMoney(batchDetails.total_original_amount / 100 || 0)}
														</Text>

														<Text fontWeight='bold' textAlign={['center', 'left']}>
															Total Liquido R${maskMoney(batchDetails.total_amount / 100 || 0)}
														</Text>
													</Flex>
												)}
											</Stack>
										</form>
									);
								}}
							</Formik>

							<Flex overflowX='auto' overflowY='hidden' direction='column'>
								{batchDetails?.sales ? (
									<SalesTable
										onRowClick={(row) => navigateToDetails(row.values.id)}
										data={batchDetails}
										setPagination={setPagination}
										pagination={pagination}
										totalPages={totalPages}
									/>
								) : null}
							</Flex>
						</Flex>
					</Flex>
				</Stack>
			</Flex>
		</Flex>
	);
};

export default PaymentDetails;
