import React from 'react';

import { HStack, TableProps, Text } from '@chakra-ui/react';

import { HeaderTitle, CellContent, SimpleTable, CellContentProps, HeaderTitleProps } from '../../../../containers/SimpleTable';
import { paymentMethodEnum, saleStatus } from 'services/enums.service';
import { AiFillCheckCircle, AiFillClockCircle, AiFillCloseCircle } from 'react-icons/ai';
import { maskDate, maskMoney } from 'services/masks.service';

const iconStatusLiteral = {
	succeeded: <AiFillCheckCircle color='#47FF62' size={16} />,
	pending: <AiFillClockCircle color='#FFA547' size={16} />,
	pending_cancel: <AiFillClockCircle color='#FFA547' size={16} />,
	canceled: <AiFillCloseCircle color='tomato' size={16} />,
	failed: <AiFillCloseCircle color='tomato' size={16} />,
	expired: <AiFillCloseCircle color='tomato' size={16} />,
};

interface IPaymentTable extends TableProps {
	data: string[];
	onRowClick?: (row: any) => void;
	showSellerColumn: boolean;
}

const HeaderTitleStyled: React.FC<HeaderTitleProps> = (props) => {
	return (
		<HeaderTitle {...props} justifyContent='flex-start' fontSize='sm' fontWeight='bold'>
			{props.children}
		</HeaderTitle>
	);
};

const CellContentStyled: React.FC<CellContentProps> = (props) => {
	return (
		<CellContent {...props} color='darkGrey' justifyContent='flex-start' fontSize='sm' fontWeight='semibold'>
			{props.children}
		</CellContent>
	);
};

export default (props: IPaymentTable) => {
	const { data, showSellerColumn, ...rest } = props;

	const sales = data.map((response) => {
		const sale = JSON.parse(JSON.stringify({ response }));

		const date = new Date(sale.response?.created_at)?.toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })?.split(' ');

		return {
			description: sale?.response?.description,
			payment_method: paymentMethodEnum[sale?.response?.type],
			type: sale?.response?.type,
			amount: sale?.response?.amount / 100,
			payer: sale?.response?.payer_name,
			payment_type: sale?.response?.payment_type_description,
			status: sale?.response?.status,
			created_at:
				date && `${maskDate(date[0].split('-').reverse().join('-'))} às ${date[1] ? date[1]?.replace('Z', '').substr(0, 5) : '-:-'}`,
			id: sale?.response?.id,
			seller_name: sale?.response?.seller_name,
		};
	});

	const columns = React.useMemo(() => {
		let cols: any = [];

		if (showSellerColumn) {
			cols.push({
				Header: ({ column }) => <HeaderTitleStyled column={column}>Estabelecimento</HeaderTitleStyled>,
				accessor: 'seller_name',
				Cell: ({ cell: { value } }) => {
					return <CellContentStyled>{value}</CellContentStyled>;
				},
			});
		}
		return cols.concat([
			{
				Header: ({ column }) => <HeaderTitleStyled column={column}>Data da Venda</HeaderTitleStyled>,
				accessor: 'created_at',
				Cell: ({ cell: { value } }) => <CellContentStyled>{value}</CellContentStyled>,
			},
			{
				Header: ({ column }) => <HeaderTitleStyled column={column}>Status</HeaderTitleStyled>,
				accessor: 'status',
				Cell: ({ cell: { value } }) => (
					<CellContentStyled onClick={() => {}}>
						<HStack>
							<Text>{iconStatusLiteral[value]}</Text>
							<Text>{saleStatus[value] ?? 'N/A'}</Text>
						</HStack>
					</CellContentStyled>
				),
			},
			{
				Header: ({ column }) => <HeaderTitleStyled column={column}>Nº do pedido</HeaderTitleStyled>,
				accessor: 'description',
				Cell: ({ cell: { value } }) => <CellContentStyled>{value}</CellContentStyled>,
			},
			{
				Header: ({ column }) => <HeaderTitleStyled column={column}>Forma de Pagamento</HeaderTitleStyled>,
				accessor: 'payment_type',
				Cell: ({ cell: { value } }) => <CellContentStyled>{value}</CellContentStyled>,
			},
			{
				Header: ({ column }) => <HeaderTitleStyled column={column}>Meio de Pagamento</HeaderTitleStyled>,
				accessor: 'payment_method',
				Cell: ({ cell: { value } }) => <CellContentStyled>{value || 'Pendente'}</CellContentStyled>,
			},
			{
				Header: ({ column }) => <HeaderTitleStyled column={column}>Valor Líquido</HeaderTitleStyled>,
				accessor: 'amount',
				Cell: ({ cell: { value } }) => <CellContentStyled>R$ {maskMoney(value)}</CellContentStyled>,
			},
			{
				Header: ({ column }) => <HeaderTitleStyled column={column}>Pagador</HeaderTitleStyled>,
				accessor: 'payer',
				Cell: ({ cell: { value } }) => <CellContentStyled>{value}</CellContentStyled>,
			},
			{
				Header: ({ column }) => (
					<HeaderTitleStyled display='none' column={column}>
						ID
					</HeaderTitleStyled>
				),
				accessor: 'id',
				Cell: ({ cell: { value } }) => <CellContentStyled display='none'>{value}</CellContentStyled>,
			},
		]);
	},
	// eslint-disable-next-line react-hooks/exhaustive-deps
	[]);

	return <SimpleTable columns={columns} data={sales} {...rest} minW='50%' variant='unstyled' />;
};
