import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { useToasts } from 'react-toast-notifications';

import FileUploadInput from 'containers/FileUploadInput';
import Attachment from './Attachment';

type props = {
  attachments: any[];
  changeAttachments: (file: any[]) => void;
};

const Attachments: React.FC<props> = ({ attachments, changeAttachments }) => {
  const { addToast } = useToasts();

  const maxSize = 10000000;
  const allowedExtensions = [
    'pdf',
    'doc',
    'docx',
    'xls',
    'xlsx',
    'csv',
    'png',
    'jpg',
    'jpeg',
    'zip',
    'rar',
  ];

  const uploadFile = async (files) => {
    const processedFiles: any[] = [];
    let totalSize = attachments.reduce((total, {size}) => {
      return total + (size || 0)
    }, 0);

    for(const file of files) {
      const { name, size } = file;

      const processedFile = await getBase64(file);

      const extension = getExtension(name);

      const isValidFile = verifyFile(extension, size, totalSize);

      if (isValidFile) {
        processedFiles.push({
          name,
          extension,
          size,
          data: processedFile
        });

        totalSize = totalSize + size;
      }
    }

    changeAttachments([...attachments, ...processedFiles]);
  };

  const getExtension = (filename: string): string => {
    const re = /(?:\.([^.]+))?$/;

    const ext = re.exec(filename)?.[1];

    return ext || '';
  }

  const getBase64 = async (file) => {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (reader.result !== null) {
          resolve(reader.result);
        } else {
          resolve(null);
        }
      };
    });
  }

  const verifyFile = (extension: string, size: number, totalSize: number): boolean => {
    if (size + totalSize > maxSize) {
      addToast('Anexo excede limite máximo de 10MB', {
        appearance: 'error',
        autoDismiss: true,
      });
      return false;
    }

    if (!allowedExtensions.includes(extension)) {
      addToast('Extensão não aceita', {
        appearance: 'error',
        autoDismiss: true,
      });
      return false;
    }

    return true;
  }

  const removeFile = (index: number) => {
    const updatedAttachments = [...attachments];
    updatedAttachments.splice(index, 1);

    changeAttachments(updatedAttachments);
  }


  return (
    <>
      <Flex
        direction='row'
        borderWidth={0.2}
        borderColor='darkGrey'
        rounded='md'
        minHeight={100}
        mb={5}
        paddingY={5}
        overflow={'auto'}
      >
        {
          attachments.length ? 
            attachments.map((attachment, index) => {
              return (
                <Attachment
                  name={attachment.name}
                  extension={attachment.type}
                  data={attachment.data}
                  url={attachment.url}
                  remove={() => removeFile(index)}
                />
              )
            })
         : (
            <Text
              fontSize='md'
              minW={'100%'}
              textAlign={'center'}
              paddingTop={5}
            >
              Nenhum anexo incluído
            </Text>
          )
        }
      </Flex>
      <Flex direction='column'>
        <Flex alignSelf='center'>
          <FileUploadInput
            label='Importar anexos'
            inputProps={{
              multiple: true,
              onChange: (e) => {
                if (e.target.files?.length) {
                  uploadFile(e.target.files);
                }
              },
            }}/>
        </Flex>
        <Text fontSize='sm' textAlign='center' maxWidth='30%' alignSelf='center'>
          Tamanho máximo: 10MB
        </Text>
        <Text fontSize='sm' textAlign='center' maxWidth='30%' alignSelf='center'>
          Formatos aceitos:
        </Text>
        <Text fontSize='sm' textAlign='center' maxWidth='30%' wordBreak='break-word' alignSelf='center'>
          {allowedExtensions.map((extension)=> `.${extension}, `)}
        </Text>
      </Flex>
    </>
  );
};

export default Attachments;


