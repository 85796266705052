/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SellerEmail
 */
export interface SellerEmail {
    /**
     * 
     * @type {string}
     * @memberof SellerEmail
     */
    key: SellerEmailKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof SellerEmail
     */
    custom_block: string;
    /**
     * 
     * @type {string}
     * @memberof SellerEmail
     */
    custom_title: string;
}

/**
* @export
* @enum {string}
*/
export enum SellerEmailKeyEnum {
    PaymentLink = 'payment_link',
    ReceiptEmail = 'receipt_email',
    ProtocolsGeneration = 'protocols_generation',
    CancelationEmail = 'cancelation_email',
    BilletEmail = 'billet_email'
}

export function SellerEmailFromJSON(json: any): SellerEmail {
    return SellerEmailFromJSONTyped(json, false);
}

export function SellerEmailFromJSONTyped(json: any, ignoreDiscriminator: boolean): SellerEmail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'custom_block': json['custom_block'],
        'custom_title': json['custom_title'],
    };
}

export function SellerEmailToJSON(value?: SellerEmail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'custom_block': value.custom_block,
        'custom_title': value.custom_title,
    };
}


