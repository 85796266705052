import React from 'react';

import { SimpleGrid, FormControl, FormLabel, Input, useBreakpointValue } from '@chakra-ui/react';

import FormZipcodeInput from 'components/Form/FormZipcodeInput';
import FormInput from 'components/Form/FormInput';
import FormSelect from 'components/Form/FormSelect';
import FormErrorMessage from 'components/Form/FormErrorMessage';

import getzipcode from 'services/getzipcode.service';

import { states } from 'config/constants';
import { requiredFields } from './types/requiredFields.type';
import { useTheme } from 'contexts/ThemeProvider';

type addressFormProps = {
	onSearchResults(obj: any): void;
	errors: any;
	requiredFields: requiredFields;
};

const AddressForm: React.FC<addressFormProps> = (props: addressFormProps) => {
	const isMobile = useBreakpointValue({ base: true, lg: false });
	const { theme } = useTheme();

	return (
		<SimpleGrid columns={isMobile ? 1 : 2} spacing='4' w='100%' mt='2'>
			<FormControl color={theme?.text_color || `darkGrey`} id='payer.address.zipcode' isRequired={props.requiredFields.address.zipcode}>
				<FormLabel color={theme?.text_color || `darkGrey`} fontSize={`md`} fontWeight={`medium`}>
					CEP
				</FormLabel>
				<FormZipcodeInput name='payer.address.zipcode' performSearch={getzipcode} onSearchResults={props.onSearchResults} />
				<FormErrorMessage name='payer.address.zipcode' />
			</FormControl>
			<FormControl color={theme?.text_color || `darkGrey`} id='payer.address.street' isRequired={props.requiredFields.address.street}>
				<FormLabel color={theme?.text_color || `darkGrey`} fontSize={`md`} fontWeight={`medium`}>
					Logradouro
				</FormLabel>
				<FormInput as={Input} name='payer.address.street' />
				<FormErrorMessage name='payer.address.street' />
			</FormControl>
			<FormControl color={theme?.text_color || `darkGrey`} id='payer.address.number' isRequired={props.requiredFields.address.number}>
				<FormLabel color={theme?.text_color || `darkGrey`} fontSize={`md`} fontWeight={`medium`}>
					Número
				</FormLabel>
				<FormInput as={Input} name='payer.address.number' />
				<FormErrorMessage name='payer.address.number' />
			</FormControl>
			<FormControl color={theme?.text_color || `darkGrey`} id='payer.address.district' isRequired={props.requiredFields.address.district}>
				<FormLabel color={theme?.text_color || `darkGrey`} fontSize={`md`} fontWeight={`medium`}>
					Bairro
				</FormLabel>
				<FormInput as={Input} name='payer.address.district' />
				<FormErrorMessage name='payer.address.district' />
			</FormControl>
			<FormControl color={theme?.text_color || `darkGrey`} id='payer.address.complement'>
				<FormLabel color={theme?.text_color || `darkGrey`} fontSize={`md`} fontWeight={`medium`}>
					Complemento
				</FormLabel>
				<FormInput as={Input} name='payer.address.complement' />
				<FormErrorMessage name='payer.address.complement' />
			</FormControl>
			<FormControl color={theme?.text_color || `darkGrey`} id='payer.address.city' isRequired={props.requiredFields.address.city}>
				<FormLabel color={theme?.text_color || `darkGrey`} fontSize={`md`} fontWeight={`medium`}>
					Cidade
				</FormLabel>
				<FormInput as={Input} name='payer.address.city' />
				<FormErrorMessage name='payer.address.city' />
			</FormControl>
			<FormControl color={theme?.text_color || `darkGrey`} id='payer.address.state' isRequired={props.requiredFields.address.state}>
				<FormLabel color={theme?.text_color || `darkGrey`} fontSize={`md`} fontWeight={`medium`}>
					UF
				</FormLabel>
				<FormSelect fontSize={`md`} name='payer.address.state'>
					<option value='' />
					{states.map((state) => (
						<option value={state.value} key={state.value}>
							{state.text}
						</option>
					))}
				</FormSelect>
				<FormErrorMessage name='payer.address.state' />
			</FormControl>
		</SimpleGrid>
	);
};

export default AddressForm;
