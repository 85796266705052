/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetAddressDto,
    GetAddressDtoFromJSON,
    GetAddressDtoFromJSONTyped,
    GetAddressDtoToJSON,
    SellerCheckouts,
    SellerCheckoutsFromJSON,
    SellerCheckoutsFromJSONTyped,
    SellerCheckoutsToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetPaymentLinkSellerDto
 */
export interface GetPaymentLinkSellerDto {
    /**
     * 
     * @type {string}
     * @memberof GetPaymentLinkSellerDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentLinkSellerDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentLinkSellerDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentLinkSellerDto
     */
    document: string;
    /**
     * 
     * @type {GetAddressDto}
     * @memberof GetPaymentLinkSellerDto
     */
    address: GetAddressDto;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentLinkSellerDto
     */
    phone: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetPaymentLinkSellerDto
     */
    no_payment_methods?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof GetPaymentLinkSellerDto
     */
    card_payer_check?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetPaymentLinkSellerDto
     */
    card_payer_check_min_value?: number;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentLinkSellerDto
     */
    extract_name?: string;
    /**
     * 
     * @type {SellerCheckouts}
     * @memberof GetPaymentLinkSellerDto
     */
    custom_checkout?: SellerCheckouts;
    /**
     * 
     * @type {boolean}
     * @memberof GetPaymentLinkSellerDto
     */
    custom_payment_link_checkout?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetPaymentLinkSellerDto
     */
    form_link_extended?: boolean;
}

export function GetPaymentLinkSellerDtoFromJSON(json: any): GetPaymentLinkSellerDto {
    return GetPaymentLinkSellerDtoFromJSONTyped(json, false);
}

export function GetPaymentLinkSellerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPaymentLinkSellerDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
        'document': json['document'],
        'address': GetAddressDtoFromJSON(json['address']),
        'phone': json['phone'],
        'no_payment_methods': !exists(json, 'no_payment_methods') ? undefined : json['no_payment_methods'],
        'card_payer_check': !exists(json, 'card_payer_check') ? undefined : json['card_payer_check'],
        'card_payer_check_min_value': !exists(json, 'card_payer_check_min_value') ? undefined : json['card_payer_check_min_value'],
        'extract_name': !exists(json, 'extract_name') ? undefined : json['extract_name'],
        'custom_checkout': !exists(json, 'custom_checkout') ? undefined : SellerCheckoutsFromJSON(json['custom_checkout']),
        'custom_payment_link_checkout': !exists(json, 'custom_payment_link_checkout') ? undefined : json['custom_payment_link_checkout'],
        'form_link_extended': !exists(json, 'form_link_extended') ? undefined : json['form_link_extended'],
    };
}

export function GetPaymentLinkSellerDtoToJSON(value?: GetPaymentLinkSellerDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'document': value.document,
        'address': GetAddressDtoToJSON(value.address),
        'phone': value.phone,
        'no_payment_methods': value.no_payment_methods,
        'card_payer_check': value.card_payer_check,
        'card_payer_check_min_value': value.card_payer_check_min_value,
        'extract_name': value.extract_name,
        'custom_checkout': SellerCheckoutsToJSON(value.custom_checkout),
        'custom_payment_link_checkout': value.custom_payment_link_checkout,
        'form_link_extended': value.form_link_extended,
    };
}


