import React from 'react';

import { Flex, Box, BreadcrumbItem, BreadcrumbLink, SimpleGrid, useBreakpointValue } from '@chakra-ui/react';
import { useToasts } from 'react-toast-notifications';

import BackofficeAlertContainer from 'containers/BackofficeAlertContainer';

import { Configuration, GetSellerDto, SellersApi } from 'clients';
import { maskCpfOrCnpj, maskZipcode } from 'services/masks.service';
import { useAuth } from '../../contexts/AuthProvider';
import { useCurrentSeller } from '../../contexts/SellerProvider';

import Loader from 'containers/Loader';
import Breadcrumb from 'components/Breadcrumb';
import Title from 'components/Title';
import Text from 'components/Text';

const MyData: React.FC = () => {
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const isMobile = useBreakpointValue({ base: true, lg: false });

	const { addToast } = useToasts();
	const { user } = useAuth();
	const { currentSeller } = useCurrentSeller();

	const [seller, setSeller] = React.useState<GetSellerDto>();

	async function fetchSeller() {
		setIsLoading(true);

		try {
			const apiConfig = new Configuration({ basePath: process.env.REACT_APP_BASE_URL, accessToken: `bearer ${user.id_token}` });

			const sellerApi = new SellersApi(apiConfig);
			const response = await sellerApi.getSeller({ sellerId: currentSeller?.id! });

			setSeller(response);
		} catch (error) {
			addToast('Seller inválidos', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	}

	React.useEffect(() => {
		if (!currentSeller) {
			return;
		}
		fetchSeller();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSeller]);

	if (!currentSeller) {
		return <BackofficeAlertContainer />;
	}
	return (
		<>
			<Loader isOpen={isLoading} />
			<Flex pb='8'>
				<Breadcrumb>
					<BreadcrumbItem>
						<BreadcrumbLink href='#' fontSize='md'>
							Resumo
						</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem>
						<BreadcrumbLink href='#' fontSize='md'>
							Minha Conta
						</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink href='#' fontSize='md' fontWeight={`extrabold`}>
							Meus Dados
						</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>
			</Flex>
			<Flex mb='4'>
				<Title fontSize={`2xl`}>MEUS DADOS</Title>
			</Flex>

			<Flex mt='8'>
				<Title fontSize={`lg`}>Serventia</Title>
			</Flex>

			<Flex flexDir={`column`} p={10} bgColor='white' rounded={`md`} boxShadow={`sm`} mt='2' w='100%'>
				<SimpleGrid columns={isMobile ? 1 : 2} spacing='4' w='100%' mt='2'>
					<Box>
						<Text fontSize={`md`} fontWeight={`semibold`}>
							Razão social
						</Text>
						<Text fontSize={`md`} fontWeight={`medium`}>
							{seller?.name || ''}
						</Text>
					</Box>
					<Box>
						<Text fontSize={`md`} fontWeight={`semibold`}>
							Nome fantasia
						</Text>
						<Text fontSize={`md`} fontWeight={`medium`}>
							{seller?.description || ''}
						</Text>
					</Box>
					<Box>
						<Text fontSize={`md`} fontWeight={`semibold`}>
							Chave da Plataforma de Cobrança
						</Text>
						<Text fontSize={`md`} fontWeight={`medium`}>
							{seller?.code || ''}
						</Text>
					</Box>
					<Box>
						<Text fontSize={`md`} fontWeight={`semibold`}>
							Id da Serventia
						</Text>
						<Text fontSize={`md`} fontWeight={`medium`}>
							{seller?.id || ''}
						</Text>
					</Box>
				</SimpleGrid>
			</Flex>

			<Flex mt='8'>
				<Title>Endereço</Title>
			</Flex>

			<Flex flexDir={`column`} p={10} bgColor='white' rounded={`md`} boxShadow={`sm`} mt='2' w='100%'>
				<SimpleGrid columns={isMobile ? 2 : 3}  spacing='4' w='100%' mt='2'>
					<Box>
						<Text fontSize={`md`} fontWeight={`semibold`}>
							CEP
						</Text>
						<Text fontSize={`md`} fontWeight={`medium`}>
							{seller?.address?.zipcode ? maskZipcode(seller.address.zipcode) : '-'}
						</Text>
					</Box>
					<Box>
						<Text fontSize={`md`} fontWeight={`semibold`}>
							Rua
						</Text>
						<Text fontSize={`md`} fontWeight={`medium`}>
							{seller?.address.street || '-'}
						</Text>
					</Box>
					<Box>
						<Text fontSize={`md`} fontWeight={`semibold`}>
							Número
						</Text>
						<Text fontSize={`md`} fontWeight={`medium`}>
							{seller?.address.number || '-'}
						</Text>
					</Box>
					<Box>
						<Text fontSize={`md`} fontWeight={`semibold`}>
							Complemento
						</Text>
						<Text fontSize={`md`} fontWeight={`medium`}>
							{seller?.address.complement || '-'}
						</Text>
					</Box>
					<Box>
						<Text fontSize={`md`} fontWeight={`semibold`}>
							Bairro
						</Text>
						<Text fontSize={`md`} fontWeight={`medium`}>
							{seller?.address.district || '-'}
						</Text>
					</Box>
					<Box>
						<Text fontSize={`md`} fontWeight={`semibold`}>
							Cidade
						</Text>
						<Text fontSize={`md`} fontWeight={`medium`}>
							{seller?.address.city || '-'}
						</Text>
					</Box>
					<Box>
						<Text fontSize={`md`} fontWeight={`semibold`}>
							Estado
						</Text>
						<Text fontSize={`md`} fontWeight={`medium`}>
							{seller?.address.state || '-'}
						</Text>
					</Box>
				</SimpleGrid>
			</Flex>

			<Flex mt='8'>
				<Title>Método de recebimento</Title>
			</Flex>

			<Flex flexDir={`column`} p={10} bgColor='white' rounded={`md`} boxShadow={`sm`} mt='2' w='100%'>
				<SimpleGrid columns={isMobile ? 2 : 3} spacing='4' w='100%' mt='2'>
					<Box>
						<Text fontSize={`md`} fontWeight={`semibold`}>
							CPF/CNPJ
						</Text>
						<Text fontSize={`md`} fontWeight={`medium`}>
							{maskCpfOrCnpj(seller?.document) || '-'}
						</Text>
					</Box>
					<Box>
						<Text fontSize={`md`} fontWeight={`semibold`}>
							Banco
						</Text>
						<Text fontSize={`md`} fontWeight={`medium`}>
							{seller?.bank_account.bank_code || '-'}
						</Text>
					</Box>
					<Box>
						<Text fontSize={`md`} fontWeight={`semibold`}>
							Agência
						</Text>
						<Text fontSize={`md`} fontWeight={`medium`}>
							{seller?.bank_account.agency_number || '-'}
							{seller?.bank_account.agency_digit !== undefined ? '-' + seller?.bank_account.agency_digit : ''}
						</Text>
					</Box>
					<Box>
						<Text fontSize={`md`} fontWeight={`semibold`}>
							Conta
						</Text>
						<Text fontSize={`md`} fontWeight={`medium`}>
							{seller?.bank_account.account_number || '-'}
							{seller?.bank_account.account_digit !== undefined ? '-' + seller?.bank_account.account_digit : ''}
						</Text>
					</Box>
					<Box>
						<Text fontSize={`md`} fontWeight={`semibold`}>
							Chave PIX
						</Text>
						<Text fontSize={`md`} fontWeight={`medium`}>
							{seller?.bank_account.pix_key || '-'}
						</Text>
					</Box>
				</SimpleGrid>
			</Flex>
		</>
	);
};

export default MyData;
