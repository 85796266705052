import { Checkbox, Flex, FlexProps, Text } from '@chakra-ui/react';
import React from 'react';
import "../style.css"

type CheckboxProps = {
	customMessage: string;
	message?: string;
	secondMessage?: string;
	isChecked?: boolean;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

type Props = CheckboxProps & FlexProps;

const ConfirmationBox: React.FC<Props> = (props: Props) => {
	const { customMessage, isChecked, onChange, message,secondMessage, ...rest } = props;

	return (
		<Flex {...rest} direction='column' alignItems={'center'} justifyContent='space-between' px='8' py='4' my='4' bgColor='#FFEEE7' rounded='md' borderWidth={1} borderColor='#F6AD55'>
			<Flex justifyContent='flex-start' direction={'column'}>
				<Text className="htmlTags" textAlign='justify' pb={'2'} fontSize='2sm' lineHeight='5' mr='5' dangerouslySetInnerHTML={{__html: customMessage}}>
				</Text>
				<Text textAlign='justify' pb={'2'} fontSize='2sm' lineHeight='5' mr='5'>
					{message}
				</Text>
				<Text textAlign='justify' fontSize='2sm' lineHeight='5' mr='5' my='5'>
					{secondMessage}
				</Text>
			</Flex>
			<Checkbox mt='3' spacing='3' borderColor='#F6AD55' onChange={onChange} isChecked={isChecked} size='lg' colorScheme='orange' className='confirmation-box__checkbox'>
				<Text fontSize='2sm'>Estou ciente</Text>
			</Checkbox>
		</Flex>
	);
};

export default ConfirmationBox;
