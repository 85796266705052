import React from 'react';

import { HStack, Text } from '@chakra-ui/react';
import { AiFillCheckCircle, AiFillCloseCircle, AiFillClockCircle } from 'react-icons/ai';

import { HeaderTitle, CellContent, SimpleTable } from '../../../containers/SimpleTable';
import { formatWithoutTimezone } from '../../../services/date.service';
import { protocolStatusEnum, protocolHistoryTypeEnum } from '../../../services/enums.service';

const iconStatusLiteral = {
	paid: <AiFillCheckCircle color='#47FF62' size={16} />,
	pending: <AiFillClockCircle color='#FFA547' size={16} />,
	canceled: <AiFillCloseCircle color='tomato' size={16} />,
	alert: <AiFillCloseCircle color='tomato' size={16} />,
	overdued: <AiFillCloseCircle color='tomato' size={16} />,
};

export default ({ data }) => {
	const columns = React.useMemo(
		() => [
			{
				Header: ({ column }) => <HeaderTitle column={column}>Tipo</HeaderTitle>,
				accessor: 'type',
				Cell: ({ cell: { value } }) => (
					<CellContent color={`darkGrey`}>
						{protocolHistoryTypeEnum[value]}
					</CellContent>
				),
			},
			{
				Header: ({ column }) => <HeaderTitle column={column}>Antes</HeaderTitle>,
				accessor: 'before',
				Cell: ({ cell: { row } }) => (
					<CellContent color={`darkGrey`}>
						{row.original.type === 'due_date' ? (
							formatWithoutTimezone(row.original.before, 'dd/MM/yyyy HH:mm:ss')
						) : (
							<HStack>
								<Text color={`darkGrey`}>{iconStatusLiteral[row.original.before]}</Text>
								<Text color={`darkGrey`}>{protocolStatusEnum[row.original.before]}</Text>
							</HStack>
						)}
					</CellContent>
				),
			},
			{
				Header: ({ column }) => <HeaderTitle column={column}>Depois</HeaderTitle>,
				accessor: 'after',
				Cell: ({ cell: { row } }) => (
					<CellContent color={`darkGrey`}>
						{row.original.type === 'due_date' ? (
							formatWithoutTimezone(row.original.after, 'dd/MM/yyyy HH:mm:ss')
						) : (
							<HStack>
								<Text color={`darkGrey`}>{iconStatusLiteral[row.original.after]}</Text>
								<Text color={`darkGrey`}>{protocolStatusEnum[row.original.after]}</Text>
							</HStack>
						)}
					</CellContent>
				),
			},
			{
				Header: ({ column }) => <HeaderTitle column={column}>Data de Alteração</HeaderTitle>,
				accessor: 'created_at',
				Cell: ({ cell: { value } }) => <CellContent color={`darkGrey`}>{formatWithoutTimezone(value, 'dd/MM/yyyy HH:mm:ss')}</CellContent>,
			},
			{
				Header: ({ column }) => <HeaderTitle column={column}>Usuário</HeaderTitle>,
				accessor: 'user_name',
				Cell: ({ cell: { value } }) => <CellContent color={`darkGrey`}>{value}</CellContent>,
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	return <SimpleTable columns={columns} data={data} isLimited={true} />;
};
