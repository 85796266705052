import React from 'react';
import { Tabs, TabList, Tab, TabPanels, TabPanel, Text, Center, Flex, Image, TabProps } from '@chakra-ui/react';
import { GetPaymentLinkDto, GetPaymentLinkSellerDto, GetSimulationDto } from '../../../clients';
import Billet from '../components/Billet';
import CreditCard from '../components/CreditCard';
import QrCode from '../components/QrCode';
import { CheckoutSaleInformationType } from '../types/checkout.type';
import { BilletQrcodeType } from '../types/permit-billet-qrcode.type';
import CreditCardIcon from '../../../assets/icons/credit-card.svg';
import QRCodeIcon from '../../../assets/icons/qr-code.svg';
import BilletIcon from '../../../assets/icons/bank-billet.svg';

type TabsWrapperProps = {
	simulationValues: Array<GetSimulationDto>;
	seller: GetPaymentLinkSellerDto;
	saleInformation: CheckoutSaleInformationType | undefined;
	setLinkPayed(payed: boolean): void;
	hasPreCapture?: boolean;
	paymentLinkData: BilletQrcodeType;
	paymentLink: GetPaymentLinkDto;
	updatePaymentLink: () => void;
};

type CustomTabProps = {
	iconSrc: string;
	label: string;
	description: string;
} & TabProps;

const CustomTab: React.FC<CustomTabProps> = (props: CustomTabProps) => {
	const { iconSrc, label, description, ...rest } = props;

	return (
		<Tab
			{...rest}
			_hover={{ bg: 'white.400' }}
			_selected={{ borderWidth: 2, borderColor: 'blue.500' }}
			backgroundColor='gray.200'
			rounded='md'
			px='6'
			py='2'
			mb={`2`}
			mr={`1`}
		>
			<Flex direction='column' alignItems='center'>
				<Image src={iconSrc} />
				<Text fontSize='md' fontWeight='semibold' align={['center', 'unset']} mb='1'>
					{label}
				</Text>
				<Text fontSize='xs'>{description}</Text>
			</Flex>
		</Tab>
	);
};

const TabsWrapper: React.FC<TabsWrapperProps> = ({
	simulationValues,
	seller,
	saleInformation,
	setLinkPayed,
	hasPreCapture,
	paymentLinkData,
	paymentLink,
	updatePaymentLink
}) => (
	<Tabs variant='enclosed' align='center'>
		<TabList flexDirection={[`column`, `unset`]} justifyContent='space-evenly'>
			{paymentLinkData?.qrcode_is_permitted && <CustomTab label='PIX QR code' description='Confirmação instantânea' iconSrc={QRCodeIcon} className='custom-tab__qrcode'/>}
			{paymentLinkData?.billet_is_permitted && (
				<CustomTab label='Boleto bancário' description='' iconSrc={BilletIcon} className='custom-tab__billet'/>
			)}
			{paymentLinkData?.card_is_permitted && (
				<CustomTab label='Cartão de crédito' description='Confirmação instantânea pelo cartório' iconSrc={CreditCardIcon} className='custom-tab__credit'/>
			)}
		</TabList>
		<Center my={`4`}>
			<Text fontSize='lg' fontWeight='semibold' align={['center', 'unset']}>
				Resumo do pagamento
			</Text>
		</Center>
		<TabPanels>
			{paymentLinkData?.qrcode_is_permitted && (
				<TabPanel p={`0`} className='tab-panel__qrcode'>
					<QrCode seller={seller} simulationValues={simulationValues} saleInformation={saleInformation} />
				</TabPanel>
			)}
			{paymentLinkData?.billet_is_permitted && (
				<TabPanel p={`0`} className='tab-panel__billet'>
					<Billet
						seller={seller}
						simulationValues={simulationValues}
						saleInformation={saleInformation}
						setLinkPayed={setLinkPayed}
						updatePaymentLink={updatePaymentLink}
						paymentLink={paymentLink}
					/>
				</TabPanel>
			)}
			{paymentLinkData?.card_is_permitted && (
				<TabPanel p={`0`} className='tab-panel__credit'>
					<CreditCard
						hasPreCapture={hasPreCapture}
						seller={seller}
						simulationValues={simulationValues}
						saleInformation={saleInformation}
						setLinkPayed={setLinkPayed}
						updatePaymentLink={updatePaymentLink}
					/>
				</TabPanel>
			)}
		</TabPanels>
	</Tabs>
);

export default TabsWrapper;
