import React from 'react';

import { Flex, Box, Select, Grid, GridItem, useBreakpointValue } from '@chakra-ui/react';
import { endOfToday, format, startOfToday } from 'date-fns';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';

import RangeDatePicker, { IPeriod } from '../../containers/RangeDatePicker';
import Summary from './components/Summary';
import SalesSummaryTable from './components/Tables/SalesSummaryTable';
import LastSalesTable from './components/Tables/LastSalesTable';
import Loader from '../../containers/Loader';

import {
	GetAllSalesSummaryRequest,
	GetAllSalesSummaryStatusEnum,	
	GetSellerSalesSummaryRequest,
	GetSellerSalesSummaryStatusEnum,
	ListSalesRequest,
	ListSellerSalesRequest,
	PaginatedDto,
	SalesApi,
	SellersApi,
} from 'clients';

import { useAuth } from '../../contexts/AuthProvider';
import { getApiAuthConfig } from '../../services/api.service';
import { useCurrentSeller } from '../../contexts/SellerProvider';
import { sale_status } from '../../config/constants';

import DetailsSalesSummaryTable from './components/Tables/DetailsSalesSummaryTable';
import QuickFilters, { QuickFiltersData } from './components/QuickFilters';
import NotificationModal from '../../components/Notification/NotificationModal';

import Title from 'components/Title';

const Dashboard: React.FC = () => {
	const { isBackoffice, isMerchant } = useAuth();
	const { currentSeller } = useCurrentSeller();
	const { addToast } = useToasts();

	const history = useHistory();
	const isMobile = useBreakpointValue({ base: true, lg: false });

	const [isLoading, setIsLoading] = React.useState(false);
	const [salesSummary, setSalesSummary] = React.useState<any>();
	
	const [summaryTable, setSummaryTable] = React.useState<any>();
	const [lastSales, setLastSales] = React.useState<PaginatedDto>();
	const [period, setPeriod] = React.useState<IPeriod[]>([
		{
			startDate: new Date(new Date().setHours(0, 0, 0, 0)),
			endDate: new Date(new Date().setHours(23, 59, 59, 999)),
			key: 'selection',
		},
	]);

	const [saleStatus, setSaleStatus] = React.useState('Succeeded');		
	const [selectedQuickFilter, setSelectedQuickFilter] = React.useState<QuickFiltersData>({
		id: 1,
		description: 'Hoje',
		period: {
			startDate: startOfToday(),
			endDate: endOfToday(),
			key: 'selection',
		},
	});

	const apiConfig = getApiAuthConfig();
	const sellersApi = new SellersApi(apiConfig);
	const salesApi = new SalesApi(apiConfig);

	async function fetchSellerSalesSummary(filter?: QuickFiltersData) {
		setSalesSummary(undefined);
		setSummaryTable(undefined);

		const startDate = filter?.period.startDate || period[0].startDate;
		const endDate = filter?.period.endDate || period[0].endDate;

		if (currentSeller) {
			try {
				const requestParams: GetSellerSalesSummaryRequest = {
					status: GetSellerSalesSummaryStatusEnum[saleStatus] || GetSellerSalesSummaryStatusEnum.Succeeded,
					startDate: format(startDate, 'yyyy-MM-dd'),
					endDate: format(endDate, 'yyyy-MM-dd'),
					sellerId: currentSeller.id,
				};

				const response: any = await sellersApi.getSellerSalesSummary(requestParams);

				setSalesSummary(response?.types);
				setSummaryTable(response);
			} catch (error) {
				addToast('Erro ao buscar os dados', {
					appearance: 'error',
					autoDismiss: true,
				});
			}
		} else {
			try {
				const requestParams: GetAllSalesSummaryRequest = {
					status: GetAllSalesSummaryStatusEnum[saleStatus] || GetAllSalesSummaryStatusEnum.Succeeded,
					startDate: format(startDate, 'yyyy-MM-dd'),
					endDate: format(endDate, 'yyyy-MM-dd'),
				};

				const response: any = await salesApi.getAllSalesSummary(requestParams);

				setSalesSummary(response?.types);
				setSummaryTable(response);
			} catch (error) {
				addToast('Erro ao buscar os dados', {
					appearance: 'error',
					autoDismiss: true,
				});
			}
		}
	}

	async function fetchListSellerSalesRequest() {
		setLastSales(undefined);

		if (currentSeller) {
			try {
				const listSellerSalesRequest: ListSellerSalesRequest = {
					limit: 10,
					currentPage: 1,
					sellerId: currentSeller.id,
					lastNumberSales: 10,
				};
				const response = await sellersApi.listSellerSales(listSellerSalesRequest);

				setLastSales(response);
			} catch (error) {
				addToast('Erro ao buscar os dados', {
					appearance: 'error',
					autoDismiss: true,
				});
			}
		} else {
			try {
				const listSellerSalesRequest: ListSalesRequest = {
					limit: 10,
					currentPage: 1,
					lastNumberSales: 10,
				};
				const response = await salesApi.listSales(listSellerSalesRequest);

				setLastSales(response);
			} catch (error) {
				addToast('Erro ao buscar os dados', {
					appearance: 'error',
					autoDismiss: true,
				});
			}
		}
	}

	async function fetchData(filter?: QuickFiltersData) {
		setIsLoading(true);

		try {
			await Promise.all([fetchListSellerSalesRequest(), fetchSellerSalesSummary(filter)]);
		} finally {
			setIsLoading(false);
		}
	}

	React.useEffect(() => {
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSeller, saleStatus]);

	function navigateToDetails(id: string) {
		history.push(`/admin/sales-history/${id}`, {route: history.location.pathname});
	}

	return (
		<Flex height='100%' direction='column'>
			<Loader isOpen={isLoading} />

			<Title fontSize={isMobile ? 'lg' : '3xl'} fontWeight='bold' mb='2'>
				RESUMO DE VENDAS
			</Title>

			{!isMobile && (
				<Box mb='10'>
					<QuickFilters
						selectedFilter={selectedQuickFilter}
						setPeriod={setPeriod}
						filterAction={(filter?: QuickFiltersData) => fetchData(filter)}
						setSelectedQuickFilter={setSelectedQuickFilter}
						showSummaryChartPeriod={false}
					/>
				</Box>
			)}

			<Grid templateColumns={['100%', '35% 65%', null, '42% 58%', '30% 70%']} templateRows='auto' mt={isMobile ? 'none' : '5'}>
				<GridItem bgColor='primary' rounded={isMobile ? '0.5rem 0.5rem 0 0' : '0.5rem 0 0 0.5rem'} px={['2', null, null, null, '5']} pt='5'>
					<Flex direction='column'>
						<Box position='relative'>
							<RangeDatePicker
								setPeriod={setPeriod}
								period={period}
								setFieldValue={() => {}}
								handleSubmit={(e) => fetchData()}
								backgroundColor='primary'
								borderWidth={1}
								borderColor='white'
								py='5'
								width='100%'
								textAlign='left'
								fontSize={['sm', null, null, null, 'md']}
							/>
						</Box>

						{(isBackoffice || isMerchant) && (
							<Box>
								<Select
									mt={2}
									_hover={{ opacity: 0.8, cursor: 'pointer' }}
									_focus={{ outline: 'none' }}
									borderRadius='md'
									bg='primary'
									iconColor='white'
									textColor='white'
									fontSize='md'
									fontWeight='semibold'
									value={saleStatus}
									onChange={(e) => {
										setSaleStatus(e.target.value);
									}}
								>
									{sale_status.map((status) => (
										<option style={{ color: 'black' }} key={status.value} value={status.value}>
											{status.label}
										</option>
									))}
								</Select>
							</Box>
						)}

						<Summary data={summaryTable} isBackoffice={isBackoffice || isMerchant} />
					</Flex>
				</GridItem>

				<GridItem bgColor='white' rounded={isMobile ? '0 0 0.5rem 0.5rem' : '0 0.5rem 0.5rem 0'}>
					<Flex direction='column' h='100%'>
						{(isBackoffice || isMerchant) && (
							<Box w='auto' p={isMobile ? '0' : '5'}>
								{summaryTable && <DetailsSalesSummaryTable data={summaryTable} />}
							</Box>
						)}
						<Box w='100%' h='100%' p={isMobile ? '0' : '5'}>
							{salesSummary && <SalesSummaryTable data={salesSummary} />}
						</Box>
					</Flex>
				</GridItem>
			</Grid>

			<Flex mt={5} mb='5'>
				<Title fontSize='lg' fontWeight='bold'>
					Últimas Vendas
				</Title>
			</Flex>

			<Box p={isMobile ? '0' : '5'} rounded='md' bgColor='white'>
				{lastSales && (
					<LastSalesTable
						data={lastSales?.results!}
						showSellerColumn={isBackoffice || isMerchant}
						onRowClick={(row) => navigateToDetails(row.values.id)}
					/>
				)}
			</Box>

			<NotificationModal/>
		</Flex>
	);
};

export default Dashboard;
