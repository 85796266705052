import React from 'react';

import { Flex, Heading } from '@chakra-ui/react';

import Modal from 'containers/Modal';

import Button from '../../../components/Button';

type AlertModalProps = {
	openAlertCancel: boolean;
	setOpenAlertCancel(open: boolean): void;
	text: string;
};

const AlertModal: React.FC<AlertModalProps> = ({ openAlertCancel, setOpenAlertCancel, text }) => (
	<Modal isOpen={openAlertCancel && text !== ''}>
		<Flex p={8} flexDirection='column'>
			<Heading fontSize='md' paddingBottom={8} lineHeight='1rem' textAlign='center'>
				{text}
			</Heading>

			<Flex flexDirection='row' alignItems='center' justifyContent='center' fontSize='md' px={3}>
				<Button
					onClick={async () => {
						setOpenAlertCancel(!openAlertCancel);
					}}
					bgColor='primary'
				>
					Ok
				</Button>
			</Flex>
		</Flex>
	</Modal>
);

export default AlertModal;
