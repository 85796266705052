import React from 'react';

import { Flex, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';
import { useToasts } from 'react-toast-notifications';

import Loader from 'containers/Loader';
import UserModal from './components/UserModal';
import UserPermissionModal from './components/UserPermissionModal';

import { SellersApi, ListSellerUsersRequest, ListUsersRequest, ListUsersTypeEnum, UsersApi } from '../../clients';

import Button from '../../components/Button';
import SampleTable from './components/SampleTable';

import { getApiAuthConfig } from '../../services/api.service';
import { useAuth } from '../../contexts/AuthProvider';
import { useCurrentSeller } from '../../contexts/SellerProvider';
import Breadcrumb from 'components/Breadcrumb';
import Title from 'components/Title';
import { useTheme } from 'contexts/ThemeProvider';

const Users: React.FC = () => {
	const apiConfig = getApiAuthConfig();
	const sellersApi = new SellersApi(apiConfig);
	const usersApi = new UsersApi(apiConfig);
	const { theme } = useTheme();

	const { user, isBackoffice, isMerchant } = useAuth();

	const [isLoading, setIsLoading] = React.useState(true);
	const [resetTable, setResetTable] = React.useState(false);
	const [openUserModal, setOpenUserModal] = React.useState(false);
	const [userID, setUserID] = React.useState('');
	const [selectedUser, setSelectedUser] = React.useState(undefined);

	const [users, setUsers] = React.useState<Array<string>>([]);
	const [pagination, setPagination] = React.useState({ currentPage: 1, limit: 100 });
	const [totalPages, setTotalPages] = React.useState(1);
	const [openUserPermissionModal, setOpenUserPermissionModal] = React.useState(false);
	const [userRole, setUserRole] = React.useState(undefined);

	const { addToast } = useToasts();
	const { currentSeller } = useCurrentSeller();

	async function fetchListSellerSalesRequest() {
		setIsLoading(true);

		if (currentSeller) {
			try {
				const requestParams: ListSellerUsersRequest = {
					currentPage: pagination.currentPage,
					limit: pagination.limit,
					sellerId: currentSeller?.id!,
				};

				const response = await sellersApi.listSellerUsers(requestParams);

				setUsers(response.results);
				setTotalPages(response.total_pages);
			} catch (error) {
				addToast('Erro ao buscar os dados', {
					appearance: 'error',
					autoDismiss: true,
				});
			} finally {
				setIsLoading(false);
			}
		} else {
			try {
				const requestParams: ListUsersRequest = {
					currentPage: pagination.currentPage,
					limit: pagination.limit,
					type: ListUsersTypeEnum.Seller,
				};

				const response = await usersApi.listUsers(requestParams);

				setUsers(response.results);
				setTotalPages(response.total_pages);
			} catch (error) {
				addToast('Erro ao buscar os dados', {
					appearance: 'error',
					autoDismiss: true,
				});
			} finally {
				setIsLoading(false);
			}
		}
	}

	const handleOpenUserModal = (user?) => {
		if (user) {
			setUserID(user.id);
			setSelectedUser(user);
			setOpenUserModal(!openUserModal);
			return;
		}

		setUserID('');
		setSelectedUser(undefined);
		setOpenUserModal(!openUserModal);
	};

	const handleOpenUserPermissionModal = (role) => {
		setUserRole(role);
		setOpenUserPermissionModal(!openUserPermissionModal);
	};

	React.useEffect(() => {
		fetchListSellerSalesRequest();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSeller, pagination, resetTable]);

	return (
		<>
			<Loader isOpen={isLoading} />
			<Flex pb='8'>
				<Breadcrumb>
					<BreadcrumbItem>
						<BreadcrumbLink href='#' fontSize='md'>
							Resumo
						</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem>
						<BreadcrumbLink href='#' fontSize='md'>
							Minha Conta
						</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink href='#' fontSize='md' fontWeight={`extrabold`}>
							Usuários
						</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>
			</Flex>

			<Flex mb='4' flexDirection='row' justify='space-between'>
				<Title fontSize={`2xl`}>USUÁRIOS</Title>
				<Button
					bgColor='transparent'
					borderWidth={1}
					borderColor={theme?.primary_color || 'black'}
					color={theme?.primary_color || 'black'}
					rounded='md'
					title={`Você deve selecionar um estabelecimento`}
					isDisabled={!currentSeller}
					onClick={() => {
						handleOpenUserModal();
					}}
				>
					Inserir novo usuário
				</Button>
			</Flex>

			<Flex flexDir={`column`} p={[4, 4, 10]} bgColor='white' rounded={`md`} boxShadow={`sm`} mt='2' w='100%'>
				{users && (
					<SampleTable
						seller={currentSeller}
						currentUser={user}
						showSellerColumn={isBackoffice || isMerchant}
						data={users}
						setPagination={setPagination}
						pagination={pagination}
						totalPages={totalPages}
						handleOpenUserModal={handleOpenUserModal}
						handleOpenUserPermissionModal={handleOpenUserPermissionModal}
					/>
				)}
			</Flex>
			<UserModal
				seller={currentSeller}
				type={ListUsersTypeEnum.Seller}
				showSellerInput={isBackoffice}
				openUserModal={openUserModal}
				handleOpenUserModal={handleOpenUserModal}
				isLoading={isLoading}
				setIsLoading={setIsLoading}
				resetTable={resetTable}
				setResetTable={setResetTable}
				userID={userID}
				selectedUser={selectedUser}
			/>
			<UserPermissionModal
				openUserPermissionModal={openUserPermissionModal}
				handleOpenUserPermissionModal={handleOpenUserPermissionModal}
				userRole={userRole}
			/>
		</>
	);
};

export default Users;
