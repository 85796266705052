import * as React from 'react';
import { Field } from 'formik';
import InputMask from 'react-input-mask';
import { InputProps } from '@chakra-ui/react';

import Input from '../Input';


const FormCnaeInput: React.FC<InputProps> = (props: InputProps) => {
  return (
    <Field {...props}>
      {({ form, field }) => {
        return (
          <InputMask
            borderColor={`darkGrey`}
            mask={'99.99-9/99'}
            maskChar={null}
            value={field.value}
            onBlur={form.handleBlur}
            onChange={(e) => {
              props.onChange && props.onChange(e);
              form.setFieldValue(field.name, e.target.value);
            }}
            {...props}
          >
            {(inputProps) => {
              return <Input type='text' {...inputProps} />;
            }}
          </InputMask>
        );
      }}
    </Field>
  );
};

export default FormCnaeInput;