import React from 'react';

import { Textarea as ChakraTextArea, TextareaProps } from '@chakra-ui/react';
import { useTheme } from 'contexts/ThemeProvider';
import { Field } from 'formik';

const Textarea: React.FC<TextareaProps> = React.forwardRef((props: TextareaProps, ref) => {
	const { theme } = useTheme();
	const { onChange, ...parsedProps } = props;

	return (
		<Field type='textarea' name={props.name} {...parsedProps}>
			{({ form, field }) => {
				return (
					<ChakraTextArea
						color={theme?.text_color || `darkGrey`}
						fontSize={`md`}
						size={`md`}
						rounded={`md`}
						borderColor='darkGrey'
						borderWidth={1}
						bgColor={`transparent`}
						onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
							props.onChange && props.onChange(e);

							form.setFieldValue(field.name, e.target.value);
						}}
						{...props}
					/>
				);
			}}
		</Field>
	);
});

export default Textarea;
