import React from 'react';
import {
  Flex,
  Tag,
  Text,
  HStack,
  Slider,
  TagLabel,
  SliderMark,
  SliderTrack,
  SliderFilledTrack,
  ButtonProps as DefaultButtonProps,
} from '@chakra-ui/react';

import { useTheme } from 'contexts/ThemeProvider';

type ButtonProps = DefaultButtonProps & {
  steps: {
    title: string,
    text: string,
    isEnabled: boolean,
    config?: {
      ml?: number,
    }
  }[];
  selected: number;
  updateStep: any;
};

const Timeline: React.FC<ButtonProps> = (props: ButtonProps) => {
  const { theme } = useTheme();

  return (
    <>
      <HStack>
        <Slider
          value={props.selected}
          max={props.steps.length - 1} step={1} 
          onChange={
            (index: number) => props.steps[index]?.isEnabled
              ? props.updateStep(index)
              : null
          }
        >
          <SliderTrack bg={theme?.default_color}>
            <SliderFilledTrack bg={theme?.primary_color} />
          </SliderTrack>
          {props.steps.map((step, index)=> (
            <SliderMark
              key={index}
              value={index}
              mt='-3'
              ml={index+1 !== props.steps.length ? 0 : step.text.length * -1}
            >
              <Flex direction={'column'} alignItems='start'>
                <Tag
                  borderRadius='full'
                  minW={'max-content'}
                  variant={props.selected >= index ? 'solid': 'outline'}
                  backgroundColor={props.selected >= index
                    ? theme?.primary_color
                    : 'white'
                  }
                  onClick={() => props.updateStep(index)}
                >
                  <TagLabel>{step.text}</TagLabel>
                </Tag>
                <Text
                  fontSize='sm'
                  as='b'
                  minW={'max-content'}
                  mt={1}
                  ml={step.config?.ml}
                >
                  {step.title}
                </Text>
              </Flex>
            </SliderMark>
          ))}
        </Slider>
      </HStack>
    </>
    
  );
};

export default Timeline;
