import React from 'react';

import { Flex, Center, FormControl, BreadcrumbItem, BreadcrumbLink, Stack, Tooltip, IconButton } from '@chakra-ui/react';

import { useToasts } from 'react-toast-notifications';

import { format } from 'date-fns';
import { Formik } from 'formik';

import Loader from '../../containers/Loader';
import Button from '../../components/Button';
import RangeDatePicker from '../../containers/RangeDatePicker';
import SampleTable from './components/SampleTable';
import Paper from '../../containers/Paper';

import IPeriod from '../../interfaces/period.interface';
import MultiSelect from '../../components/MultiSelect';

import { useAuth } from '../../contexts/AuthProvider';
import { useCurrentSeller } from '../../contexts/SellerProvider';
import { getApiAuthConfig } from '../../services/api.service';

import { PaginatedDto, GetFileExportRequestDtoStatusEnum, FileExportRequestsApi } from '../../clients';
import Breadcrumb from 'components/Breadcrumb';
import { RepeatIcon } from '@chakra-ui/icons';

const Reports: React.FC = () => {
	const [isLoading, setIsLoading] = React.useState(false);
	const [reports, setReports] = React.useState<PaginatedDto>();
	const [pagination, setPagination] = React.useState({ currentPage: 1, limit: 100 });
	const [totalPages, setTotalPages] = React.useState(1);
	const [filters, setFilters] = React.useState({});

	const [period, setPeriod] = React.useState<IPeriod[]>([
		{
			startDate: new Date(new Date().setHours(0, 0, 0, 0)),
			endDate: new Date(new Date().setHours(23, 59, 59, 999)),
			key: 'selection',
		},
	]);
	const initialValues = {
		id: '',
		status: [],
		formPayment: [],
		startDate: format(period[0].startDate, 'yyyy-MM-dd'),
		endDate: format(period[0].endDate, 'yyyy-MM-dd'),
	};
	const { addToast } = useToasts();
	const { isBackoffice, isMerchant } = useAuth();
	const { currentSeller } = useCurrentSeller();

	const apiConfig = getApiAuthConfig();
	const reportsApi = new FileExportRequestsApi(apiConfig);

	async function fetchResults(params) {
		setIsLoading(true);

		try {
			const response = await reportsApi.listFileExportRequests({
				currentPage: pagination.currentPage,
				limit: pagination.limit,
				startDate: format(period[0].startDate, 'yyyy-MM-dd'),
				endDate: format(period[0].endDate, 'yyyy-MM-dd'),
				status: params.status?.map((v) => v.value),
				sellerId: currentSeller?.id!,
			});

			setReports(response);
			setTotalPages(response.total_pages);
		} catch (error) {
			addToast('Erro ao buscar os dados', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	}

	const handleFormSubmit = async (values) => {
		setFilters(values);

		await fetchResults(values);
	};

	React.useEffect(() => {
		fetchResults(filters);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pagination, currentSeller]);

	return (
		<Flex direction='column'>
			<Loader isOpen={isLoading} />

			<Breadcrumb>
				<BreadcrumbItem>
					<BreadcrumbLink fontSize='md'>Resumo</BreadcrumbLink>
				</BreadcrumbItem>

				<BreadcrumbItem>
					<BreadcrumbLink fontSize='md'>Gestão Financeira</BreadcrumbLink>
				</BreadcrumbItem>

				<BreadcrumbItem isCurrentPage>
					<BreadcrumbLink opacity='0.9' cursor='unset' fontSize='md' fontWeight='semibold'>
						Relatório
					</BreadcrumbLink>
				</BreadcrumbItem>
			</Breadcrumb>

			<Paper p={6}>
				<Formik enableReinitialize initialValues={initialValues} onSubmit={handleFormSubmit}>
					{({ handleSubmit, handleChange, setFieldValue, values, resetForm }) => {
						return (
							<form onSubmit={handleSubmit}>
								<Stack direction={['column', 'column', 'row']} spacing={4} justify="space-between">
									<Stack direction={['column', 'column', 'row']}>
										<Center position='relative'>
											<RangeDatePicker
												period={period}
												setPeriod={setPeriod}
												setFieldValue={setFieldValue}
												handleSubmit={handleSubmit}
												backgroundColor='primary'
												py='5'
											/>
										</Center>

										<Center pt={0} minWidth='250px'>
											<FormControl>
												<MultiSelect
													id='status'
													placeholder='Status'
													options={[
														{ value: GetFileExportRequestDtoStatusEnum.Pending, label: 'Pendente' },
														{ value: GetFileExportRequestDtoStatusEnum.Finished, label: 'Concluído' },
														{ value: GetFileExportRequestDtoStatusEnum.Failed, label: 'Falhou' },
													]}
													value={values.status}
													onChange={(value) => {
														setFieldValue('status', value);
														handleSubmit();
													}}
												/>
											</FormControl>
										</Center>
									</Stack>

									<Center alignContent='flex-end'>
										<Tooltip label={'Refazer a busca'} placement='top' hasArrow>
											<IconButton
												_hover={{ backgroundColor: 'transparent' }}
												_focus={{ outline: 'none' }}
												aria-label='Refazer a busca'
												icon={<RepeatIcon />}
												color='primary'
												backgroundColor='transparent'
												size="lg"
												onClick={() => handleSubmit()}
											/>
										</Tooltip>
										{(values.status.length > 0) && (
											<Button
												onClick={() => {
													resetForm();
													handleSubmit();
												}}
												paddingInline='10px'
												_focus={{ outline: 'none' }}
												color='secondary'
												bgColor='transparent'
											>
												Limpar Filtros
											</Button>
										)}
									</Center>
								</Stack>
							</form>
						);
					}}
				</Formik>

				<Flex overflowX='auto' overflowY='hidden' direction='column'>
					{reports && (
						<Flex mt={6}>
							<SampleTable
								showSellerColumn={isBackoffice || isMerchant}
								data={reports}
								setPagination={setPagination}
								pagination={pagination}
								totalPages={totalPages}
							/>
						</Flex>
					)}
				</Flex>
			</Paper>
		</Flex>
	);
};

export default Reports;
