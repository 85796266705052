/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateFileExportRequestDto,
    CreateFileExportRequestDtoFromJSON,
    CreateFileExportRequestDtoToJSON,
    CreateSellerFileExportRequestDto,
    CreateSellerFileExportRequestDtoFromJSON,
    CreateSellerFileExportRequestDtoToJSON,
    GetFileExportRequestDto,
    GetFileExportRequestDtoFromJSON,
    GetFileExportRequestDtoToJSON,
    PaginatedDto,
    PaginatedDtoFromJSON,
    PaginatedDtoToJSON,
} from '../models';

export interface CreateFileExportRequestRequest {
    createFileExportRequestDto: CreateFileExportRequestDto;
}

export interface CreateFileExportRequestV2Request {
    sellerId: string;
    createSellerFileExportRequestDto: CreateSellerFileExportRequestDto;
}

export interface ListFileExportRequestsRequest {
    limit: number;
    currentPage: number;
    startDate?: string;
    endDate?: string;
    status?: Array<ListFileExportRequestsStatusEnum>;
    type?: Array<ListFileExportRequestsTypeEnum>;
    user?: string;
    sellerId?: string;
    sort?: ListFileExportRequestsSortEnum;
}

/**
 * 
 */
export class FileExportRequestsApi extends runtime.BaseAPI {

    /**
     * Em alternativa a esta rota, utilize a v2.
     * Gerar uma requisição de exportação de arquivo
     */
    async createFileExportRequestRaw(requestParameters: CreateFileExportRequestRequest): Promise<runtime.ApiResponse<GetFileExportRequestDto>> {
        if (requestParameters.createFileExportRequestDto === null || requestParameters.createFileExportRequestDto === undefined) {
            throw new runtime.RequiredError('createFileExportRequestDto','Required parameter requestParameters.createFileExportRequestDto was null or undefined when calling createFileExportRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/file-exports/requests`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateFileExportRequestDtoToJSON(requestParameters.createFileExportRequestDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetFileExportRequestDtoFromJSON(jsonValue));
    }

    /**
     * Em alternativa a esta rota, utilize a v2.
     * Gerar uma requisição de exportação de arquivo
     */
    async createFileExportRequest(requestParameters: CreateFileExportRequestRequest): Promise<GetFileExportRequestDto> {
        const response = await this.createFileExportRequestRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gerar uma requisição de exportação de arquivo
     */
    async createFileExportRequestV2Raw(requestParameters: CreateFileExportRequestV2Request): Promise<runtime.ApiResponse<GetFileExportRequestDto>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling createFileExportRequestV2.');
        }

        if (requestParameters.createSellerFileExportRequestDto === null || requestParameters.createSellerFileExportRequestDto === undefined) {
            throw new runtime.RequiredError('createSellerFileExportRequestDto','Required parameter requestParameters.createSellerFileExportRequestDto was null or undefined when calling createFileExportRequestV2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/file-exports/requests/seller/{sellerId}`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSellerFileExportRequestDtoToJSON(requestParameters.createSellerFileExportRequestDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetFileExportRequestDtoFromJSON(jsonValue));
    }

    /**
     * Gerar uma requisição de exportação de arquivo
     */
    async createFileExportRequestV2(requestParameters: CreateFileExportRequestV2Request): Promise<GetFileExportRequestDto> {
        const response = await this.createFileExportRequestV2Raw(requestParameters);
        return await response.value();
    }

    /**
     * Listar requisições de exportação de arquivos
     */
    async listFileExportRequestsRaw(requestParameters: ListFileExportRequestsRequest): Promise<runtime.ApiResponse<PaginatedDto>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling listFileExportRequests.');
        }

        if (requestParameters.currentPage === null || requestParameters.currentPage === undefined) {
            throw new runtime.RequiredError('currentPage','Required parameter requestParameters.currentPage was null or undefined when calling listFileExportRequests.');
        }

        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        if (requestParameters.status) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.type) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.user !== undefined) {
            queryParameters['user'] = requestParameters.user;
        }

        if (requestParameters.sellerId !== undefined) {
            queryParameters['sellerId'] = requestParameters.sellerId;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.currentPage !== undefined) {
            queryParameters['current_page'] = requestParameters.currentPage;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/file-exports/requests`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedDtoFromJSON(jsonValue));
    }

    /**
     * Listar requisições de exportação de arquivos
     */
    async listFileExportRequests(requestParameters: ListFileExportRequestsRequest): Promise<PaginatedDto> {
        const response = await this.listFileExportRequestsRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum ListFileExportRequestsStatusEnum {
    Pending = 'pending',
    Failed = 'failed',
    Finished = 'finished'
}
/**
    * @export
    * @enum {string}
    */
export enum ListFileExportRequestsTypeEnum {
    Sales = 'sales',
    SalesConcil = 'sales_concil',
    Payments = 'payments',
    Settlement = 'settlement',
    SettlementTransactions = 'settlement_transactions',
    SettlementsBatchTransactions = 'settlements_batch_transactions',
    Protocols = 'protocols',
    SalesHistory = 'sales_history',
    MonthlyAlerts = 'monthly_alerts',
    Sellers = 'sellers',
    SalesBillPayment = 'sales_bill_payment',
    AssignmentTransactionAlerts = 'assignment_transaction_alerts',
    ConsolidateCostSales = 'consolidate_cost_sales'
}
/**
    * @export
    * @enum {string}
    */
export enum ListFileExportRequestsSortEnum {
    Descending = 'time-descending',
    Ascending = 'time-ascending'
}
