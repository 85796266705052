import React from 'react';

import { Formik } from 'formik';
import { format } from 'date-fns';
import { Stack, Center, FormControl, useBreakpointValue, InputGroup, InputRightElement, IconButton, Tooltip } from '@chakra-ui/react';

import IPeriod from '../../../interfaces/period.interface';

import { GetPaymentLinkDtoStatusEnum, GetPaymentLinkSaleDtoFormPaymentEnum } from '../../../clients';

import RangeDatePicker from '../../../containers/RangeDatePicker';
import MultiSelect from '../../../components/MultiSelect';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import { SearchIcon, RepeatIcon } from '@chakra-ui/icons';

type PaymentLinksFiltersProps = {
	onChangeFilters: (filters) => void;
};

const PaymentLinksFilters: React.FC<PaymentLinksFiltersProps> = (props: PaymentLinksFiltersProps) => {
	const isMobile = useBreakpointValue({ base: true, lg: false });
	const pagination = { currentPage: 1, limit: 10 };

	const [period, setPeriod] = React.useState<IPeriod[]>([
		{
			startDate: new Date(new Date().setHours(0, 0, 0, 0)),
			endDate: new Date(new Date().setHours(23, 59, 59, 999)),
			key: 'selection',
		},
	]);

	const initialValues = {
		status: [],
		form_payment: [],
		startDate: format(period[0].startDate, 'yyyy-MM-dd'),
		endDate: format(period[0].endDate, 'yyyy-MM-dd'),
	};

	const handleFormSubmit = async (values) => {
		let parsedFilters = {
			currentPage: pagination.currentPage,
			limit: pagination.limit,
			startDate: format(period[0].startDate, 'yyyy-MM-dd'),
			endDate: format(period[0].endDate, 'yyyy-MM-dd'),
			status: values.status?.map((v) => v.value),
			formPayment: values.form_payment?.map((v) => v.value),
			payerName: values.payer_name,
			description: values.description,
		};

		if (parsedFilters.status && !parsedFilters.status.length) {
			delete parsedFilters.status;
		}

		if (parsedFilters.formPayment && !parsedFilters.formPayment.length) {
			delete parsedFilters.formPayment;
		}

		props.onChangeFilters(parsedFilters);
	};

	return (
		<Formik enableReinitialize initialValues={initialValues} onSubmit={handleFormSubmit}>
			{({ handleSubmit, handleChange, setFieldValue, values, resetForm }) => {
				return (
					<form onSubmit={handleSubmit}>
						<Stack direction={isMobile ? 'column' : 'row'} spacing={0}  mb={4} wrap='wrap'>
							<Center position='relative' mr={{lg: 9}} mb={4}>
								<RangeDatePicker
									bgColor={`primary`}
									p='6'
									period={period}
									setPeriod={setPeriod}
									setFieldValue={setFieldValue}
									handleSubmit={handleSubmit}
								/>
							</Center>
							<Center pt={0} minWidth='250px' mr={{lg: 4}} mb={4}>
								<FormControl>
									<MultiSelect
										id='status'
										placeholder='Status'
										options={[
											{ value: GetPaymentLinkDtoStatusEnum.Active, label: 'Pendente' },
											{ value: GetPaymentLinkDtoStatusEnum.Expired, label: 'Expirado' },
											{ value: GetPaymentLinkDtoStatusEnum.Canceled, label: 'Cancelado' },
											{ value: GetPaymentLinkDtoStatusEnum.Paid, label: 'Pago' },
										]}
										value={values.status}
										onChange={(value) => {
											setFieldValue('status', value);
											handleSubmit();
										}}
									/>
								</FormControl>
							</Center>
							<Center pt={0} minWidth='250px' mr={{lg: 4}} mb={4}>
								<FormControl>
									<InputGroup>
										<Input
											borderColor='darkGrey'
											id='description'
											placeholder='N° do Pedido'
											onChange={(event) => {
												handleChange(event);
											}}
											onBlur={() => handleSubmit()}
											py='5'
										/>

										<InputRightElement
											children={
												<IconButton
													_hover={{ backgroundColor: 'transparent' }}
													_focus={{ outline: 'none' }}
													aria-label='Pesquisar por número do pedido'
													icon={<SearchIcon />}
													color='primary'
													backgroundColor='transparent'
													onClick={() => handleSubmit()}
												/>
											}
										/>
									</InputGroup>
								</FormControl>
							</Center>
							<Center pt={0} minWidth='250px' mr={{lg: 4}} mb={4}>
								<FormControl>
									<MultiSelect
										id='form-payment'
										placeholder='Forma de Pagamento'
										options={[
											{ value: GetPaymentLinkSaleDtoFormPaymentEnum.Credit, label: 'Crédito' },
											{ value: GetPaymentLinkSaleDtoFormPaymentEnum.Debit, label: 'Débito' },
											{ value: GetPaymentLinkSaleDtoFormPaymentEnum.Billet, label: 'Boleto' },
											{ value: GetPaymentLinkSaleDtoFormPaymentEnum.Pix, label: 'Pix' },
										]}
										value={values.form_payment}
										onChange={(value) => {
											setFieldValue('form_payment', value);
											handleSubmit();
										}}
									/>
								</FormControl>
							</Center>
							<Center pt={0} minWidth='250px' mr={{lg: 4}} mb={4}>
								<FormControl>
									<InputGroup>
										<Input
											borderColor='darkGrey'
											id='payer_name'
											placeholder='Pagador'
											onChange={(event) => {
												handleChange(event);
											}}
											onBlur={() => handleSubmit()}
											py='5'
										/>

										<InputRightElement
											children={
												<IconButton
													_hover={{ backgroundColor: 'transparent' }}
													_focus={{ outline: 'none' }}
													aria-label='Pesquisar por nome de pagador'
													icon={<SearchIcon />}
													color='primary'
													backgroundColor='transparent'
													onClick={() => handleSubmit()}
												/>
											}
										/>
									</InputGroup>
								</FormControl>
							</Center>
								<Tooltip label={'Refazer a busca'} placement='top' hasArrow>
									<IconButton
										_hover={{ backgroundColor: 'transparent' }}
										_focus={{ outline: 'none' }}
										aria-label='Refazer a busca'
										icon={<RepeatIcon />}
										color='primary'
										backgroundColor='transparent'
										size="lg"
										onClick={() => handleSubmit()}
									/>
								</Tooltip>
								{(values.form_payment.length > 0 || values.status.length > 0) && (
									<Button
										onClick={() => {
											resetForm();
											handleSubmit();
										}}
										_focus={{ outline: 'none' }}
										color='secondary'
										bgColor='transparent'
									>
										Limpar Filtros
									</Button>
								)}
						</Stack>
					</form>
				);
			}}
		</Formik>
	);
};

export default PaymentLinksFilters;
