import React from 'react';
import { ImFileText2 } from 'react-icons/im';
import { Flex, Text, Icon, Button } from '@chakra-ui/react';


type props = {
  name: string;
  extension: string;
  data: string;
  url: string;
  remove: () => void;
};

const Attachment: React.FC<props> = ({ name, url, remove }) => {
  const download = (url: string) => {
    window.open(url);
  }

  return (
    <Flex
      direction='column'
      maxW={{base: '25vw', md: '10vw', xl: '6vw'}}
      minWidth={{base: '25vw', md: '10vw', xl: '6vw'}}
      mx={5}
      mb={5}
    >
      <Icon
        as={ImFileText2}
        w={'55%'}
        h={'80%'}
        alignSelf={'center'}
        {
          ...(
            url && {
              cursor: 'pointer',
              onClick: () => download(url)
            }
          )
        }
      />
      <Text fontSize='md' alignSelf={'center'}>
        {
          name.length > 17
            ? `${name.substring(0, 5)}...${name.substring(name.length-7)}`
            : name
        }
      </Text>
      <Button backgroundColor='white' alignSelf='end'>
        <Text fontSize='sm' color='blue.800' as='u' onClick={() => remove()}>
          Excluir
        </Text>
      </Button>
    </Flex>
  );
};

export default Attachment;


