import React from 'react';
import { Center, FormControl, FormLabel, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import FormErrorMessage from '../../../components/Form/FormErrorMessage';

import { GetAcquirerDto, GetSellerCredentialResponseDto } from 'clients';

import Button from 'components/Button';
import FormInput from 'components/Form/FormInput';
import FormSelect from 'components/Form/FormSelect';
import Input from 'components/Input';

type CredentialsForm = {
  acquirers: GetAcquirerDto[];
  isLoading?: boolean;
  isValid: boolean;
  editingCredential?: GetSellerCredentialResponseDto;
}

const CredentialsForm: React.FC<CredentialsForm> = ({ acquirers, isLoading, isValid, editingCredential }) => {
  return (
    <SimpleGrid color='gray.700' spacing='2.5rem' marginLeft='1%'>
      <Stack direction={'column'} spacing={2}>
        <FormControl id='api_key'>
          <FormLabel fontSize='md' fontWeight='bold'>
            Chave da Api
          </FormLabel>
          <FormInput as={Input} name='api_key' />
          <FormErrorMessage name='api_key' />
        </FormControl>

        <FormControl id='client_id' isRequired>
          <FormLabel fontSize='md' fontWeight='bold'>
            Id do Cliente (carteira)
          </FormLabel>
          <FormInput as={Input} name='client_id' />
          <FormErrorMessage name='client_id' />
        </FormControl>

        <FormControl id='acquirer_code' isRequired>
          <FormLabel fontSize='md' fontWeight='bold'>
            Adquirente
          </FormLabel>
          {!!editingCredential ?
            <Text>{editingCredential.acquirer.name}</Text> :
            <FormSelect name='acquirer_code'>
              <option value='' />
              {acquirers.map((acquirer) => (
                <option value={acquirer.code} key={acquirer.id}>
                  {acquirer.name}
                </option>
              ))}
            </FormSelect>}
          <FormErrorMessage name='acquirer_code' />
        </FormControl>
        <Center pt='6' justifyContent='space-around'>
          <Button bgColor='primary' size='md' disabled={!isValid} type='submit' isLoading={isLoading}>
            Salvar Credencial
          </Button>
        </Center>
      </Stack>
    </SimpleGrid>
  );
};

export default CredentialsForm;
