import React from 'react';
import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	Flex,
	Link,
	Table,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
	Box,
	Text,
	Heading,
	SimpleGrid,
	Grid,
	GridItem,
} from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import Loader from 'containers/Loader';
import { SalesPlansApi, GetSalePlanDto, GetSalePlanRequest } from '../../../clients';
import { useParams } from 'react-router-dom';
import { getApiAuthConfig } from 'services/api.service';
import { useToasts } from 'react-toast-notifications';
import Paper from '../../../containers/Paper';
import { paymentMethodEnum, salePlanType, paymentTypeEnum } from '../../../services/enums.service';
import { maskMoney } from 'services/masks.service';

const SalesPlanDetails: React.FC = () => {
	const [isLoading, setIsLoading] = React.useState(true);
	const [sale, setSale] = React.useState<GetSalePlanDto>();

	const { id } = useParams();
	const { addToast } = useToasts();

	const apiConfig = getApiAuthConfig();
	const salesApi = new SalesPlansApi(apiConfig);

	async function fetchGetSaleRequest() {
		setIsLoading(true);

		try {
			const requestParams: GetSalePlanRequest = {
				salePlanId: id,
			};
			const response = await salesApi.getSalePlan(requestParams);
			setSale(response);
		} catch (error) {
			addToast('Erro ao buscar os dados', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	}

	React.useEffect(() => {
		fetchGetSaleRequest();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Flex flexDirection='column' color='primary'>
			<Loader isOpen={isLoading} />

			<Breadcrumb fontSize='md' color='darkGrey' mb='4' separator={<ChevronRightIcon />}>
				<BreadcrumbItem>
					<BreadcrumbLink>Resumo</BreadcrumbLink>
				</BreadcrumbItem>

				<BreadcrumbItem>
					<BreadcrumbLink as={Link} href='/admin/planos-de-venda'>
						Plano de Vendas
					</BreadcrumbLink>
				</BreadcrumbItem>

				<BreadcrumbItem isCurrentPage fontWeight='semibold'>
					<BreadcrumbLink>Detalhes de Plano de Venda</BreadcrumbLink>
				</BreadcrumbItem>
			</Breadcrumb>
			<Flex direction='column'>
				{sale && (
					<Paper title='Dados da venda'>
						<Box>
							<Grid templateColumns='repeat(4, 1fr)' templateRows='repeat(2, 1fr)' gap={6} mb={10}>
								<GridItem>
									<Text fontWeight='bold'>Nome</Text>
									<Text>{sale.name}</Text>
								</GridItem>
								<GridItem>
									<Text fontWeight='bold'>Descrição</Text>
									<Text>{sale.description}</Text>
								</GridItem>
								<GridItem>
									<Text fontWeight='bold'>Tipo</Text>
									<Text>{salePlanType[sale.type]}</Text>
								</GridItem>
								<GridItem>
									<Text fontWeight='bold'>Método de Pagamento</Text>
									<Text>{paymentMethodEnum[sale.payment_method]}</Text>
								</GridItem>
								<GridItem>
									<Text fontWeight='bold'>Merchant</Text>
									<Text>{sale.merchant.description}</Text>
								</GridItem>
								<GridItem>
									<Text fontWeight='bold'>Adquirente</Text>
									<Text>{sale.acquirer.name}</Text>
								</GridItem>
								<GridItem>
									<Text fontWeight='bold'>Plano de Liquidação</Text>
									<Text>{sale.settlement_plan.name}</Text>
								</GridItem>
								<GridItem>
									<Text fontWeight='bold'>Plano de Custo</Text>
									<Text>{sale.cost_plan.name}</Text>
								</GridItem>
							</Grid>
							<SimpleGrid>
								<Heading color='gray.700' as='h6' size='md' py='1%'>
									Tipos de Pagamento
								</Heading>
							</SimpleGrid>
							<SimpleGrid>
								{sale.payment_types && (
									<Table>
										<Thead>
											<Tr>
												<Th>Código</Th>
												<Th>Metódo de Pagamento</Th>
												<Th>Taxa</Th>
												<Th>Teto</Th>
												<Th>Piso</Th>
											</Tr>
										</Thead>
										<Tbody>
											{sale.payment_types?.map((paymentType, i: number) => (
												<Tr key={i}>
													<Td>{paymentType.code}</Td>
													<Td>{paymentTypeEnum[paymentType.operation_mode]}</Td>
													<Td>{paymentType.code === 'billet' || paymentType.code === 'pix' 
															? 
															`${paymentType.teto && paymentType.piso ? `${paymentType.fee} %` : `R$ ${paymentType.fee}`}` 
															: 
															`${paymentType.fee} %`}
													</Td>
													<Td>{paymentType.teto ? `R$ ${maskMoney(paymentType.teto)}` : '-'}</Td>
													<Td>{paymentType.piso ? `R$ ${maskMoney(paymentType.piso)}` : '-'}</Td>
												</Tr>
											))}
										</Tbody>
									</Table>
								)}
							</SimpleGrid>
						</Box>
					</Paper>
				)}
			</Flex>
		</Flex>
	);
};

export default SalesPlanDetails;
