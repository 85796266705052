import { Text, TextProps } from '@chakra-ui/react';
import React from 'react';

import { useTheme } from 'contexts/ThemeProvider';

type TitleProps = TextProps & {
	children: string;
    fontSize?: string;
};

const Title: React.FC<TitleProps> = ({ children, fontSize = 'lg' }: TitleProps) => {
	const { theme } = useTheme();

	return (
		<Text color={theme?.secondary_color || `blackAlpha.600`} fontSize={fontSize} fontWeight='bold'>
			{children}
		</Text>
	);
};

export default Title;
