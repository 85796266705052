import React from 'react';

import { Flex, Stack, CheckboxGroup, SimpleGrid, BreadcrumbItem, BreadcrumbLink, useBreakpointValue } from '@chakra-ui/react';
import { Formik } from 'formik';
import { useToasts } from 'react-toast-notifications';
import { addDays } from 'date-fns';

import Loader from 'containers/Loader';

import FormInput from 'components/Form/FormInput';
import FormErrorMessage from 'components/Form/FormErrorMessage';
import Button from 'components/Button';
import FormCurrencyInput from 'components/Form/FormCurrencyInput';
import FormCpfCnpjInput from 'components/Form/FormCpfCnpjInput';
import FormDatePickerInput from 'components/Form/FormDatePickerInput';

import Paper from 'containers/Paper';
import yup from 'services/yup.service';
import ProtestLinksFilters from './components/ProtestLinksFilters';
import BackofficeAlertContainer from 'containers/BackofficeAlertContainer';
import ProtestLinksTable from './components/ProtestLinksTable';

import { SellersApi, ProtestLinksApi, ListSellerProtestLinksRequest, GetProtestLinkDto } from '../../clients';
import { useHistory } from 'react-router-dom';

import { useCurrentSeller } from '../../contexts/SellerProvider';

import { getApiAuthConfig } from '../../services/api.service';
import { paymentTypeEnum } from 'services/enums.service';
import { Checkbox } from '@chakra-ui/checkbox';
import Breadcrumb from 'components/Breadcrumb';
import Title from 'components/Title';
import FormControl from 'components/Form/FormControl';
import FormLabel from 'components/Form/FormLabel';
import Text from 'components/Text';

const ProtestLinkSale: React.FC = () => {
	const { currentSeller } = useCurrentSeller();
	const { addToast } = useToasts();
	const isMobile = useBreakpointValue({ base: true, lg: false });
	const history = useHistory();

	const [isLoading, setIsLoading] = React.useState(true);
	const [protestLinks, setProtestLinks] = React.useState<Array<GetProtestLinkDto>>([]);
	const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
	const [pagination, setPagination] = React.useState({ currentPage: 1, limit: 100 });
	const [lastProtestLinksTotalPages, setLastProtestLinksTotalPages] = React.useState(1);

	const apiConfig = getApiAuthConfig();
	const sellerApi = new SellersApi(apiConfig);
	const protestLinkApi = new ProtestLinksApi(apiConfig);

	const getProtestMethods = () => {
		let methods = ['card', 'billet', 'pix'];

		if (currentSeller && currentSeller?.no_payment_methods && Array.isArray(currentSeller?.no_payment_methods)) {
			if (currentSeller.no_payment_methods.includes('billet')) {
				methods = methods.filter((method) => method !== 'billet');
			}

			if (currentSeller.no_payment_methods.includes('pix')) {
				methods = methods.filter((method) => method !== 'pix');
			}
		}
		return methods;
	};

	const paymentLinkInitialValues = {
		amount: '',
		fee: '',
		description: '',
		expiresIn: addDays(new Date(), 7),
		pre_capture: false,
		payer: {
			name: '',
			document: '',
			email: '',
		},
		search: {
			payer: '',
			document: '',
		},
		paymentMethods: getProtestMethods(),
	};

	const schema = yup.object().shape({
		amount: yup.string().required('Campo obrigatório'),
		fee: yup.string().required('Campo obrigatório'),
		description: yup.string().trim().required('Campo obrigatório'),
		expiresIn: yup.string(),
		payer: yup.object().shape({
			name: yup.string().isCompleteName('Obrigatório nome completo').required('Campo obrigatório'),
			document: yup.string().isCpfOrCnpjOptional('Documento inválido'),
			email: yup.string().email('E-mail inválido').required('Campo obrigatório'),
		}),
		paymentMethods: yup.array().required('1 Método de pagamento é obrigatório'),
	});

	const fetchProtestLinks = async (filters = {}) => {
		const listSellerProtestLinksRequest: ListSellerProtestLinksRequest = {
			currentPage: pagination.currentPage,
			limit: pagination.limit,
			sellerId: currentSeller?.id!,
			...filters,
		};

		try {
			setIsLoading(true);

			const response = await sellerApi.listSellerProtestLinks(listSellerProtestLinksRequest);

			const filteredResults: Array<GetProtestLinkDto> = response.results.map((response) => {
				const filterResponse = JSON.parse(JSON.stringify({ response }));
				const parsedResponse = {
					...filterResponse.response,
					amount: filterResponse?.response?.amount / 100,
					fee: filterResponse?.response?.fee / 100,
					amount_without_fees: (filterResponse?.response?.amount - filterResponse?.response?.fee) / 100,
					form_payment: paymentTypeEnum[filterResponse?.response?.sale?.form_payment],
					updated_at: filterResponse?.response?.sale?.updated_at,
				};

				return parsedResponse;
			});

			setProtestLinks(filteredResults);
			setLastProtestLinksTotalPages(response.total_pages);
		} catch (e) {
			addToast('Não foi possível listar os links de protesto', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	};

	const submitForm = async (values, actions) => {
		const payload = {
			createProtestLinkDto: {
				seller_id: currentSeller?.id!,
				amount: values.amount * 100,
				fee: values.fee * 100,
				description: values.description.trim(),
				expires_in: values.expiresIn,
				payer: {
					document: values?.payer?.document,
					email: values.payer.email,
					name: values.payer.name.trim(),
				},
				payment_methods: values.paymentMethods,
			},
		};

		if (!payload.createProtestLinkDto.payer.document) {
			delete payload.createProtestLinkDto.payer.document;
		}

		if (currentSeller && currentSeller.merchant && currentSeller.merchant.pre_capture) {
			payload.createProtestLinkDto['pre_capture'] = values.pre_capture;
		}
		try {
			setIsSubmitting(true);

			await protestLinkApi.createProtestLink(payload);

			addToast('Link enviado com sucesso', {
				appearance: 'success',
				autoDismiss: true,
			});

			setIsSubmitting(false);

			await fetchProtestLinks();

			actions.resetForm();
		} catch (e) {
			addToast('Não foi possível salvar o link de pagamento', {
				appearance: 'error',
				autoDismiss: true,
			});

			setIsSubmitting(false);
		}
	};

	function navigateToDetails(id: string) {
		history.push(`/admin/sales-history/${id}`, {route: history.location.pathname});
	}

	React.useEffect(() => {
		if (currentSeller) {
			fetchProtestLinks();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSeller, pagination]);

	if (!currentSeller) {
		return <BackofficeAlertContainer />;
	}

	return (
		<Formik enableReinitialize initialValues={paymentLinkInitialValues} validationSchema={schema} onSubmit={submitForm}>
			{({ handleSubmit, setFieldValue, resetForm, values, errors, isValid }) => {
				return (
					<form onSubmit={handleSubmit} style={{ width: '100%' }}>
						<Loader isOpen={isLoading} />
						<Flex pb='8'>
							<Breadcrumb>
								<BreadcrumbItem>
									<BreadcrumbLink href='#' fontSize='md'>
										Resumo
									</BreadcrumbLink>
								</BreadcrumbItem>

								<BreadcrumbItem>
									<BreadcrumbLink href='#' fontSize='md'>
										Venda
									</BreadcrumbLink>
								</BreadcrumbItem>

								<BreadcrumbItem isCurrentPage>
									<BreadcrumbLink opacity='0.9' cursor='unset' href='#' fontSize='md' fontWeight={`semibold`}>
										Link de Protesto
									</BreadcrumbLink>
								</BreadcrumbItem>
							</Breadcrumb>
						</Flex>

						<Title fontSize={isMobile ? `lg` : '2xl'}>Venda por Link</Title>

						<Flex flexDirection={`column`}>
							<Paper>
								<Stack spacing={3} color={`primary`}>
									<Stack isInline spacing={4}>
										<FormControl isRequired>
											<FormLabel>Valor Total do Serviço</FormLabel>
											<FormCurrencyInput name='amount' isDisabled={isSubmitting} />
											<FormErrorMessage name='amount' />
										</FormControl>
										<FormControl isRequired>
											<FormLabel>Emolumento</FormLabel>
											<FormCurrencyInput name='fee' isDisabled={isSubmitting} />
											<FormErrorMessage name='fee' />
										</FormControl>
										<FormControl isRequired>
											<FormLabel>N° do Pedido</FormLabel>
											<FormInput name='description' isDisabled={isSubmitting} />
											<FormErrorMessage name='description' />
										</FormControl>
										<FormControl>
											<FormLabel>Data de Vencimento</FormLabel>
											<FormDatePickerInput name='expiresIn' placeholder={`Informe o período`} minDate={addDays(new Date(), 1)} />
											<FormErrorMessage name='expiresIn' />
										</FormControl>
									</Stack>
									<Stack isInline spacing={4}>
										<FormControl isRequired>
											<FormLabel>Nome Completo</FormLabel>
											<FormInput name='payer[name]' isDisabled={isSubmitting} />
											<FormErrorMessage name='payer[name]' />
										</FormControl>
										<FormControl isRequired>
											<FormLabel>E-mail </FormLabel>
											<FormInput name='payer[email]' isDisabled={isSubmitting} />
											<FormErrorMessage name='payer[email]' />
										</FormControl>
										<FormControl>
											<FormLabel>CPF/CNPJ</FormLabel>
											<FormCpfCnpjInput name='payer[document]' isDisabled={isSubmitting} isSearchPayer={true} setSelectedPayer={() => {}} />
											<FormErrorMessage name='payer[document]' />
										</FormControl>
									</Stack>
									<SimpleGrid columns={3}>
										<FormControl>
											<Stack>
												<Text fontSize={`md`} fontWeight={`bold`}>
													Formas de pagamento
												</Text>
												<CheckboxGroup
													colorScheme='green'
													value={values.paymentMethods}
													onChange={(value) => setFieldValue('paymentMethods', value)}
												>
													<Stack isInline spacing={4}>
														<Checkbox value='card'>Cartão</Checkbox>
														{!currentSeller?.no_payment_methods?.includes('billet') && <Checkbox value='billet'>Boleto</Checkbox>}
														{!currentSeller?.no_payment_methods?.includes('pix') && <Checkbox value='pix'>Pix</Checkbox>}
													</Stack>
												</CheckboxGroup>
											</Stack>
										</FormControl>

										{currentSeller && currentSeller.merchant && currentSeller.merchant.pre_capture && (
											<FormControl>
												<Stack>
													<Text color={`gray.700`} fontSize={`md`} fontWeight={`bold`}>
														Pré-captura
													</Text>

													<Checkbox
														isChecked={values.pre_capture}
														onChange={(e) => {
															setFieldValue('pre_capture', e.target.checked);
														}}
													>
														Sim
													</Checkbox>
												</Stack>
											</FormControl>
										)}
									</SimpleGrid>
								</Stack>
								<FormControl display='flex' flexDirection='column' alignItems='center' marginTop={'2%'}>
									<Button size='lg' type='submit' disabled={!isValid || isSubmitting} bgColor={'primary'} isLoading={isSubmitting}>
										Gerar Link
									</Button>
								</FormControl>
							</Paper>

							<Paper title='Links Gerados'>
								<ProtestLinksFilters
									onChangeFilters={async (filters) => {
										await fetchProtestLinks(filters);
									}}
								/>

								<Flex overflowX='auto'>
									<ProtestLinksTable
										data={protestLinks}
										setPagination={setPagination}
										pagination={pagination}
										totalPages={lastProtestLinksTotalPages}
										onRowClick={(row) => navigateToDetails(row.original.sale.id)}
										seller={currentSeller}
										afterCancel={fetchProtestLinks}
									/>
								</Flex>
							</Paper>
						</Flex>
					</form>
				);
			}}
		</Formik>
	);
};

export default ProtestLinkSale;
