import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink, Flex,
  FormControl,
  FormLabel,
  Stack,
  Tag, Text,
  useBreakpointValue
} from '@chakra-ui/react';
import Button from 'components/Button';
import FormErrorMessage from 'components/Form/FormErrorMessage';
import FormSelect from 'components/Form/FormSelect';
import BackofficeAlertContainer from 'containers/BackofficeAlertContainer';
import { Formik } from 'formik';
import React, { useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useToasts } from 'react-toast-notifications';
import { EditSellerPartialRequest, SellerCheckoutKeyEnum, SellersApi } from '../../clients';
import { useCurrentSeller } from '../../contexts/SellerProvider';
import { getApiAuthConfig } from '../../services/api.service';

const CheckoutsCustomization: React.FC = () => {
  const { currentSeller, updateCurrentSeller } = useCurrentSeller();
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const apiConfig = getApiAuthConfig();
  const { addToast } = useToasts();

  const sellersApi = new SellersApi(apiConfig);

  const customCheckoutsInitialValues = {};
  const customCheckouts = currentSeller?.custom_checkout as Object || {};

  const [selectedCheckouts, setSelectedCheckouts] = React.useState<string>(SellerCheckoutKeyEnum.Pix);
  const [appliedCheckouts, setAppliedCheckouts] = React.useState<string[]>(['-']);
  const [customBlock, setCustomBlock] = React.useState<string>('');
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const getAppliedCheckouts = (template: string) => {
    const appliedCheckouts = {
      pix: ['Pix'],
      billet: ['Boleto'],
      credit_card: ['Cartão de Crédito'],
    };

    return appliedCheckouts[template] || ['-'];
  }

  useEffect(() => {
    const customBlock = customCheckouts[selectedCheckouts]?.custom_block || '';
    const appliedCheckouts = getAppliedCheckouts(selectedCheckouts) || [];
    setCustomBlock(customBlock);
    setAppliedCheckouts(appliedCheckouts)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCheckouts, currentSeller])

  const submitForm = async () => {
    if (currentSeller) {
      const requestParams = {
        editSellerPartialDto: {
          custom_checkout: {
            ...currentSeller.custom_checkout,
            [selectedCheckouts]: {
              key: selectedCheckouts,
              custom_block: customBlock
            },
        }},
        sellerId: currentSeller.id,
      };

      try {
        setIsSubmitting(true);

        const seller = await sellersApi.editSellerPartial(requestParams as unknown as EditSellerPartialRequest);

        updateCurrentSeller(seller);

        addToast('Personalização salva com sucesso', {
          appearance: 'success',
          autoDismiss: true,
        });

        setIsSubmitting(false);
      } catch (e) {
        addToast('Não foi possível salvar a personalização.', {
          appearance: 'error',
          autoDismiss: true,
        });

        setIsSubmitting(false);
      }
    }
  };

  if (!currentSeller) {
    return <BackofficeAlertContainer />;
  }

  return (
    <Flex flexDirection={`column`} w='100%'>
      <Flex pb='8'>
        <Breadcrumb separator={<ChevronRightIcon />} color={`darkGrey`}>
          <BreadcrumbItem>
            <BreadcrumbLink href='#' fontSize='md'>
              Resumo
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink href='#' fontSize='md'>
              Minha Conta
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink href='#' fontSize='md' fontWeight={`semibold`}>
              Personalização de Checkouts
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Flex>

      <Formik
        enableReinitialize
        initialValues={customCheckoutsInitialValues}
        onSubmit={async (_, { resetForm }) => {
          await submitForm();

          resetForm();
        }}
      >
        {({ handleSubmit, isValid }) => {
          return (
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <Flex mb='4'>
                <Text color={`darkGrey`} fontSize={isMobile ? 'lg' : '2xl'} fontWeight={`bold`}>
                  PERSONALIZAÇÃO DE CHECKOUTS
                </Text>
              </Flex>
              

              <Flex flexDirection={`column`}>
                <Flex flexDir={`column`} p={10} bgColor='white' rounded={`md`} boxShadow={`sm`} mt='2' w='100%'>
                  <Stack spacing={3} color={`primary`}>
                    <Stack direction='column' spacing={4}>
                      <Stack direction={isMobile ? 'column' : 'row'} spacing={4}>
                        <FormControl>
                          <FormLabel color={`darkGrey`} fontSize={`md`} fontWeight={`medium`}>
                            Checkout
                          </FormLabel>
                          <FormSelect fontSize={`md`} name='templateKeys'  onChange={(event) => setSelectedCheckouts(event.target.value)}>
                            <option value={SellerCheckoutKeyEnum.Pix} key={SellerCheckoutKeyEnum.Pix}>
                              Pix
                            </option>
                            <option value={SellerCheckoutKeyEnum.Billet} key={SellerCheckoutKeyEnum.Billet}>
                            Boleto
                            </option>
                            <option value={SellerCheckoutKeyEnum.CreditCard} key={SellerCheckoutKeyEnum.CreditCard}>
                              Cartão de Crédito
                            </option>
                          </FormSelect>
                        </FormControl>
                        <FormControl>
                          <FormLabel color={`darkGrey`} fontSize={`md`} fontWeight={`medium`}>
                            Aplicado em
                          </FormLabel>
                          {
                            appliedCheckouts.map((appliedCheckout: string) => (
                              <Tag size='md' mr='2' mb='2'>
                                {appliedCheckout}
                              </Tag>
                            ))
                          }
                        </FormControl>
                      </Stack>
                      <Stack>
                        <FormControl>
                          <FormLabel color={`darkGrey`} fontSize={`md`} fontWeight={`medium`}>
                            Informação adicional
                          </FormLabel>
                          <ReactQuill
                            theme='snow'
                            value={customBlock}

                            onChange={(value) => {
                              setCustomBlock(value);
                            }}
                          />
                          <FormErrorMessage name='customBlock' />
                        </FormControl>
                      </Stack>
                    </Stack>
                  </Stack>
                  <FormControl display='flex' justifyContent={isMobile ? 'center' : 'flex-end'} mt='4'>
                    <Button size='lg' type='submit' disabled={!isValid || isSubmitting} bgColor={'primary'} isLoading={isSubmitting}>
                      Salvar Personalização
                    </Button>
                  </FormControl>
                </Flex>
              </Flex>
            </form>
          );
        }}
      </Formik>
    </Flex>
  );
};

export default CheckoutsCustomization;
