/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateSalePlanDto,
    CreateSalePlanDtoFromJSON,
    CreateSalePlanDtoToJSON,
    GetPaymentTypeDto,
    GetPaymentTypeDtoFromJSON,
    GetPaymentTypeDtoToJSON,
    GetSalePlanDto,
    GetSalePlanDtoFromJSON,
    GetSalePlanDtoToJSON,
    IncludePaymentTypesDto,
    IncludePaymentTypesDtoFromJSON,
    IncludePaymentTypesDtoToJSON,
    PaginatedDto,
    PaginatedDtoFromJSON,
    PaginatedDtoToJSON,
} from '../models';

export interface AddPaymentTypeRequest {
    salePlanId: string;
    includePaymentTypesDto: IncludePaymentTypesDto;
}

export interface CreateSalesPlanRequest {
    createSalePlanDto: CreateSalePlanDto;
}

export interface GetSalePlanRequest {
    salePlanId: string;
}

export interface ListAllSalePlanRequest {
    limit: number;
    currentPage: number;
    sort?: ListAllSalePlanSortEnum;
    id?: string;
    type?: Array<ListAllSalePlanTypeEnum>;
    name?: string;
}

export interface ListSalePlanRequest {
    mode?: ListSalePlanModeEnum;
}

export interface ListSalePlanPaymentTypesRequest {
    salePlanId: string;
}

/**
 * 
 */
export class SalesPlansApi extends runtime.BaseAPI {

    /**
     * Associar um ou mais tipo(s) de pagamento(s) a um plano de vendas
     */
    async addPaymentTypeRaw(requestParameters: AddPaymentTypeRequest): Promise<runtime.ApiResponse<GetSalePlanDto>> {
        if (requestParameters.salePlanId === null || requestParameters.salePlanId === undefined) {
            throw new runtime.RequiredError('salePlanId','Required parameter requestParameters.salePlanId was null or undefined when calling addPaymentType.');
        }

        if (requestParameters.includePaymentTypesDto === null || requestParameters.includePaymentTypesDto === undefined) {
            throw new runtime.RequiredError('includePaymentTypesDto','Required parameter requestParameters.includePaymentTypesDto was null or undefined when calling addPaymentType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sales-plans/{salePlanId}/payment-types`.replace(`{${"salePlanId"}}`, encodeURIComponent(String(requestParameters.salePlanId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncludePaymentTypesDtoToJSON(requestParameters.includePaymentTypesDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSalePlanDtoFromJSON(jsonValue));
    }

    /**
     * Associar um ou mais tipo(s) de pagamento(s) a um plano de vendas
     */
    async addPaymentType(requestParameters: AddPaymentTypeRequest): Promise<GetSalePlanDto> {
        const response = await this.addPaymentTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Criar plano de vendas
     */
    async createSalesPlanRaw(requestParameters: CreateSalesPlanRequest): Promise<runtime.ApiResponse<GetSalePlanDto>> {
        if (requestParameters.createSalePlanDto === null || requestParameters.createSalePlanDto === undefined) {
            throw new runtime.RequiredError('createSalePlanDto','Required parameter requestParameters.createSalePlanDto was null or undefined when calling createSalesPlan.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sales-plans`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSalePlanDtoToJSON(requestParameters.createSalePlanDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSalePlanDtoFromJSON(jsonValue));
    }

    /**
     * Criar plano de vendas
     */
    async createSalesPlan(requestParameters: CreateSalesPlanRequest): Promise<GetSalePlanDto> {
        const response = await this.createSalesPlanRaw(requestParameters);
        return await response.value();
    }

    /**
     * Obtém dados do plano de venda através do id.
     */
    async getSalePlanRaw(requestParameters: GetSalePlanRequest): Promise<runtime.ApiResponse<GetSalePlanDto>> {
        if (requestParameters.salePlanId === null || requestParameters.salePlanId === undefined) {
            throw new runtime.RequiredError('salePlanId','Required parameter requestParameters.salePlanId was null or undefined when calling getSalePlan.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sales-plans/{salePlanId}`.replace(`{${"salePlanId"}}`, encodeURIComponent(String(requestParameters.salePlanId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSalePlanDtoFromJSON(jsonValue));
    }

    /**
     * Obtém dados do plano de venda através do id.
     */
    async getSalePlan(requestParameters: GetSalePlanRequest): Promise<GetSalePlanDto> {
        const response = await this.getSalePlanRaw(requestParameters);
        return await response.value();
    }

    /**
     * Listar Todos planos de venda
     */
    async listAllSalePlanRaw(requestParameters: ListAllSalePlanRequest): Promise<runtime.ApiResponse<PaginatedDto>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling listAllSalePlan.');
        }

        if (requestParameters.currentPage === null || requestParameters.currentPage === undefined) {
            throw new runtime.RequiredError('currentPage','Required parameter requestParameters.currentPage was null or undefined when calling listAllSalePlan.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.currentPage !== undefined) {
            queryParameters['current_page'] = requestParameters.currentPage;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.type) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sales-plans`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedDtoFromJSON(jsonValue));
    }

    /**
     * Listar Todos planos de venda
     */
    async listAllSalePlan(requestParameters: ListAllSalePlanRequest): Promise<PaginatedDto> {
        const response = await this.listAllSalePlanRaw(requestParameters);
        return await response.value();
    }

    /**
     * Listar planos de venda
     */
    async listSalePlanRaw(requestParameters: ListSalePlanRequest): Promise<runtime.ApiResponse<Array<GetSalePlanDto>>> {
        const queryParameters: any = {};

        if (requestParameters.mode !== undefined) {
            queryParameters['mode'] = requestParameters.mode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sales-plans/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetSalePlanDtoFromJSON));
    }

    /**
     * Listar planos de venda
     */
    async listSalePlan(requestParameters: ListSalePlanRequest): Promise<Array<GetSalePlanDto>> {
        const response = await this.listSalePlanRaw(requestParameters);
        return await response.value();
    }

    /**
     * Listar as formas de pagamento do plano de vendas informado
     */
    async listSalePlanPaymentTypesRaw(requestParameters: ListSalePlanPaymentTypesRequest): Promise<runtime.ApiResponse<Array<GetPaymentTypeDto>>> {
        if (requestParameters.salePlanId === null || requestParameters.salePlanId === undefined) {
            throw new runtime.RequiredError('salePlanId','Required parameter requestParameters.salePlanId was null or undefined when calling listSalePlanPaymentTypes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sales-plans/{salePlanId}/payment-types`.replace(`{${"salePlanId"}}`, encodeURIComponent(String(requestParameters.salePlanId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetPaymentTypeDtoFromJSON));
    }

    /**
     * Listar as formas de pagamento do plano de vendas informado
     */
    async listSalePlanPaymentTypes(requestParameters: ListSalePlanPaymentTypesRequest): Promise<Array<GetPaymentTypeDto>> {
        const response = await this.listSalePlanPaymentTypesRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum ListAllSalePlanSortEnum {
    Descending = 'time-descending',
    Ascending = 'time-ascending'
}
/**
    * @export
    * @enum {string}
    */
export enum ListAllSalePlanTypeEnum {
    Markup = 'markup',
    Spread = 'spread',
    Split = 'split'
}
/**
    * @export
    * @enum {string}
    */
export enum ListSalePlanModeEnum {
    Raw = 'raw',
    Full = 'full'
}
