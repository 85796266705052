import React from 'react';

import { HeaderTitle, CellContent, SimpleTable } from '../../../containers/SimpleTable';

import { Button, Icon } from '@chakra-ui/react';

import { FiEdit } from 'react-icons/fi';

import { formatWithoutTimezone } from 'services/date.service';
import { listPosStatus } from 'services/enums.service';

export default ({ data, setPagination, pagination, totalPages, handleOpenPosModal }) => {
	const columns = React.useMemo(
		() => [
			{
				Header: ({ column }) => <HeaderTitle column={column}>Data de Vinculação</HeaderTitle>,
				accessor: 'associated_at',
				Cell: ({ cell: { value } }) => <CellContent>{formatWithoutTimezone(value, 'dd/MM/yyyy HH:mm:ss')}</CellContent>,
			},
			{
				Header: ({ column }) => <HeaderTitle column={column}>Estabelecimento</HeaderTitle>,
				accessor: 'seller',
				Cell: ({ cell: { row } }) => <CellContent textAlign={`center`}>{row.original.seller.name}</CellContent>,
			},
			{
				Header: ({ column }) => <HeaderTitle column={column}>Código da máquina</HeaderTitle>,
				accessor: 'terminal_id',
				Cell: ({ cell: { value } }) => <CellContent>{value}</CellContent>,
			},
			{
				Header: ({ column }) => <HeaderTitle column={column}>Status</HeaderTitle>,
				accessor: 'status',
				Cell: ({ cell: { value } }) => <CellContent textAlign={`center`}>{listPosStatus[value]}</CellContent>,
			},
			{
				Header: ({ column }) => <HeaderTitle column={column}>Editar</HeaderTitle>,
				accessor: 'id',
				Cell: ({ cell: { row } }) => (
					<CellContent>
						<Button
							background={'transparent'}
							onClick={() => {
								handleOpenPosModal(row.original);
							}}
						>
							<Icon as={FiEdit} boxSize={5} />
						</Button>
					</CellContent>
				),
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	return <SimpleTable columns={columns} data={data} setPagination={setPagination} pagination={pagination} totalPages={totalPages} pl={2} />;
};
