import { Button, Icon } from '@chakra-ui/react';
import React from 'react';
import { FiEdit, FiTrash2 } from 'react-icons/fi';

import { formatAmount } from 'services/masks.service';

import { CellContent, HeaderTitle, SimpleTable } from 'containers/SimpleTable';
import { SplitSellerValue } from 'containers/SplitRulesWrapper';

type SplitSellerValueMapped = {
	sellerName: string;
	amount: number;
	edit: number;
	remove: number;
};

export default ({ data, editSplit, removeFromSplits, ...rest }) => {
	const [splits, setSplits] = React.useState<SplitSellerValueMapped[]>([]);

	React.useEffect(() => {
		(() => {
			const splitValuesProp = [...(data as SplitSellerValue[])];

			const splitsMapped = splitValuesProp?.map((splitSellerValue, i) => {
				return {
					sellerName: splitSellerValue.seller.name,
					amount: splitSellerValue.amount,
					edit: i,
					remove: i,
				};
			});
			setSplits(splitsMapped);
		})();
	}, [data]);

	const columns = [
		{
			Header: ({ column }) => <HeaderTitle column={column}>Estabelecimento</HeaderTitle>,
			accessor: 'sellerName',
			Cell: ({ cell: { value } }) => <CellContent>{value}</CellContent>,
		},
		{
			Header: ({ column }) => <HeaderTitle column={column}>Valor</HeaderTitle>,
			accessor: 'amount',
			Cell: ({ cell: { value } }) => <CellContent>{`R$ ${formatAmount(value)}`}</CellContent>,
		},
		{
			Header: ({ column }) => <HeaderTitle column={column}>Editar</HeaderTitle>,
			accessor: 'edit',
			Cell: ({ cell: { value } }) => (
				<CellContent>
					<Button
						_focus={{ outline: 'none', background: 'transparent' }}
						_hover={{ background: 'transparent', opacity: 0.8 }}
						background={'transparent'}
						onClick={() => editSplit(value)}
					>
						<Icon as={FiEdit} boxSize={5} />
					</Button>
				</CellContent>
			),
		},
		{
			Header: ({ column }) => <HeaderTitle column={column}>Remover</HeaderTitle>,
			accessor: 'remove',
			Cell: ({ cell: { value } }) => (
				<CellContent>
					<Button
						_focus={{ outline: 'none', background: 'transparent' }}
						_hover={{ background: 'transparent', opacity: 0.8 }}
						background={'transparent'}
						onClick={() => removeFromSplits(value)}
					>
						<Icon as={FiTrash2} boxSize={5} />
					</Button>
				</CellContent>
			),
		},
	];

	return <SimpleTable data={splits} columns={columns} variant={rest.variant || 'normal'} />;
};
