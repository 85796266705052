import React from 'react';

import { Flex, Heading, Box, Spacer, Text } from '@chakra-ui/react';

import Modal from '../../../containers/NewModal';
import Button from '../../../components/Button';

type BilletModalProps = {
	confirmPayment: boolean;
	url: string;
	setConfirmPayment(open: boolean): void;
	resetForm(): void;
	isLoading: boolean;
	setLinkPayed(payed: boolean): void;
};

const BilletModal: React.FC<BilletModalProps> = ({ confirmPayment, url, setConfirmPayment, resetForm, isLoading, setLinkPayed }) => (
	<Modal onClose={() => {}} isOpen={confirmPayment}>
		<Box borderRadius='md'>
			<Flex p={12} flexDirection='column' justifyContent='center'>
				<Heading paddingBottom={8}>
					<Text textAlign='center' fontSize='lg' fontWeight='semibold'>Boleto gerado com sucesso!</Text>
				</Heading>
				<Flex flexDirection='row' alignItems='center' fontSize='md' fontWeight='semibold' mt={4}>
					<Spacer />

					<Button
						fontWeight='semibold'
						size='lg'
						bgColor='primary'
						color='white'
						isFullWidth
						onClick={async () => {
							window.open(url, '_blank');
							setConfirmPayment(!confirmPayment);
							resetForm();
							setLinkPayed(true);
						}}
						isLoading={isLoading}
					>
						Download
					</Button>
				</Flex>
			</Flex>
		</Box>
	</Modal>
);

export default BilletModal;
