import React from 'react';
import {
	FormControl,
	SimpleGrid,
	FormLabel,
	GridItem,
	Input,
} from '@chakra-ui/react';

import FormZipcodeInput from 'components/Form/FormZipcodeInput';
import FormErrorMessage from 'components/Form/FormErrorMessage';
import FormSelect from 'components/Form/FormSelect';
import getzipcode from 'services/getzipcode.service';
import FormInput from 'components/Form/FormInput';
import { states } from 'config/constants';

type Props = {
	onSearchResults(obj: any): void;
	errors: any;
};

const AddressForm: React.FC<Props> = (props) => (
	<SimpleGrid
		columns={{base: 1, md: 6, xl: 12}}
		color='gray.700'
		gap={4}
	>
		<GridItem colStart={{base: 1}} colSpan={{base: 4, md: 2, xl: 2}}>
			<FormControl id='address.zipcode' isRequired>
				<FormLabel fontSize='md' fontWeight='bold'>
					CEP
				</FormLabel>
				<FormZipcodeInput
					name='address.zipcode'
					performSearch={getzipcode}
					onSearchResults={props.onSearchResults}
				/>
				<FormErrorMessage name='address.zipcode' />
			</FormControl>
		</GridItem>

		<GridItem colStart={{base: 1}} colSpan={{base: 4, md: 3, xl: 4}}>
			<FormControl id='address.street' isRequired>
				<FormLabel fontSize='md' fontWeight='bold'>
					Logradouro
				</FormLabel>
				<FormInput as={Input} name='address.street' />
				<FormErrorMessage name='address.street' />
			</FormControl>
		</GridItem>

		<GridItem colStart={{base: 1}} colSpan={{base: 4, md: 1, xl: 2}}>
			<FormControl id='address.number' isRequired>
				<FormLabel fontSize='md' fontWeight='bold'>
					Número
				</FormLabel>
				<FormInput as={Input} name='address.number' />
				<FormErrorMessage name='address.number' />
			</FormControl>
		</GridItem>

		<GridItem colStart={{base: 1}} colSpan={{base: 4, md: 3, xl: 4}}>
			<FormControl id='address.complement'>
				<FormLabel fontSize='md' fontWeight='bold'>
					Complemento
				</FormLabel>
				<FormInput as={Input} name='address.complement' />
				<FormErrorMessage name='address.complement' />
			</FormControl>
		</GridItem>

		<GridItem colStart={{base: 1, xl: 1}} colSpan={{base: 4, md: 3, xl: 5}}>
			<FormControl id='address.district' isRequired>
				<FormLabel fontSize={`md`} fontWeight='bold'>
					Bairro
				</FormLabel>
				<FormInput as={Input} name='address.district' />
				<FormErrorMessage name='address.district' />
			</FormControl>
		</GridItem>

		<GridItem colStart={{base: 1}} colSpan={{base: 4, md: 3, xl: 5}}>
			<FormControl id='address.city' isRequired>
				<FormLabel fontSize='md' fontWeight='bold'>
					Cidade
				</FormLabel>
				<FormInput as={Input} name='address.city' />
				<FormErrorMessage name='address.city' />
			</FormControl>
		</GridItem>

		<GridItem colStart={{base: 1}} colSpan={{base: 4, md: 3, xl: 2}}>
			<FormControl id='address.state' isRequired>
				<FormLabel fontSize='md' fontWeight='bold'>
					UF
				</FormLabel>
				<FormSelect name='address.state'>
					<option value='' />
					{states.map((state) => (
						<option value={state.value} key={state.value}>
							{state.text}
						</option>
					))}
				</FormSelect>
				<FormErrorMessage name='address.state' />
			</FormControl>
		</GridItem>
	</SimpleGrid>
);

export default AddressForm;
