import React from 'react';

import { HeaderTitle, CellContent, SimpleTable, HeaderTitleProps, CellContentProps } from '../../../containers/SimpleTable';
import { Icon, Button, Flex, Tooltip, IconButton } from '@chakra-ui/react';
import { FiChevronLeft, FiChevronRight, FiChevronsLeft, FiChevronsRight } from 'react-icons/fi';
import FormErrorMessage from '../../../components/Form/FormErrorMessage';
import CurrencyInput from '../../../components/CurrencyInput';

import { pagination } from '../../../services/pagination.service';

import { ResponsibleGoalDtoTypeEnum } from '../../../clients';

interface responsiblesGoalI {
	name: string;
	goal: string;
	type: ResponsibleGoalDtoTypeEnum;
}

export type responsibleGoalType = {
	[key: string]: responsiblesGoalI;
};

const HeaderTitleStyled: React.FC<HeaderTitleProps> = (props) => {
	return (
		<HeaderTitle {...props} color='black' justifyContent='flex-start' fontSize='sm' fontWeight='bold'>
			{props.children}
		</HeaderTitle>
	);
};

const CellContentStyled: React.FC<CellContentProps> = (props) => {
	return (
		<CellContent {...props} color='primary' justifyContent='flex-start' fontSize='sm' fontWeight='semibold'>
			{props.children}
		</CellContent>
	);
};

function ResponsiblesGoalTable({ responsibles, createReference, responsiblesGoal, setResponsiblesGoal }) {
	const [tablePagination, setTablePagination] = React.useState({ currentPage: 1 });

	const itemsByPagination = 3;

	const { current_page, total_pages, results } = pagination(responsibles, tablePagination.currentPage, itemsByPagination);

	function handleResponsibleChange(event, row) {
		const { id, type } = row?.original;
		const parsedResponsible = { name: row?.original?.name, goal: event.target.value, type: type };

		setResponsiblesGoal({ ...responsiblesGoal, [id]: parsedResponsible });
	}

	const columns = React.useMemo(
		() => {
			let cols: any = [];

			return cols.concat([
				{
					Header: ({ column }) => <HeaderTitleStyled column={column}>Responsável</HeaderTitleStyled>,
					accessor: 'name',
					Cell: ({ cell: { value } }) => <CellContentStyled>{value}</CellContentStyled>,
				},
				{
					Header: ({ column }) => <HeaderTitle column={column}>Meta</HeaderTitle>,
					accessor: 'goal',
					Cell: ({ cell: { row } }) => {
						return createReference ? (
							<CellContent>
								<CurrencyInput
									key={`input-${row.original.name}-${row.original.type}`.toLowerCase()}
									name={`input-${row.original.name}-${row.original.type}`.toLowerCase()}
									defaultValue={responsiblesGoal[row.original.id]?.goal || ''}
									onBlur={(event) => handleResponsibleChange(event, row)}
								/>
								<FormErrorMessage name={`input-${row.values.name}`.toLowerCase()} />
							</CellContent>
						) : (
							<CellContentStyled>
								<CurrencyInput
									key={`input-${row.original.name}-${row.original.type}`.toLowerCase()}
									name={`input-${row.original.name}-${row.original.type}`.toLowerCase()}
									defaultValue={responsiblesGoal[row.original.id]?.goal || ''}
									onBlur={(event) => handleResponsibleChange(event, row)}
								/>
								<FormErrorMessage name={`input-${row.values.name}`.toLowerCase()} />
							</CellContentStyled>
						);
					},
				},
			]);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[responsiblesGoal]
	);

	function handlePreviousPaginate() {
		if (tablePagination.currentPage > 1) {
			setTablePagination({ currentPage: tablePagination.currentPage - 1 });
		}
	}

	function handleNextPaginate() {
		if (tablePagination.currentPage < total_pages) {
			setTablePagination({ currentPage: tablePagination.currentPage + 1 });
		}
	}

	function handleFirstPaginate() {
		setTablePagination({ currentPage: 1 });
	}

	function handleLastPaginate() {
		setTablePagination({ currentPage: total_pages });
	}

	return (
		<Flex flexDirection='column'>
			<SimpleTable columns={columns} data={results} pl={2} variant='unstyled' minHeight='42vh' />

			<Flex w='100%' justifyContent='space-evenly' mt={4}>
				<Tooltip label='Primeira Página'>
					<IconButton
						isDisabled={tablePagination.currentPage === 1 ? true : false}
						_hover={{ outline: 'none' }}
						_focus={{ outline: 'none' }}
						size='sm'
						icon={<Icon as={FiChevronsLeft}></Icon>}
						aria-label='Primeira Página'
						onClick={handleFirstPaginate}
					/>
				</Tooltip>

				<Tooltip label='Página Anterior'>
					<IconButton
						_hover={{ outline: 'none' }}
						_focus={{ outline: 'none' }}
						size='sm'
						icon={<Icon as={FiChevronLeft}></Icon>}
						aria-label='Página Anterior'
						onClick={handlePreviousPaginate}
					/>
				</Tooltip>

				<Tooltip label='Página Atual'>
					<Button _hover={{ outline: 'none' }} _focus={{ outline: 'none' }} size='sm' aria-label='Página Atual'>
						{current_page || 1}
					</Button>
				</Tooltip>

				<Tooltip label='Próxima Página'>
					<IconButton
						_hover={{ outline: 'none' }}
						_focus={{ outline: 'none' }}
						size='sm'
						icon={<Icon as={FiChevronRight}></Icon>}
						aria-label='Próxima Página'
						onClick={handleNextPaginate}
					/>
				</Tooltip>

				<Tooltip label='Última Página'>
					<IconButton
						isDisabled={tablePagination.currentPage === total_pages ? true : false}
						_hover={{ outline: 'none' }}
						_focus={{ outline: 'none' }}
						size='sm'
						icon={<Icon as={FiChevronsRight}></Icon>}
						aria-label='Última Página'
						onClick={handleLastPaginate}
					/>
				</Tooltip>
			</Flex>
		</Flex>
	);
}

export default ResponsiblesGoalTable;
