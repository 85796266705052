import React from 'react';

import {
	Flex,
	FormControl,
	InputGroup,
	InputRightElement,
	IconButton,
	BreadcrumbItem,
	BreadcrumbLink,
	useBreakpointValue,
	SimpleGrid,
	GridItem,
} from '@chakra-ui/react';
import { useToasts } from 'react-toast-notifications';

import Loader from 'containers/Loader';

import { BiExport } from 'react-icons/bi';

import {
	CreateBankAccountDto,
	EditSellerDto,
	EditSellerDtoBilletProviderEnum,
	EditSellerDtoSettlementTypeEnum,
	EditSellerDtoStatusEnum,
	EditSellerDtoTypeEnum,
	GetSellerDto,
	SellersApi,
	ListSellersModeEnum,
	CreateAddressDto
	} from '../../clients';

import Button from '../../components/Button';
import Paper from '../../containers/Paper';
import SampleTable from './components/SampleTable';

import { getApiAuthConfig } from '../../services/api.service';
import { useAuth } from '../../contexts/AuthProvider';
import { useCurrentSeller } from '../../contexts/SellerProvider';
import { useHistory } from 'react-router-dom';

import MultiSelect from '../../components/MultiSelect';
import Input from '../../components/Input';

import { SearchIcon } from '@chakra-ui/icons';
import { Formik } from 'formik';
import { seller_status } from '../../config/constants';
import ActivationModal from './components/ActivationModal';
import FormCnsInput from '../../components/Form/FormCnsInput';
import FormCpfCnpjInput from '../../components/Form/FormCpfCnpjInput';
import Breadcrumb from 'components/Breadcrumb';
import ExportFileSuccessModal from '../../containers/ExportFileSuccessModal';

const Sellers: React.FC = () => {
	const apiConfig = getApiAuthConfig();
	const sellersApi = new SellersApi(apiConfig);
	const history = useHistory();

	const { isBackoffice } = useAuth();
	const isMobile = useBreakpointValue({ base: true, lg: false });

	const [isLoading, setIsLoading] = React.useState(false);
	const [filters, setFilters] = React.useState({});

	const [sellers, setSellers] = React.useState<any>();
	const [pagination, setPagination] = React.useState({ currentPage: 1, limit: 100 });
	const [totalPages, setTotalPages] = React.useState(1);
	const [activationModalIsOpen, setActivationModalIsOpen] = React.useState<boolean>(false);
	const [activingSeller, setActivingSeller] = React.useState<GetSellerDto | undefined>(undefined);
	const [exportIsLoading, setExportIsLoading] = React.useState<boolean>(false);
	const [openExportSuccessModal, setOpenExportSuccessModal] = React.useState<boolean>(false);

	const { addToast } = useToasts();
	const { currentSeller } = useCurrentSeller();

	const initialValues = {
		status: [],
		name: '',
		document: '',
		cns: '',
		code: '',
	};

	async function fetchListSellersRequest(params) {
		const { status, document, cns, ...parsedParams } = params;

		setIsLoading(true);

		if (currentSeller) {
			try {
				const response = await sellersApi.listSellers({
					currentPage: pagination.currentPage,
					limit: pagination.limit,
					sellerId: currentSeller.id,
					status: status?.map((v) => v.value),
					mode: ListSellersModeEnum.RawWithSummary,
					document: !document ? '' : document.replace(/\D+/gi, ''),
					cns: !cns ? '' : cns.replace(/\D+/gi, ''),
					...parsedParams,
				});

				setSellers(response.results);
				setTotalPages(response.total_pages);
			} catch (error) {
				addToast('Erro ao buscar os dados', {
					appearance: 'error',
					autoDismiss: true,
				});
			} finally {
				setIsLoading(false);
			}
		} else {
			try {
				const response = await sellersApi.listSellers({
					currentPage: pagination.currentPage,
					limit: pagination.limit,
					status: status?.map((v) => v.value),
					mode: ListSellersModeEnum.RawWithSummary,
					document: !document ? '' : document.replace(/\D+/gi, ''),
					cns: !cns ? '' : cns.replace(/\D+/gi, ''),
					...parsedParams,
				});
				setSellers(response.results);
				setTotalPages(response.total_pages);
			} catch (error) {
				addToast('Erro ao buscar os dados', {
					appearance: 'error',
					autoDismiss: true,
				});
			} finally {
				setIsLoading(false);
			}
		}
	}

	React.useEffect(() => {
		fetchListSellersRequest(filters);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSeller, pagination]);

	const handleFormSubmit = async (values) => {
		setFilters(values);
		fetchListSellersRequest(values);
	};

	const openActivationModal = async (id: string) => {
		setIsLoading(true);
		try {
			const seller = await sellersApi.getSeller({ sellerId: id });

			setActivingSeller(seller);
		} catch (error) {
			addToast('Erro ao buscar os dados', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
			setActivationModalIsOpen(!activationModalIsOpen);
		}
	}

	const closeActivationModal = () => {
		setActivingSeller(undefined);
		setActivationModalIsOpen(!activationModalIsOpen);
	}

	const confirmActivationSeller = async () => {
		setIsLoading(true);
		if (!activingSeller || !activingSeller.merchant.id) {
			return;
		}
		try {
			const editSellerDto: EditSellerDto = {
				merchant_id: activingSeller.merchant.id,
				sale_plan_id: activingSeller.sale_plan.id,
				status: EditSellerDtoStatusEnum.Activated,
				name: activingSeller.name,
				description: activingSeller.description,
				document: activingSeller.document,
				state_registration: activingSeller.state_registration,
				code: activingSeller.code,
				settlement_type: activingSeller.settlement_type as unknown as EditSellerDtoSettlementTypeEnum,
				address: activingSeller.address as unknown as CreateAddressDto,
				bank_account: activingSeller.bank_account as unknown as CreateBankAccountDto,
				monthly_billing: activingSeller.monthly_billing,
				covenant: activingSeller.covenant,
				is_split: activingSeller.is_split,
				phone: activingSeller.phone,
				absorb_costs: activingSeller.absorb_costs,
				type: activingSeller.type as unknown as EditSellerDtoTypeEnum,
				pix_pos: activingSeller.pix_pos,
				billet_provider: activingSeller.billet_provider as unknown as EditSellerDtoBilletProviderEnum,
				no_payment_methods: activingSeller.no_payment_methods,
				has_split_rules: activingSeller.has_split_rules,
				protocol_customizations: activingSeller.protocol_customizations
			}

			await sellersApi.editSeller({ editSellerDto, sellerId: activingSeller.id });

		} catch (error) {
			addToast('Erro ao ativar estabelecimento', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setActivingSeller(undefined);
			setActivationModalIsOpen(!activationModalIsOpen);
			setIsLoading(false);
			fetchListSellersRequest(filters);
		}
	}

	const handleExportReportFile = async (params) => {
		setExportIsLoading(true);

		try {
			const { name, document, cns, code, status } = params;

			await sellersApi.createSellersListExportRequest({
				createExportSellersListDto: {
					name,
					document,
					cns,
					code,
					status: status?.map((v) => v.value),
				}
			});
			setOpenExportSuccessModal(true);
		} catch (e) {
			addToast('Ocorreu um erro no processamento', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setExportIsLoading(false);
		}
	};

	return (
		<Flex flexDirection='column'>
			<Loader isOpen={isLoading} />

			<Flex direction={['column', 'column', 'row']} justify='space-between'>
				<Breadcrumb>
					<BreadcrumbItem>
						<BreadcrumbLink fontSize='md'>Resumo</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink fontSize='md' fontWeight='semibold'>
							Estabelecimentos
						</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>

				<Flex direction={isMobile ? 'column' : 'row'}>
					{isBackoffice && (
						<Button
							title='Exportar para Excel'
							bgColor='black'
							isDisabled={exportIsLoading || (!(sellers?.length))}
							isLoading={exportIsLoading}
							onClick={() => handleExportReportFile(filters)}
							leftIcon={<BiExport size={20} />}
							className='button__export-seller'
							mr={isMobile ? 0 : 1}
							mb={3}
							order={[1, 0]}
						>
							Exportar Excel
						</Button>
					)}
					<Button
						title='Cadastrar Estabelecimento'
						isDisabled={!isBackoffice}
						bgColor='primary'
						onClick={() => {
							history.push(`/admin/estabelecimentos/criar`);
						}}
						className='button__register-seller'
						mb={3}
					>
						Cadastrar Estabelecimento
					</Button>
				</Flex>
			</Flex>

			<ExportFileSuccessModal
				showCloseButton
				isOpen={openExportSuccessModal}
				onOkClick={() => {
					setOpenExportSuccessModal(false);
				}}
				onClose={() => {
					setOpenExportSuccessModal(false);
				}}
			/>

			<Formik enableReinitialize initialValues={initialValues} onSubmit={handleFormSubmit}>
				{({ handleSubmit, handleChange, setFieldValue, values, resetForm }) => {
					return (
						<form onSubmit={handleSubmit}>
							<Paper p={6}>
								<SimpleGrid columns={{base: 1, md: 12, xl: 12}} gap={4}>
									<GridItem colStart={{base: 1}} colSpan={{base: 6, md: 12, xl: 3}}>
										<FormControl>
											<InputGroup>
												<Input
													name='name'
													placeholder='Nome do Estabelecimento'
													value={values.name}
													onChange={(event) => {
														handleChange(event);
													}}
													borderColor='darkGrey'
													onBlur={() => handleSubmit()}
												/>
												<InputRightElement
													children={
														<IconButton
															_hover={{ backgroundColor: 'transparent' }}
															_focus={{ outline: 'none' }}
															aria-label='Search database'
															icon={<SearchIcon />}
															color='primary'
															backgroundColor='transparent'
															onClick={() => handleSubmit()}
														/>
													}
												/>
											</InputGroup>
										</FormControl>
									</GridItem>

									<GridItem colSpan={{base: 6, md: 4, xl: 2}}>
										<FormControl>
											<InputGroup>
												<FormCpfCnpjInput
													name='document'
													placeholder='CPF/CNPJ'
													isDisabled={false}
													isSearchPayer={false}
													value={values.document}
													onBlur={()=> handleSubmit()}
												/>
												<InputRightElement
													children={
														<IconButton
															_hover={{ backgroundColor: 'transparent' }}
															_focus={{ outline: 'none' }}
															aria-label='Search database'
															icon={<SearchIcon />}
															color='primary'
															backgroundColor='transparent'
															onClick={() => handleSubmit()}
														/>
													}
												/>
											</InputGroup>
										</FormControl>
									</GridItem>

									<GridItem colStart={{base: 1}} colSpan={{base: 6, md: 5, xl: 2}}>
										<FormControl>
											<InputGroup>
												<Input
													name='code'
													placeholder='Chave'
													value={values.code}
													onChange={(event) => {
														handleChange(event);
													}}
													borderColor='darkGrey'
													onBlur={() => handleSubmit()}
												/>
												<InputRightElement
													children={
														<IconButton
															_hover={{ backgroundColor: 'transparent' }}
															_focus={{ outline: 'none' }}
															aria-label='Search database'
															icon={<SearchIcon />}
															color='primary'
															backgroundColor='transparent'
															onClick={() => handleSubmit()}
														/>
													}
												/>
											</InputGroup>
										</FormControl>
									</GridItem>

									<GridItem colSpan={{base: 6, md: 3, xl: 2}}>
										<FormControl>
											<InputGroup>
												<FormCnsInput
													name='cns'
													placeholder='CNS'
													value={values.cns}
													onBlur={() => handleSubmit()}
												/>
												<InputRightElement
													children={
														<IconButton
															_hover={{ backgroundColor: 'transparent' }}
															_focus={{ outline: 'none' }}
															aria-label='Search database'
															icon={<SearchIcon />}
															color='primary'
															backgroundColor='transparent'
															onClick={() => handleSubmit()}
														/>
													}
												/>
											</InputGroup>
										</FormControl>
									</GridItem>

									<GridItem colStart={{base: 1}} colSpan={{base: 6, md: 9, xl: 2}}>
										<FormControl>
											<MultiSelect
												id='status'
												placeholder='Status'
												options={seller_status.map((costs) => ({ value: costs.value, label: costs.text }))}
												value={values.status}
												onChange={(value) => {
													setFieldValue('status', value);
													handleSubmit();
												}}
											/>
										</FormControl>
									</GridItem>

									<GridItem colStart={{base: 3, md: 10}} colSpan={{base: 4, md: 2, xl: 2}}>
										{(
											values.name !== ''
												|| values.status.length > 0
												|| values.document !== ''
												|| values.cns !== ''
												|| values.code !== ''
										) && (
											<Button
												onClick={() => {
													resetForm();
													handleSubmit();
												}}
												paddingInline='10px'
												_focus={{ outline: 'none' }}
												color='secondary'
												bgColor='transparent'
											>
												Limpar Filtros
											</Button>
										)}
									</GridItem>
								</SimpleGrid>

								<Flex overflowX='auto' overflowY='hidden' flexDirection='column'>
									{sellers && (
										<SampleTable
											openActivationModal={openActivationModal}
											data={sellers}
											setPagination={setPagination}
											pagination={pagination}
											totalPages={totalPages}
										/>
									)}
								</Flex>
							</Paper>
						</form>
					);
				}}
			</Formik>
			<ActivationModal confirmActivationSeller={confirmActivationSeller} isOpen={activationModalIsOpen} closeActivationModal={closeActivationModal} />
		</Flex>
	);
};

export default Sellers;
