import React from 'react';

import { Field } from 'formik';
import { InputProps, InputGroup, InputRightElement, Button, Icon } from '@chakra-ui/react';
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs';

import { NumberInputProps } from '../NumberInput';

import Input from '../Input';

type FormPasswordInputProps = InputProps & NumberInputProps;

const FormPasswordInput: React.FC<FormPasswordInputProps> = React.forwardRef((props: FormPasswordInputProps, ref) => {
	const { ...fieldProps } = props;

	const [show, setShow] = React.useState(false)
  const handleClick = () => setShow(!show)

	return (
		<Field {...fieldProps}>
			{({ form, field }) => {
				return (
					<InputGroup size="md">
						<Input {...field} {...fieldProps} onBlur={form.handleBlur}
							pr="4.5rem"
							type={show ? "text" : "password"}							
						/>
						<InputRightElement width="4.5rem">
							<Button size={`xs`} bgColor={`transparent`} onClick={handleClick}>
								<Icon as={show ? BsEyeFill : BsEyeSlashFill} boxSize={5} />
							</Button>
						</InputRightElement>
					</InputGroup>					
				);
			}}
		</Field>
	);
});

export default FormPasswordInput;