import React from 'react';

import { Flex, FormControl, Center, Stack, Checkbox, Grid, GridItem, Collapse, Box } from '@chakra-ui/react';
import { SettingsIcon } from '@chakra-ui/icons';
import { useToasts } from 'react-toast-notifications';
import { Formik } from 'formik';

import * as yup from 'yup';

import NewModal from 'containers/NewModal';

import Button from 'components/Button';
import FormLabel from 'components/Form/FormLabel';
import FormErrorMessage from 'components/Form/FormErrorMessage';
import FormInput from 'components/Form/FormInput';
import FormSelect from 'components/Form/FormSelect';

import { userType, subjectTitle, actionTitle } from 'services/enums.service';

import { getApiAuthConfig } from 'services/api.service';

import { RolesApi, CreateRoleRequest, UpdateRoleRequest, PermissionSubjectEnum, PermissionActionsEnum, Permission } from 'clients';

const roleSchema = yup.object().shape({
	name: yup.string(),
	type: yup.string(),
});

type UserModalProps = {
	openRoleModal: boolean;
	handleOpenRoleModal(id?: string): void;
	isLoading: boolean;
	setIsLoading(load: boolean): void;
	fetchRoles(): void;
	selectedRole?: any;
};

const RoleModal: React.FC<UserModalProps> = ({ openRoleModal, handleOpenRoleModal, isLoading, setIsLoading, fetchRoles, selectedRole }) => {
	const [checkedSubjects, setCheckedSubjects] = React.useState<any>([]);
	const [openPermission, setOpenPermission] = React.useState<boolean>(false);
	const { addToast } = useToasts();

	const roleInitialValues = {
		name: selectedRole?.name || '',
		type: selectedRole?.type || '',
	};

	React.useEffect(() => {
		if (selectedRole?.id && selectedRole?.permissions) {
			selectedRole.permissions.forEach((permission) => {
				let subjectActions = permission.actions;

				setCheckedSubjects((prev) => Object.assign([], { ...prev, [permission.subject]: subjectActions }));
			});
		} else {
			setCheckedSubjects([]);
		}
	}, [selectedRole]);

	const handleCheckSubject = (subject, checked) => {
		let subjectActions = Object.values(PermissionActionsEnum);

		if (checked) {
			setCheckedSubjects((prev) => Object.assign([], { ...prev, [subject]: subjectActions }));
		} else {
			let { [subject]: _, ...subjects } = checkedSubjects;
			setCheckedSubjects(Object.assign([], { ...subjects }));
		}
	};

	const handleCheckAction = (subject, action, checked) => {
		let subjectActions = checkedSubjects[subject];

		if (checked) {
			subjectActions?.push(action);
		} else {
			subjectActions = subjectActions?.filter((item) => item !== action);
		}

		setCheckedSubjects((prev) => Object.assign([], { ...prev, [subject]: subjectActions }));
	};

	const handleSubmit = (values) => {
		const permissions: Array<Permission> = [];

		Object.keys(checkedSubjects)?.forEach((subject: any) => {
			permissions.push({
				actions: checkedSubjects[subject],
				subject,
			});
		});

		values.permissions = permissions;

		if (selectedRole?.id) {
			return editRole(values);
		}

		return createRole(values);
	};

	const createRole = async (values) => {
		setIsLoading(true);

		try {
			const apiConfig = getApiAuthConfig();

			const rolesApi = new RolesApi(apiConfig);

			const requestParams: CreateRoleRequest = {
				createRoleDto: {
					name: values.name,
					type: values.type,
					permissions: values.permissions,
				},
			};

			await rolesApi.createRole(requestParams);

			addToast('Perfil criado com sucesso!', {
				appearance: 'success',
				autoDismiss: true,
			});

			handleOpenRoleModal();
			fetchRoles();
		} catch (error) {
			addToast('Erro ao criar perfil!', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	};

	const editRole = async (values) => {
		setIsLoading(true);

		const { id } = selectedRole;

		try {
			const apiConfig = getApiAuthConfig();

			const rolesApi = new RolesApi(apiConfig);

			const requestParams: UpdateRoleRequest = {
				roleId: id,
				updateRoleDto: {
					name: values.name,
					type: values.type,
					permissions: values.permissions,
				},
			};

			await rolesApi.updateRole(requestParams);

			addToast('Perfil atualizado com sucesso!', {
				appearance: 'success',
				autoDismiss: true,
			});

			handleOpenRoleModal();
			fetchRoles();
		} catch (error) {
			addToast('Erro ao editar perfil', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	};

	const renderPermissions = () => (
		<Grid templateColumns='repeat(3, 1fr)' templateRows='auto' gap={4}>
			{Object.values(PermissionSubjectEnum)
				?.slice(1)
				?.map((subject) => (
					<GridItem key={subject}>
						<Box ml='1'>
							<Checkbox
								isChecked={checkedSubjects.hasOwnProperty(subject)}
								onChange={(e) => {
									handleCheckSubject(subject, e.target.checked);
								}}
							>
								{subjectTitle[subject]}
							</Checkbox>
						</Box>
						<Collapse startingHeight={0} in={checkedSubjects.hasOwnProperty(subject)}>
							<Stack pl={6} mt={1} spacing={1}>
								{checkedSubjects.hasOwnProperty(subject) &&
									Object.values(PermissionActionsEnum)?.map((action) => (
										<Box key={action}>
											<Checkbox
												isChecked={checkedSubjects[subject]?.includes(action)}
												onChange={(e) => {
													handleCheckAction(subject, action, e.target.checked);
												}}
											>
												{actionTitle[action]}
											</Checkbox>
										</Box>
									))}
							</Stack>
						</Collapse>
					</GridItem>
				))}
		</Grid>
	);

	return (
		<NewModal
			isOpen={openRoleModal}
			size='4xl'
			onClose={() => {
				handleOpenRoleModal();
			}}
			showCloseButton
		>
			<Flex px={12} py={8} flexDirection='column'>
				<Formik enableReinitialize initialValues={roleInitialValues} validationSchema={roleSchema} onSubmit={handleSubmit}>
					{({ handleSubmit, isValid, values }) => {
						return (
							<form onSubmit={handleSubmit} style={{ width: '100%' }}>
								<FormControl isRequired id='name' my={2}>
									<FormLabel fontSize={`md`} fontWeight='bold'>
										Nome
									</FormLabel>
									<FormInput fontSize={`md`} fontWeight='medium' name='name' />
									<FormErrorMessage name='name' />
								</FormControl>
								<FormControl isRequired id='role' my={2}>
									<FormLabel fontSize={`md`} fontWeight='bold'>
										Tipo
									</FormLabel>
									<FormSelect fontSize={`md`} size={`md`} name='type' value={values.type}>
										<option value='' />
										{Object.keys(userType).map((type) => (
											<option value={type} key={type}>
												{userType[type]}
											</option>
										))}
									</FormSelect>
									<FormErrorMessage name='type' />
								</FormControl>
								<FormControl id='role' my={2}>
									<FormLabel
										fontSize={`md`}
										fontWeight='bold'
										mb='2'
										mt='4'
										cursor='pointer'
										onClick={() => setOpenPermission(!openPermission)}
									>
										Permissões
										<SettingsIcon fontWeight='bold' w={4} ml='2' />
									</FormLabel>
									<Collapse startingHeight={0} in={openPermission}>
										{openPermission && renderPermissions()}
									</Collapse>
									<FormErrorMessage name='permission' />
								</FormControl>
								<Center>
									<Button
										mt={4}
										backgroundColor='primary'
										fontSize={`md`}
										fontWeight={`semibold`}
										disabled={!isValid}
										type='submit'
										isLoading={isLoading}
									>
										{selectedRole ? 'Atualizar Perfil' : 'Criar Perfil'}
									</Button>
								</Center>
							</form>
						);
					}}
				</Formik>
			</Flex>
		</NewModal>
	);
};

export default RoleModal;
