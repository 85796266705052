import React from 'react';

import {
	Flex,
	FormControl,
	FormLabel,
	Text,
	Stack,
	CheckboxGroup,
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	useBreakpointValue,
} from '@chakra-ui/react';
import { Checkbox } from '@chakra-ui/checkbox';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Formik } from 'formik';
import { useToasts } from 'react-toast-notifications';
import ReactQuill from 'react-quill';
import { addDays } from 'date-fns';

import Loader from 'containers/Loader';
import BackofficeAlertContainer from 'containers/BackofficeAlertContainer';

import FormInput from 'components/Form/FormInput';
import FormErrorMessage from 'components/Form/FormErrorMessage';
import Button from 'components/Button';
import FormCurrencyInput from 'components/Form/FormCurrencyInput';
import FormDatePickerInput from 'components/Form/FormDatePickerInput';
import ProtocolsTable from './components/ProtocolsTable';

import {
	CreateProtocolRequest,
	ProtocolApi,
	GetProtocolDto,
	GetPaginatedProtocolsBySellerRequest,
	GetDueDateBySellerRequest,
} from '../../clients';
import { useHistory } from 'react-router-dom';

import { useCurrentSeller } from '../../contexts/SellerProvider';

import yup from 'services/yup.service';
import { getApiAuthConfig } from '../../services/api.service';
import { round } from '../../services/math.service';

const Protocols: React.FC = () => {
	const { currentSeller } = useCurrentSeller();
	const { addToast } = useToasts();
	const isMobile = useBreakpointValue({ base: true, lg: false });

	const history = useHistory();

	const [isLoading, setIsLoading] = React.useState(true);
	const [dueDate, setDueDate] = React.useState(addDays(new Date(), 5));
	const [creationDate, setCreationDate] = React.useState(new Date());
	const [dueDateLoading, setDueDateLoading] = React.useState(false);
	const [protocols, setProtocols] = React.useState<Array<GetProtocolDto>>([]);
	const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
	const [pagination, setPagination] = React.useState({ currentPage: 1, limit: 100 });
	const [lastProtocolsTotalPages, setLastProtocolsTotalPages] = React.useState(1);

	const apiConfig = getApiAuthConfig();
	const protocolApi = new ProtocolApi(apiConfig);

	const getPaymentMethods = () => {
		let methods = ['card', 'billet', 'pix'];

		if (currentSeller && currentSeller?.no_payment_methods && Array.isArray(currentSeller?.no_payment_methods)) {
			if (currentSeller.no_payment_methods.includes('billet')) {
				methods = methods.filter((method) => method !== 'billet');
			}

			if (currentSeller.no_payment_methods.includes('pix')) {
				methods = methods.filter((method) => method !== 'pix');
			}
		}
		return methods;
	};

	const protocolInitialValues = {
		number: '',
		presenter_name: '',
		value_cents: '',
		creation_date: creationDate,
		due_date: dueDate,
		has_email_notification: false,
		payer_name: '',
		payer_email: undefined,
		email_content: currentSeller?.protocol_customizations?.default_email_content ?? '',
		has_payment_link: false,
		payment_methods: getPaymentMethods(),
	};

	const schema = yup.object().shape({
		number: yup.string().required('Campo obrigatório'),
		presenter_name: yup.string().trim().required('Campo obrigatório'),
		value_cents: yup.number().when('has_email_notification', {
			is: (has_email_notification) => has_email_notification === true,
			then: yup.number().required('Ao enviar email o valor é obrigatório.'),
		}),
		creation_date: yup.date().required('Campo obrigatório'),
		due_date: yup.date().required('Campo obrigatório'),
		has_email_notification: yup.boolean(),
		payer_name: yup.string(),
		payer_email: yup.string().email(),
		email_content: yup.string(),
		has_payment_link: yup.boolean(),
		payment_methods: yup.array(),
	});

	const getDueDate = async (date) => {
		if (!currentSeller) {
			return;
		}

		const getDueDateBySellerResquest: GetDueDateBySellerRequest = {
			sellerId: currentSeller.id,
			dateStart: date.toISOString(),
		};

		try {
			setDueDateLoading(true);

			const response = await protocolApi.getDueDateBySeller(getDueDateBySellerResquest);

			if (response.due_date) {
				setDueDate(new Date(response.due_date));
			}

			setDueDateLoading(false);
		} catch (e) {
			addToast('Não foi possível buscar a data de vencimento.', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setDueDateLoading(false);
		}
	};

	const fetchProtocols = async () => {
		const listProtocolRequest: GetPaginatedProtocolsBySellerRequest = {
			getProtocolsQueryDto: {
				seller_id: currentSeller?.id!,
				current_page: pagination.currentPage,
				limit: pagination.limit,
			},
		};

		try {
			setIsLoading(true);

			const response = await protocolApi.getPaginatedProtocolsBySeller(listProtocolRequest);

			const filteredResults: Array<GetProtocolDto> = response.results.map((response) => {
				const filterResponse = JSON.parse(JSON.stringify({ response }));
				const parsedResponse = {
					...filterResponse.response,
					value_cents: filterResponse?.response?.value_cents / 100,
				};

				return parsedResponse;
			});

			setProtocols(filteredResults);
			setLastProtocolsTotalPages(response.total_pages);
		} catch (e) {
			addToast('Não foi possível listar os protocolos.', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	};

	const submitForm = async (values) => {
		if (currentSeller) {
			const payload: CreateProtocolRequest = {
				createProtocolDto: {
					seller_id: currentSeller.id,
					number: values.number,
					presenter_name: values.presenter_name,
					value_cents: round(Number(values.value_cents) * 100),
					has_email_notification: values.has_email_notification,
					payer_email: values.payer_email,
					email_content: values.has_email_notification ? values.email_content : undefined,
					has_payment_link: values.has_payment_link,
					payment_link: values.has_payment_link
						? {
								payer_name: values.payer_name,
								payment_methods: values.payment_methods,
						  }
						: undefined,
					due_date: values.due_date.toISOString(),
					creation_date: values.creation_date.toISOString(),
				},
			};

			try {
				setIsSubmitting(true);

				await protocolApi.createProtocol(payload);

				addToast('Protocolo gerado com sucesso', {
					appearance: 'success',
					autoDismiss: true,
				});

				setIsSubmitting(false);

				await fetchProtocols();
			} catch (e) {
				addToast('Não foi possível salvar o protocolo.', {
					appearance: 'error',
					autoDismiss: true,
				});

				setIsSubmitting(false);
			}
		}
	};

	function navigateToDetails(id: string) {
		history.push(`/admin/protocolos/${id}`);
	}

	React.useEffect(() => {
		if (currentSeller) {
			fetchProtocols();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSeller, pagination]);

	React.useEffect(() => {
		getDueDate(creationDate);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [creationDate]);

	if (!currentSeller) {
		return <BackofficeAlertContainer />;
	}

	return (
		<Flex flexDirection={`column`} w='100%'>
			<Flex pb='8'>
				<Breadcrumb separator={<ChevronRightIcon />} color={`darkGrey`}>
					<BreadcrumbItem>
						<BreadcrumbLink href='#' fontSize='md'>
							Resumo
						</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem>
						<BreadcrumbLink href='#' fontSize='md'>
							Gestão de Protocolos
						</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink href='#' fontSize='md' fontWeight={`semibold`}>
							Protocolos
						</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>
			</Flex>

			<Formik
				enableReinitialize
				initialValues={protocolInitialValues}
				validationSchema={schema}
				onSubmit={async (values, { resetForm }) => {
					await submitForm(values);

					resetForm();
				}}
			>
				{({ handleSubmit, setFieldValue, values }) => {
					return (
						<form onSubmit={handleSubmit} style={{ width: '100%' }}>
							<Loader isOpen={isLoading || dueDateLoading} />

							<Flex mb='4'>
								<Text color={`darkGrey`} fontSize={isMobile ? 'lg' : '2xl'} fontWeight={`bold`}>
									PROTOCOLOS
								</Text>
							</Flex>

							<Flex flexDirection={`column`}>
								<Flex flexDir={`column`} p={10} bgColor='white' rounded={`md`} boxShadow={`sm`} mt='2' w='100%'>
									<Stack spacing={3} color={`primary`}>
										<Stack direction={isMobile ? 'column' : 'row'} spacing={4} mb={isMobile ? '0' : '4'}>
											<FormControl isRequired>
												<FormLabel color={`darkGrey`} fontSize={`md`} fontWeight={`medium`}>
													Data do Protocolo
												</FormLabel>
												<FormDatePickerInput
													name='creation_date'
													placeholder={`Informe o período`}
													borderColor={`darkGrey`}
													onDateChange={(date) => setCreationDate(new Date(date))}
												/>
												<FormErrorMessage name='due_date' />
											</FormControl>
											<FormControl isRequired>
												<FormLabel color={`darkGrey`} fontSize={`md`} fontWeight={`medium`}>
													Data de Vencimento do Protocolo
												</FormLabel>
												<FormDatePickerInput
													name='due_date'
													placeholder={`Informe o período`}
													minDate={new Date()}
													borderColor={`darkGrey`}
													isDisabled={dueDateLoading}
												/>
												<FormErrorMessage name='due_date' />
											</FormControl>
										</Stack>
										<Stack direction={isMobile ? 'column' : 'row'} spacing={4} mb={isMobile ? '0' : '4'}>
											<FormControl isRequired>
												<FormLabel color={`darkGrey`} fontSize={`md`} fontWeight={`medium`}>
													N° do Protocolo
												</FormLabel>
												<FormInput name='number' isDisabled={isSubmitting} />
												<FormErrorMessage name='number' />
											</FormControl>
											<FormControl isRequired>
												<FormLabel color={`darkGrey`} fontSize={`md`} fontWeight={`medium`}>
													Nome do Apresentante
												</FormLabel>
												<FormInput name='presenter_name' isDisabled={isSubmitting} />
												<FormErrorMessage name='presenter_name' />
											</FormControl>
											<FormControl isRequired={values.has_email_notification}>
												<FormLabel color={`darkGrey`} fontSize={`md`} fontWeight={`medium`}>
													Valor Total do Serviço
												</FormLabel>
												<FormCurrencyInput name='value_cents' isDisabled={isSubmitting} borderColor={`darkGrey`} />
												<FormErrorMessage name='value_cents' />
											</FormControl>
										</Stack>
										<Stack isInline spacing={4}>
											<Checkbox
												name='has_email_notification'
												onChange={(e) => {
													setFieldValue('has_email_notification', e.target.checked);
												}}
												color={`darkGrey`}
												fontSize={`md`}
												fontWeight={`medium`}
											>
												Enviar Email?
											</Checkbox>
										</Stack>
										{values.has_email_notification && (
											<Stack direction='column' spacing={4}>
												<Stack direction={isMobile ? 'column' : 'row'} spacing={4}>
													<FormControl isRequired>
														<FormLabel color={`darkGrey`} fontSize={`md`} fontWeight={`medium`}>
															Nome Completo
														</FormLabel>
														<FormInput name='payer_name' isDisabled={isSubmitting} />
														<FormErrorMessage name='payer_name' />
													</FormControl>
													<FormControl isRequired>
														<FormLabel color={`darkGrey`} fontSize={`md`} fontWeight={`medium`}>
															E-mail
														</FormLabel>
														<FormInput name='payer_email' isDisabled={isSubmitting} />
														<FormErrorMessage name='payer_email' />
													</FormControl>
												</Stack>
												<Stack>
													<FormControl isRequired>
														<FormLabel color={`darkGrey`} fontSize={`md`} fontWeight={`medium`}>
															Corpo do Email
														</FormLabel>
														<ReactQuill
															theme='snow'
															value={values.email_content}
															onChange={(value) => {
																setFieldValue('email_content', value);
															}}
														/>
														<FormErrorMessage name='email_content' />
													</FormControl>
												</Stack>
												<Stack mt='4' isInline spacing={4}>
													<Checkbox
														name='has_payment_link'
														onChange={(e) => {
															setFieldValue('has_payment_link', e.target.checked);
														}}
														color={`darkGrey`}
														fontSize={`md`}
														fontWeight={`medium`}
													>
														Enviar Link de Pagamento?
													</Checkbox>
												</Stack>
												<Stack>
													{values.has_payment_link && (
														<FormControl>
															<Stack>
																<Text color={`darkGrey`} fontSize={`md`} fontWeight={`medium`}>
																	Formas de pagamento
																</Text>
																<CheckboxGroup
																	colorScheme='green'
																	value={values.payment_methods}
																	onChange={(value) => setFieldValue('payment_methods', value)}
																>
																	<Stack isInline spacing={4}>
																		<Checkbox value='card' color={`darkGrey`} fontSize={`md`} fontWeight={`medium`}>
																			Cartão
																		</Checkbox>
																		{!currentSeller?.no_payment_methods?.includes('billet') && (
																			<Checkbox value='billet' color={`darkGrey`} fontSize={`md`} fontWeight={`medium`}>
																				Boleto
																			</Checkbox>
																		)}
																		{!currentSeller?.no_payment_methods?.includes('pix') && (
																			<Checkbox value='pix' color={`darkGrey`} fontSize={`md`} fontWeight={`medium`}>
																				Pix
																			</Checkbox>
																		)}
																	</Stack>
																</CheckboxGroup>
															</Stack>
														</FormControl>
													)}
												</Stack>
											</Stack>
										)}
									</Stack>
									<FormControl display='flex' justifyContent={isMobile ? 'center' : 'flex-end'} mt='4'>
										<Button size='lg' type='submit' disabled={isSubmitting} bgColor={'primary'} isLoading={isSubmitting}>
											Gerar Protocolo
										</Button>
									</FormControl>
								</Flex>
							</Flex>
						</form>
					);
				}}
			</Formik>

			<Flex mt='8'>
				<Text color={`darkGrey`} fontSize={`lg`} fontWeight={`bold`}>
					Protocolos Gerados
				</Text>
			</Flex>

			<Flex flexDir={`column`} p={10} bgColor='white' rounded={`md`} boxShadow={`sm`} mt='2' w='100%'>
				<Flex overflowX='auto'>
					<ProtocolsTable
						data={protocols}
						setPagination={setPagination}
						pagination={pagination}
						totalPages={lastProtocolsTotalPages}
						onRowClick={(row) => navigateToDetails(row.original.id)}
					/>
				</Flex>
			</Flex>
		</Flex>
	);
};

export default Protocols;
