import React from 'react';

import { HeaderTitle, CellContent, SimpleTable, HeaderTitleProps, CellContentProps } from '../../../containers/SimpleTable';

import { sellerStatus } from 'services/enums.service';
import { maskMoney } from '../../../services/masks.service';
import { Text, Icon, Button, Flex, Tooltip } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { FiEdit } from 'react-icons/fi';
import { BsToggleOff } from 'react-icons/bs';
import { IoKeyOutline } from 'react-icons/io5';
import { useAuth } from 'contexts/AuthProvider';

const HeaderTitleStyled: React.FC<HeaderTitleProps> = (props) => {
	return (
		<HeaderTitle {...props} color='black' justifyContent='flex-start' fontSize='sm' fontWeight='bold'>
			{props.children}
		</HeaderTitle>
	);
};

const CellContentStyled: React.FC<CellContentProps> = (props) => {
	return (
		<CellContent {...props} color='primary' justifyContent='flex-start' fontSize='sm' fontWeight='semibold'>
			{props.children}
		</CellContent>
	);
};

export default ({ data, setPagination, pagination, totalPages, openActivationModal }) => {

	const columns = React.useMemo(() => {
		const history = useHistory();
		const { isBackoffice } = useAuth();

		let cols: any = [];

		const goToEditPage = (sellerId: string) => {
			history.push(`/admin/estabelecimentos/${sellerId}`);
		};

		const goToCredentialsPage = (sellerId: string) => {
			history.push(`/admin/estabelecimentos/${sellerId}/credenciais`);
		};

		return cols.concat([
			{
				Header: ({ column }) => <HeaderTitleStyled column={column}>ID</HeaderTitleStyled>,
				accessor: 'id',
				Cell: ({ cell: { value } }) => <CellContentStyled>{value}</CellContentStyled>,
			},
			{
				Header: ({ column }) => <HeaderTitle column={column}>Ações</HeaderTitle>,
				accessor: 'edit-seller-button',
				Cell: ({ cell: { row } }) => (
					<CellContent>
						<Flex>
							<Tooltip label='Editar Estabelecimentos'>
								<Button
									background={'transparent'}
									onClick={() => {
										goToEditPage(row.values.id)
											;
									}}
								>
									<Icon as={FiEdit} boxSize={5} />
								</Button>
							</Tooltip>
							<Tooltip label='Credenciais'>
								<Button
									background={'transparent'}
									onClick={() => {
										goToCredentialsPage(row.values.id)
											;
									}}
								>
									<Icon as={IoKeyOutline} boxSize={5} />
								</Button>
							</Tooltip>
						</Flex>
					</CellContent>
				),
			},
			{
				Header: ({ column }) => <HeaderTitleStyled column={column}>Status</HeaderTitleStyled>,
				accessor: 'status',
				Cell: ({ cell: { row, value } }) => (
					<CellContentStyled display={`flex`} flexDirection={`row`} onClick={() => {}}>
						<Flex>
							<Text>{sellerStatus[value] ?? 'N/A'}</Text>
							{(value === 'activation_sent' && isBackoffice) && (
								<Tooltip label='Ativar' placement='top'>
									<Button
									background={'transparent'}
									onClick={() => {
										openActivationModal(row.values.id)
									}}
									>
										<Icon as={BsToggleOff} boxSize={5} />
									</Button>
								</Tooltip>
							)}
						</Flex>
					</CellContentStyled>
				),
			},
			{
				Header: ({ column }) => <HeaderTitleStyled column={column}>Nome</HeaderTitleStyled>,
				accessor: 'name',
				Cell: ({ cell: { value } }) => <CellContentStyled>{value}</CellContentStyled>,
			},
			{
				Header: ({ column }) => <HeaderTitleStyled column={column}>Data de Criação</HeaderTitleStyled>,
				accessor: 'created_at',
				Cell: ({ cell: { value } }) => (
					<CellContentStyled>{new Date(value)?.toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })}</CellContentStyled>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitleStyled column={column} textAlign='center'>
						Volume Transacionado Total
					</HeaderTitleStyled>
				),
				accessor: 'totalBatch',
				Cell: ({ cell: { value } }) => <CellContentStyled>R$ {maskMoney(value / 100)}</CellContentStyled>,
			},
			{
				Header: ({ column }) => (
					<HeaderTitleStyled column={column} textAlign='center'>
						Volume Transacionado em 30 dias
					</HeaderTitleStyled>
				),
				accessor: 'latestSettlement',
				Cell: ({ cell: { value } }) => <CellContentStyled>R$ {maskMoney(value / 100)}</CellContentStyled>,
			},
		]);
	}, [openActivationModal]);

	return (
		<SimpleTable
			columns={columns}
			data={data}
			setPagination={setPagination}
			pagination={pagination}
			totalPages={totalPages}
			pl={2}
			variant='unstyled'
			maxHeightTableWrapper='42vh'
		/>
	);
};
