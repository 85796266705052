import React from 'react';

import FormCurrencyInput from 'components/Form/FormCurrencyInput';
import FormErrorMessage from '../../../components/Form/FormErrorMessage';
import { FieldArray } from 'formik';
import { Flex, Heading, Text } from '@chakra-ui/react';

type SplitsCaptureListProps = {
	splits: any[];
	setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

const SplitsCaptureList: React.FC<SplitsCaptureListProps> = ({ splits, setFieldValue }) => {
	return (
		<FieldArray
			name='splits'
			render={() => (
				<Flex flexDirection='column'>
					<Heading fontSize='md' paddingY={6}>
						Regras de divisão
					</Heading>
					{splits.map((split, index) => (
						<Flex my='2' justifyContent='space-between' alignItems='center'>
							<Text color='primary'>{split?.seller_name}</Text>
							<FormCurrencyInput maxW='150px' name={`splits[${index}].amount`} />
							<FormErrorMessage name={`splits[${index}].amount`} />
						</Flex>
					))}
				</Flex>
			)}
		/>
	)
};

export default SplitsCaptureList;