import React from 'react';
import { useHistory } from 'react-router-dom';
import { CellContent, CellContentProps, HeaderTitle, HeaderTitleProps, SimpleTable } from '../../../containers/SimpleTable';
import { formatToBrazilianTimezone, maskCpfOrCnpj, maskMoney } from '../../../services/masks.service';

const HeaderTitleStyled: React.FC<HeaderTitleProps> = (props) => {
	return (
		<HeaderTitle {...props} color='black' justifyContent='flex-start' fontSize='sm' fontWeight='bold'>
			{props.children}
		</HeaderTitle>
	);
};

const CellContentStyled: React.FC<CellContentProps> = (props) => {
	return (
		<CellContent {...props} color='primary' justifyContent='flex-start' fontSize='sm' fontWeight='semibold' w='100%'>
			{props.children}
		</CellContent>
	);
};

export default ({ data, setPagination, pagination, totalPages }) => {
	const history = useHistory();
	const columns = React.useMemo(() => {
		let cols: any = [];

		return cols.concat([
			{
				Header: ({ column }) => <HeaderTitleStyled column={column}>ID da Venda</HeaderTitleStyled>,
				accessor: 'sale_id',
				id: 'sale_id',
				Cell: ({ cell: { value } }) => <CellContentStyled textAlign={`center`}>{value}</CellContentStyled>,
			},
			{
				Header: ({ column }) => <HeaderTitleStyled column={column}>Código de Barras</HeaderTitleStyled>,
				accessor: 'barcode',
				id: 'barcode',
				Cell: ({ cell: { value } }) => <CellContentStyled textAlign={`center`}>{value}</CellContentStyled>,
			},
			{
				Header: ({ column }) => <HeaderTitleStyled column={column}>Cartório</HeaderTitleStyled>,
				accessor: 'seller_name',
				id: 'seller_name',
				Cell: ({ cell: { value } }) => <CellContentStyled textAlign={`center`}>{value}</CellContentStyled>,
			},
			{
				Header: ({ column }) => <HeaderTitleStyled column={column}>Descrição</HeaderTitleStyled>,
				accessor: 'sale_description',
				id: 'sale_description',
				Cell: ({ cell: { value } }) => <CellContentStyled textAlign={`center`}>{value}</CellContentStyled>,
			},
			{
				Header: ({ column }) => <HeaderTitleStyled column={column}>Valor</HeaderTitleStyled>,
				accessor: 'sale_original_amount',
				id: 'sale_original_amount',
				Cell: ({ cell: { value } }) => <CellContentStyled>{`R$${maskMoney(value / 100)}`}</CellContentStyled>,
			},
			{
				Header: ({ column }) => <HeaderTitleStyled column={column}>Nome Pagador</HeaderTitleStyled>,
				accessor: 'payer_name',
				id: 'payer_name',
				Cell: ({ cell: { value } }) => <CellContentStyled>{value}</CellContentStyled>,
			},
			{
				Header: ({ column }) => <HeaderTitleStyled column={column}>Documento Pagador</HeaderTitleStyled>,
				accessor: 'payer_document',
				id: 'payer_document',
				Cell: ({ cell: { value } }) => <CellContentStyled>{maskCpfOrCnpj(value)}</CellContentStyled>,
			},
			{
				Header: ({ column }) => <HeaderTitleStyled column={column}>Data Expiração</HeaderTitleStyled>,
				accessor: 'expires_at',
				id: 'expires_at',
				Cell: ({ cell: { value } }) => <CellContentStyled>{formatToBrazilianTimezone(value)}</CellContentStyled>,
			},
		]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history]);

	return (
		<SimpleTable
			columns={columns}
			data={data}
			setPagination={setPagination}
			pagination={pagination}
			totalPages={totalPages}
			pl={2}
			variant='unstyled'
			maxHeightTableWrapper='42vh'
		/>
	);
};
