import React from 'react';
import {
  Flex,
  BreadcrumbItem,
  BreadcrumbLink,
  FormControl,
  InputGroup,
  Input,
  InputRightElement,
  IconButton,
  Stack,
  useBreakpointValue,
  Center,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { BiExport } from 'react-icons/bi';

import Loader from 'containers/Loader';
import Paper from '../../containers/Paper';
import Button from '../../components/Button';
import Breadcrumb from 'components/Breadcrumb';
import SampleTable from './components/SampleTable';
import RangeDatePicker, { IPeriod } from 'containers/RangeDatePicker';
import { getApiAuthConfig } from '../../services/api.service';
import { SearchIcon } from '@chakra-ui/icons';
import {
  AssignmentTransactionAlertsApi,
  ListAssignmentTransactionAlertsRequest
} from '../../clients';
import ExportFileSuccessModal from 'containers/ExportFileSuccessModal';


const AssignmentTransactionAlerts: React.FC = () => {
  const history = useHistory();
  const apiConfig = getApiAuthConfig();
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const assignmentTransactionAlertsApi = new AssignmentTransactionAlertsApi(apiConfig);

  const { addToast } = useToasts();

  const [isLoading, setIsLoading] = React.useState(false);
  const [exportIsLoading, setExportIsLoading] = React.useState(false);
  const [openExportSuccessModal, setOpenExportSuccessModal] = React.useState<boolean>(false);
  const [pagination, setPagination] = React.useState({ currentPage: 1, limit: 100 });
  const [totalPages, setTotalPages] = React.useState(1);
  const [filters, setFilters] = React.useState({});

  const [description, setDescription] = React.useState('');
  const [period, setPeriod] = React.useState<IPeriod[]>([
		{
			startDate: new Date(new Date().setHours(0, 0, 0, 0)),
			endDate: new Date(new Date().setHours(23, 59, 59, 999)),
			key: 'selection',
		},
	]);

  const [alerts, setAlerts] = React.useState<any>([]);

  async function fetchListAlertsRequest(params, currentPage?: number) {
    setIsLoading(true);

    const filter: ListAssignmentTransactionAlertsRequest = {
      currentPage: currentPage || pagination.currentPage,
      limit: pagination.limit,
      startDate: params.startDate,
      endDate: params.endDate,
      description: params.description,
    };

    try {
      const response = await assignmentTransactionAlertsApi
        .listAssignmentTransactionAlerts(filter)

      setAlerts(response.results);
      setTotalPages(response.total_pages);
    } catch (error) {
      addToast('Erro ao buscar alertas', {
        appearance: 'error',
        autoDismiss: true,
      });
    } finally {
      setIsLoading(false);
    }
  }

  const handleFormSubmit = async () => {
    await fetchListAlertsRequest(filters, 1);
  };

  const navigateToDetails = (id: string) => {
    history.push(
      `/admin/alertas-atribuicao/${id}`,
      {filters, route: history.location.pathname}
    );
  };

  const persistFilters = (filters) => {
    const { startDate, endDate, description } = filters;

    setDescription(description);
    setPeriod([{
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        key: 'selection',
      }]);

    fetchListAlertsRequest(filters);
  };

  const handleExportReportFile = async (params) => {
    setExportIsLoading(true);

    try {
      await assignmentTransactionAlertsApi.createAlertExportRequest({
        createExportAssignmentTransactionAlertsDto: {
          start_date: filters['startDate'],
          end_date: filters['endDate'],
          sale_description: filters['description']
        }
      });

      setOpenExportSuccessModal(true);
    } catch (e) {
      addToast('Ocorreu um erro no processamento', {
        appearance: 'error',
        autoDismiss: true,
      });
    } finally {
      setExportIsLoading(false);
    }
  };

  React.useEffect(() => {
    const { location } = history;
    const { state } = location || {};
    const { filters: previousFilters } = state || {};

    if (previousFilters) {
      persistFilters(previousFilters)
    } else {
      fetchListAlertsRequest({
        startDate: period[0].startDate.toISOString(),
        endDate: period[0].endDate.toISOString(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  React.useEffect(() => {
    setFilters({
      startDate: period[0].startDate.toISOString(),
      endDate: period[0].endDate.toISOString(),
      description
    });
  }, [period, description]);

  return (
    <Flex flexDirection='column'>
      <Loader isOpen={isLoading} />
  		<Flex direction={['column', 'column', 'row']} justify='space-between'>
        <Breadcrumb>
          <BreadcrumbItem>
            <BreadcrumbLink fontSize='md'>Resumo</BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink>Alertas</BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink fontSize='md' fontWeight='semibold' cursor='unset'>
              Alertas de Transações Suspeitas (Atribuição)
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <ExportFileSuccessModal
          showCloseButton
          isOpen={openExportSuccessModal}
          onOkClick={() => {
            setOpenExportSuccessModal(false);
          }}
          onClose={() => {
            setOpenExportSuccessModal(false);
          }}
        />

        <Button
          {...(alerts?.length === 0 && { disabled: true })}
          bgColor='primary'
          isDisabled={exportIsLoading}
          isLoading={exportIsLoading}
          onClick={() => handleExportReportFile(filters)}
          leftIcon={<BiExport size={20} />}
        >
          Exportar para Excel
        </Button>
      </Flex>
      <Paper p={6}>
        <FormControl width='auto'>
          <Stack direction={isMobile ? 'column' : 'row'} spacing='0' wrap='wrap'>
            <Center minW='13.2rem' mb={1} mr={{sm: 0, lg: 4}}>
              <RangeDatePicker
                period={period}
                setPeriod={setPeriod}
                handleSubmit={handleFormSubmit}
                backgroundColor='primary'
                borderWidth={1}
                borderColor='white'
                py='5'
                width={'100%'}
              />
            </Center>
            <Center minW='13.2rem' mb={1} mr={{sm: 0, lg: 4}}>
              <InputGroup>
                <Input
                  borderColor='darkGrey'
                  name='description'
                  placeholder='Descrição'
                  onChange={(event) => {
                    setDescription(event.target.value);
                  }}
                  onBlur={() => handleFormSubmit()}
                  value={description}
                  py='5'
                />
                <InputRightElement
                  children={
                    <IconButton
                      _hover={{ backgroundColor: 'transparent' }}
                      _focus={{ outline: 'none' }}
                      aria-label='Search database'
                      icon={<SearchIcon />}
                      color='primary'
                      backgroundColor='transparent'
                      onClick={() => handleFormSubmit()}
                    />
                  }
                />
              </InputGroup>
            </Center>
          </Stack>
        </FormControl>
      </Paper>
      <Paper p={6}>
        <Flex overflowX='auto' overflowY='hidden' flexDirection='column'>
          {
            alerts &&
              <SampleTable
                data={alerts}
                setPagination={setPagination}
                pagination={pagination}
                totalPages={totalPages}
                onRowClick={(row) => navigateToDetails(row.values.id)}
              />
          }
        </Flex>
      </Paper>
    </Flex>
  );
};

export default AssignmentTransactionAlerts;
