import { Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { components } from 'react-select';
import AsyncSelect, { Async } from 'react-select/async';

import './style.css';

import { GetSellerDto, SellersApi } from '../../clients';
import { useInMemorySellers } from '../../contexts/InMemorySellersProvider';
import { getApiAuthConfig } from '../../services/api.service';
import { getAll as getUserSessionData } from '../../services/local-storage.service';

export type SellerSelectHandle = {
	clear: () => void;
};

type SellerSelectProps = {
	onChange?: (value: GetSellerDto) => void;
	sellers?: Array<Partial<GetSellerDto>>;
};

const changeSeller = async (data, setSelectedSeller, props, createOrUpdateCacheSeller) => {
	if (data && data.id) {
		const userData = getUserSessionData();
		const authApiConfig = getApiAuthConfig(userData.id_token);
		const sellersApi = new SellersApi(authApiConfig);
		const seller = await sellersApi.getSeller({ sellerId: data.id });
		setSelectedSeller({ value: seller.id, label: seller.name, ...seller });
		createOrUpdateCacheSeller(seller);
		props.onChange && props.onChange({ value: seller.id, label: seller.name, ...seller });
	}
};

const Option = (props) => {
	const { data } = props;
	return (
		<components.Option className='select-search__option' {...props}>
			<Stack spacing={2} fontSize='sm' pb={2}>
				<Text fontWeight='bold' as='span'>
					{data.name}
				</Text>
				<Text as='span'>{data.document}</Text>
			</Stack>
		</components.Option>
	);
};

const Control = (props) => {
	return (
		<div className='select-search'>
			<components.Control border='none' {...props} />
		</div>
	);
};

const SellerSelect: React.RefForwardingComponent<SellerSelectHandle, SellerSelectProps> = (props, forwardedRef) => {
	const [selectedSeller, setSelectedSeller] = React.useState<GetSellerDto | undefined>(undefined);
	const [debounceTimeout, setDebounceTimeout] = React.useState<NodeJS.Timeout | null>(null);
	const selectInputRef = React.useRef<Async>();
	const { sellers, newSeller, editSellerOrder } = useInMemorySellers();

	async function loadOptions(query: string) {
		return new Promise((resolve) => {
			if (debounceTimeout) {
				clearTimeout((debounceTimeout as unknown) as number);
			}
			setDebounceTimeout(
				setTimeout(async () => {
					const userData = getUserSessionData();
					const authApiConfig = getApiAuthConfig(userData.id_token);
					const sellersApi = new SellersApi(authApiConfig);
					const searchedSellers = await sellersApi.searchSeller({ search: query });
					const parsedSellers = searchedSellers.map((seller) => ({ value: seller.id, label: seller.name, ...seller }));
					resolve(parsedSellers);
				}, 1500)
			);
			return () => {
				clearTimeout((debounceTimeout as unknown) as number);
			};
		});
	}

	function createOrUpdateCacheSeller(seller) {
		if (!sellers || !sellers.length) {
			newSeller(seller);
			return;
		}
		if (sellers.map((cachedSeller) => cachedSeller.id).indexOf(seller.id) === -1) {
			newSeller(seller);
		} else {
			editSellerOrder(seller.id, seller);
		}
	}

	React.useImperativeHandle(forwardedRef, () => ({
		clear() {
			selectInputRef.current.select.select.clearValue();
			setSelectedSeller(undefined);
		},
	}));

	return (
		<AsyncSelect
			ref={selectInputRef}
			defaultOptions={sellers}
			loadOptions={loadOptions}
			value={selectedSeller}
			components={{ Option, Control }}
			placeholder='Buscar pelo nome, CPF ou CNPJ'
			noOptionsMessage={() => 'Nenhum estabelecimento encontrado'}
			onChange={(e) => changeSeller(e, setSelectedSeller, props, createOrUpdateCacheSeller)}
		/>
	);
};

export default React.forwardRef(SellerSelect);
