import React from 'react';

import { SimpleGrid, FormControl, FormLabel, Input, InputGroup, InputRightAddon } from '@chakra-ui/react';

import FormInput from 'components/Form/FormInput';
import FormErrorMessage from 'components/Form/FormErrorMessage';

import { Divider, Stack } from '@chakra-ui/layout';
import FormSelect from '../../../components/Form/FormSelect';
import { absorb_costs } from '../../../config/constants';
import FormCurrencyInput from '../../../components/Form/FormCurrencyInput';
import { IncludePaymentTypeDtoOperationModeEnum } from '../../../clients';

type Props = {
	errors: any;
	index: number;
	values: any;
};

const inputPorcent = (name) => (
	<InputGroup>
		<FormInput as={Input} name={name} type='number' />
		<InputRightAddon children={'%'} />
	</InputGroup>
);

const PaymentTypeForm: React.FC<Props> = (props) => (
	<>
		<SimpleGrid color='gray.700' spacing='2.5rem'>
			<Stack direction={['column', 'column', 'row']} spacing={4}>
				<FormControl id={`payment_types.${[props.index]}.code`} isRequired>
					<FormLabel fontSize='md' fontWeight='bold'>
						Código
					</FormLabel>
					<FormSelect isDisabled name={`payment_types.${[props.index]}.code`}>
						<option value='' />
						{absorb_costs.map((type) => (
							<option value={type.value} key={type.value}>
								{type.text}
							</option>
						))}
					</FormSelect>
					<FormErrorMessage name={`payment_types.${[props.index]}.code`} />
				</FormControl>

				<FormControl id={`payment_types.${[props.index]}.fee`} isRequired>
					<FormLabel fontSize={`md`} fontWeight='bold'>
						Taxa
					</FormLabel>
					{props?.values?.payment_types?.[props.index]?.operation_mode === IncludePaymentTypeDtoOperationModeEnum.Pix || 
					props?.values?.payment_types?.[props.index]?.operation_mode === IncludePaymentTypeDtoOperationModeEnum.PixPos ||
					props?.values?.payment_types?.[props.index]?.operation_mode === IncludePaymentTypeDtoOperationModeEnum.Billet ? (
						<FormCurrencyInput name={`payment_types.${[props.index]}.fee`} />
					) : (
						inputPorcent(`payment_types.${[props.index]}.fee`)
					)}
					<FormErrorMessage name={`payment_types.${[props.index]}.fee`} />
				</FormControl>

				<FormControl id={`payment_types.${[props.index]}.teto`}>
					<FormLabel fontSize='md' fontWeight='bold'>
						Teto
					</FormLabel>
					<FormCurrencyInput name={`payment_types.${[props.index]}.teto`} />
					<FormErrorMessage name={`payment_types.${[props.index]}.teto`} />
				</FormControl>

				<FormControl id={`payment_types.${[props.index]}.piso`} isRequired>
					<FormLabel fontSize='md' fontWeight='bold'>
						Piso
					</FormLabel>
					<FormCurrencyInput name={`payment_types.${[props.index]}.piso`} />
					<FormErrorMessage name={`payment_types.${[props.index]}.piso`} />
				</FormControl>
			</Stack>
		</SimpleGrid>
		
		<Divider />
	</>
);

export default PaymentTypeForm;
