import * as React from 'react';

import { InputGroup, InputRightElement, InputProps, Spinner } from '@chakra-ui/react';
import { useDebouncedCallback } from 'use-debounce';
import { Field } from 'formik';

import InputMask from 'react-input-mask';

import Input from '../Input';

type FormZipcodeInputProps = InputProps & {
	beforeSearch?: () => void;
	afterSearch?: () => void;
	performSearch: (zipcode: string) => Promise<any>;
	onSearchFailed?: () => void;
	onSearchResults?: (data) => void;
};

const FormZipcodeInput: React.FC<FormZipcodeInputProps> = (props: FormZipcodeInputProps) => {
	const [isLoading, setIsLoading] = React.useState(false);

	const debounced = useDebouncedCallback(async (value) => {
		const parsedZipcode = value.replace(/\D+/gi, '');

		if (parsedZipcode.length !== 8) {
			return;
		}
		setIsLoading(true);

		try {
			const data = await props.performSearch(parsedZipcode);

			props.onSearchResults && props.onSearchResults(data);
		} catch (e) {
			props.onSearchFailed && props.onSearchFailed();
		} finally {
			setIsLoading(false);
		}
		props.afterSearch && props.afterSearch();
	}, 1000);

	React.useEffect(
		() => () => {
			debounced.flush();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[debounced]
	);

	const { onSearchFailed, onSearchResults, beforeSearch, afterSearch, performSearch, ...parsedProps } = props;

	return (
		<Field {...parsedProps}>
			{({ form, field }) => {
				return (
					<InputMask
						borderColor={`darkGrey`}
						mask={`99999-999`}
						maskChar={null}
						{...parsedProps}
						value={field.value}
						onBlur={form.handleBlur}
						onChange={(e) => {
							props.onChange && props.onChange(e);
							form.setFieldValue(field.name, e.target.value);

							if (e.target.value.replace(/\D+/gi, '').length !== 8) {
								return;
							}
							props.beforeSearch && props.beforeSearch();

							debounced.callback(e.target.value);
						}}
					>
						{(inputProps) => {
							return (
								<InputGroup rounded={`lg`}>
									<Input type='text' {...inputProps} />
									{isLoading && (
										<InputRightElement
											pb={2}
											children={<Spinner thickness='1px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='sm' p={2} mb={2} />}
										/>
									)}
								</InputGroup>
							);
						}}
					</InputMask>
				);
			}}
		</Field>
	);
};

export default FormZipcodeInput;
