import React from 'react';

import { Flex, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';
import { useToasts } from 'react-toast-notifications';

import Loader from 'containers/Loader';

import { MerchantsApi } from '../../clients';

import Button from '../../components/Button';
import Paper from '../../containers/Paper';
import SampleTable from './components/SampleTable';
import Breadcrumb from 'components/Breadcrumb';

import { getApiAuthConfig } from '../../services/api.service';
import { useAuth } from '../../contexts/AuthProvider';
import { useHistory } from 'react-router-dom';

const Merchants: React.FC = () => {
	const apiConfig = getApiAuthConfig();
	const merchantsApi = new MerchantsApi(apiConfig);
	const history = useHistory();

	const { isBackoffice } = useAuth();

	const [isLoading, setIsLoading] = React.useState(false);

	const [merchants, setMerchants] = React.useState<any>();

	const { addToast } = useToasts();

	async function fetchListMerchantsRequest() {
		setIsLoading(true);

		try {
			const response = await merchantsApi.listMerchants();

			setMerchants(response);
		} catch (error) {
			addToast('Erro ao buscar merchants', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	}

	React.useEffect(() => {
		fetchListMerchantsRequest();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Flex flexDirection='column'>
			<Loader isOpen={isLoading} />

			<Flex direction={['column', 'column', 'row']} justify='space-between'>
				<Breadcrumb>
					<BreadcrumbItem>
						<BreadcrumbLink fontSize='md'>Resumo</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink fontSize='md' fontWeight='semibold'>
							Merchants
						</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>

				<Button
					title='Cadastrar Merchant'
					isDisabled={!isBackoffice}
					bgColor='primary'
					onClick={() => {
						history.push(`/admin/merchants/criar`);
					}}
					className='button__register-seller'
				>
					Cadastrar Merchant
				</Button>
			</Flex>
			<Paper p={6}>
				<Flex overflowX='auto' overflowY='hidden' flexDirection='column'>
					{merchants && <SampleTable data={merchants} />}
				</Flex>
			</Paper>
		</Flex>
	);
};

export default Merchants;
