import React from 'react';
import {
  SimpleGrid,
  FormControl,
  FormLabel,
  Heading,
  Divider,
  Input
} from '@chakra-ui/react';
import { GridItem } from '@chakra-ui/layout';

import {
  absorb_costs,
  settlement_type,
  pix_pos,
  billet_provider,
  pix_provider
} from 'config/constants';
import { GetMerchantDto } from '../../../clients';
import ThreeDsForm from '../components/ThreeDsForm';
import FormSelect from 'components/Form/FormSelect';
import MultiSelect from '../../../components/MultiSelect';
import FormErrorMessage from 'components/Form/FormErrorMessage';
import FormCurrencyInput from 'components/Form/FormCurrencyInput';
import FormInput from 'components/Form/FormInput';
import FormPercentInput from 'components/Form/FormPercentInput';

type Props = {
  values: any;
  setFieldValue: any;
  merchants: GetMerchantDto[];
  errors: any;
};

const SettingsForm: React.FC<Props> = ({
  values,
  errors,
  merchants,
  setFieldValue,
}) => (
  <SimpleGrid
    columns={{base: 1, md: 6, xl: 12}}
    color='gray.700'
    gap={4}
  >
    <GridItem colStart={{base: 1}} colSpan={{base: 3, xl: 6}}>
      <FormControl id='merchant_id' isRequired>
        <FormLabel fontSize='md' fontWeight='bold'>
          Merchant
        </FormLabel>
        <FormSelect name='merchant_id'>
          <option value='' />
          {merchants?.map((merchant) => (
            <option value={merchant.id} key={merchant.id}>
              {merchant.description}
            </option>
          ))}
        </FormSelect>
        <FormErrorMessage name='merchant_id' />
      </FormControl>
    </GridItem>

    <GridItem colStart={{base: 1, md: 4, xl: 7}} colSpan={{base: 3, xl: 6}}>
      <FormControl id='settlement_type' isRequired>
        <FormLabel fontSize='md' fontWeight='bold'>
          Tipo de liquidação
        </FormLabel>
        <FormSelect name='settlement_type'>
          <option value='' />
          {settlement_type.map((type) => (
            <option value={type.value} key={type.value}>
              {type.text}
            </option>
          ))}
        </FormSelect>
        <FormErrorMessage name='settlement_type' />
      </FormControl>
    </GridItem>

    <GridItem colStart={{base: 1, xl: 1}} colSpan={{base: 3, md: 3, xl: 3}}>
      <FormControl id='pix_provider' isRequired>
        <FormLabel fontSize='md' fontWeight='bold'>
          Provedor de Pix
        </FormLabel>
        <FormSelect name='pix_provider'>
          <option value='' />
          {pix_provider.map((pix) => (
            <option value={pix.value} key={pix.value}>
              {pix.text}
            </option>
          ))}
        </FormSelect>
        <FormErrorMessage name='pix_provider' />
      </FormControl>
    </GridItem>

    <GridItem colStart={{base: 1}} colSpan={{base: 3, md: 3, xl: 3}}>
      <FormControl id='billet_provider' isRequired>
        <FormLabel fontSize='md' fontWeight='bold'>
          Provedor de Boleto
        </FormLabel>
        <FormSelect name='billet_provider'>
          <option value='' />
          {billet_provider.map((billet) => (
            <option value={billet.value} key={billet.value}>
              {billet.text}
            </option>
          ))}
        </FormSelect>
        <FormErrorMessage name='billet_provider' />
      </FormControl>
    </GridItem>

    <GridItem colStart={{base: 1, '2xl': 7}} colSpan={{base: 3, md: 2, xl: 3}} order={{xl: 6}}>
      <FormControl id='has_split_rules' isRequired>
        <FormLabel fontSize='md' fontWeight='bold'>
          Regras de divisão
        </FormLabel>
        <FormSelect name='has_split_rules'>
          <option value='false'>Não</option>
          <option value='true'>Sim</option>
        </FormSelect>
      </FormControl>
    </GridItem>

    <GridItem colStart={{base: 1}} colSpan={{base: 3, md: 2, xl: 3}}>
      <FormControl id='pix_pos' isRequired>
        <FormLabel fontSize='md' fontWeight='bold'>
          Pix POS
        </FormLabel>
        <FormSelect name='pix_pos'>
          <option value='' />
          {pix_pos.map((type) => (
            <option value={type.value} key={type.value}>
              {type.text}
            </option>
          ))}
        </FormSelect>
        <FormErrorMessage name='pix_pos' />
      </FormControl>
    </GridItem>

    <GridItem colStart={{base: 1}} colSpan={{base: 3, md: 2, xl: 3}}>
      <FormControl id='is_assignor' isRequired>
        <FormLabel fontSize='md' fontWeight='bold'>
          Cedente do Boleto
        </FormLabel>
        <FormSelect name='is_assignor'>
          <option value='' />
          <option value='true'>Sim</option>
          <option value='false'>Não</option>
        </FormSelect>
        <FormErrorMessage name='is_assignor' />
      </FormControl>
    </GridItem>

    <GridItem colStart={{base: 1, xl: 1}} colSpan={{base: 3, md: 3, xl: 6}}>
      <FormControl>
        <FormLabel fontSize='md' fontWeight='bold'>
          Pagamentos não utilizados
        </FormLabel>
        <MultiSelect
          id='no_payment_methods'
          placeholder='Formas de pagamento'
          options={absorb_costs.map((costs) => ({
            value: costs.value,
            label: costs.text
          }))}
          value={values.no_payment_methods && absorb_costs.filter((cost) => {
              return values.no_payment_methods.includes(cost.value);
            }).map((cost) => {
              return ({value: cost.value, label: cost.text});
            })}
          onChange={(value) => {
            setFieldValue('no_payment_methods', value.map((obj) => obj.value));
          }}
        />
      </FormControl>
    </GridItem>

    <GridItem colStart={{base: 1, md: 4}} colSpan={{base: 3, md: 3, xl: 6}}>
      <FormControl>
        <FormLabel fontSize='md' fontWeight='bold'>
          Absorver Custos
        </FormLabel>
        <MultiSelect
          id='absorb_costs'
          placeholder='Absorver custos de...'
          options={absorb_costs.map((costs) => ({
            value: costs.value,
            label: costs.text
          }))}
          value={values.absorb_costs && absorb_costs.filter((cost) => {
            return values.absorb_costs.includes(cost.value);
          }).map((cost) => {
            return ({ value: cost.value, label: cost.text});
          })}
          onChange={(value) => {
            setFieldValue('absorb_costs', value.map((obj) => obj.value));
          }}
        />
      </FormControl>
    </GridItem>

    <GridItem colSpan={{base: 3, xl: 6}} order={{xl: 5}}>
      <FormControl id='extract_name'>
        <FormLabel fontSize='md' fontWeight='bold'>
          Identificação Extrato (cartão)
        </FormLabel>
        <FormInput as={Input} name='extract_name' maxLength={22} />
        <FormErrorMessage name='extract_name' />
      </FormControl>
    </GridItem>

    <GridItem colStart={{base: 1}} colSpan={{base: 3, md: 3, xl: 3}}>
      <FormControl id='card_payer_check'>
        <FormLabel fontSize='md' fontWeight='bold'>
          Validar Nome no Link
        </FormLabel>
        <FormSelect name='card_payer_check'>
        <option value='' />
        <option value='false'>Desativado</option>
        <option value='true'>Ativo</option>
        </FormSelect>
        <FormErrorMessage name='card_payer_check' />
      </FormControl>
    </GridItem>

    <GridItem colStart={{base: 1}} colSpan={{base: 3, md: 3, xl: 3}}>
      <FormControl id='card_payer_check_min_value'>
        <FormLabel fontSize='md' fontWeight='bold'>
          Mínimo Para Validar Nome no Link
        </FormLabel>
        <FormCurrencyInput borderColor='darkGrey' name='card_payer_check_min_value' />
        <FormErrorMessage name='card_payer_check_min_value' />
      </FormControl>
    </GridItem>

    <GridItem colStart={{base: 1}} colSpan={{base: 3, md: 6, xl: 12}} mt={5}>
      <Heading color='gray.700' as='h6' size='sm' py='1%'>
        Ativar 3Ds
      </Heading>
      <Divider />
    </GridItem>

    <GridItem colStart={{base: 1}} colSpan={{base: 3, md: 6, xl: 12}} order={{base: 8}}>
      <ThreeDsForm errors={errors} />
    </GridItem>

    <GridItem colStart={{base: 1}} colSpan={{base: 3, md: 6, xl: 12}} order={{base: 9}} mt={5}>
      <Heading color='gray.700' as='h6' size='sm' py='1%'>
        Ativar Antifraude
      </Heading>
      <Divider />
    </GridItem>

    <GridItem colStart={{base: 1}} colSpan={{base: 3, md: 3, xl: 3}} order={{base: 10}}>
      <FormControl id='risk_active'>
        <FormLabel fontSize='md' fontWeight='bold'>
          Antifraude
        </FormLabel>
        <FormSelect name='risk_active'>
        <option value='' />
        <option value='false'>Desativado</option>
        <option value='true'>Ativo</option>
        </FormSelect>
        <FormErrorMessage name='risk_active' />
      </FormControl>
    </GridItem>

    <GridItem colStart={{base: 1}} colSpan={{base: 3, md: 3, xl: 3}} order={{base: 11}}>
      <FormControl id='min_risk'>
        <FormLabel fontSize='md' fontWeight='bold'>
          Mínimo Para Antifraude
        </FormLabel>
        <FormCurrencyInput borderColor='darkGrey' name='min_risk' />
        <FormErrorMessage name='min_risk' />
      </FormControl>
    </GridItem>

		<GridItem colStart={{base: 1}} colSpan={{base: 3, md: 6, xl: 12}} order={{base: 12}} mt={5}>
      <Heading color='gray.700' as='h6' size='sm' py='1%'>
        Pagamento de Contas
      </Heading>
      <Divider />
    </GridItem>

		<GridItem colStart={{base: 1}} colSpan={{base: 3, md: 3, xl: 3}} order={{base: 13}}>
      <FormControl id='celcoin_fee_type'>
        <FormLabel fontSize='md' fontWeight='bold'>
          Tipo Taxa Pagamento de Contas
        </FormLabel>
        <FormSelect name='celcoin_fee_type'>
        <option value='' />
        <option value='real'>Real</option>
        <option value='percentage'>Porcentagem</option>
        </FormSelect>
        <FormErrorMessage name='celcoin_fee_type' />
      </FormControl>
    </GridItem>

		<GridItem colStart={{base: 1}} colSpan={{base: 3, md: 3, xl: 3}} order={{base: 14}}>
      <FormControl id='celcoin_value'>
        <FormLabel fontSize='md' fontWeight='bold'>
          Taxa Pagamento de Contas
        </FormLabel>
        {values.celcoin_fee_type === 'real' && <FormCurrencyInput  borderColor='darkGrey' name='celcoin_value' />}
				{values.celcoin_fee_type === 'percentage' && <FormPercentInput borderColor='darkGrey' name='celcoin_value' />}
        <FormErrorMessage name='celcoin_value' />
      </FormControl>
    </GridItem>
  </SimpleGrid>
);

export default SettingsForm;