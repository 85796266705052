import React from 'react';

import { Flex, Text } from '@chakra-ui/react';

const PageNotFound: React.FC = () => {
	return (		
		<Flex>
			<Text>Ops, a página que você está procurando não foi encontrada</Text>				
		</Flex>		
	)	
}

export default PageNotFound;