import React from 'react';

import { InputGroup, InputProps, InputRightElement, Icon } from '@chakra-ui/react';

import FormInput from './FormInput';
import ReactDatePicker from 'react-datepicker';
import { GoCalendar } from 'react-icons/go';

import { monthNumberToName } from 'services/date.service';
import { monthName } from 'services/enums.service';

type DatePickerProps = {
	selectedDate: Date;
	setSelectedDate: React.Dispatch<React.SetStateAction<Date>>;
	dateFormat?: string;
	onDateChange?: (date: Date) => void;
};

const locale = {
	localize: { month: (month) => Object.values(monthName)[month] },
	formatLong: {
		date: () => 'dd/MM/YYYY',
	},
};

const FormMonthPickerInput: React.FC<DatePickerProps & InputProps> = (props: DatePickerProps & InputProps) => {
	const { selectedDate, setSelectedDate } = props;

	return (
		<ReactDatePicker
		selected={selectedDate}
		onChange={(date: Date) => {
			setSelectedDate(date);
		}}
		dateFormat='MM/yyyy'
		locale={locale}
		showMonthYearPicker
		customInput={
			<InputGroup>
				<FormInput
					_hover={{ opacity: 0.8 }}
					value={`${monthNumberToName(selectedDate.getMonth() + 1)} de ${selectedDate.getFullYear()}`}
				/>
				<InputRightElement children={<Icon as={GoCalendar} color='primary' />} />
			</InputGroup>
		}
		/>
	);
};

export default FormMonthPickerInput;
