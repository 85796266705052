/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SellerEmail,
    SellerEmailFromJSON,
    SellerEmailFromJSONTyped,
    SellerEmailToJSON,
} from './';

/**
 * 
 * @export
 * @interface SellerEmails
 */
export interface SellerEmails {
    /**
     * 
     * @type {SellerEmail}
     * @memberof SellerEmails
     */
    payment_link: SellerEmail;
    /**
     * 
     * @type {SellerEmail}
     * @memberof SellerEmails
     */
    receipt_email: SellerEmail;
    /**
     * 
     * @type {SellerEmail}
     * @memberof SellerEmails
     */
    protocols_generation: SellerEmail;
    /**
     * 
     * @type {SellerEmail}
     * @memberof SellerEmails
     */
    cancelation_email: SellerEmail;
    /**
     * 
     * @type {SellerEmail}
     * @memberof SellerEmails
     */
    billet_email: SellerEmail;
}

export function SellerEmailsFromJSON(json: any): SellerEmails {
    return SellerEmailsFromJSONTyped(json, false);
}

export function SellerEmailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SellerEmails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'payment_link': SellerEmailFromJSON(json['payment_link']),
        'receipt_email': SellerEmailFromJSON(json['receipt_email']),
        'protocols_generation': SellerEmailFromJSON(json['protocols_generation']),
        'cancelation_email': SellerEmailFromJSON(json['cancelation_email']),
        'billet_email': SellerEmailFromJSON(json['billet_email']),
    };
}

export function SellerEmailsToJSON(value?: SellerEmails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'payment_link': SellerEmailToJSON(value.payment_link),
        'receipt_email': SellerEmailToJSON(value.receipt_email),
        'protocols_generation': SellerEmailToJSON(value.protocols_generation),
        'cancelation_email': SellerEmailToJSON(value.cancelation_email),
        'billet_email': SellerEmailToJSON(value.billet_email),
    };
}


