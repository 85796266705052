import { FormControl, Stack, VStack } from '@chakra-ui/react';
import React from 'react';

import FormCpfCnpjInput from '../../../components/Form/FormCpfCnpjInput';
import FormErrorMessage from '../../../components/Form/FormErrorMessage';
import FormInput from '../../../components/Form/FormInput';
import FormZipcodeInput from '../../../components/Form/FormZipcodeInput';
import getzipcode from '../../../services/getzipcode.service';

type AddressFormProps = {
	setFieldValue: any;
	isDebit?: boolean;
};

const AddressForm: React.FC<AddressFormProps> = ({ setFieldValue, isDebit }) => {
	return (
		<VStack spacing={4} mt={5} mb={2}>
			<Stack direction={['column', 'row']} w='100%'>
				<FormControl>
					<FormCpfCnpjInput
						isSearchPayer={!isDebit && true}
						variant='flushed'
						placeholder='CPF/CNPJ'
						size='lg'
						name='cpf'
						autoFocus
						borderTop='none'
						borderLeft='none'
						borderRight='none'
						setSelectedPayer={() => {}}
					/>
					<FormErrorMessage name='cpf' />
				</FormControl>
				<FormControl>
					<FormZipcodeInput
						variant='flushed'
						placeholder='CEP'
						size='lg'
						name='cep'
						borderTop='none'
						borderLeft='none'
						borderRight='none'
						performSearch={getzipcode}
						onSearchResults={(data) => {
							setFieldValue('street', data.logradouro);
							setFieldValue('district', data.bairro);
							setFieldValue('city', data.localidade);
							setFieldValue('state', data.uf);
							setFieldValue('complement', data.complemento);
							setFieldValue('country', 'BR');
						}}
					/>
					<FormErrorMessage name='cep' />
				</FormControl>
				<FormControl>
					<FormInput variant='flushed' placeholder='Cidade' size='lg' name='city' borderTop='none' borderLeft='none' borderRight='none' />
					<FormErrorMessage name='city' />
				</FormControl>
			</Stack>
			<Stack direction={['column', 'row']} w='100%'>
				<FormControl>
					<FormInput variant='flushed' placeholder='Estado' size='lg' name='state' borderTop='none' borderLeft='none' borderRight='none' />
					<FormErrorMessage name='state' />
				</FormControl>
				<FormControl>
					<FormInput variant='flushed' placeholder='Rua' size='lg' name='street' borderTop='none' borderLeft='none' borderRight='none' />
					<FormErrorMessage name='street' />
				</FormControl>
			</Stack>
			{!isDebit ? (
				<>
					<Stack direction={['column', 'row']} w='100%'>
						<FormControl>
							<FormInput
								variant='flushed'
								placeholder='Bairro'
								size='lg'
								name='district'
								borderTop='none'
								borderLeft='none'
								borderRight='none'
							/>
							<FormErrorMessage name='district' />
						</FormControl>
						<FormControl>
							<FormInput
								variant='flushed'
								placeholder='Número'
								size='lg'
								name='number'
								borderTop='none'
								borderLeft='none'
								borderRight='none'
							/>
							<FormErrorMessage name='number' />
						</FormControl>
					</Stack>
					<Stack direction={['column', 'row']} w='100%'>
						<FormControl>
							<FormInput
								variant='flushed'
								placeholder='Pais'
								size='lg'
								name='country'
								borderTop='none'
								borderLeft='none'
								borderRight='none'
							/>
							<FormErrorMessage name='country' />
						</FormControl>
						<FormControl>
							<FormInput
								variant='flushed'
								placeholder='Complemento'
								size='lg'
								name='complement'
								borderTop='none'
								borderLeft='none'
								borderRight='none'
							/>
							<FormErrorMessage name='complement' />
						</FormControl>
					</Stack>
				</>
			) : (
				<Stack direction={['column', 'row']} w='100%'>
					<FormControl>
						<FormInput
							variant='flushed'
							placeholder='Número'
							size='lg'
							name='number'
							borderTop='none'
							borderLeft='none'
							borderRight='none'
						/>
						<FormErrorMessage name='number' />
					</FormControl>
					<FormControl>
						<FormInput
							variant='flushed'
							placeholder='Pais'
							size='lg'
							name='country'
							borderTop='none'
							borderLeft='none'
							borderRight='none'
						/>
						<FormErrorMessage name='country' />
					</FormControl>
				</Stack>
			)}
		</VStack>
	);
};

export default AddressForm;
