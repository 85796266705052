import * as React from 'react';

import { InputProps } from '@chakra-ui/react';
import { Field } from 'formik';
import { any as anyType } from 'prop-types';

import IntlCurrencyInput from "react-intl-currency-input"

import Input from '../Input';

const currencyConfig = {
	locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

type FormCurrencyInputProps = InputProps & {	
	onAmountChange?: (e: any, value: any, maskedValue: any) => void;	
};

const FormCurrencyInput: React.FC<FormCurrencyInputProps> = (props: FormCurrencyInputProps) => {
	const { onAmountChange, ...parsedProps } = props;

	return (		
		<Field {...parsedProps}>
			{({ form, field }) => {
				return (
					<IntlCurrencyInput {...parsedProps}
						onBlur={form.handleBlur}
						onChange={(e, value, maskedValue) => {
							props.onAmountChange && props.onAmountChange(e, value, maskedValue)

							form.setFieldValue(field.name, value);
						}} 
						borderColor='darkGrey'
						value={field.value}
						component={Input} 
						currency="BRL" 
						config={currencyConfig} />
				)				
			}}
		</Field>
	)
};

IntlCurrencyInput.propTypes = {
	...IntlCurrencyInput.propTypes, 
	component: anyType
};

export default FormCurrencyInput;
