import { FormControl, FormLabel, Stack, VStack } from '@chakra-ui/react';
import React from 'react';

import FormErrorMessage from '../../../components/Form/FormErrorMessage';
import FormInput from '../../../components/Form/FormInput';
import FormZipcodeInput from '../../../components/Form/FormZipcodeInput';
import getzipcode from '../../../services/getzipcode.service';

type AddressFormProps = {
	setFieldValue: any;
	isDebit?: boolean;
};

const AddressForm: React.FC<AddressFormProps> = ({ setFieldValue }) => {
	return (
		<VStack spacing={4} mt={5} mb={2}>
			<Stack direction={['column', 'row']} w='100%'>
				<FormControl isRequired>
					<FormLabel color='darkGrey' fontSize='md' fontWeight='medium'>
						CEP
					</FormLabel>
					<FormZipcodeInput
						size='lg'
						name='payer.zipcode'
						performSearch={getzipcode}
						onSearchResults={(data) => {
							setFieldValue('payer.street', data.logradouro);
							setFieldValue('payer.district', data.bairro);
							setFieldValue('payer.city', data.localidade);
							setFieldValue('payer.state', data.uf);
							setFieldValue('payer.complement', data.complemento);
						}}
					/>
					<FormErrorMessage name='zipcode' />
				</FormControl>
				<FormControl isRequired>
					<FormLabel color='darkGrey' fontSize='md' fontWeight='medium'>
						Cidade
					</FormLabel>
					<FormInput size='lg' name='payer.city' />
					<FormErrorMessage name='city' />
				</FormControl>
			</Stack>
			<Stack direction={['column', 'row']} w='100%'>
				<FormControl isRequired>
					<FormLabel color='darkGrey' fontSize='md' fontWeight='medium'>
						Estado
					</FormLabel>
					<FormInput size='lg' name='payer.state' />
					<FormErrorMessage name='state' />
				</FormControl>
				<FormControl isRequired>
					<FormLabel color='darkGrey' fontSize='md' fontWeight='medium'>
						Rua
					</FormLabel>
					<FormInput size='lg' name='payer.street' />
					<FormErrorMessage name='street' />
				</FormControl>
			</Stack>
			<Stack direction={['column', 'row']} w='100%'>
				<FormControl isRequired>
					<FormLabel color='darkGrey' fontSize='md' fontWeight='medium'>
						Bairro
					</FormLabel>
					<FormInput size='lg' name='payer.district' />
					<FormErrorMessage name='district' />
				</FormControl>
				<FormControl isRequired>
					<FormLabel color='darkGrey' fontSize='md' fontWeight='medium'>
						Número
					</FormLabel>
					<FormInput size='lg' name='payer.number' />
					<FormErrorMessage name='number' />
				</FormControl>
			</Stack>
		</VStack>
	);
};

export default AddressForm;
