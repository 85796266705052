import React from 'react';
import { Box, Flex, Stack, Divider, VStack } from '@chakra-ui/react';
import { maskCpfOrCnpj } from '../../../services/masks.service';
import { GetSellerDto } from '../../../clients';
import Text from 'components/Text';

const SellerInfo: (data: Partial<GetSellerDto>) => JSX.Element = (data: Partial<GetSellerDto>) => {
	return (
		<Stack fontSize='md' color='primary' justifyContent='space-around'>
			<Flex justifyContent='space-around' mt={5}>
				<Stack bg='white' w='100%' spacing={3} p={5} px={8} pr={12} borderColor='gray.300' rounded='md' boxShadow='md'>
					<Text fontSize='xl' fontWeight='bold'>
						Favorecido
					</Text>

					<Divider orientation='horizontal' mb={3} />

					<VStack alignItems='normal'>
						<Stack direction={['column', 'column', 'row']} spacing={['3', '3', '5', '10']} justifyContent='space-between'>
							<Box>
								<Text fontWeight='bold'>Nome</Text>
								<Text>{data?.name}</Text>
							</Box>

							<Box>
								<Text fontWeight='bold'>Descrição</Text>
								<Text>{data?.description}</Text>
							</Box>

							<Box>
								<Text fontWeight='bold'>CPF/CNPJ</Text>
								<Text>{maskCpfOrCnpj(data.document)}</Text>
							</Box>

							<Box>
								<Text fontWeight='bold'>Banco</Text>
								<Text>{data?.bank_account?.bank_code}</Text>
							</Box>

							<Box>
								<Text fontWeight='bold'>Agência</Text>
								<Text>{data?.bank_account?.agency_number}</Text>
							</Box>

							<Box>
								<Text fontWeight='bold'>Conta</Text>
								<Text>{data?.bank_account?.account_number}</Text>
							</Box>

							<Box>
								<Text fontWeight='bold'>Chave PIX</Text>
								<Text>{data?.bank_account?.pix_key ? data.bank_account.pix_key : 'N/A'}</Text>
							</Box>
						</Stack>
					</VStack>
				</Stack>
			</Flex>
		</Stack>
	);
};

export default SellerInfo;
