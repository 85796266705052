import React from 'react';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Formik } from 'formik';
import {
	Flex,
	Stack,
	BreadcrumbItem,
	BreadcrumbLink,
	FormControl,
	FormLabel,
	Input,
	Text,
	Icon,
	Fade,
	Box
} from '@chakra-ui/react';
import { Center } from '@chakra-ui/layout';
import { Checkbox } from '@chakra-ui/checkbox';
import { FiEdit } from 'react-icons/fi';

import {
	EditMerchantRequest,
	GetMerchantRequest,
	MerchantsApi,
	PermissionSubjectEnum,
	SalesHistoryReportPermissionsFieldsEnum,
	SalesHistoryTablePermissionsFieldsEnum,
	SettlementBatchTransactionsReportFieldsEnum,
	SettlementBatchSalesReportFieldsEnum,
	SettlementBatchSalesTableFieldsEnum,
} from 'clients';

import Loader from 'containers/Loader';
import Paper from 'containers/Paper';
import { getApiAuthConfig } from 'services/api.service';
import Button from 'components/Button';
import Breadcrumb from 'components/Breadcrumb';
import FormInput from 'components/Form/FormInput';
import FormErrorMessage from 'components/Form/FormErrorMessage';
import FormCpfCnpjInput from 'components/Form/FormCpfCnpjInput';
import MultiSelect from 'components/MultiSelect';

import {
	default_merchant_subjects,
	default_merchant_seller_subjects,
	merchant_types
} from 'config/constants';
import yup from 'services/yup.service';
import {
	subjectTitle,
	salesHistoryReport,
	salesHistoryTable,
	settlementBatchTransactionReport,
	settlementBatchSaleReport,
	settlementBatchSaleTable,
} from 'services/enums.service';
import FormCurrencyInput from 'components/Form/FormCurrencyInput';
import ThreeDsForm from './components/ThreeDsForm';
import FormSelect from 'components/Form/FormSelect';

const EditMerchant: React.FC = () => {
	const { id } = useParams();
	const { addToast } = useToasts();

	const apiConfig = getApiAuthConfig();
	const merchantsApi = new MerchantsApi(apiConfig);
	const [isLoading, setIsLoading] = React.useState(false);
	const [showReports, setShowReports] = React.useState<boolean>(false);
	const [showTables, setShowTables] = React.useState<boolean>(false);

	const handleToggleReports = () => {
		setShowReports(!showReports);
	};

	const handleToggleTables = () => {
		setShowTables(!showTables);
	};

	const [initialValues, setInitalValues] = React.useState<any>({
		name: '',
		description: '',
		document: '',
		code: '',
		is_active: true,
		risk_active: false,
		min_risk: 0,
		merchant_api_key: null,
		merchant_account: null,
		merchant_subjects: default_merchant_subjects,
		seller_subjects: default_merchant_seller_subjects,
		sales_history_report: [],
		sales_history_table: [],
		settlement_batch_transaction_report: [],
		settlement_batch_sale_report: [],
		settlement_batch_sale_table: [],
		type: '',
	});

	const updateMerchantSchema = yup.object().shape({
		name: yup.string().required('Campo obrigatório'),
		description: yup.string().required('Campo obrigatório'),
		document: yup.string().required('Campo obrigatório').isCpfOrCnpjOptional('Documento inválido'),
		code: yup.string().required('Campo obrigatório'),
		is_active: yup.boolean(),
		risk_active: yup.boolean(),
		min_risk: yup.number(),
		merchant_api_key: yup.string().nullable(),
		merchant_account: yup.string().nullable(),
		merchant_subjects: yup.array().nullable(),
		seller_subjects: yup.array().nullable(),
		sales_history_report: yup.array().nullable(),
		sales_history_table: yup.array().nullable(),
		settlement_batch_transaction_report: yup.array().nullable(),
		settlement_batch_sale_report: yup.array().nullable(),
		settlement_batch_sale_table: yup.array().nullable(),
		type: yup.string().nullable(),
	});

	const filterDeniedFields = (arr: Array<string>, fields: Array<string>): Array<string> => {
		const deniedFields = fields?.filter((e) => {
			return arr?.indexOf(e) === -1;
		});

		return deniedFields;
	};

	const handleEditMerchant = async (values) => {
		const sales_history_report = filterDeniedFields(values.sales_history_report, Object.values(SalesHistoryReportPermissionsFieldsEnum));
		const sales_history_table = filterDeniedFields(values.sales_history_table, Object.values(SalesHistoryTablePermissionsFieldsEnum));
		const settlement_batch_transaction_report = filterDeniedFields(
			values.settlement_batch_transaction_report,
			Object.values(SettlementBatchTransactionsReportFieldsEnum)
		);
		const settlement_batch_sale_report = filterDeniedFields(
			values.settlement_batch_sale_report,
			Object.values(SettlementBatchSalesReportFieldsEnum)
		);
		const settlement_batch_sale_table = filterDeniedFields(
			values.settlement_batch_sale_table,
			Object.values(SettlementBatchSalesTableFieldsEnum)
		);

		const payload: EditMerchantRequest = {
			merchantId: id,
			updateMerchantDto: {
				name: values.name,
				description: values.description,
				document: values.document,
				code: values.code,
				is_active: values.is_active,
				risk_active: values.risk_active,
				min_risk: Math.trunc(values.min_risk * 100),
				merchant_api_key: values.merchant_api_key,
				merchant_account: values.merchant_account,
				merchant_subjects: values.merchant_subjects,
				seller_subjects: values.seller_subjects,
				reports_permissions: {
					sales_history: { denied_fields: sales_history_report },
					settlement_batch_transaction: { denied_fields: settlement_batch_transaction_report },
					settlement_batch_sale: { denied_fields: settlement_batch_sale_report },
				},
				tables_permissions: {
					sales_history: { denied_fields: sales_history_table },
					settlement_batch_sale: { denied_fields: settlement_batch_sale_table },
				},
				three_ds_active: values.three_ds_active === 'true' ? true : false,
				min_three_ds: Math.trunc(values.min_three_ds * 100),
				three_ds_value: Math.trunc(values.three_ds_value * 100),
				risk_value: Math.trunc(values.risk_value * 100),
				type: values.type?.length ? values.type : null,
			},
		};

		try {
			setIsLoading(true);

			await merchantsApi.editMerchant(payload);

			addToast('Merchant atualizado com sucesso!', {
				appearance: 'success',
				autoDismiss: true,
			});
		} catch (error) {
			addToast('Erro ao atualizar Merchant', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	};

	const getMerchant = async () => {
		setIsLoading(true);

		try {
			const getByIdParams: GetMerchantRequest = { merchantId: id };

			const merchant = await merchantsApi.getMerchant(getByIdParams);

			setInitalValues({
				name: merchant.name,
				description: merchant.description,
				document: merchant.document,
				code: merchant.code,
				is_active: merchant.is_active,
				risk_active: merchant.risk_active,
				merchant_api_key: merchant.merchant_api_key,
				merchant_account: merchant.merchant_account,
				merchant_subjects: merchant.merchant_subjects,
				seller_subjects: merchant.seller_subjects,
				sales_history_report: merchant.reports_permissions?.sales_history?.allowed_fields,
				sales_history_table: merchant.tables_permissions?.sales_history?.allowed_fields,
				settlement_batch_transaction_report: merchant.reports_permissions?.settlement_batch_transaction?.allowed_fields,
				settlement_batch_sale_report: merchant.reports_permissions?.settlement_batch_sale?.allowed_fields,
				settlement_batch_sale_table: merchant.tables_permissions?.settlement_batch_sale?.allowed_fields,
				min_three_ds: merchant?.min_three_ds ? (merchant.min_three_ds / 100).toFixed(2) : 0,
				three_ds_value: merchant?.three_ds_value ? (merchant.three_ds_value / 100).toFixed(2) : 0,
				min_risk: merchant && merchant.min_risk ? (merchant.min_risk / 100).toFixed(2) : 0,
				risk_value: merchant?.risk_value ? (merchant.risk_value / 100).toFixed(2) : 0,
				type: merchant?.type,
			});

			setIsLoading(false);
		} catch (error) {
			addToast('Erro ao buscar Merchant', {
				appearance: 'error',
				autoDismiss: true,
			});

			setIsLoading(false);
		}
	};

	React.useEffect(() => {
		getMerchant();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Flex flexDirection='column'>
			<Loader isOpen={isLoading} />

			<Breadcrumb>
				<BreadcrumbItem>
					<BreadcrumbLink>Resumo</BreadcrumbLink>
				</BreadcrumbItem>

				<BreadcrumbItem>
					<BreadcrumbLink href='/admin/merchants'>Merchants</BreadcrumbLink>
				</BreadcrumbItem>

				<BreadcrumbItem isCurrentPage>
					<BreadcrumbLink fontWeight='semibold'>Editar Merchant</BreadcrumbLink>
				</BreadcrumbItem>
			</Breadcrumb>

			<Paper p={6}>
				<Flex flexDirection='column'>
					<>
						<Formik enableReinitialize initialValues={initialValues} validationSchema={updateMerchantSchema} onSubmit={handleEditMerchant}>
							{({ handleSubmit, isValid, setFieldValue, values, errors }) => {
								return (
									<form onSubmit={handleSubmit} style={{ width: '100%' }}>
										<Stack spacing={4}>
											<Stack direction={['column', 'column', 'row']} spacing={4}>
												<FormControl id='name' isRequired>
													<FormLabel fontSize='md' fontWeight='bold'>
														Nome
													</FormLabel>
													<FormInput name='name' />
													<FormErrorMessage name='name' />
												</FormControl>

												<FormControl id='document' isRequired>
													<FormLabel fontSize='md' fontWeight='bold'>
														CPF/CNPJ
													</FormLabel>
													<FormCpfCnpjInput name='document' isDisabled={false} isSearchPayer={false} />
													<FormErrorMessage name='document' />
												</FormControl>

												<FormControl id='description' isRequired>
													<FormLabel fontSize='md' fontWeight='bold'>
														Descrição
													</FormLabel>
													<FormInput as={Input} name='description' />
													<FormErrorMessage name='description' />
												</FormControl>
											</Stack>
											<Stack direction={['column', 'column', 'row']} spacing={4}>
												<FormControl id='code' isRequired>
													<FormLabel fontSize='md' fontWeight='bold'>
														Código
													</FormLabel>
													<FormInput name='code' />
													<FormErrorMessage name='code' />
												</FormControl>
												<FormControl id='min_risk'>
													<FormLabel fontSize='md' fontWeight='bold'>
														Mínimo Para Antifraude
													</FormLabel>
													<FormCurrencyInput borderColor='darkGrey' name='min_risk' />
													<FormErrorMessage name='min_risk' />
												</FormControl>
												<FormControl id='risk_value'>
													<FormLabel fontSize='md' fontWeight='bold'>
														Valor Antifraude
													</FormLabel>
													<FormCurrencyInput borderColor='darkGrey' name='risk_value' />
													<FormErrorMessage name='risk_value' />
												</FormControl>
											</Stack>
											<ThreeDsForm errors={errors} />
											<Stack direction={['column', 'column', 'row']} spacing={4}>
												<Box minW='33%'>
													<FormControl id='type'>
														<FormLabel fontSize='md' fontWeight='bold'>
															Tipo
														</FormLabel>
														<FormSelect name='type'>
															<option value='' />
															{merchant_types.map((type) => (
																<option
																	value={type.value}
																	key={type.key}
																>
																	{type.text}
																</option>
															))}
														</FormSelect>
														<FormErrorMessage name='type' />
													</FormControl>
												</Box>
												<Stack isInline spacing={4} align='end'>
													<Checkbox
														name='is_active'
														isChecked={values.is_active}
														color={`darkGrey`}
														fontSize={`md`}
														fontWeight={`medium`}
														onChange={(e) => {
															setFieldValue('is_active', e.target.checked);
														}}
													>
														Ativo
													</Checkbox>
													<Checkbox
														name='risk_active'
														isChecked={values.risk_active}
														color={`darkGrey`}
														fontSize={`md`}
														fontWeight={`medium`}
														onChange={(e) => {
															setFieldValue('risk_active', e.target.checked);
														}}
													>
														Risco Ativo
													</Checkbox>
												</Stack>
											</Stack>
											<Stack isInline spacing={4}>
												<FormControl id='merchant_subjects' my={2}>
													<FormLabel fontSize={`md`} fontWeight='bold'>
														Permissões Merchant
													</FormLabel>
													<MultiSelect
														id='merchant_subjects'
														placeholder='Permissões Merchant'
														options={Object.values(PermissionSubjectEnum)
															?.slice(1)
															?.map((value) => ({
																label: subjectTitle[value],
																value: value,
															}))}
														value={values.merchant_subjects?.map((value) => ({
															label: subjectTitle[value],
															value: value,
														}))}
														onChange={(values) => {
															setFieldValue(
																'merchant_subjects',
																values.map((item) => item.value)
															);
														}}
													/>
													<FormErrorMessage name='merchant_subjects' />
												</FormControl>
											</Stack>
											<Stack isInline spacing={4}>
												<FormControl id='seller_subjects' my={2}>
													<FormLabel fontSize={`md`} fontWeight='bold'>
														Permissões Estabelecimento
													</FormLabel>
													<MultiSelect
														id='seller_subjects'
														placeholder='Permissões Merchant'
														options={Object.values(PermissionSubjectEnum)
															?.slice(1)
															?.map((value) => ({
																label: subjectTitle[value],
																value: value,
															}))}
														value={values.seller_subjects?.map((value) => ({
															label: subjectTitle[value],
															value: value,
														}))}
														onChange={(values) => {
															setFieldValue(
																'seller_subjects',
																values.map((item) => item.value)
															);
														}}
													/>
													<FormErrorMessage name='merchant_subjects' />
												</FormControl>
											</Stack>
											<Stack isInline spacing={4}>
												<FormControl id='reports_permissions'>
													<FormLabel fontSize={`md`} fontWeight='bold' cursor='pointer' onClick={handleToggleReports}>
														Permissões de Relatórios &nbsp;
														<Icon as={FiEdit} boxSize={4} />
													</FormLabel>
													<Fade in={showReports} unmountOnExit>
														<Stack spacing='2'>
															<Flex flexDirection='row' alignItems='center' mb='1'>
																<Text color='primary' fontSize={`sm`} fontWeight={`semibold`}>
																	Histórico de Vendas
																</Text>
															</Flex>
															<MultiSelect
																id='sales_history_report'
																placeholder='Permissões do Histórico de Vendas'
																options={Object.values(SalesHistoryReportPermissionsFieldsEnum)?.map((value) => ({
																	label: salesHistoryReport[value],
																	value: value,
																}))}
																value={values.sales_history_report?.map((value) => ({
																	label: salesHistoryReport[value],
																	value: value,
																}))}
																onChange={(values) => {
																	setFieldValue(
																		'sales_history_report',
																		values.map((item) => item.value)
																	);
																}}
															/>
															<Flex flexDirection='row' alignItems='center' mb='1'>
																<Text color='primary' fontSize={`sm`} fontWeight={`semibold`}>
																	Histórico Liquidação - Transações
																</Text>
															</Flex>
															<MultiSelect
																id='settlement_batch_transaction_report'
																placeholder='Permissões do Histórico Liquidação - Transações'
																options={Object.values(SettlementBatchTransactionsReportFieldsEnum)?.map((value) => ({
																	label: settlementBatchTransactionReport[value],
																	value: value,
																}))}
																value={values.settlement_batch_transaction_report?.map((value) => ({
																	label: settlementBatchTransactionReport[value],
																	value: value,
																}))}
																onChange={(values) => {
																	setFieldValue(
																		'settlement_batch_transaction_report',
																		values.map((item) => item.value)
																	);
																}}
															/>
															<Flex flexDirection='row' alignItems='center' mb='1'>
																<Text color='primary' fontSize={`sm`} fontWeight={`semibold`}>
																	Histórico Liquidação - Transações do Lote de Liquidação
																</Text>
															</Flex>
															<MultiSelect
																id='settlement_batch_sale_report'
																placeholder='Permissões do Histórico Liquidação - Transações do Lote de Liquidação'
																options={Object.values(SettlementBatchSalesReportFieldsEnum)?.map((value) => ({
																	label: settlementBatchSaleReport[value],
																	value: value,
																}))}
																value={values.settlement_batch_sale_report?.map((value) => ({
																	label: settlementBatchSaleReport[value],
																	value: value,
																}))}
																onChange={(values) => {
																	setFieldValue(
																		'settlement_batch_sale_report',
																		values.map((item) => item.value)
																	);
																}}
															/>
														</Stack>
													</Fade>
												</FormControl>
											</Stack>
											<Stack isInline spacing={4}>
												<FormControl id='tables_permissions'>
													<FormLabel fontSize={`md`} fontWeight='bold' cursor='pointer' onClick={handleToggleTables}>
														Permissões de Tabelas &nbsp;
														<Icon as={FiEdit} boxSize={4} />
													</FormLabel>
													<Fade in={showTables} unmountOnExit>
														<Stack spacing='2'>
															<Flex flexDirection='row' alignItems='center' mb='1'>
																<Text color='primary' fontSize={`sm`} fontWeight={`semibold`}>
																	Histórico de Vendas
																</Text>
															</Flex>
															<MultiSelect
																id='sales_history_table'
																placeholder='Permissões do Histórico de Vendas'
																options={Object.values(SalesHistoryTablePermissionsFieldsEnum)?.map((value) => ({
																	label: salesHistoryTable[value],
																	value: value,
																}))}
																value={values.sales_history_table?.map((value) => ({
																	label: salesHistoryTable[value],
																	value: value,
																}))}
																onChange={(values) => {
																	setFieldValue(
																		'sales_history_table',
																		values.map((item) => item.value)
																	);
																}}
															/>
															<Flex flexDirection='row' alignItems='center' mb='1'>
																<Text color='primary' fontSize={`sm`} fontWeight={`semibold`}>
																	Histórico Liquidação - Transações do Lote de Liquidação
																</Text>
															</Flex>
															<MultiSelect
																id='settlement_batch_sale_table'
																placeholder='Permissões do Histórico Liquidação - Transações do Lote de Liquidação'
																options={Object.values(SettlementBatchSalesTableFieldsEnum)?.map((value) => ({
																	label: settlementBatchSaleTable[value],
																	value: value,
																}))}
																value={values.settlement_batch_sale_table?.map((value) => ({
																	label: settlementBatchSaleTable[value],
																	value: value,
																}))}
																onChange={(values) => {
																	setFieldValue(
																		'settlement_batch_sale_table',
																		values.map((item) => item.value)
																	);
																}}
															/>
														</Stack>
													</Fade>
												</FormControl>
											</Stack>
											<Stack direction={['column', 'column', 'row']} spacing={4}>
												<FormControl id='merchant_api_key'>
													<FormLabel fontSize='md' fontWeight='bold'>
														API KEY
													</FormLabel>
													<FormInput name='merchant_api_key' />
													<FormErrorMessage name='merchant_api_key' />
												</FormControl>
												<FormControl id='merchant_account'>
													<FormLabel fontSize='md' fontWeight='bold'>
														ACCOUNT
													</FormLabel>
													<FormInput as={Input} name='merchant_account' />
													<FormErrorMessage name='merchant_account' />
												</FormControl>
											</Stack>
										</Stack>

										<Center pt='6'>
											<Button bgColor='primary' size='md' disabled={!isValid} type='submit' isLoading={isLoading}>
												Editar Merchant
											</Button>
										</Center>
									</form>
								);
							}}
						</Formik>
					</>
				</Flex>
			</Paper>
		</Flex>
	);
};

export default EditMerchant;
