import React from 'react';
import { SimpleGrid, FormControl, FormLabel, GridItem } from '@chakra-ui/react';

import FormSelect from 'components/Form/FormSelect';
import { GetResponsibleDto } from '../../../clients';
import FormErrorMessage from 'components/Form/FormErrorMessage';

type Props = {
	supports: GetResponsibleDto[];
	commercials: GetResponsibleDto[];
	errors: any;
};

const ResponsibleForm: React.FC<Props> = (props) => (
	<SimpleGrid
      columns={{base: 1, md: 6, xl: 12}}
      color='gray.700'
      gap={4}
    >
			<GridItem colStart={{base: 1}} colSpan={{base: 4, md: 3, xl: 3}}>
				<FormControl id='responsible.support' isRequired>
					<FormLabel fontSize='md' fontWeight='bold'>
						Suporte
					</FormLabel>
					<FormSelect name='responsible.support'>
						<option value='' />
						{props.supports.map((support) => (
							<option value={support.id} key={support.id}>
								{support.name}
							</option>
						))}
					</FormSelect>
					<FormErrorMessage name='responsible.support' />
				</FormControl>
			</GridItem>
			<GridItem colStart={{base: 1}} colSpan={{base: 4, md: 3, xl: 3}}>
				<FormControl id='responsible.commercial' isRequired>
					<FormLabel fontSize='md' fontWeight='bold'>
						Comercial
					</FormLabel>
					<FormSelect name='responsible.commercial'>
						<option value='' />
						{props.commercials.map((commercial) => (
							<option value={commercial.id} key={commercial.id}>
								{commercial.name}
							</option>
						))}
					</FormSelect>
					<FormErrorMessage name='responsible.commercial' />
				</FormControl>
			</GridItem>
	</SimpleGrid>
);

export default ResponsibleForm;
