import React from 'react';

import { Select as DefaultSelect, SelectProps } from '@chakra-ui/react';

const Select: React.FC<SelectProps> = (props: SelectProps) => {
	return (
		<DefaultSelect size={`sm`} rounded={`sm`} fontSize={`sm`} bg={`#F5F5F5`} {...props}>
			{props.children}
		</DefaultSelect>
	);
};

export default Select;
