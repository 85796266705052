import React from 'react';

import {
	Flex,
	Center,
	FormControl,
	IconButton,
	InputGroup,
	InputRightElement,
	HStack,
	Stack,
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	FormLabel,
	useBreakpointValue,
} from '@chakra-ui/react';

import { BiExport } from 'react-icons/bi';

import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';

import { ChevronRightIcon, SearchIcon } from '@chakra-ui/icons';
import { Formik } from 'formik';
import { addDays, subDays } from 'date-fns';

import Loader from '../../containers/Loader';
import BackofficeAlertContainer from 'containers/BackofficeAlertContainer';

import Button from '../../components/Button';
import Input from '../../components/Input';
import MultiSelect from '../../components/MultiSelect';
import RangeDatePicker from '../../containers/RangeDatePicker';
import ExportFileSuccessModal from '../../containers/ExportFileSuccessModal';
import ProtocolsTable from './components/ProtocolsTable';

import IPeriod from '../../interfaces/period.interface';

import { getApiAuthConfig } from '../../services/api.service';
import { useCurrentSeller } from '../../contexts/SellerProvider';

import { ProtocolApi, GetPaginatedProtocolsBySellerRequest, GetProtocolDto, CreateProtocolsListExportRequestRequest } from '../../clients';

const ProtocolsHistory: React.FC = () => {
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [exportIsLoading, setExportIsLoading] = React.useState<boolean>(false);
	const [openExportSuccessModal, setOpenExportSuccessModal] = React.useState<boolean>(false);
	const isMobile = useBreakpointValue({ base: true, lg: false });

	const [protocols, setProtocols] = React.useState<GetProtocolDto[]>();
	const [pagination, setPagination] = React.useState({ currentPage: 1, limit: 100 });
	const [totalPages, setTotalPages] = React.useState(1);

	const [filters, setFilters] = React.useState({
		number: undefined,
		status: [],
	});

	const [periodCreationDate, setPeriodCreationDate] = React.useState<IPeriod[]>([
		{
			startDate: subDays(new Date(new Date().setHours(0, 0, 0, 0)), 30),
			endDate: new Date(new Date().setHours(23, 59, 59, 999)),
			key: 'selection',
		},
	]);

	const [periodDueDate, setPeriodDueDate] = React.useState<IPeriod[]>([
		{
			startDate: subDays(new Date(new Date().setHours(0, 0, 0, 0)), 30),
			endDate: addDays(new Date(new Date().setHours(23, 59, 59, 999)), 30),
			key: 'selection',
		},
	]);

	const [periodStatus, setPeriodStatus] = React.useState<IPeriod[]>([
		{
			startDate: subDays(new Date(new Date().setHours(0, 0, 0, 0)), 30),
			endDate: addDays(new Date(new Date().setHours(23, 59, 59, 999)), 30),
			key: 'selection',
		},
	]);

	const initialValues = {
		number: undefined,
		status: [],
		creation_date_start: periodCreationDate[0].startDate.toISOString(),
		creation_date_end: periodCreationDate[0].endDate.toISOString(),
		due_date_start: periodDueDate[0].startDate.toISOString(),
		due_date_end: periodDueDate[0].endDate.toISOString(),
		status_date_start: periodStatus[0].startDate.toISOString(),
		status_date_end: periodStatus[0].endDate.toISOString(),
	};

	const { addToast } = useToasts();
	const { currentSeller } = useCurrentSeller();
	const history = useHistory();
	const apiConfig = getApiAuthConfig();
	const protocolApi = new ProtocolApi(apiConfig);

	function formatParsedParams(parsedParams) {
		if (parsedParams.number && parsedParams.number.length > 0) {
			parsedParams = {
				creation_date_start: undefined,
				creation_date_end: undefined,
				due_date_start: undefined,
				due_date_end: undefined,
				status_date_start: undefined,
				status_date_end: undefined,
				status: undefined,
				number: parsedParams.number,
			};
		}

		return parsedParams;
	}

	async function fetchListProtocolsRequest(params, currentPage?: number) {
		let { status, ...parsedParams } = params;

		parsedParams = formatParsedParams(parsedParams);

		setIsLoading(true);

		if (currentSeller) {
			try {
				let requestParams: GetPaginatedProtocolsBySellerRequest = {
					getProtocolsQueryDto: {
						seller_id: currentSeller.id,
						current_page: currentPage || pagination.currentPage,
						limit: pagination.limit,
						creation_date_start: periodCreationDate[0].startDate.toISOString(),
						creation_date_end: periodCreationDate[0].endDate.toISOString(),
						due_date_start: periodDueDate[0].startDate.toISOString(),
						due_date_end: periodDueDate[0].endDate.toISOString(),
						status_date_start: periodStatus[0].startDate.toISOString(),
						status_date_end: periodStatus[0].endDate.toISOString(),
						status: status?.map((v) => v.value),
						...parsedParams,
					},
				};

				const response = await protocolApi.getPaginatedProtocolsBySeller(requestParams);

				const filteredResults: Array<GetProtocolDto> = response.results.map((response) => {
					const filterResponse = JSON.parse(JSON.stringify({ response }));
					const parsedResponse = {
						...filterResponse.response,
						value_cents: filterResponse?.response?.value_cents / 100,
					};

					return parsedResponse;
				});

				setProtocols(filteredResults);
				setTotalPages(response.total_pages);
			} catch (error) {
				addToast('Erro ao buscar os dados', {
					appearance: 'error',
					autoDismiss: true,
				});
			} finally {
				setIsLoading(false);
			}
		}
	}

	const handleFormSubmit = async (values) => {
		setFilters(values);

		await fetchListProtocolsRequest(values, 1);
	};

	const handleExportReportFile = async (params) => {
		let { status, ...parsedParams } = params;

		parsedParams = formatParsedParams(parsedParams);

		setExportIsLoading(true);

		try {
			const params: CreateProtocolsListExportRequestRequest = {
				createExportProtocolListDto: {
					seller_id: currentSeller?.id,
					creation_date_start: periodCreationDate[0].startDate.toISOString(),
					creation_date_end: periodCreationDate[0].endDate.toISOString(),
					due_date_start: periodDueDate[0].startDate.toISOString(),
					due_date_end: periodDueDate[0].endDate.toISOString(),
					status: status?.map((v) => v.value),
					...parsedParams,
				},
			};

			await protocolApi.createProtocolsListExportRequest(params);

			setOpenExportSuccessModal(true);
		} catch (e) {
			addToast('Ocorreu um erro no processamento', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setExportIsLoading(false);
		}
	};

	function navigateToDetails(id: string) {
		history.push(`/admin/protocolos/${id}`);
	}

	React.useEffect(() => {
		fetchListProtocolsRequest(filters);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pagination, currentSeller]);

	if (!currentSeller) {
		return <BackofficeAlertContainer />;
	}

	return (
		<Flex flexDirection='column'>
			<Loader isOpen={isLoading} />

			<Breadcrumb separator={<ChevronRightIcon />} color='darkGrey' mb='4'>
				<BreadcrumbItem>
					<BreadcrumbLink fontSize='md'>Resumo</BreadcrumbLink>
				</BreadcrumbItem>

				<BreadcrumbItem>
					<BreadcrumbLink fontSize='md' href='/admin/protocolos'>
						Gestão de protocolos
					</BreadcrumbLink>
				</BreadcrumbItem>

				<BreadcrumbItem isCurrentPage>
					<BreadcrumbLink fontSize='md' fontWeight='semibold'>
						Histórico
					</BreadcrumbLink>
				</BreadcrumbItem>
			</Breadcrumb>

			<ExportFileSuccessModal
				showCloseButton
				isOpen={openExportSuccessModal}
				onOkClick={() => {
					setOpenExportSuccessModal(false);
				}}
				onClose={() => {
					setOpenExportSuccessModal(false);
				}}
			/>

			<Formik initialValues={initialValues} onSubmit={handleFormSubmit}>
				{({ handleSubmit, setFieldValue, values, resetForm }) => {
					return (
						<form onSubmit={handleSubmit}>
							<HStack justify='space-between' mb={4}>
								<FormControl width='auto'>
									<InputGroup>
										<Input
											borderColor='darkGrey'
											name='number'
											placeholder='N° do Protocolo'
											onChange={(event) => {
												setFieldValue('number', event.target.value);
											}}
											value={values.number}
											onBlur={() => handleSubmit()}
											py='5'
										/>

										<InputRightElement
											children={
												<IconButton
													_hover={{ backgroundColor: 'transparent' }}
													_focus={{ outline: 'none' }}
													aria-label='Search database'
													icon={<SearchIcon />}
													color='primary'
													backgroundColor='transparent'
													onClick={() => handleSubmit()}
												/>
											}
										/>
									</InputGroup>
								</FormControl>
								<Button
									{...(protocols?.length === 0 && { disabled: true })}
									bgColor='primary'
									isDisabled={exportIsLoading}
									isLoading={exportIsLoading}
									onClick={() => handleExportReportFile(values)}
									leftIcon={<BiExport size={20} />}
								>
									Exportar para Excel
								</Button>
							</HStack>

							<Flex direction='column' p='5' rounded='md' bgColor='white'>
								<Flex direction={isMobile ? 'column' : 'row'} justifyContent='space-between' mb='5'>
									<Stack direction={isMobile ? 'column' : 'row'} spacing='4'>
										<FormControl>
											<FormLabel color={`darkGrey`} fontSize={`md`} fontWeight={`medium`}>
												Data de Criação
											</FormLabel>
											<RangeDatePicker
												period={periodCreationDate}
												setPeriod={setPeriodCreationDate}
												setFieldValue={(field, value) => {
													field === 'startDate' && setFieldValue('creation_date_start', new Date(value).toISOString());
													field === 'endDate' && setFieldValue('creation_date_end', new Date(value).toISOString());
												}}
												handleSubmit={handleSubmit}
												backgroundColor='primary'
												borderWidth={1}
												borderColor='white'
												py='5'
											/>
										</FormControl>
										<FormControl>
											<FormLabel color={`darkGrey`} fontSize={`md`} fontWeight={`medium`}>
												Data de Vencimento
											</FormLabel>
											<RangeDatePicker
												period={periodDueDate}
												setPeriod={setPeriodDueDate}
												setFieldValue={(field, value) => {
													field === 'startDate' && setFieldValue('due_date_start', new Date(value).toISOString());
													field === 'endDate' && setFieldValue('due_date_end', new Date(value).toISOString());
												}}
												handleSubmit={handleSubmit}
												backgroundColor='primary'
												borderWidth={1}
												borderColor='white'
												py='5'
											/>
										</FormControl>
										<FormControl>
											<FormLabel color={`darkGrey`} fontSize={`md`} fontWeight={`medium`}>
												Data do Status
											</FormLabel>
											<RangeDatePicker
												period={periodStatus}
												setPeriod={setPeriodStatus}
												setFieldValue={(field, value) => {
													field === 'startDate' && setFieldValue('status_date_start', new Date(value).toISOString());
													field === 'endDate' && setFieldValue('status_date_end', new Date(value).toISOString());
												}}
												handleSubmit={handleSubmit}
												backgroundColor='primary'
												borderWidth={1}
												borderColor='white'
												py='5'
											/>
										</FormControl>
									</Stack>
								</Flex>
								<Flex direction={isMobile ? 'column' : 'row'} justifyContent='space-between'>
									<Stack direction={isMobile ? 'column' : 'row'} spacing='4'>
										<Center minW='13.2rem'>
											<FormControl>
												<MultiSelect
													id='status'
													placeholder='Status'
													options={[
														{ value: 'paid', label: 'Pago' },
														{ value: 'pending', label: 'Pendente' },
														{ value: 'overdued', label: 'Vencido sem pagamento' },
														{ value: 'canceled', label: 'Cancelado ' },
														{ value: 'alert', label: 'Alerta' },
													]}
													value={values.status}
													onChange={(value) => {
														setFieldValue('status', value);
														handleSubmit();
													}}
												/>
											</FormControl>
										</Center>
									</Stack>

									<Button
										onClick={() => {
											resetForm();
											handleSubmit();
										}}
										paddingInline='10px'
										_focus={{ outline: 'none' }}
										color='primary'
										bgColor='transparent'
									>
										Limpar Filtros
									</Button>
								</Flex>
								<Flex overflowX='auto' overflowY='hidden' flexDirection='column' mt='4'>
									{protocols && (
										<ProtocolsTable
											data={protocols}
											onRowClick={(row) => navigateToDetails(row.original.id)}
											setPagination={setPagination}
											pagination={pagination}
											totalPages={totalPages}
										/>
									)}
								</Flex>
							</Flex>
						</form>
					);
				}}
			</Formik>
		</Flex>
	);
};

export default ProtocolsHistory;
