import React from 'react';
import {
	Flex,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	ButtonProps,
	MenuButtonProps,
	Grid,
	GridItem,
	IconButton,
	Icon,
	Stack,
} from '@chakra-ui/react';
import ReactDatePicker from 'react-datepicker';
import Button from 'components/Button';
import { format } from 'date-fns';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';


import { monthNumberToName } from 'services/date.service';
import { monthName } from 'services/enums.service';

const locale = {
	localize: { month: (month) => Object.values(monthName)[month] },
	formatLong: {
		date: () => 'dd/MM/YYYY',
	},
};

export interface IPeriod {
	startDate: Date;
	endDate: Date;
	key?: string;
}

type IRangeDatePicker = ButtonProps &
	MenuButtonProps & {
		period: IPeriod;
		setPeriod: React.Dispatch<React.SetStateAction<IPeriod>>;
		setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
		handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
	};

const RangeDatePicker: React.FC<IRangeDatePicker> = (props: IRangeDatePicker) => {
	const { period, setPeriod, setFieldValue, handleSubmit, p, fontSize, size, ...rest } = props;

	function handleNextMonth() {
		setPeriod({ startDate: new Date(), endDate: new Date(new Date().setMonth(new Date().getMonth() + 1)) });
	}

	function handleNextSixMonths() {
		setPeriod({ startDate: new Date(), endDate: new Date(new Date().setMonth(new Date().getMonth() + 5)) });
	}

	const customHeaderStart = ({ date, decreaseYear, increaseYear, prevYearButtonDisabled, nextYearButtonDisabled }) => {
		return (
			<Grid templateColumns='repeat(5, 1fr)' w='300px'>
				<GridItem colStart={1} colEnd={2}>
					<IconButton
						bg='transparent'
						_hover={{ bg: 'transparent' }}
						_focus={{ bg: 'transparent', outline: 'none' }}
						_active={{ bg: 'transparent' }}
						onClick={decreaseYear}
						disabled={prevYearButtonDisabled}
						icon={<Icon as={FiChevronLeft}></Icon>}
						aria-label='Ano anterior'
						size='lg'
					/>
				</GridItem>

				<GridItem alignContent='center' w='100%' colStart={3} colEnd={4} mt={3} fontWeight='bold' fontSize='14px'>
					{`De ${date.getFullYear()}`}
				</GridItem>

				<GridItem colStart={5} colEnd={6}>
					<IconButton
						bg='transparent'
						_hover={{ bg: 'transparent' }}
						_focus={{ bg: 'transparent', outline: 'none' }}
						_active={{ bg: 'transparent' }}
						onClick={increaseYear}
						disabled={nextYearButtonDisabled}
						icon={<Icon as={FiChevronRight}></Icon>}
						aria-label='Próximo ano'
						size='lg'
					/>
				</GridItem>
			</Grid>
		);
	};

	const customHeaderEnd = ({ date, decreaseYear, increaseYear, prevYearButtonDisabled, nextYearButtonDisabled }) => {
		return (
			<Grid templateColumns='repeat(5, 1fr)' w='300px'>
				<GridItem colStart={1} colEnd={2}>
					<IconButton
						bg='transparent'
						_hover={{ bg: 'transparent' }}
						_focus={{ bg: 'transparent', outline: 'none' }}
						_active={{ bg: 'transparent' }}
						onClick={decreaseYear}
						disabled={prevYearButtonDisabled}
						icon={<Icon as={FiChevronLeft}></Icon>}
						aria-label='Ano anterior'
						size='lg'
					/>
				</GridItem>

				<GridItem alignContent='center' colStart={3} colEnd={4} mt={3} fontWeight='bold' fontSize='14px'>
					{`Até ${date.getFullYear()}`}
				</GridItem>

				<GridItem colStart={5} colEnd={6}>
					<IconButton
						bg='transparent'
						_hover={{ bg: 'transparent' }}
						_focus={{ bg: 'transparent', outline: 'none' }}
						_active={{ bg: 'transparent' }}
						onClick={increaseYear}
						disabled={nextYearButtonDisabled}
						icon={<Icon as={FiChevronRight}></Icon>}
						aria-label='Próximo ano'
						size='lg'
					/>
				</GridItem>
			</Grid>
		);
	};

	return (
		<Menu
			onClose={() => {
				if (handleSubmit) {
					if (setFieldValue) {
						setFieldValue('startDate', format(period.startDate, 'yyyy-MM-dd'));
						setFieldValue('endDate', format(period.endDate, 'yyyy-MM-dd'));
					}
					handleSubmit();
				}
			}}
			isLazy
		>
			<MenuButton as={Button} _focus={{ outline: 'none' }} px={p || 4} fontSize={fontSize || 'md'} size={size || `sm`} {...rest}>
				{`${monthNumberToName(period.startDate.getMonth() + 1)} de ${period.startDate.getFullYear()} → ${monthNumberToName(
					period.endDate.getMonth() + 1
				)} de ${period.endDate.getFullYear()}`}
			</MenuButton>
			<MenuList w='100%'>
				<Flex flexDirection='column' padding='10px 10px 0px 10px'>
					<Stack direction={['column', 'row', 'row']} justify='space-evenly'>
						<ReactDatePicker
							selected={period.startDate}
							renderCustomHeader={customHeaderStart}
							onChange={(date: Date) => {
								setPeriod({ startDate: date, endDate: period?.endDate });
							}}
							dateFormat='MM/yyyy'
							locale={locale}
							showMonthYearPicker
							selectsStart
							startDate={period.startDate}
							inline
						/>

						<ReactDatePicker
							selected={period.endDate}
							renderCustomHeader={customHeaderEnd}
							onChange={(date: Date) => {
								setPeriod({ startDate: period?.startDate, endDate: date });
							}}
							dateFormat='MM/yyyy'
							locale={locale}
							showMonthYearPicker
							selectsEnd
							endDate={period.endDate}
							inline
						/>
					</Stack>

					<Stack direction={['column', 'row']} justify='flex-end'>
						<MenuItem backgroundColor='transparent' w='150px' _focus={{ outline: 'none' }}>
							<Button size={size || `sm`} color='primary' backgroundColor='transparent' onClick={handleNextMonth}>
								Próximo Mês
							</Button>
						</MenuItem>

						<MenuItem backgroundColor='transparent' w='200px' _focus={{ outline: 'none' }} onClick={handleNextSixMonths}>
							<Button size={size || `sm`} color='primary' backgroundColor='transparent'>
								Próximos 6 Meses
							</Button>
						</MenuItem>

						<MenuItem backgroundColor='transparent' w={'100px'} _focus={{ outline: 'none' }}>
							<Button size={size || `sm`} color='primary' backgroundColor='transparent'>
								Filtrar
							</Button>
						</MenuItem>
					</Stack>
				</Flex>
			</MenuList>
		</Menu>
	);
};

export default RangeDatePicker;
