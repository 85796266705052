import React from 'react';

import { HeaderTitle, CellContent, SimpleTable, HeaderTitleProps, CellContentProps } from '../../../containers/SimpleTable';
import { Icon, Button, Flex, Tooltip, chakra } from '@chakra-ui/react';
import { FiEdit, FiChevronDown, FiChevronUp } from 'react-icons/fi';
import ResponsiblesTable from './ResponsiblesTable';

import { maskMoney } from '../../../services/masks.service';
import { monthNumberToName } from '../../../services/date.service';


const HeaderTitleStyled: React.FC<HeaderTitleProps> = (props) => {
	return (
		<HeaderTitle {...props} color='black' justifyContent='flex-start' fontSize='sm' fontWeight='bold'>
			{props.children}
		</HeaderTitle>
	);
};

const CellContentStyled: React.FC<CellContentProps> = (props) => {
	return (
		<CellContent {...props} color='primary' justifyContent='flex-start' fontSize='sm' fontWeight='semibold'>
			{props.children}
		</CellContent>
	);
};

function SampleTable({ data, setIsModalOpen, setCreateReference, setResponsiblesReference, setGoalIdReference }) {
	const columns = React.useMemo(
		() => {
			let cols: any = [];

			return cols.concat([
				{
					Header: ({ column }) => <HeaderTitle column={column}>Ações</HeaderTitle>,
					accessor: 'edit-goal-button',
					Cell: ({ cell: { row } }) => {
						return (
							<CellContent>
								<Flex>
									<Tooltip label='Editar Meta'>
										<Button
											background={'transparent'}
											onClick={() => {
												setResponsiblesReference(row?.values?.subRows);
												setIsModalOpen(true);
												setCreateReference(false);
												setGoalIdReference(row?.original?.id);
											}}
										>
											<Icon as={FiEdit} boxSize={5} />
										</Button>
									</Tooltip>
								</Flex>
							</CellContent>
						);
					},
				},
				{
					Header: ({ column }) => <HeaderTitleStyled column={column}>Date de Referência</HeaderTitleStyled>,
					accessor: 'reference_date',
					Cell: ({ cell: { value } }) => (
						<CellContentStyled>
							{monthNumberToName(new Date(value).getMonth() + 1)} de {new Date(value).getFullYear()}
						</CellContentStyled>
					),
				},
				{
					Header: ({ column }) => <HeaderTitleStyled column={column}>Dias Úteis</HeaderTitleStyled>,
					accessor: 'business_days',
					Cell: ({ cell: { value } }) => <CellContentStyled>{value}</CellContentStyled>,
				},
				{
					Header: ({ column }) => (
						<HeaderTitleStyled column={column} textAlign='center'>
							Meta Geral
						</HeaderTitleStyled>
					),
					accessor: 'overall_goal',
					Cell: ({ cell: { value } }) => <CellContentStyled>R$ {maskMoney(value / 100)}</CellContentStyled>,
				},
				{
					Header: ({ column }) => (
						<HeaderTitleStyled column={column} textAlign='center'>
							Responsáveis
						</HeaderTitleStyled>
					),
					accessor: 'subRows',
					Cell: ({ cell: { row } }) => {
						return row.canExpand ? (
							<chakra.span {...row.getToggleRowExpandedProps()}>
								{row.isExpanded ? <Icon as={FiChevronUp} boxSize={5} /> : <Icon as={FiChevronDown} boxSize={5} />}
							</chakra.span>
						) : null;
					},
				},
			]);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const renderRowSubComponent = React.useCallback(({ row }) => {
		return <ResponsiblesTable data={row.values} />;
	}, []);

	return (
		<SimpleTable
			columns={columns}
			data={data}
			pl={2}
			variant='unstyled'
			maxHeightTableWrapper='42vh'
			renderRowSubComponent={renderRowSubComponent}
		/>
	);
}

export default SampleTable;
