import React from 'react';

import { Flex, FormControl, Input, Center } from '@chakra-ui/react';
import { useToasts } from 'react-toast-notifications';
import { Formik } from 'formik';

import * as yup from 'yup';

import NewModal from 'containers/NewModal';

import Button from 'components/Button';
import FormInput from 'components/Form/FormInput';
import FormPasswordInput from '../../../components/Form/FormPasswordInput';
import FormLabel from 'components/Form/FormLabel';
import FormErrorMessage from 'components/Form/FormErrorMessage';
import FormSelect from 'components/Form/FormSelect';

import { getApiAuthConfig } from 'services/api.service';

import {
	SellersApi,
	UsersApi,
	RolesApi,
	GetSellerDto,
	ListUsersTypeEnum,
	CreateSellerUserRequest,
	UpdateSellerUserRequest,
	CreateBackofficeUserRequest,
	UpdateBackofficeUserRequest,
	ListRolesByTypeRequest,
} from 'clients';

import { user_status } from 'config/constants';
import { userStatus } from 'services/enums.service';
import Text from 'components/Text';

const createUserSchema = yup.object().shape({
	name: yup.string().required('Campo obrigatório'),
	email: yup.string().email('Formato de e-mail inválido').required('Campo obrigatório'),
	role: yup.string().required('Campo obrigatório'),
	password: yup.string().required('Campo obrigatório'),
});

const editUserSchema = yup.object().shape({
	name: yup.string(),
	role: yup.string(),
	status: yup.string(),
});

type UserModalProps = {
	openUserModal: boolean;
	type: any;
	seller?: GetSellerDto;
	showSellerInput: boolean;
	handleOpenUserModal(id?: string): void;
	isLoading: boolean;
	setIsLoading(load: boolean): void;
	resetTable: boolean;
	setResetTable(load: boolean): void;
	userID?: string;
	selectedUser?: any;
};

const UserModal: React.FC<UserModalProps> = ({
	openUserModal,
	type,
	seller,
	showSellerInput,
	handleOpenUserModal,
	isLoading,
	setIsLoading,
	resetTable,
	setResetTable,
	userID,
	selectedUser,
}) => {
	const { addToast } = useToasts();
	const [roles, setRoles] = React.useState<any>();

	const apiConfig = getApiAuthConfig();
	const sellersApi = new SellersApi(apiConfig);
	const usersApi = new UsersApi(apiConfig);
	const rolesApi = new RolesApi(apiConfig);

	const createUserInitialValues = {
		name: '',
		email: '',
		role: '',
		password: '',
	};

	const editUserInitialValues = {
		name: selectedUser?.name,
		email: selectedUser?.email,
		role: selectedUser?.role?.id,
		status: selectedUser?.status,
	};

	async function fetchRoles() {
		setIsLoading(true);

		try {
			const requestParams: ListRolesByTypeRequest = {
				listByTypeDto: {
					type: type,
				},
			};

			const response = await rolesApi.listRolesByType(requestParams);

			setRoles(response);
		} catch (error) {
			addToast('Erro ao buscar perfis.', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	}

	const createUser = async (values, { resetForm }) => {
		setIsLoading(true);

		if (type === ListUsersTypeEnum.Backoffice) {
			try {
				const requestParams: CreateBackofficeUserRequest = {
					createUserDto: {
						name: values.name,
						password: values.password,
						email: values.email,
						role_id: values.role,
					},
				};
				await usersApi.createBackofficeUser(requestParams);
				resetForm({});
				setResetTable(!resetTable);
				handleOpenUserModal();
			} catch (error) {
				addToast('Erro ao criar usuário', {
					appearance: 'error',
					autoDismiss: true,
				});
			} finally {
				setIsLoading(false);
			}
		} else {
			try {
				const requestParams: CreateSellerUserRequest = {
					sellerId: seller?.id!,
					createSellerUserDto: {
						name: values.name,
						password: values.password,
						email: values.email,
						role_id: values.role,
					},
				};
				await sellersApi.createSellerUser(requestParams);
				resetForm({});
				setResetTable(!resetTable);
				handleOpenUserModal();
			} catch (error) {
				addToast('Erro ao criar usuário', {
					appearance: 'error',
					autoDismiss: true,
				});
			} finally {
				setIsLoading(false);
			}
		}
	};

	const editUser = async (values, { resetForm }) => {
		if (!userID) return;

		setIsLoading(true);

		if (type === ListUsersTypeEnum.Backoffice) {
			try {
				const requestParams: UpdateBackofficeUserRequest = {
					userId: userID,
					updateUserDto: {
						name: values.name || undefined,
						role_id: values.role || undefined,
						status: values.status || undefined,
						email: values.email || undefined,
					},
				};
				await usersApi.updateBackofficeUser(requestParams);

				resetForm({});
				setResetTable(!resetTable);
				handleOpenUserModal();
			} catch (error) {
				addToast('Erro ao editar usuário ', {
					appearance: 'error',
					autoDismiss: true,
				});
			} finally {
				setIsLoading(false);
			}
		} else {
			try {
				const requestParams: UpdateSellerUserRequest = {
					sellerId: selectedUser?.seller?.id! || seller?.id!,
					userId: userID,
					updateSellerUserDto: {
						name: values.name || undefined,
						role_id: values.role || undefined,
						status: values.status || undefined,
						email: values.email || undefined,
					},
				};
				await sellersApi.updateSellerUser(requestParams);

				resetForm({});
				setResetTable(!resetTable);
				handleOpenUserModal();
			} catch (error) {
				addToast('Erro ao editar usuário ', {
					appearance: 'error',
					autoDismiss: true,
				});
			} finally {
				setIsLoading(false);
			}
		}
	};

	React.useEffect(() => {
		fetchRoles();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<NewModal
			isOpen={openUserModal}
			size={`lg`}
			onClose={() => {
				handleOpenUserModal();
			}}
		>
			<Flex px={12} py={8} flexDirection='column'>
				{!userID ? (
					<Formik enableReinitialize initialValues={createUserInitialValues} validationSchema={createUserSchema} onSubmit={createUser}>
						{({ handleSubmit, isValid }) => {
							return (
								<form onSubmit={handleSubmit} style={{ width: '100%' }}>
									{showSellerInput && (
										<FormControl my={2}>
											<FormLabel fontSize='md' fontWeight='medium'>
												Estabelecimento
											</FormLabel>
											<Input
												isDisabled
												size={`md`}
												_hover={{ outine: 'none' }}
												_focus={{ outline: 'none' }}
												value={seller?.name!}
												fontSize={`md`}
												fontWeight={`medium`}
												borderColor='darkGrey'
											/>
										</FormControl>
									)}
									<FormControl id='name' my={2} isRequired>
										<FormLabel fontSize='md' fontWeight='medium'>
											Nome
										</FormLabel>
										<FormInput name='name' fontSize={`md`} fontWeight={`medium`} />
										<FormErrorMessage name='name' />
									</FormControl>
									<FormControl id='email' my={2} isRequired>
										<FormLabel fontSize={`md`} fontWeight='medium'>
											E-mail
										</FormLabel>
										<FormInput name='email' fontSize={`md`} fontWeight={`medium`} />
										<FormErrorMessage name='email' />
									</FormControl>
									<FormControl id='role' my={2} isRequired>
										<FormLabel fontSize={`md`} fontWeight='medium'>
											Perfil
										</FormLabel>
										<FormSelect fontSize={`md`} size={`md`} name='role'>
											<option value='' />
											{roles?.map((role) => (
												<option value={role.id} key={role.id}>
													{role.name}
												</option>
											))}
										</FormSelect>
										<FormErrorMessage name='role' />
									</FormControl>
									<FormControl id='password' my={2} isRequired>
										<FormLabel fontSize={`md`} fontWeight='medium'>
											Senha Inicial
										</FormLabel>
										<FormPasswordInput name='password' fontSize={`md`} fontWeight={`medium`} placeholder='Digite sua senha aqui' autoComplete='nope' />
										<FormErrorMessage name='password' />
									</FormControl>

									<Text my={2} align='center' fontSize='md' fontWeight='medium'>
										Sugere-se que o usuário troque a senha após o primeiro acesso
									</Text>
									<Center>
										<Button
											mt={4}
											backgroundColor='primary'
											fontSize={`md`}
											fontWeight={`semibold`}
											disabled={!isValid}
											type='submit'
											isLoading={isLoading}
										>
											Criar Usuário
										</Button>
									</Center>
								</form>
							);
						}}
					</Formik>
				) : (
					<Formik enableReinitialize initialValues={editUserInitialValues} validationSchema={editUserSchema} onSubmit={editUser}>
						{({ handleSubmit, isValid }) => {
							return (
								<form onSubmit={handleSubmit} style={{ width: '100%' }}>
									<FormControl id='name' my={2}>
										<FormLabel fontSize={`md`} fontWeight='medium'>
											Nome
										</FormLabel>
										<FormInput fontSize={`md`} fontWeight='medium' name='name' />
										<FormErrorMessage name='name' />
									</FormControl>
									<FormControl id='email' my={2}>
										<FormLabel fontSize={`md`} fontWeight='medium'>
											E-mail
										</FormLabel>
										<FormInput name='email' color={`darkGrey`} fontSize={`md`} fontWeight={`medium`} />
										<FormErrorMessage name='email' />
									</FormControl>
									<FormControl id='status' my={2}>
										<FormLabel fontSize={`md`} fontWeight='medium'>
											Status
										</FormLabel>
										<FormSelect fontSize={`md`} name='status'>
											<option value='' />
											{user_status.map((state) => (
												<option value={state.value} key={state.value}>
													{userStatus[state.text]}
												</option>
											))}
										</FormSelect>
										<FormErrorMessage name='status' />
									</FormControl>
									<FormControl id='role' my={2}>
										<FormLabel fontSize={`md`} fontWeight='medium'>
											Perfil
										</FormLabel>
										<FormSelect fontSize={`md`} size={`md`} name='role'>
											<option value='' />
											{roles?.map((role) => (
												<option value={role.id} key={role.id}>
													{role.name}
												</option>
											))}
										</FormSelect>
										<FormErrorMessage name='role' />
									</FormControl>

									<Center>
										<Button
											mt={4}
											backgroundColor='primary'
											fontSize={`md`}
											fontWeight={`semibold`}
											disabled={!isValid}
											type='submit'
											isLoading={isLoading}
										>
											Atualizar Usuário
										</Button>
									</Center>
								</form>
							);
						}}
					</Formik>
				)}
			</Flex>
		</NewModal>
	);
};

export default UserModal;
