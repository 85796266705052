import React from 'react';

import { Divider, Flex, Box } from '@chakra-ui/react';
import Text from 'components/Text';

type PaperProps = {
	title?: string;
	p?: number;
	w?: {base?: string, sm?: string, md?: string, lg?: string, xl?: string, '2xl'?: string }
};

const Paper: React.FC<PaperProps> = ({ children, title, p, w }) => {
	return (
		<Flex
			flexDir={`column`}
			bgColor={`#fff`}
			boxShadow={`md`}
			rounded={`md`}
			p={p || 10}
			mt={5} w={w ||'100%'}
		>
			{title && (
				<Box>
					<Text fontSize='xl' fontWeight='bold' mb={3}>
						{title}
					</Text>
					<Divider orientation={`horizontal`} mb={5} />
				</Box>
			)}
			{children}
		</Flex>
	);
};

export default Paper;
