import React from 'react';

import { useToasts } from 'react-toast-notifications';

import { FormControl, Button, FormLabel } from '@chakra-ui/react';
import { Box, Stack, Text, VStack, Flex } from '@chakra-ui/layout';

import { Formik } from 'formik';

import yup from '../../../services/yup.service';

import {
	BilletsApi,
	GetPaymentLinkSellerDto,
	CreateBilletPaymentInternalRequest,
	GetSimulationDto,
	GetPaymentLinkDto
} from '../../../clients';

import FormErrorMessage from '../../../components/Form/FormErrorMessage';
import FormInput from '../../../components/Form/FormInput';
import FormZipcodeInput from '../../../components/Form/FormZipcodeInput';
import getzipcode from '../../../services/getzipcode.service';

import { getApiDefaultConfig } from '../../../services/api.service';
import { maskMoney, maskPorcent } from '../../../services/masks.service';
import { CheckoutSaleInformationType } from '../types/checkout.type';
import { states } from '../../../config/constants';

import BilletModal from './BilletModal';
import FormCpfCnpjInput from '../../../components/Form/FormCpfCnpjInput';
import FormSelect from '../../../components/Form/FormSelect';
import ConfirmationBox from './ConfirmationBox';

type BilletProps = {
	seller: GetPaymentLinkSellerDto;
	saleInformation: CheckoutSaleInformationType | undefined;
	simulationValues: Array<GetSimulationDto>;
	setLinkPayed(payed: boolean): void;
	paymentLink: GetPaymentLinkDto;
	updatePaymentLink: () => void;
};

const Billet: React.FC<BilletProps> = ({ seller, saleInformation, simulationValues, setLinkPayed, paymentLink, updatePaymentLink }) => {
	const { addToast } = useToasts();
	
	const [value, setValue] = React.useState({
		simulated: 0,
		original: 0,
		cet: 0,
	});

	const [url, setUrl] = React.useState('');

	const [confirmPayment, setConfirmPayment] = React.useState(false);
	const [checked, setChecked] = React.useState(false);

	const [isLoading, setIsLoading] = React.useState(false);
	const initialValues = {
		cpf: paymentLink.payer.document ?? '',
		cep: paymentLink.payer.address?.zipcode ?? '',
		city: paymentLink.payer.address?.city ?? '',
		state: paymentLink.payer.address?.state ?? '',
		street: paymentLink.payer.address?.street ?? '',
		district: paymentLink.payer.address?.district ?? '',
		number: paymentLink.payer.address?.number ?? '',
		complement: paymentLink.payer.address?.complement ?? '',
		name: paymentLink.payer.name ?? '',
	};
	const customMessage: string = seller.custom_checkout?.billet?.custom_block || ''

	const schema = yup.object().shape({
		cpf: yup.string().trim().isCpfOrCnpjOptional('Documento inválido').required('Campo obrigatório'),
		cep: yup.string().trim().required('Campo obrigatório'),
		city: yup.string().trim().required('Campo obrigatório'),
		state: yup.string().trim().required('Campo obrigatório'),
		street: yup.string().trim().required('Campo obrigatório'),
		district: yup.string().trim().required('Campo obrigatório'),
		number: yup.string().trim().required('Campo obrigatório'),
		complement: yup.string(),
		name: yup.string().isCompleteName('Obrigatório nome completo').required('Campo obrigatório'),
	});

	const handlePayment = async (values) => {
		try {
			if (url !== '') {
				setConfirmPayment(!confirmPayment);
				return;
			}
			if (!value.simulated) return;

			setIsLoading(true);

			const apiConfig = getApiDefaultConfig();

			const paymentPayloadRequest: CreateBilletPaymentInternalRequest = {
				sellerId: seller.id,
				createBilletInternalDto: [{
						value: value.simulated,
						social_security_number: values.cpf.trim(),
						shopper_statement: saleInformation!.description,
						description: saleInformation!.description,
						delivery_date: paymentLink?.expires_in ? new Date(paymentLink.expires_in) : new Date(),
						shopper: {
							first_name: values.name.split(' ')[0],
							last_name: values.name.split(' ')[1],
							email: saleInformation!.email,
							billing_address: {
								city: values.city.trim(),
								house_number_or_name: values.number.trim(),
								postal_code: values.cep.replace('-', '').trim(),
								state_or_province: values.state.trim(),
								street: values.street,
								complement: values.complement !== '' ? values.complement.trim() : 'N/A',
								district: values.district.trim(),
							},
						},
						sale_id: saleInformation!.id,
					}],
			};

			const sellerApi = new BilletsApi(apiConfig);
			const response = await sellerApi.createBilletPaymentInternal(paymentPayloadRequest);
			await updatePaymentLink();

			if (!response && !response[0]) return;

			setUrl(response[0].url);
			setConfirmPayment(!confirmPayment);
			addToast('Boleto gerado com sucesso', {
				appearance: 'success',
				autoDismiss: true,
			});
		} catch (e) {
			addToast('Dados Inválidos', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	};

	React.useEffect(() => {
		
		(async function () {
			const amount = simulationValues.find((payment) => payment.type === 'billet');

			if (!amount) return;

			setValue({
				simulated: amount.total_amount_cents,
				original: amount.original_amount_cents,
				cet: amount.cet,
			});
		})();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Formik enableReinitialize initialValues={initialValues} validationSchema={schema} onSubmit={handlePayment}>
			{({ handleSubmit, setFieldValue, isValid, resetForm, values }) => {
				return (
					<form onSubmit={handleSubmit}>
						<Flex direction='column' mt={`8`}>
							<Stack direction='column' spacing='4' fontSize='2sm' bgColor='gray.200' rounded='md' p='4'>
								<Flex justifyContent='space-between'>
									<Text>Valor original do serviço destinado ao cartório</Text>

									<Text>{maskMoney(value.original / 100)}</Text>
								</Flex>
								<Flex justifyContent='space-between'>
									<Text>* Custo financeiro da operação:</Text>

									<Text>{maskMoney((value.simulated - value.original) / 100)}</Text>
								</Flex>
								<Flex justifyContent='space-between'>
									<Text>* Custo efetivo total:</Text>

									<Text>{maskPorcent(value.cet)}</Text>
								</Flex>

								<Box bgColor='gray.400' height='0.063rem' />

								<Flex justifyContent='space-between'>
									<Text>Valor a ser pago com acréscimo</Text>

									<Text>{maskMoney(value.simulated / 100)}</Text>
								</Flex>
							</Stack>
						</Flex>

						<Flex direction='column' alignItems='center' my={`4`}>
							<ConfirmationBox
								isChecked={checked}
								onChange={() => {
									setChecked(!checked);
								}}
								customMessage={customMessage}
								message='A solicitação do seu serviço somente será liberada após o prazo de compensação do boleto que poderá ser de até três dias úteis pela instituição financeira.'
							/>
						</Flex>
						{checked && (
							<VStack my={4} w='100%' fontSize='2sm' border='1px' borderColor='gray.400' rounded='md' p='5' className='data__billet'>
								<Stack direction={['column', 'row']} w='100%' spacing={4}>
									<FormControl>
										<FormLabel color={`black`} fontWeight={`semibold`} fontSize={`md`}>
											Nome Completo
										</FormLabel>
										<FormInput
											variant='flushed'
											placeholder='Digite aqui'
											size='lg'
											autoFocus
											name='name'
											borderColor='gray.500'
											rounded='md'
											p='5'
											isDisabled={paymentLink?.is_edit === false}
										/>
										<FormErrorMessage name='name' />
									</FormControl>

									<FormControl>
										<FormLabel color={`black`} fontWeight={`semibold`} fontSize={`md`}>
											CPF/CNPJ
										</FormLabel>
										<FormCpfCnpjInput
											isSearchPayer={true}
											variant='flushed'
											placeholder='Digite aqui'
											size='lg'
											autoFocus
											name='cpf'
											borderColor='gray.500'
											rounded='md'
											p='5'
											setSelectedPayer={() => {}}
											className='form-data__document'
											isDisabled={paymentLink?.is_edit === false}
											/>
										<FormErrorMessage name='cpf' />
									</FormControl>
								</Stack>

								<Stack direction={['column', 'row']} w='100%' spacing={4}>
									<FormControl>
										<FormLabel color={`black`} fontWeight={`semibold`} fontSize={`md`}>
											CEP
										</FormLabel>
										<FormZipcodeInput
											variant='flushed'
											placeholder='Digite aqui'
											size='lg'
											autoFocus
											name='cep'
											borderColor='gray.500'
											rounded='md'
											p='5'
											performSearch={getzipcode}
											onSearchResults={(data) => {
												setFieldValue('street', data.logradouro);
												setFieldValue('district', data.bairro);
												setFieldValue('city', data.localidade);
												setFieldValue('state', data.uf);
												setFieldValue('complement', data.complemento);
											}}
											className='form-data__zipcode'
											isDisabled={paymentLink?.is_edit === false}
										/>
										<FormErrorMessage name='cep' />
									</FormControl>

									<FormControl>
										<FormLabel color={`black`} fontWeight={`semibold`} fontSize={`md`}>
											Cidade
										</FormLabel>
										<FormInput
											variant='flushed'
											placeholder='Digite aqui'
											size='lg'
											autoFocus
											name='city'
											borderColor='gray.500'
											rounded='md'
											p='5'
											isDisabled={paymentLink?.is_edit === false}
										/>
										<FormErrorMessage name='city' />
									</FormControl>
								</Stack>
								<Stack direction={['column', 'row']} w='100%'>
									<FormControl rounded='md'>
										<FormLabel color={`black`} fontWeight={`semibold`} fontSize={`md`}>
											Estado
										</FormLabel>
										<FormSelect
											textColor={!!values.state ? `black` : `gray.500`}
											variant='outline'
											size='lg'
											fontSize='md'
											autoFocus
											name='state'
											border='1px'
											borderColor='gray.500'
											isDisabled={paymentLink?.is_edit === false}
										>
											<option value=''>Selecione</option>
											{states.map((state) => (
												<option value={state.value} key={state.value}>
													{state.text}
												</option>
											))}
										</FormSelect>
										<FormErrorMessage name='state' />
									</FormControl>
									<FormControl>
										<FormLabel color={`black`} fontWeight={`semibold`} fontSize={`md`}>
											Rua
										</FormLabel>
										<FormInput
											variant='flushed'
											placeholder='Rua'
											size='lg'
											autoFocus
											name='street'
											borderColor='gray.500'
											rounded='md'
											p='5'
											isDisabled={paymentLink?.is_edit === false}
										/>
										<FormErrorMessage name='street' />
									</FormControl>
								</Stack>
								<Stack direction={['column', 'row']} w='100%'>
									<FormControl>
										<FormLabel color={`black`} fontWeight={`semibold`} fontSize={`md`}>
											Bairro
										</FormLabel>
										<FormInput
											variant='flushed'
											placeholder='Bairro'
											size='lg'
											autoFocus
											name='district'
											borderColor='gray.500'
											rounded='md'
											p='5'
											isDisabled={paymentLink?.is_edit === false}
										/>
										<FormErrorMessage name='district' />
									</FormControl>
									<FormControl>
										<FormLabel color={`black`} fontWeight={`semibold`} fontSize={`md`}>
											Número
										</FormLabel>
										<FormInput
											variant='flushed'
											placeholder='Número'
											size='lg'
											autoFocus
											name='number'
											borderColor='gray.500'
											rounded='md'
											p='5'
											className='form-data__house-number'
											isDisabled={paymentLink?.is_edit === false}
										/>
										<FormErrorMessage name='number' />
									</FormControl>
								</Stack>
								<Stack direction={['column', 'row']} w='100%'>
									<FormControl>
										<FormLabel color={`black`} fontWeight={`semibold`} fontSize={`md`}>
											Complemento
										</FormLabel>
										<FormInput
											variant='flushed'
											placeholder='Complemento'
											size='lg'
											autoFocus
											name='complement'
											borderColor='gray.500'
											rounded='md'
											p='5'
											isDisabled={paymentLink?.is_edit === false}
										/>
										<FormErrorMessage name='complement' />
									</FormControl>
								</Stack>
								<Button
									isLoading={isLoading}
									size='lg'
									w='100%'
									disabled={!isValid || isLoading}
									color='white'
									fontSize={`md`}
									bgColor='primary'
									type='submit'
									className='button__generate-billet'
								>
									Gerar Boleto
								</Button>
							</VStack>
						)}
						<BilletModal
							confirmPayment={confirmPayment}
							url={url}
							setConfirmPayment={setConfirmPayment}
							resetForm={resetForm}
							isLoading={isLoading}
							setLinkPayed={setLinkPayed}
						/>
					</form>
				);
			}}
		</Formik>
	);
};

export default Billet;
