import { Box, Flex, FormControl, FormLabel, Icon, Input, SimpleGrid, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { Formik } from 'formik';
import React from 'react';
import { BiDetail } from 'react-icons/bi';
import * as yup from 'yup';
import { GetOrdersStatusEnum } from '../../../clients/apis/PaperRollOrderApi';
import { GetPaperRollOrderDto, GetPaperRollOrderDtoStatusEnum } from '../../../clients/models/GetPaperRollOrderDto';
import Button from '../../../components/Button';
import FormErrorMessage from '../../../components/Form/FormErrorMessage';
import FormInput from '../../../components/Form/FormInput';
import NewModal from '../../../containers/NewModal';
import { maskZipcode } from '../../../services/masks.service';
import { usePaperRollOrder } from '../context/PaperRollOrderContext';
import { statusText } from './StatusText';
import { SentPaperRollOrderDto, SentPaperRollOrderDtoShippingTypeEnum } from '../../../clients';
import FormSelect from '../../../components/Form/FormSelect';

type DetailModalProps = {
	paperRollOrder: GetPaperRollOrderDto;
};

const DetailModal: React.FC<DetailModalProps> = ({ paperRollOrder }) => {
	const { getOrders, paperRollOrderApi, addToast, isMobile } = usePaperRollOrder();

	const { isOpen, onOpen, onClose } = useDisclosure();
	const [isLoading, setIsLoading] = React.useState(false);

	const shippingTypeArray = Object.keys(SentPaperRollOrderDtoShippingTypeEnum).map((key) => SentPaperRollOrderDtoShippingTypeEnum[key]);

	const initialValues = {
		actionType: '',
		backoffice_note: paperRollOrder.backoffice_note || '',
		sent_amount: paperRollOrder.sent_amount || '',
		shipping_type: paperRollOrder.shipping_type || '',
		tracking_code: paperRollOrder.tracking_code || '',
	};

	const validationSchema = yup.object().shape({
		actionType: yup.string(),
		backoffice_note: yup.string().when('actionType', {
			is: 'cancelled',
			then: yup.string().required('Observação é obrigatória ao cancelar o pedido.'),
		}),
		sent_amount: yup.string().when('actionType', {
			is: 'sent',
			then: yup.string().required('Quantidade é obrigatória ao enviar o pedido.'),
		}),
		shipping_type: yup.string().when('actionType', {
			is: 'sent',
			then: yup.string().required('Forma de Envio é obrigatória ao enviar o pedido.'),
		}),
		tracking_code: yup.string().when('actionType', {
			is: 'sent',
			then: yup.string().required('Código de Rastreio é obrigatório ao enviar o pedido.'),
		}),
	});

	const sentOrder = async (sent: boolean, values) => {
		setIsLoading(true);
		try {
			const { backoffice_note, sent_amount, shipping_type, tracking_code } = values;
			const parsedPayload: SentPaperRollOrderDto = {
				sent,
				backoffice_note,
				sent_amount,
				shipping_type: shipping_type !== '' ? shipping_type : undefined,
				tracking_code,
			};
			await paperRollOrderApi.sentPaperRollOrder({ id: paperRollOrder.id, sentPaperRollOrderDto: parsedPayload });
			addToast('Pedido enviado com sucesso', {
				appearance: 'success',
				autoDismiss: true,
			});
		} catch (error) {
			addToast('Falha ao enviar o pedido', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			onClose();
			getOrders();
			setIsLoading(false);
		}
	};

	return (
		<>
			<Stack w='100%' _hover={{ opacity: 0.7, cursor: 'pointer' }} onClick={onOpen}>
				<Icon as={BiDetail} boxSize={5} />
			</Stack>
			<NewModal isOpen={isOpen} onClose={onClose} size='4xl' showCloseButton>
				<Box borderRadius='md'>
					<Flex py={4} px={12} flexDirection='column'>
						<Stack w='100%' align='center' mb='10px'>
							<Text color='darkGrey' fontSize='md' fontWeight='bold'>
								Solicitação de bobina
							</Text>
							<Text color='darkGrey' fontSize='md' fontWeight='bold'>
								ID: {paperRollOrder.id}
							</Text>
							<Flex flexDirection='row' color='darkGrey' fontWeight='bold' fontSize='md' alignItems='center'>
								{statusText[(paperRollOrder.status as unknown) as GetOrdersStatusEnum].icon}
								{statusText[(paperRollOrder.status as unknown) as GetOrdersStatusEnum].text}
							</Flex>
						</Stack>
						<Formik
							enableReinitialize
							initialValues={initialValues}
							validationSchema={validationSchema}
							onSubmit={(values) => {
								if (values.actionType === 'sent') {
									sentOrder(true, values);
								} else if (values.actionType === 'cancelled') {
									sentOrder(false, values);
								}
							}}
						>
							{({ handleSubmit, setFieldValue }) => {
								return (
									<form onSubmit={handleSubmit}>
										<SimpleGrid columns={isMobile ? 1 : 2} spacing='4' w='100%' mt='2'>
											<Flex>
												<FormControl id='description'>
													<FormLabel fontSize='md' fontWeight='bold'>
														Cartório
													</FormLabel>
													<FormInput as={Input} name='seller_name' isDisabled value={paperRollOrder.seller_name} variant='flushed' />
												</FormControl>
											</Flex>
											<Flex>
												<FormControl id='requesting_amount'>
													<FormLabel fontSize='md' fontWeight='bold'>
														Quantidade Solicitada
													</FormLabel>
													<FormInput
														as={Input}
														name='requesting_amount'
														isDisabled
														value={paperRollOrder.requesting_amount}
														variant='flushed'
													/>
												</FormControl>
											</Flex>
											<Flex>
												<FormControl id='description'>
													<FormLabel fontSize='md' fontWeight='bold'>
														Descrição
													</FormLabel>
													<FormInput as={Input} name='description' isDisabled value={paperRollOrder.description} variant='flushed' />
												</FormControl>
											</Flex>
											<Flex>
												<FormControl id='requesting_user_name'>
													<FormLabel fontSize='md' fontWeight='bold'>
														Solicitante
													</FormLabel>
													<FormInput
														as={Input}
														name='requesting_user_name'
														isDisabled
														value={paperRollOrder.requesting_user_name}
														variant='flushed'
													/>
												</FormControl>
											</Flex>
										</SimpleGrid>
										<Stack w='100%' align='center' my='20px'>
											<Text color='darkGrey' fontSize='md' fontWeight='bold'>
												Endereço de Envio
											</Text>
											{!paperRollOrder.default_address && (
												<Text color='red' fontSize='sm'>
													*Este endereço não é o padrão do cartório.
												</Text>
											)}
										</Stack>
										<SimpleGrid columns={isMobile ? 1 : 2} spacing='4' w='100%'>
											<Flex>
												<FormControl id='address.street'>
													<FormLabel fontSize='md' fontWeight='bold'>
														Logradouro
													</FormLabel>
													<FormInput as={Input} name='address.street' isDisabled value={paperRollOrder.address.street} variant='flushed' />
												</FormControl>
											</Flex>
											<Flex>
												<FormControl id='address.number'>
													<FormLabel fontSize='md' fontWeight='bold'>
														Número
													</FormLabel>
													<FormInput as={Input} name='address.number' isDisabled value={paperRollOrder.address.number} variant='flushed' />
												</FormControl>
											</Flex>
											<Flex>
												<FormControl id='address.complement'>
													<FormLabel fontSize='md' fontWeight='bold'>
														Complemento
													</FormLabel>
													<FormInput
														as={Input}
														name='address.complement'
														isDisabled
														value={paperRollOrder.address.complement}
														variant='flushed'
													/>
												</FormControl>
											</Flex>
											<Flex>
												<FormControl id='address.district'>
													<FormLabel fontSize='md' fontWeight='bold'>
														Bairro
													</FormLabel>
													<FormInput
														as={Input}
														name='address.district'
														isDisabled
														value={paperRollOrder.address.district}
														variant='flushed'
													/>
												</FormControl>
											</Flex>
											<Flex>
												<FormControl id='address.city'>
													<FormLabel fontSize='md' fontWeight='bold'>
														Cidade
													</FormLabel>
													<FormInput as={Input} name='address.city' isDisabled value={paperRollOrder.address.city} variant='flushed' />
												</FormControl>
											</Flex>
											<Flex>
												<FormControl id='address.state'>
													<FormLabel fontSize='md' fontWeight='bold'>
														UF
													</FormLabel>
													<FormInput as={Input} name='address.state' isDisabled value={paperRollOrder.address.state} variant='flushed' />
												</FormControl>
											</Flex>
											<Flex>
												<FormControl id='address.zipcode'>
													<FormLabel fontSize='md' fontWeight='bold'>
														CEP
													</FormLabel>
													<FormInput
														as={Input}
														name='address.zipcode'
														isDisabled
														value={maskZipcode(paperRollOrder.address.zipcode)}
														variant='flushed'
													/>
												</FormControl>
											</Flex>
										</SimpleGrid>
										<Stack w='100%' align='center' my='20px'>
											<Text color='darkGrey' fontSize='md' fontWeight='bold'>
												Suporte
											</Text>
										</Stack>
										<SimpleGrid columns={isMobile ? 1 : 2} spacing='4' w='100%' mt='2'>
											{paperRollOrder.backoffice_user_name && (
												<Flex>
													<FormControl id='backoffice_user_name'>
														<FormLabel fontSize='md' fontWeight='bold'>
															Usuário
														</FormLabel>
														<FormInput
															as={Input}
															name='backoffice_user_name'
															isDisabled
															value={paperRollOrder.backoffice_user_name}
															variant='flushed'
														/>
													</FormControl>
												</Flex>
											)}
											<Flex>
												<FormControl id='backoffice_note'>
													<FormLabel fontSize='md' fontWeight='bold'>
														Observação
													</FormLabel>
													<FormInput
														as={Input}
														name='backoffice_note'
														variant='flushed'
														isDisabled={paperRollOrder.status !== GetPaperRollOrderDtoStatusEnum.Pending}
													/>
													<FormErrorMessage name='backoffice_note' />
												</FormControl>
											</Flex>
											<Flex>
												<FormControl id='sent_amount'>
													<FormLabel fontSize='md' fontWeight='bold'>
														Quantidade Enviada
													</FormLabel>
													<FormInput
														as={Input}
														name='sent_amount'
														variant='flushed'
														isDisabled={paperRollOrder.status !== GetPaperRollOrderDtoStatusEnum.Pending}
													/>
													<FormErrorMessage name='sent_amount' />
												</FormControl>
											</Flex>
											<Flex>
												<FormControl id='shipping_type'>
													<FormLabel fontSize='md' fontWeight='bold'>
														Forma de Envio
													</FormLabel>

													<FormSelect
														name='shipping_type'
														variant='flushed'
														isDisabled={paperRollOrder.status !== GetPaperRollOrderDtoStatusEnum.Pending}
													>
														<option value='' />
														{shippingTypeArray.map((value, index) => (
															<option value={value} key={index}>
																{value}
															</option>
														))}
													</FormSelect>

													<FormErrorMessage name='shipping_type' />
												</FormControl>
											</Flex>
											<Flex>
												<FormControl id='tracking_code'>
													<FormLabel fontSize='md' fontWeight='bold'>
														Código de Rastreio
													</FormLabel>
													<FormInput
														as={Input}
														name='tracking_code'
														variant='flushed'
														isDisabled={paperRollOrder.status !== GetPaperRollOrderDtoStatusEnum.Pending}
													/>
													<FormErrorMessage name='tracking_code' />
												</FormControl>
											</Flex>
										</SimpleGrid>
										{paperRollOrder.status === GetPaperRollOrderDtoStatusEnum.Pending && (
											<Stack flexDirection={'row'} alignItems={'end'} spacing={4}>
												<Button
													onClick={() => setFieldValue('actionType', 'cancelled')}
													mx={2}
													bgColor='red'
													color='white'
													borderColor='black'
													isFullWidth
													isLoading={isLoading}
													type='submit'
												>
													Cancelar Pedido
												</Button>

												<Button
													onClick={() => setFieldValue('actionType', 'sent')}
													mx={2}
													bgColor='primary'
													color='white'
													borderColor='black'
													isFullWidth
													isLoading={isLoading}
													type='submit'
												>
													Enviar Pedido
												</Button>
											</Stack>
										)}
									</form>
								);
							}}
						</Formik>
					</Flex>
				</Box>
			</NewModal>
		</>
	);
};

export default DetailModal;
