import React from 'react';

import { Box, Flex, Spacer, Text, VStack } from '@chakra-ui/react';
import { maskMoney } from 'services/masks.service';

export default ({ data, isBackoffice }) => {
	return (
		<VStack spacing='3' color='white' py='6' alignItems='flex-start'>
			<Flex direction='column'>
				<Text fontSize={['sm', null, null, null, 'md']}>Valor Líquido Transacionado</Text>
				<Text fontSize={['md', null, null, null, 'lg']} fontWeight='bold'>
					R$ {data && data.total_financial_volume ? maskMoney(data.total_financial_volume / 100) : maskMoney(0)}
				</Text>
			</Flex>

			<Flex direction='column'>
				<Text fontSize={['sm', null, null, null, 'md']}>Valor Bruto Transacionado</Text>
				<Text fontSize={['md', null, null, null, 'lg']} fontWeight='bold'>
					R$ {data && data.total_gross_volume ? maskMoney(data.total_gross_volume / 100) : maskMoney(0)}
				</Text>
			</Flex>

			<Flex direction='column'>
				<Text fontSize={['sm', null, null, null, 'md']}>Número de Vendas</Text>
				<Text fontSize={['md', null, null, null, 'lg']} fontWeight='bold'>
					{data && data.total_sales_number ? data.total_sales_number : 0}
				</Text>
			</Flex>

			{isBackoffice && (
				<Flex direction='column'>
					<Text fontSize={['sm', null, null, null, 'md']}>Número de Clientes</Text>
					<Text fontSize={['md', null, null, null, 'lg']} fontWeight='bold'>
						{data && data.total_sellers ? data.total_sellers : 0}
					</Text>
				</Flex>
			)}

			<Flex direction='column'>
				<Text fontSize={['sm', null, null, null, 'md']}>Ticket Médio</Text>
				<Text fontSize={['md', null, null, null, 'lg']} fontWeight='bold'>
					R$ {data && data.average_ticket ? maskMoney(data.average_ticket / 100) : maskMoney(0)}
				</Text>
			</Flex>

			<Flex direction="row" alignItems='flex-end' width={'100%'}>
				<Box>
					<Text fontSize={['sm', null, null, null, 'md']}>Vendas Físicas (Bruto)</Text>
					<Text fontSize={['md', null, null, null, 'lg']} fontWeight='bold'>
						R$ {data && data.physical_gross_volume ? maskMoney(data.physical_gross_volume / 100) : maskMoney(0)}
					</Text>
				</Box>
				<Spacer/>
				<Box>
					<Text fontSize={['sm', null, null, null, 'md']}>Vendas Físicas (Líquido)</Text>
					<Text fontSize={['md', null, null, null, 'lg']} fontWeight='bold'>
						R$ {data && data.physical_financial_volume ? maskMoney(data.physical_financial_volume / 100) : maskMoney(0)}
					</Text>
				</Box>
			</Flex>

			<Flex direction="row" alignItems='flex-end' width={'100%'}>
				<Box>
					<Text fontSize={['sm', null, null, null, 'md']}>Vendas Online (Bruto)</Text>
					<Text fontSize={['md', null, null, null, 'lg']} fontWeight='bold'>
						R$ {data && data.online_gross_volume ? maskMoney(data.online_gross_volume / 100) : maskMoney(0)}
					</Text>
				</Box>
				<Spacer/>
				<Box>
					<Text fontSize={['sm', null, null, null, 'md']}>Vendas Online (Líquido)</Text>
					<Text fontSize={['md', null, null, null, 'lg']} fontWeight='bold'>
						R$ {data && data.online_financial_volume ? maskMoney(data.online_financial_volume / 100) : maskMoney(0)}
					</Text>
				</Box>
			</Flex>
		</VStack>
	);
};
