/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateExportSalesListDto
 */
export interface CreateExportSalesListDto {
    /**
     * Email do pagador
     * @type {string}
     * @memberof CreateExportSalesListDto
     */
    payer_email?: string;
    /**
     * Telefone do pagador
     * @type {string}
     * @memberof CreateExportSalesListDto
     */
    payer_phone?: string;
    /**
     * Formato: yyyy-mm-dd
     * @type {string}
     * @memberof CreateExportSalesListDto
     */
    start_date?: string;
    /**
     * Formato: yyyy-mm-dd
     * @type {string}
     * @memberof CreateExportSalesListDto
     */
    end_date?: string;
    /**
     * Dia da atualização
     * @type {boolean}
     * @memberof CreateExportSalesListDto
     */
    updated_day?: boolean;
    /**
     * Usuário logado
     * @type {string}
     * @memberof CreateExportSalesListDto
     */
    created_by_user_name?: string;
    /**
     * Fonte da requisição
     * @type {string}
     * @memberof CreateExportSalesListDto
     */
    source?: CreateExportSalesListDtoSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateExportSalesListDto
     */
    id?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateExportSalesListDto
     */
    status?: Array<CreateExportSalesListDtoStatusEnum>;
    /**
     * 
     * @type {string}
     * @memberof CreateExportSalesListDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateExportSalesListDto
     */
    search?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateExportSalesListDto
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateExportSalesListDto
     */
    original_amount?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateExportSalesListDto
     */
    type?: Array<CreateExportSalesListDtoTypeEnum>;
    /**
     * 
     * @type {string}
     * @memberof CreateExportSalesListDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateExportSalesListDto
     */
    payer_name?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateExportSalesListDto
     */
    user?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateExportSalesListDto
     */
    form_payment?: Array<CreateExportSalesListDtoFormPaymentEnum>;
    /**
     * 
     * @type {string}
     * @memberof CreateExportSalesListDto
     */
    extension?: CreateExportSalesListDtoExtensionEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateExportSalesListDto
     */
    seller_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateExportSalesListDto
     */
    payment_day?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum CreateExportSalesListDtoSourceEnum {
    SalesHistory = 'sales-history',
    SalesPaymentHistory = 'sales-payment-history',
    SettlementSalesHistory = 'settlement-sales-history'
}/**
* @export
* @enum {string}
*/
export enum CreateExportSalesListDtoStatusEnum {
    Pending = 'pending',
    PendingCapture = 'pending_capture',
    InProgressCapture = 'in_progress_capture',
    Succeeded = 'succeeded',
    Canceled = 'canceled',
    PendingCancel = 'pending_cancel',
    Abort = 'abort',
    Failed = 'failed',
    WaitingConfirmation = 'waiting_confirmation',
    Expired = 'expired',
    WaitingCashIn = 'waiting_cashIn'
}/**
* @export
* @enum {string}
*/
export enum CreateExportSalesListDtoTypeEnum {
    Boleto = 'boleto',
    Pos = 'pos',
    PaymentLink = 'payment_link',
    ProtestLink = 'protest_link',
    Online = 'online',
    AutoGeneratePaymentLink = 'auto_generate_payment_link',
    AutoGenerateProtestLink = 'auto_generate_protest_link',
    BilletCode = 'billet_code',
    BillPayment = 'bill_payment'
}/**
* @export
* @enum {string}
*/
export enum CreateExportSalesListDtoFormPaymentEnum {
    Credit = 'credit',
    Debit = 'debit',
    Pix = 'pix',
    Billet = 'billet',
    BilletCode = 'billet_code',
    PixPos = 'pix_pos'
}/**
* @export
* @enum {string}
*/
export enum CreateExportSalesListDtoExtensionEnum {
    Xls = 'xls',
    Csv = 'csv',
    Json = 'json',
    Pdf = 'pdf'
}

export function CreateExportSalesListDtoFromJSON(json: any): CreateExportSalesListDto {
    return CreateExportSalesListDtoFromJSONTyped(json, false);
}

export function CreateExportSalesListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateExportSalesListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'payer_email': !exists(json, 'payer_email') ? undefined : json['payer_email'],
        'payer_phone': !exists(json, 'payer_phone') ? undefined : json['payer_phone'],
        'start_date': !exists(json, 'start_date') ? undefined : json['start_date'],
        'end_date': !exists(json, 'end_date') ? undefined : json['end_date'],
        'updated_day': !exists(json, 'updated_day') ? undefined : json['updated_day'],
        'created_by_user_name': !exists(json, 'created_by_user_name') ? undefined : json['created_by_user_name'],
        'source': !exists(json, 'source') ? undefined : json['source'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'search': !exists(json, 'search') ? undefined : json['search'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'original_amount': !exists(json, 'original_amount') ? undefined : json['original_amount'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'payer_name': !exists(json, 'payer_name') ? undefined : json['payer_name'],
        'user': !exists(json, 'user') ? undefined : json['user'],
        'form_payment': !exists(json, 'form_payment') ? undefined : json['form_payment'],
        'extension': !exists(json, 'extension') ? undefined : json['extension'],
        'seller_id': !exists(json, 'seller_id') ? undefined : json['seller_id'],
        'payment_day': !exists(json, 'payment_day') ? undefined : json['payment_day'],
    };
}

export function CreateExportSalesListDtoToJSON(value?: CreateExportSalesListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'payer_email': value.payer_email,
        'payer_phone': value.payer_phone,
        'start_date': value.start_date,
        'end_date': value.end_date,
        'updated_day': value.updated_day,
        'created_by_user_name': value.created_by_user_name,
        'source': value.source,
        'id': value.id,
        'status': value.status,
        'description': value.description,
        'search': value.search,
        'amount': value.amount,
        'original_amount': value.original_amount,
        'type': value.type,
        'name': value.name,
        'payer_name': value.payer_name,
        'user': value.user,
        'form_payment': value.form_payment,
        'extension': value.extension,
        'seller_id': value.seller_id,
        'payment_day': value.payment_day,
    };
}


