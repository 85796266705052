import { Text as DefaultText, TextProps } from '@chakra-ui/react';
import React from 'react';

import { useTheme } from 'contexts/ThemeProvider';

const Text: React.FC<TextProps> = (props: TextProps) => {
	const { theme } = useTheme();

	return (
		<DefaultText color={theme?.text_color || `blackAlpha.600`} {...props} >
			{props.children}
		</DefaultText>
	);
};

export default Text;
