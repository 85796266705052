import React from 'react';

import { Flex, Text, HStack } from '@chakra-ui/react';
import { useToasts } from 'react-toast-notifications';

import Loader from 'containers/Loader';
import UserModal from './components/UserModal';
import UserPermissionModal from './components/UserPermissionModal';

import { ListUsersRequest, ListUsersTypeEnum, UsersApi } from '../../clients';

import Button from '../../components/Button';
import SampleTable from './components/SampleTable';

import { getApiAuthConfig } from '../../services/api.service';
import { useAuth } from '../../contexts/AuthProvider';
import { useCurrentSeller } from '../../contexts/SellerProvider';

const BackofficeUsers: React.FC = () => {
	const apiConfig = getApiAuthConfig();
	const usersApi = new UsersApi(apiConfig);

	const { user } = useAuth();

	const [isLoading, setIsLoading] = React.useState(true);
	const [resetTable, setResetTable] = React.useState(false);
	const [openUserModal, setOpenUserModal] = React.useState(false);
	const [userID, setUserID] = React.useState('');
	const [selectedUser, setSelectedUser] = React.useState(undefined);

	const [users, setUsers] = React.useState<Array<string>>([]);
	const [pagination, setPagination] = React.useState({ currentPage: 1, limit: 100 });
	const [totalPages, setTotalPages] = React.useState(1);
	const [openUserPermissionModal, setOpenUserPermissionModal] = React.useState(false);
	const [userRole, setUserRole] = React.useState(undefined);

	const { addToast } = useToasts();
	const { currentSeller } = useCurrentSeller();

	async function fetchListSellerSalesRequest() {
		setIsLoading(true);

		try {
			const requestParams: ListUsersRequest = {
				currentPage: pagination.currentPage,
				limit: pagination.limit,
				type: ListUsersTypeEnum.Backoffice,
			};

			const response = await usersApi.listUsers(requestParams);

			setUsers(response.results);
			setTotalPages(response.total_pages);
		} catch (error) {
			addToast('Erro ao buscar os dados', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	}

	const handleOpenUserModal = (user?) => {
		if (user) {
			setUserID(user.id);
			setSelectedUser(user);
			setOpenUserModal(!openUserModal);
			return;
		}

		setUserID('');
		setSelectedUser(undefined);
		setOpenUserModal(!openUserModal);
	};

	const handleOpenUserPermissionModal = (role) => {
		setUserRole(role);
		setOpenUserPermissionModal(!openUserPermissionModal);
	};

	React.useEffect(() => {
		fetchListSellerSalesRequest();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pagination, resetTable]);

	return (
		<Flex flexDirection='column'>
			<Loader isOpen={isLoading} />

			<HStack justify='space-between'>
				<Text color={`gray.700`} fontSize={`lg`} fontWeight={`bold`}>
					Usuários Backoffice
				</Text>

				<Button
					title={`Você deve selecionar um estabelecimento`}
					bgColor='primary'
					onClick={() => {
						handleOpenUserModal();
					}}
				>
					Inserir Usuários
				</Button>
			</HStack>

			<Flex direction='column' p={[4, 4, 10]} bgColor='#fff' rounded='md' boxShadow='md' mt={5} w='100%'>
				<Flex overflowX='auto' overflowY='hidden' flexDirection='column'>
					{users && (
						<SampleTable
							seller={currentSeller}
							currentUser={user}
							showSellerColumn={false}
							data={users}
							setPagination={setPagination}
							pagination={pagination}
							totalPages={totalPages}
							handleOpenUserModal={handleOpenUserModal}
							handleOpenUserPermissionModal={handleOpenUserPermissionModal}
						/>
					)}
				</Flex>
			</Flex>

			<UserModal
				seller={currentSeller}
				type={ListUsersTypeEnum.Backoffice}
				showSellerInput={false}
				openUserModal={openUserModal}
				handleOpenUserModal={handleOpenUserModal}
				isLoading={isLoading}
				setIsLoading={setIsLoading}
				resetTable={resetTable}
				setResetTable={setResetTable}
				userID={userID}
				selectedUser={selectedUser}
			/>
			<UserPermissionModal
				openUserPermissionModal={openUserPermissionModal}
				handleOpenUserPermissionModal={handleOpenUserPermissionModal}
				userRole={userRole}
			/>
		</Flex>
	);
};

export default BackofficeUsers;
