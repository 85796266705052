import React from 'react';

import { CellContent, HeaderTitle, SimpleTable } from 'containers/SimpleTable';
import { maskMoney } from 'services/masks.service';
import Paper from 'containers/Paper';

const SplitsTable: React.FC<any> = ({ splits }) => {
	const data = splits.map((split) => {
		return { seller: split.seller_name, amount: split.amount, description: split.description };
	});

	const columns = React.useMemo(
		() => [
			{
				Header: ({ column }) => <HeaderTitle column={column}>Estabelecimento</HeaderTitle>,
				accessor: 'seller',
				Cell: ({ cell: { value } }) => <CellContent>{value}</CellContent>,
			},
			{
				Header: ({ column }) => <HeaderTitle column={column}>Valor</HeaderTitle>,
				accessor: 'amount',
				Cell: ({ cell: { value } }) => <CellContent>{`R$ ${maskMoney(value / 100)}`}</CellContent>,
			},
			{
				Header: ({ column }) => <HeaderTitle column={column}>Descrição</HeaderTitle>,
				accessor: 'description',
				Cell: ({ cell: { value } }) => <CellContent>{value || 'N/A'}</CellContent>,
			}
		],
		[]
	);

	return (
		<Paper title='Regras de divisão'>
			<SimpleTable columns={columns} data={data} variant='striped' />
		</Paper>
	);
};

export default SplitsTable;