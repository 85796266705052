import React from 'react';

import {
	Flex,
	Center,
	FormControl,
	IconButton,
	InputGroup,
	InputRightElement,
	Stack,
	BreadcrumbItem,
	BreadcrumbLink,
	useBreakpointValue,
	Tooltip,
	chakra,
} from '@chakra-ui/react';

import { BiExport } from 'react-icons/bi';

import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';

import { SearchIcon, RepeatIcon } from '@chakra-ui/icons';
import { format } from 'date-fns';
import { Formik } from 'formik';

import Loader from '../../containers/Loader';
import Button from '../../components/Button';
import Input from '../../components/Input';
import MultiSelect from '../../components/MultiSelect';
import RangeDatePicker from '../../containers/RangeDatePicker';
import ExportFileSuccessModal from '../../containers/ExportFileSuccessModal';
import SampleTable from './components/SampleTable';
import FormPhoneInput from 'components/Form/FormPhoneInput';

import IPeriod from '../../interfaces/period.interface';

import { getApiAuthConfig } from '../../services/api.service';
import { useAuth } from '../../contexts/AuthProvider';
import { useCurrentSeller } from '../../contexts/SellerProvider';
import { maskMoney } from '../../services/masks.service';

import {
	SellersApi,
	PaginatedDto,
	ListSalesRequest,
	SalesApi,
	CreateExportSalesListDtoExtensionEnum,
	InternalListSellerSalesRequest,
	CelcoinApi,
} from '../../clients';
import { IoInformationCircleOutline } from 'react-icons/io5';
import Breadcrumb from 'components/Breadcrumb';
import Text from 'components/Text';
import { useTheme } from 'contexts/ThemeProvider';

const BillPaymentHistory: React.FC = () => {
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [exportIsLoading, setExportIsLoading] = React.useState<boolean>(false);
	const [openExportSuccessModal, setOpenExportSuccessModal] = React.useState<boolean>(false);
	const isMobile = useBreakpointValue({ base: true, lg: false });
	const { theme } = useTheme();
	const [isLoadingPaymentCashIn, setIsLoadingPaymentCashIn] = React.useState<boolean>(false);

	const [sales, setSales] = React.useState<PaginatedDto>();
	const [pagination, setPagination] = React.useState({ currentPage: 1, limit: 100 });
	const [totalPages, setTotalPages] = React.useState(1);
	const [filters, setFilters] = React.useState({
		status: [{ value: 'succeeded', label: 'Aprovado' }],
		type: [{ value: 'bill_payment', label: 'Pagamento de Contas' }],
	});

	const [period, setPeriod] = React.useState<IPeriod[]>([
		{
			startDate: new Date(new Date().setHours(0, 0, 0, 0)),
			endDate: new Date(new Date().setHours(23, 59, 59, 999)),
			key: 'selection',
		},
	]);

	const { addToast } = useToasts();
	const { isBackoffice, isMerchant } = useAuth();
	const { currentSeller } = useCurrentSeller();

	const initialValues = {
		id: '',
		search: '',
		payer_name: '',
		status: [{ value: 'succeeded', label: 'Aprovado' }],
		formPayment: [],
		type: [{ value: 'bill_payment', label: 'Pagamento de Contas' }],
		created_by_user_name: '',
		startDate: format(period[0].startDate, 'yyyy-MM-dd'),
		endDate: format(period[0].endDate, 'yyyy-MM-dd'),
	};

	const history = useHistory();
	const apiConfig = getApiAuthConfig();
	const sellersApi = new SellersApi(apiConfig);
	const salesApi = new SalesApi(apiConfig);
	const celcoinApi = new CelcoinApi(apiConfig);

	function formatParsedParams(parsedParams) {
		if (parsedParams.search && parsedParams.search.length > 0) {
			const status = [{ value: '', label: '' }];

			parsedParams = {
				startDate: '',
				endDate: '',
				status: status?.map((v) => v.value),
				formPayment: [],
				type: [],
				search: parsedParams.search,
				created_by_user_name: '',
			};
		}

		return parsedParams;
	}

	async function fetchListSellerSalesRequest(params, currentPage?: number) {
		let { status, formPayment, type, payer_name, payer_email, payer_phone, created_by_user_name, ...parsedParams } = params;

		parsedParams = formatParsedParams(parsedParams);
		setIsLoading(true);

		if (currentSeller) {
			try {
				let requestParams: InternalListSellerSalesRequest = {
					currentPage: currentPage || pagination.currentPage,
					limit: pagination.limit,
					startDate: format(period[0].startDate, 'yyyy-MM-dd'),
					endDate: format(period[0].endDate, 'yyyy-MM-dd'),
					sellerId: currentSeller?.id!,
					status: status?.map((v) => v.value),
					formPayment: formPayment?.map((v) => v.value),
					type: type?.map((v) => v.value),
					payerName: payer_name,
					payerEmail: payer_email,
					payerPhone: payer_phone,
					createdByUserName: created_by_user_name,
					...parsedParams,
				};

				const response = await sellersApi.internalListSellerSales(requestParams);

				setSales(response);
				setTotalPages(response.total_pages);
			} catch (error) {
				addToast('Erro ao buscar os dados', {
					appearance: 'error',
					autoDismiss: true,
				});
			} finally {
				setIsLoading(false);
			}
		} else {
			try {
				const requestParams: ListSalesRequest = {
					currentPage: currentPage || pagination.currentPage,
					limit: pagination.limit,
					startDate: format(period[0].startDate, 'yyyy-MM-dd'),
					endDate: format(period[0].endDate, 'yyyy-MM-dd'),
					status: status?.map((v) => v.value),
					formPayment: formPayment?.map((v) => v.value),
					type: type?.map((v) => v.value),
					payerName: payer_name,
					payerEmail: payer_email,
					payerPhone: payer_phone,
					createdByUserName: created_by_user_name,
					...parsedParams,
				};

				const response = await salesApi.listSales(requestParams);

				setSales(response);
				setTotalPages(response.total_pages);
			} catch (error) {
				addToast('Erro ao buscar os dados', {
					appearance: 'error',
					autoDismiss: true,
				});
			} finally {
				setIsLoading(false);
			}
		}
	}

	const handleFormSubmit = async (values) => {
		setFilters(values);

		await fetchListSellerSalesRequest(values, 1);
	};

	const paymentWaitingCashInSales = async () => {
		setIsLoadingPaymentCashIn(true);
		try {
			await celcoinApi.celcoinCashin();

			addToast('Solicitação de Pagamento Enviada!', {
				appearance: 'success',
				autoDismiss: true,
			});
		} catch (e) {
			addToast('Ocorreu um erro no processamento!', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoadingPaymentCashIn(false);
		}
	};

	const handleExportReportFile = async (params) => {
		setExportIsLoading(true);

		try {
			let { status, formPayment, type, payer_name, ...parsedParams } = params;

			if (parsedParams.search && parsedParams.search.length > 0) {
				const status = [{ value: '', label: '' }];

				parsedParams = {
					start_date: '',
					end_date: '',
					status: status?.map((v) => v.value),
					formPayment: [],
					type: [],
					search: parsedParams.search,
				};
			}

			await salesApi.createSalesBillPaymentListExportRequest({
				createExportSalesListDto: {
					start_date: format(period[0].startDate, 'yyyy-MM-dd'),
					end_date: format(period[0].endDate, 'yyyy-MM-dd'),
					status: status?.map((v) => v.value),
					form_payment: formPayment?.map((v) => v.value),
					type: type?.map((v) => v.value),
					extension: CreateExportSalesListDtoExtensionEnum.Xls,
					seller_id: currentSeller?.id,
					payer_name,
					...parsedParams,
				},
			});
			setOpenExportSuccessModal(true);
		} catch (e) {
			addToast('Ocorreu um erro no processamento', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setExportIsLoading(false);
		}
	};

	function navigateToDetails(id: string) {
		history.push(`/admin/sales-history/${id}`, { filters, route: history.location.pathname });
	}

	const persistFilters = (filters) => {
		const { id, search, payer_name, status, formPayment, type, startDate, endDate, created_by_user_name } = filters;

		handleFormSubmit(filters);

		if (startDate && endDate) {
			setPeriod([
				{
					startDate: new Date(`${startDate} 00:00:00`),
					endDate: new Date(`${endDate} 00:00:00`),
					key: 'selection',
				},
			]);
			initialValues.startDate = startDate;
			initialValues.endDate = endDate;
		}

		initialValues.id = id;
		initialValues.search = search;
		initialValues.payer_name = payer_name;
		initialValues.status = status || [];
		initialValues.formPayment = formPayment || [];
		initialValues.type = type || [];
		initialValues.created_by_user_name = created_by_user_name;
	};

	React.useEffect(() => {
		const { location } = history;
		const { state } = location || {};
		const { filters: previousFilters } = state || {};

		if (previousFilters) {
			persistFilters(previousFilters);
		} else {
			fetchListSellerSalesRequest(filters);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pagination, currentSeller]);

	return (
		<Flex flexDirection='column'>
			<Loader isOpen={isLoading} />

			<Breadcrumb>
				<BreadcrumbItem>
					<BreadcrumbLink fontSize='md'>Resumo</BreadcrumbLink>
				</BreadcrumbItem>

				<BreadcrumbItem>
					<BreadcrumbLink fontSize='md'>Gestão Financeira</BreadcrumbLink>
				</BreadcrumbItem>

				<BreadcrumbItem isCurrentPage>
					<BreadcrumbLink opacity='0.9' cursor='unset' fontSize='md' fontWeight='semibold'>
						Histórico de Pagamento de Contas
					</BreadcrumbLink>
				</BreadcrumbItem>
			</Breadcrumb>

			<ExportFileSuccessModal
				showCloseButton
				isOpen={openExportSuccessModal}
				onOkClick={() => {
					setOpenExportSuccessModal(false);
				}}
				onClose={() => {
					setOpenExportSuccessModal(false);
				}}
			/>

			<Formik initialValues={initialValues} onSubmit={handleFormSubmit}>
				{({ handleSubmit, handleChange, setFieldValue, values, resetForm }) => {
					return (
						<form onSubmit={handleSubmit}>
							<Flex direction={isMobile ? 'column' : 'row'} justify='space-between' mb={1}>
								<FormControl width='auto' mb={3}>
									<InputGroup>
										<Input
											borderColor='darkGrey'
											name='search'
											placeholder='Busca...'
											onChange={(event) => {
												handleChange(event);
											}}
											onBlur={() => handleSubmit()}
											py='5'
											onKeyDown={(e) => {
												if (e.key === 'Enter') {
													handleSubmit();
												}
											}}
										/>

										<InputRightElement
											children={
												<IconButton
													_hover={{ backgroundColor: 'transparent' }}
													_focus={{ outline: 'none' }}
													aria-label='Search database'
													icon={<SearchIcon />}
													color='primary'
													backgroundColor='transparent'
													onClick={() => handleSubmit()}
												/>
											}
										/>
									</InputGroup>
								</FormControl>
								<Flex direction={isMobile ? 'column' : 'row'}>
									<Button
										{...(sales?.results.length === 0 && { disabled: true })}
										bgColor='primary'
										isDisabled={isLoadingPaymentCashIn}
										isLoading={isLoadingPaymentCashIn}
										onClick={() => paymentWaitingCashInSales()}
										mb={3}
										mr={3}
										order={[0, 1]}
									>
										Pagar Títulos em Espera
									</Button>

									<Button
										{...(sales?.results.length === 0 && { disabled: true })}
										bgColor='primary'
										isDisabled={exportIsLoading}
										isLoading={exportIsLoading}
										onClick={() => handleExportReportFile(values)}
										leftIcon={<BiExport size={20} />}
										mb={3}
										order={[0, 1]}
									>
										Exportar para Excel
									</Button>
								</Flex>
							</Flex>

							<Flex direction='column' p='5' rounded='md' bgColor='white'>
								<Flex direction={isMobile ? 'column' : 'row'} justifyContent='space-between' mb='5'>
									<Flex>
										<Center position='relative'>
											<RangeDatePicker
												period={period}
												setPeriod={setPeriod}
												setFieldValue={setFieldValue}
												handleSubmit={handleSubmit}
												backgroundColor='primary'
												borderWidth={1}
												borderColor='white'
												py='5'
											/>
										</Center>

										<Center position='relative'>
											<Tooltip label={'Data da Criação'} placement='top' hasArrow>
												<chakra.span>
													<IoInformationCircleOutline size='1.3rem' color='primary' />
												</chakra.span>
											</Tooltip>
										</Center>
									</Flex>

									<Flex width='auto' px='2'>
										<InputGroup>
											<Input
												borderColor='darkGrey'
												name='payer_name'
												placeholder='Pagador...'
												value={values.payer_name}
												onChange={(event) => {
													handleChange(event);
												}}
												onBlur={() => handleSubmit()}
												py='5'
											/>

											<InputRightElement
												children={
													<IconButton
														_hover={{ backgroundColor: 'transparent' }}
														_focus={{ outline: 'none' }}
														aria-label='Search database'
														icon={<SearchIcon />}
														color='primary'
														backgroundColor='transparent'
														onClick={() => handleSubmit()}
													/>
												}
											/>
										</InputGroup>

										<InputGroup>
											<Input
												borderColor='darkGrey'
												name='created_by_user_name'
												placeholder='Usuário'
												onChange={(event) => {
													handleChange(event);
												}}
												onBlur={() => handleSubmit()}
												py='5'
												ml='3'
											/>

											<InputRightElement
												children={
													<IconButton
														_hover={{ backgroundColor: 'transparent' }}
														_focus={{ outline: 'none' }}
														aria-label='Pesquisar por usuário'
														icon={<SearchIcon />}
														color='primary'
														backgroundColor='transparent'
														onClick={() => handleSubmit()}
													/>
												}
											/>
										</InputGroup>
									</Flex>

									{sales && sales.additional_data && (
										<Flex mt={isMobile ? '4' : '0'}>
											<Text fontSize='md' fontWeight='semibold' mr='3'>
												Total Bruto: R$ {maskMoney(sales.additional_data['total_original_amount'] / 100 || 0)}
											</Text>
											<Text fontSize='md' fontWeight='semibold'>
												Total Liquido: R$ {maskMoney(sales.additional_data['total_amount'] / 100 || 0)}
											</Text>
										</Flex>
									)}
								</Flex>

								<Flex direction={isMobile ? 'column' : 'row'} justifyContent='space-between'>
									<Stack direction={isMobile ? 'column' : 'row'} spacing='0' wrap='wrap'>
										<Center minW='13.2rem' mb={4} mr={{ sm: 0, lg: 4 }}>
											<FormControl>
												<MultiSelect
													id='status'
													placeholder='Status'
													options={[
														{ value: 'pending', label: 'Pendente' },
														{ value: 'succeeded', label: 'Aprovado' },
														{ value: 'canceled', label: 'Cancelado' },
														{ value: 'failed', label: 'Falho' },
														{ value: 'waiting_cashIn', label: 'Aguardando Pagamento' },
													]}
													value={values.status}
													onChange={(value) => {
														setFieldValue('status', value);
														handleSubmit();
													}}
												/>
											</FormControl>
										</Center>

										<Center minW='13.2rem' mb={4} mr={{ sm: 0, lg: 4 }}>
											<FormControl>
												<MultiSelect
													id='form-payment'
													placeholder='Forma de Pagamento'
													options={[
														{ value: 'credit', label: 'Crédito' },
														{ value: 'debit', label: 'Débito' },
													]}
													value={values.formPayment}
													onChange={(value) => {
														setFieldValue('formPayment', value);
														handleSubmit();
													}}
												/>
											</FormControl>
										</Center>

										<Center minW='13.2rem' mb={4} mr={{ sm: 0, lg: 4 }}>
											<InputGroup>
												<Input
													borderColor='darkGrey'
													name='payer_email'
													placeholder='Email'
													onChange={(event) => {
														handleChange(event);
													}}
													onBlur={() => handleSubmit()}
													py='5'
												/>

												<InputRightElement
													children={
														<IconButton
															_hover={{ backgroundColor: 'transparent' }}
															_focus={{ outline: 'none' }}
															aria-label='Search database'
															icon={<SearchIcon />}
															color='primary'
															backgroundColor='transparent'
															onClick={() => handleSubmit()}
														/>
													}
												/>
											</InputGroup>
										</Center>

										<Center minW='13.2rem' mb={4}>
											<InputGroup>
												<FormPhoneInput
													borderColor='darkGrey'
													name='payer_phone'
													placeholder='Telefone'
													onChange={(event) => {
														handleChange(event);
													}}
													onBlur={() => handleSubmit()}
													py='5'
												/>

												<InputRightElement
													children={
														<IconButton
															_hover={{ backgroundColor: 'transparent' }}
															_focus={{ outline: 'none' }}
															aria-label='Pesquisar por telefone'
															icon={<SearchIcon />}
															color='primary'
															backgroundColor='transparent'
															onClick={() => handleSubmit()}
														/>
													}
												/>
											</InputGroup>
										</Center>
									</Stack>

									<Center>
										<Tooltip label={'Refazer a busca'} placement='top' hasArrow>
											<IconButton
												_hover={{ backgroundColor: 'transparent' }}
												_focus={{ outline: 'none' }}
												aria-label='Refazer a busca'
												icon={<RepeatIcon />}
												color='primary'
												backgroundColor='transparent'
												size='lg'
												onClick={() => handleSubmit()}
											/>
										</Tooltip>
										{(values.formPayment.length > 0 || values.status.length > 0) && (
											<Button
												onClick={() => {
													resetForm();
													handleSubmit();
												}}
												paddingInline='10px'
												_focus={{ outline: 'none' }}
												color={theme?.primary_color || 'primary'}
												bgColor='transparent'
											>
												Limpar Filtros
											</Button>
										)}
									</Center>
								</Flex>

								<Flex overflowX='auto' overflowY='hidden' flexDirection='column'>
									{sales && (
										<SampleTable
											showSellerColumn={isBackoffice || isMerchant}
											data={sales}
											onRowClick={(row) => navigateToDetails(row.values.id)}
											setPagination={setPagination}
											pagination={pagination}
											totalPages={totalPages}
										/>
									)}
								</Flex>
							</Flex>
						</form>
					);
				}}
			</Formik>
		</Flex>
	);
};

export default BillPaymentHistory;
