import React from 'react';

import { HeaderTitle, CellContent, SimpleTable, HeaderTitleProps, CellContentProps } from '../../../containers/SimpleTable';

import { salePlanType } from 'services/enums.service';
import { Text } from '@chakra-ui/react';
import { BsEyeFill } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import { Button } from '@chakra-ui/button';
import Icon from '@chakra-ui/icon';

const HeaderTitleStyled: React.FC<HeaderTitleProps> = (props) => {
	return (
		<HeaderTitle {...props} color='black' justifyContent='flex-start' fontSize='sm' fontWeight='bold'>
			{props.children}
		</HeaderTitle>
	);
};

const CellContentStyled: React.FC<CellContentProps> = (props) => {
	return (
		<CellContent {...props} color='primary' justifyContent='flex-start' fontSize='sm' fontWeight='semibold'>
			{props.children}
		</CellContent>
	);
};

export default ({ data, setPagination, pagination, totalPages }) => {
	const history = useHistory();

	const columns = React.useMemo(() => {
		let cols: any = [];

		return cols.concat([
			{
				Header: ({ column }) => <HeaderTitleStyled column={column}>Nome</HeaderTitleStyled>,
				accessor: 'name',
				Cell: ({ cell: { value } }) => <CellContentStyled>{value}</CellContentStyled>,
			},
			{
				Header: ({ column }) => <HeaderTitleStyled column={column}>Descrição</HeaderTitleStyled>,
				accessor: 'description',
				Cell: ({ cell: { value } }) => <CellContentStyled>{value}</CellContentStyled>,
			},
			{
				Header: ({ column }) => <HeaderTitleStyled column={column}>Tipo</HeaderTitleStyled>,
				accessor: 'type',
				Cell: ({ cell: { value } }) => (
					<CellContentStyled onClick={() => {}}>
						<Text>{salePlanType[value] ?? 'N/A'}</Text>
					</CellContentStyled>
				),
			},
			{
				Header: ({ column }) => <HeaderTitleStyled column={column}></HeaderTitleStyled>,
				accessor: 'id',
				id: 'view',
				Cell: ({ cell: { value } }) => (
					<Button background='transparent' onClick={() => history.push(`/admin/planos-de-venda/${value}`)}>
						<Icon as={BsEyeFill} boxSize={5} />
					</Button>
				),
			},
		]);
	}, [history]);

	return (
		<SimpleTable
			columns={columns}
			data={data}
			setPagination={setPagination}
			pagination={pagination}
			totalPages={totalPages}
			pl={2}
			variant='unstyled'
			maxHeightTableWrapper='42vh'
		/>
	);
};
