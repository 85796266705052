/* eslint-disable @typescript-eslint/no-explicit-any */
import { Flex, FormControl, Stack, Wrap } from '@chakra-ui/react';
import { addHours } from 'date-fns';

import CreateSearchPayerWrapper from 'containers/CreateSearchPayerWrapper';
import Button from 'components/Button';
import FormDebouncedInput from 'components/Form/FormDebouncedInput';
import FormErrorMessage from 'components/Form/FormErrorMessage';
import FormInput from 'components/Form/FormInput';
import FormLabel from 'components/Form/FormLabel';
import Title from 'components/Title';

import Paper from 'containers/Paper';
import Loader from 'containers/Loader';

import { Formik } from 'formik';

import React, { useState } from 'react';

import { maskDate, maskMoney, maskPorcent } from 'services/masks.service';
import { round } from 'services/math.service';
import * as yup from 'yup';

import {
	CelcoinApi,
	CelcoinPosRequest,
	CreateSellerPayerRequest,
	GetSellerPosDto,
	ListSellerPosRequest,
	SellersApi,
} from '../../../clients';
import Modal from './BillSalePos/Modal';
import Simulate from './BillSalePos/Simulate';
import InformationBox from './BillSalePos/InformationBox';

interface Props {
	amount: string;
	addToast: any;
	simulator: any;
	showAmount: string;
	installmentsSimulation: any;
	radioValue: any;
	setIsLoading: any;
	isLoading: boolean;
	isMobile?: boolean;
	theme?: any;
	setRadioValue: any;
	setPaymentType: any;
	setBarcode: any;
	setBarCodeDisable: any;
	setSimulator: any;
	setAmount: any;
	setShowAmount: any;
	setInstallmentsSimulation: any;
	fetchSales: any;
	selectedFilters: any;
	celcoinResponse: any;
	currentSeller: any;
	apiConfig: any;
	setUpdateTable: any;
	updateTable: any;
	dueDate: string;
	setDueDate: any;
}

const defaultProps = {
	isMobile: false,
	theme: null,
};

const BillSalePos: React.FC<Props> = ({
	amount,
	addToast,
	simulator,
	installmentsSimulation,
	radioValue,
	isLoading,
	isMobile,
	theme,
	setAmount,
	setBarcode,
	setBarCodeDisable,
	setIsLoading,
	setPaymentType,
	setRadioValue,
	setShowAmount,
	setSimulator,
	fetchSales,
	setInstallmentsSimulation,
	selectedFilters,
	celcoinResponse,
	currentSeller,
	apiConfig,
	showAmount,
	setUpdateTable,
	updateTable,
	dueDate,
	setDueDate
}: Props): JSX.Element => {
	const [confirmPayment, setConfirmPayment] = useState(false);
	const [description, setDescription] = useState('');
	const [listPOS, setListPOS] = useState<Array<GetSellerPosDto>>([]);
	const [selectedPayer, setSelectedPayer] = useState<any>();
	const [selectedPOS, setSelectedPOS] = useState('');

	const sellerApi = new SellersApi(apiConfig);
	const celcoinApi = new CelcoinApi(apiConfig);

	const salePosInitialValues = {
		description: '',
		createPayer: false,
		payer: {
			name: '',
			document: '',
			email: '',
			phone: '',
			address: { zipcode: '', street: '', district: '', city: '', state: '', complement: '', number: '' },
		},
		search: {
			name: '',
			document: '',
		},
	};

	const schema = yup.object().shape({
		description: yup.string(),
		createPayer: yup.boolean(),
		payer: yup.object().shape({
			name: yup.string().when('createPayer', {
				is: true,
				then: yup.string().required('Campo obrigatório'),
				})
				.required('Campo obrigatório'),
			document: yup
				.string()
				.when('createPayer', {
					is: true,
					then: yup.string().required('Campo obrigatório'),
				})
				.isCpfOrCnpjOptional('Documento inválido')
				.required('Campo obrigatório'),
			phone: yup
				.string()
				.when('createPayer', {
					is: true,
					then: yup.string().required('Campo obrigatório'),
				})
				.min(14, 'Número obrigatório'),
			email: yup
				.string()
				.when('createPayer', {
					is: true,
					then: yup.string().required('Campo obrigatório'),
				})
				.email('Email inválido'),
		}),
	});

	const handleGetPOS = async () => {
		setIsLoading(true);

		const listSellerPosRequest: ListSellerPosRequest = {
			sellerId: currentSeller?.id!,
		};

		const response = await sellerApi.listSellerPos(listSellerPosRequest);

		setListPOS(response);
		setIsLoading(false);
	};

	const handleSavePayerFormSubmit = async (values) => {
		try {
			setIsLoading(true);

			const { payer } = values;

			const addressIsNull = Object.values(payer.address).every((att) => !att);

			if (addressIsNull) {
				delete payer.address;
			}

			const createPayerDto = payer;
			const createSellerPayerRequest: CreateSellerPayerRequest = {
				sellerId: currentSeller?.id!,
				createPayerDto,
			};

			const response = await sellerApi.createSellerPayer(createSellerPayerRequest);

			setSelectedPayer(response);
		} catch (e) {
			addToast('Dados Inválidos', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
			setConfirmPayment(!confirmPayment);
		}
	};

	const handleSubmit = async (values, isValid) => {
		if (!isValid) return;

		setDescription(values.description);
		await handleGetPOS();
		await handleSavePayerFormSubmit(values);
		setConfirmPayment(!confirmPayment);
	};

	const formatDate = (param: string) => {
		if (!param) {
			return '--/--/----';
		}

		const date = addHours(new Date(param), 3)?.toLocaleString('pt-BR')?.split(' ');

		return maskDate(date[0]);
	};

	const handleSimulateOnChange = (data) => {
		const sim = JSON.parse(data);
		setSimulator(sim);
		setRadioValue(data);
	};

	const handlePayment = async () => {
		try {
			if (!simulator.totalAmount) {
				addToast('Valor inválido', {
					appearance: 'error',
					autoDismiss: true,
				});
				return;
			}
			setIsLoading(true);

			const posControllerPaymentsRequest: CelcoinPosRequest = {
				sellerId: currentSeller?.id!,
				paymentPosRequest: {
					terminal_id: selectedPOS,
					amount: round(simulator.totalAmount),
					is_debit: simulator.type === 'debit',
					instalments: Number(simulator.installment.split('x')[0]),
					service_id: Math.random().toString(36).substr(2, 8),
					is_pix: simulator.type === 'pix_pos',
					description,
					celcoinId: celcoinResponse.celcoinId,
					document: celcoinResponse.documentPayer,
				},
			};

			if (selectedPayer) {
				posControllerPaymentsRequest.paymentPosRequest.document = selectedPayer.document;
			}
			const response = await celcoinApi.celcoinPos(posControllerPaymentsRequest);

			if (response) {
				setAmount('');
				setShowAmount('');
				setDescription('');
				setSimulator({ installment: '', totalAmount: 0, installmentAmount: 0, type: '', cet: 0 });
				setInstallmentsSimulation([]);
				setUpdateTable(!updateTable);
				setBarcode('');
				setBarCodeDisable(true);
				setPaymentType('');
				setRadioValue('');
			}
			addToast('Venda enviada para a máquina com sucesso', {
				appearance: 'success',
				autoDismiss: true,
			});
		} catch (e) {
			addToast('Dados Inválidos', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			await fetchSales(selectedFilters);
			setIsLoading(false);
		}
	};

	return (
		<>
			<Formik enableReinitialize initialValues={salePosInitialValues} validationSchema={schema} onSubmit={handleSubmit}>
				{({ handleSubmit, setFieldValue, resetForm, values, errors, isValid }) => {
					return (
						<form onSubmit={handleSubmit} style={{ width: '100%' }} className='form__sale-data'>
							<Loader isOpen={isLoading} />

							<Flex mb='4'>
								<Title fontSize={isMobile ? 'lg' : '2xl'}>Via Máquina</Title>
							</Flex>

							<Flex flexDirection='column' pb='4'>
								<Flex flexDir='column' p={isMobile ? 4 : 10} bgColor='white' rounded='md' boxShadow='sm' mt='2' w='100%'>
									<Stack spacing={3} color={theme?.text_color || 'darkGrey'}>
										<Stack direction={isMobile ? 'column' : 'row'} spacing={4} mb='4'>
											<FormControl isRequired>
												<FormLabel fontSize='md' fontWeight='medium'>
													Valor dos Serviços
												</FormLabel>
												<FormDebouncedInput isDisabled name='amount' value={showAmount} />
											</FormControl>
											<FormControl m={1} color={theme?.text_color || 'darkGrey'}>
												<FormLabel fontSize='md' fontWeight='medium'>
													Data de vencimento
												</FormLabel>
												<FormInput borderColor='darkGrey' isDisabled name='due_date' value={formatDate(dueDate)} />
												<FormErrorMessage name='description' />
											</FormControl>
											<FormControl m={1} color={theme?.text_color || 'darkGrey'}>
												<FormLabel fontSize='md' fontWeight='medium'>
													Nº do Pedido
												</FormLabel>
												<FormInput borderColor='darkGrey' name='description' />
												<FormErrorMessage name='description' />
											</FormControl>
										</Stack>
									</Stack>
									{installmentsSimulation.length >= 1 && amount !== '' && (
										<>
											<Simulate
												handleSimulateOnChange={handleSimulateOnChange}
												radioValue={radioValue}
												simulation={installmentsSimulation}
											/>
											<Wrap justify='flex-start' my='4'>
												<InformationBox header='Valor Original' body={`R$ ${maskMoney(Number(amount) / 100)}`} />
												<InformationBox header='Parcelas' body={`${simulator.installment} R$ ${maskMoney(simulator.installmentAmount)}`} />
												<InformationBox header='Valor a cobrar' body={`R$ ${maskMoney(simulator.totalAmount)}`} />
												<InformationBox header='Custo efetivo total' body={`${maskPorcent(simulator.cet)}`} />
											</Wrap>
										</>
									)}
								</Flex>
								<Paper>
									<CreateSearchPayerWrapper
										selectedPayer={selectedPayer}
										setSelectedPayer={setSelectedPayer}
										setFieldValue={setFieldValue}
										errors={errors}
										requiredFields={{
											document: true,
											name: true,
											email: true,
											phone: true,
											address: {
												zipcode: false,
												street: false,
												district: false,
												city: false,
												state: false,
												number: false,
											},
										}}
									/>
									<Flex display='flex' flexDirection='column' alignItems={isMobile ? 'center' : 'flex-end'} mt='4'>
										<Button
											size='lg'
											type='submit'
											disabled={!isValid || !simulator.totalAmount}
											bgColor='primary'
											className='button__generate-sale'
										>
											Gerar Venda
										</Button>
									</Flex>
								</Paper>
							</Flex>
							<Modal
								confirmPayment={confirmPayment}
								handlePayment={handlePayment}
								listPOS={listPOS}
								setConfirmPayment={setConfirmPayment}
								setSelectedPOS={setSelectedPOS}
								resetForm={resetForm}
								isLoading={isLoading}
								setSelectedPayer={setSelectedPayer}
							/>
						</form>
					);
				}}
			</Formik>
		</>
	);
};

BillSalePos.defaultProps = defaultProps;

export default BillSalePos;
