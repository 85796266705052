import { Flex, Heading, Box, Spacer, Text } from '@chakra-ui/react';
import React from 'react';

import Modal from 'containers/Modal';

import Button from 'components/Button';

type CreditCardModalProps = {
	confirmPayment: boolean;
	isLoading: boolean;
	handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => void;
};
const CreditCardModal: React.FC<CreditCardModalProps> = ({ confirmPayment, isLoading, handleSubmit }) => {
	return (
		<Modal isOpen={confirmPayment}>
			<Box bg='gray.100' borderWidth='1px' borderRadius='md'>
				<Flex p={8} flexDirection='column'>
					<Heading fontSize='lg' paddingBottom={8}>
						Confirmação de pagamento
					</Heading>

					<Box mt={2} mb={5}>
						<Text align='center'>
							Esta transação estará identificada na sua fatura como <strong>ParcelaExpress.</strong>
						</Text>
					</Box>

					<Flex flexDirection='row' alignItems='center' fontSize='md' px={3} pb={2}>
						<Spacer />

						<Button size='lg' color='white' backgroundColor='primary' isFullWidth onClick={() => handleSubmit()} isLoading={isLoading}>
							Confirmar Pagamento!
						</Button>
					</Flex>
				</Flex>
			</Box>
		</Modal>
	);
};

export default CreditCardModal;
