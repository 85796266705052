import React from 'react';
import { maskDate, maskMoney } from 'services/masks.service';

import { HeaderTitle, CellContent, SimpleTable, HeaderTitleProps, CellContentProps } from '../../../containers/SimpleTable';

import { paymentMethodEnum, settlementStatus } from 'services/enums.service';
import { HStack, Text } from '@chakra-ui/layout';
import { AiFillCheckCircle, AiFillClockCircle, AiFillCloseCircle, AiFillDollarCircle, AiFillMinusCircle } from 'react-icons/ai';
import { useAuth } from '../../../contexts/AuthProvider';
import { SettlementBatchStatusEnum } from 'clients';

const iconStatusLiteral = {
	paid: <AiFillCheckCircle color='#47FF62' size={16} />,
	pending: <AiFillClockCircle color='#FFA547' size={16} />,
	pending_cancel: <AiFillClockCircle color='#FFA547' size={16} />,
	canceled: <AiFillMinusCircle color='tomato' size={16} />,
	sent: <AiFillDollarCircle color='#47FF62' size={16} />,
	failed: <AiFillCloseCircle color='tomato' size={16} />,
};

const HeaderTitleStyled: React.FC<HeaderTitleProps> = (props) => {
	return (
		<HeaderTitle {...props} color='black' justifyContent='flex-start' fontSize='sm' fontWeight='bold'>
			{props.children}
		</HeaderTitle>
	);
};

const CellContentStyled: React.FC<CellContentProps> = (props) => {
	return (
		<CellContent {...props} color='primary' justifyContent='flex-start' fontSize='sm' fontWeight='semibold'>
			{props.children}
		</CellContent>
	);
};

export default ({ data, setPagination, pagination, totalPages, onRowClick, ...rest }) => {
	const { isBackoffice, user } = useAuth();

	let deniedFields: string[] = [];

	if (!isBackoffice) {
		deniedFields = user.merchant?.tables_permissions?.settlement_batch_sale?.denied_fields || [];
	}

	const sales = data?.sales?.map((sale) => {
		const creationDate =
			sale?.created_at && new Date(sale?.created_at)?.toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })?.split(' ');
		const updateDate =
			sale?.updated_at && new Date(sale?.updated_at)?.toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })?.split(' ');
		const paymentDay =
			sale?.payment_day && new Date(sale?.payment_day)?.toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })?.split(' ');
		const confirmationDay =
			sale?.confirmation_day && new Date(sale?.confirmation_day)?.toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })?.split(' ');

		return {
			sale_plan: sale?.payment_type?.fee,
			id: sale?.id,
			settlement_status: (sale?.settlement_batch_cancellation && sale?.settlement_batch_cancellation.id === data?.settlement_batch.id) ? SettlementBatchStatusEnum.Canceled : sale?.settlement_status,
			payment_method: paymentMethodEnum[sale?.type],
			formatted_description: sale?.formatted_description,
			formatted_pre_capture: sale?.formatted_pre_capture,
			generate_payer: sale?.type === 'online' ? 'Sim' : 'Não',
			payer: sale?.payer?.name,
			financial: maskMoney((sale?.original_amount - sale?.amount) / 100),
			created_at:
				creationDate &&
				`${maskDate(creationDate[0].split('-').reverse().join('-'))} às ${creationDate[1] ? creationDate[1]?.substr(0, 5) : '-:-'}`,
			updated_at:
				updateDate && `${maskDate(updateDate[0].split('-').reverse().join('-'))} às ${updateDate[1] ? updateDate[1]?.substr(0, 5) : '-:-'}`,
			payment_day:
				paymentDay && `${maskDate(paymentDay[0].split('-').reverse().join('-'))} às ${paymentDay[1] ? paymentDay[1]?.substr(0, 5) : '-:-'}`,
			expiration_day: sale?.expiration_day,
			settlement_day: sale?.settlement_day.split('-').reverse().join('/'),
			form_payment: sale?.payment_type_description || 'Não reconhecido',
			original_amount: maskMoney(sale?.original_amount / 100),
			amount: maskMoney(sale?.amount / 100),
			settlement_batch: sale?.settlement_batch,
			confirmation_day:
				confirmationDay &&
				`${maskDate(confirmationDay[0].split('-').reverse().join('-'))} às ${
					confirmationDay[1] ? confirmationDay[1]?.substr(0, 5) : '-:-'
				}`,
			formatted_split_description: sale?.formatted_split_description,
			acquirer_reference: sale?.acquirer_reference
		};
	});

	const columns = React.useMemo(
		() => {
			let cols: any = [];

			if(isBackoffice){
				cols.push({
					Header: ({ column }) => <HeaderTitleStyled column={column}>PSP</HeaderTitleStyled>,
					accessor: 'acquirer_reference',
					Cell: ({ cell: { value } }) => <CellContentStyled>{value}</CellContentStyled>,
				})
			}

			return cols.concat([
				{
					Header: ({ column }) => <HeaderTitleStyled column={column}>Data de Liquidação</HeaderTitleStyled>,
					accessor: 'settlement_day',
					Cell: ({ cell: { value } }) => <CellContentStyled>{value}</CellContentStyled>,
				},
				{
					Header: ({ column }) => (
						<HeaderTitleStyled justifyContent='flex-start' column={column}>
							Status do lote
						</HeaderTitleStyled>
					),
					accessor: 'settlement_status',
					Cell: ({ cell: { value } }) => (
						<CellContentStyled justifyContent={`flex-start`}>
							<HStack>
								<Text>{iconStatusLiteral[value]}</Text>
								<Text>{settlementStatus[value] ?? 'N/A'}</Text>
							</HStack>
						</CellContentStyled>
					),
				},
				{
					Header: ({ column }) => <HeaderTitleStyled column={column}>Nº do Pedido</HeaderTitleStyled>,
					accessor: 'formatted_description',
					Cell: ({ cell: { value } }) => <CellContentStyled>{value}</CellContentStyled>,
				},
				{
					Header: ({ column }) => <HeaderTitle column={column}>Tem Pré-Captura</HeaderTitle>,
					accessor: 'formatted_pre_capture',
					Cell: ({ cell: { value } }) => <CellContentStyled color={`darkGrey`} className='cell-content__pre-capture'>{value}</CellContentStyled>,
				},
				{
					Header: ({ column }) => <HeaderTitleStyled column={column}>Descrição da Divisão</HeaderTitleStyled>,
					accessor: 'formatted_split_description',
					Cell: ({ cell: { value } }) => <CellContentStyled>{value}</CellContentStyled>,
				},
				{
					Header: ({ column }) => <HeaderTitleStyled column={column}>Forma de Pagamento</HeaderTitleStyled>,
					accessor: 'form_payment',
					Cell: ({ cell: { value } }) => <CellContentStyled>{value}</CellContentStyled>,
				},
				{
					Header: ({ column }) => <HeaderTitleStyled column={column}>Meio de Pagamento</HeaderTitleStyled>,
					accessor: 'payment_method',
					Cell: ({ cell: { value } }) => <CellContentStyled>{value}</CellContentStyled>,
				},
				{
					Header: ({ column }) => <HeaderTitleStyled column={column}>Valor Bruto</HeaderTitleStyled>,
					accessor: 'original_amount',
					Cell: ({ cell: { value } }) => <CellContentStyled minW={100}>R$ {value}</CellContentStyled>,
				},
				{
					Header: ({ column }) => <HeaderTitleStyled column={column}>Custo Financeiro</HeaderTitleStyled>,
					accessor: 'financial',
					Cell: ({ cell: { value } }) => <CellContentStyled>R$ {value}</CellContentStyled>,
				},
				{
					Header: ({ column }) => (
						<HeaderTitleStyled justifyContent='flex-end' column={column}>
							Valor Líquido
						</HeaderTitleStyled>
					),
					accessor: 'amount',
					Cell: ({ cell: { value } }) => <CellContentStyled justifyContent={`flex-end`}>R$ {value}</CellContentStyled>,
				},
				{
					Header: ({ column }) => <HeaderTitleStyled column={column}>Pagador</HeaderTitleStyled>,
					accessor: 'payer',
					Cell: ({ cell: { value } }) => <CellContentStyled>{value}</CellContentStyled>,
				},
				{
					Header: ({ column }) => <HeaderTitleStyled column={column}>Data de Criação</HeaderTitleStyled>,
					accessor: 'created_at',
					Cell: ({ cell: { value } }) => <CellContentStyled>{value}</CellContentStyled>,
				},
				{
					Header: ({ column }) => <HeaderTitleStyled column={column}>Data de Pagamento</HeaderTitleStyled>,
					accessor: 'payment_day',
					Cell: ({ cell: { value } }) => <CellContentStyled>{value}</CellContentStyled>,
				},
				{
					Header: ({ column }) => <HeaderTitleStyled column={column}>Data da Confirmação do Serviço</HeaderTitleStyled>,
					accessor: 'confirmation_day',
					Cell: ({ cell: { value } }) => <CellContentStyled>{value}</CellContentStyled>,
				},
				{
					Header: ({ column }) => <HeaderTitleStyled column={column}>Gerado Pelo Pagador</HeaderTitleStyled>,
					accessor: 'generate_payer',
					Cell: ({ cell: { value } }) => <CellContentStyled>{value}</CellContentStyled>,
				},
				{
					Header: ({ column }) => <HeaderTitleStyled column={column}>ID do Lote</HeaderTitleStyled>,
					accessor: 'id',
					Cell: ({ cell: { value } }) => <CellContentStyled>{value}</CellContentStyled>,
				},
			])
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const allowedColumns = columns.filter((col) => !deniedFields?.includes(col?.accessor));

	return (
		<SimpleTable
			columns={allowedColumns}
			onRowClick={onRowClick}
			data={sales}
			setPagination={setPagination}
			pagination={pagination}
			totalPages={totalPages}
			variant='unstyled'
			pl={2}
			maxHeightTableWrapper={420}
			{...rest}
		/>
	);
};
