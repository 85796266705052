import React from 'react';

import { FormControl, FormLabel, Flex, Box, Icon } from '@chakra-ui/react';

import FormErrorMessage from '../../../components/Form/FormErrorMessage';

import { SellerSelectHandle } from './SellerSelect/SellerSelect';
import { GetSellerDto } from 'clients';

import Button from 'components/Button';
import FormCurrencyInput from 'components/Form/FormCurrencyInput';

import { Formik } from 'formik';

import yup from 'services/yup.service';

import { MdClose } from 'react-icons/md';
import { EditingSplitType, SplitSellerValue } from '..';
import { round } from 'services/math.service';
import { useTheme } from 'contexts/ThemeProvider';

import Text from 'components/Text';
import SellerSelect from 'components/SellerSelect';

type SplitFormType = {
	setSplits: React.Dispatch<React.SetStateAction<SplitSellerValue[]>>;
	splits: SplitSellerValue[];
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
	editingSplit?: EditingSplitType;
	setEditingSplit: React.Dispatch<React.SetStateAction<EditingSplitType | undefined>>;
}

const SplitForm: React.FC<SplitFormType> = ({ splits, setSplits, setIsOpen, editingSplit, setEditingSplit }) => {
	const ref = React.useRef<SellerSelectHandle>(null);
	
	const { theme } = useTheme();

	const splitRulesInitialValues = {
		amount: editingSplit?.amount ? (editingSplit?.amount / 100).toFixed(2).toString() : '',
		seller: editingSplit?.seller ? editingSplit?.seller : ({} as GetSellerDto),
	};

	const schema = yup.object().shape({
		amount: yup.number().required('Campo obrigatório'),
		seller: yup.object().required('Escolha um estabelecimento'),
	});

	const roundAmount = (amount: number): number => {
		return round(Number(amount) * 100);
	};

	const handleSubmit = (values, isValid) => {
		if (!isValid || Object.keys(values.seller).length === 0) return;

		if (editingSplit) {
			const parsedSplits = [...splits];
			parsedSplits[editingSplit.index] = { amount: roundAmount(values.amount), seller: values.seller };
			setSplits(parsedSplits);
			setEditingSplit(undefined);
		} else {
			setSplits([...splits, { amount: roundAmount(values.amount), seller: values.seller }]);
		}

		setIsOpen(false);
	};

	return (
		<Formik validateOnMount enableReinitialize initialValues={splitRulesInitialValues} validationSchema={schema} onSubmit={handleSubmit}>
			{({ handleSubmit, setFieldValue, resetForm, values, errors, isValid }) => {
				return (
					<form onSubmit={handleSubmit} style={{ width: '100%' }}>
						<Flex py={4} px={4} flexDirection='column'>
							<FormControl id='seller' d='flex' flexDirection='column' alignItems='center'>
								<FormLabel color={theme?.text_color || `darkGrey`} fontSize='md' fontWeight='medium'>
									Estabelecimento
								</FormLabel>
								{values.seller?.name ? (
									<Flex w='100%' justifyContent='space-around'>
										<Text>{values.seller?.name}</Text>
										<Box
											_focus={{ outline: 'none', background: 'transparent' }}
											_hover={{ background: 'transparent', opacity: 0.8, cursor: 'pointer' }}
											background={'transparent'}
											onClick={() => setFieldValue('seller', {})}
										>
											<Icon as={MdClose} boxSize={5} />
										</Box>
									</Flex>)
									: <SellerSelect ref={ref} onChange={(e) => setFieldValue('seller', e)} />}
								<FormErrorMessage name='seller' />
							</FormControl>
							
							<FormControl id='amount' my='4' d='flex' flexDirection='column' alignItems='center'>
								<FormLabel color={theme?.text_color || `darkGrey`} fontSize='md' fontWeight='medium'>
									Valor
								</FormLabel>
								<FormCurrencyInput
									maxW='300px'
									name='amount'
									borderColor='darkGrey'
									color={theme?.text_color || `darkGrey`}
									fontSize={'md'}
									fontWeight='medium'
								/>
								<FormErrorMessage name='amount' />
							</FormControl>
						</Flex>
						<FormControl d='flex' justifyContent='center' alignItems='end'>
							<Button
								_active={{ bgColor: 'primary' }}
								_focus={{ outline: 'none' }}
								_hover={{ bgColor: 'primary', opacity: 0.8 }}
								bgColor='primary'
								type='button'
								disabled={!isValid || Object.keys(values.seller).length === 0}
								onClick={() => handleSubmit()}
							>
								Salvar
							</Button>
						</FormControl>
					</form>
				)
			}}
		</Formik>
	);
};

export default SplitForm;
