import * as React from 'react';

import { InputProps } from '@chakra-ui/react';
import { Field } from 'formik';
import { any as anyType } from 'prop-types';

import IntlCurrencyInput from "react-intl-currency-input"

import Input from '../Input';
import { useDebouncedCallback } from 'use-debounce';

const currencyConfig = {
	locale: "pt-BR",
	formats: {
		number: {
			BRL: {
				style: "currency",
				currency: "BRL",
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			},
		},
	},
};

type FormDebouncedCurrencyInputProps = InputProps & {
	onAmountChange?: (e: any, value: any, maskedValue: any) => void;
	as?: React.FC;
	debounce?: number;
	afterDebounce?: (value: string) => void;
	maxWidth?: string;
};

const FormDebouncedCurrencyInput: React.FC<FormDebouncedCurrencyInputProps> = (props: FormDebouncedCurrencyInputProps) => {
	const { as, afterDebounce, debounce, onAmountChange, ...parsedProps } = props;
	let Component = Input;

	if (props.as) {
		Component = props.as;
	}

	const debounced = useDebouncedCallback(async (value) => {
		props.afterDebounce && props.afterDebounce(value);
	}, props.debounce || 1000);

	React.useEffect(
		() => () => {
			debounced.flush();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[debounced]
	);

	return (
		<Field {...parsedProps}>
			{({ form, field }) => {
				return (
					<IntlCurrencyInput {...parsedProps}
						onBlur={form.handleBlur}
						onChange={(e, value, maskedValue) => {
							props.onAmountChange && props.onAmountChange(e, value, maskedValue)

							form.setFieldValue(field.name, value);
							debounced.callback(e.target.value);
						}}
						borderColor='darkGrey'
						value={field.value}
						component={Component}
						currency="BRL"
						config={currencyConfig} />
				)
			}}
		</Field>
	)
};

IntlCurrencyInput.propTypes = {
	...IntlCurrencyInput.propTypes,
	component: anyType
};

export default FormDebouncedCurrencyInput;
