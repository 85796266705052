import React, { useEffect } from 'react';

import {
	Flex,
	FormControl,
	FormLabel,
	Text,
	Stack,
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	useBreakpointValue,
	Tag,
} from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Formik } from 'formik';
import { useToasts } from 'react-toast-notifications';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import BackofficeAlertContainer from 'containers/BackofficeAlertContainer';

import FormSelect from 'components/Form/FormSelect';
import FormErrorMessage from 'components/Form/FormErrorMessage';
import Button from 'components/Button';

import { EditSellerPartialRequest, SellerEmailKeyEnum, SellersApi } from '../../clients';
import { useCurrentSeller } from '../../contexts/SellerProvider';
import { getApiAuthConfig } from '../../services/api.service';
import Checkbox from 'components/Checkbox';
import Input from '../../components/Input';

const EmailsCustom: React.FC = () => {
	const { currentSeller, updateCurrentSeller } = useCurrentSeller();
	const isMobile = useBreakpointValue({ base: true, lg: false });
	const apiConfig = getApiAuthConfig();
	const { addToast } = useToasts();

	const sellersApi = new SellersApi(apiConfig);

	const customEmailsInitialValues = {};
	const customEmails = (currentSeller?.custom_emails as Object) || {};

	const [selectedEmail, setSelectedEmail] = React.useState<string>(SellerEmailKeyEnum.BilletEmail);
	const [appliedEmails, setAppliedEmails] = React.useState<string[]>(['-']);
	const [customBlock, setCustomBlock] = React.useState<string>('');
	const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
	const [checkboxChecked, setCheckboxChecked] = React.useState<boolean>(false);
	const [saledescriptionNumbercheckboxChecked, setSaleDescriptionNumberCheckboxChecked] = React.useState<boolean>(false);
	const [payerNameCheckboxChecked, setPayerNameCheckboxChecked] = React.useState<boolean>(false);
	const [saleAmountCheckboxChecked, setSaleAmountCheckboxChecked] = React.useState<boolean>(false);
	const [saleExpiresInCheckboxChecked, setSaleExpiresInCheckboxChecked] = React.useState<boolean>(false);
	const [customTitle, setCustomTitle] = React.useState<string>('');

	const getAppliedEmails = (template: string) => {
		const appliedEmails = {
			billet_email: ['Boleto criado'],
			cancelation_email: ['Estorno de venda'],
			payment_link: ['Link de pagamento criado'],
			receipt_email: ['Recibo de link de pagamentos', 'Recibo de vendas'],
			protocols_generation: ['Procotolo criado'],
		};

		return appliedEmails[template] || ['-'];
	};

	useEffect(() => {
		const customBlock = customEmails[selectedEmail]?.custom_block || '';
		const customTitle = customEmails[selectedEmail]?.custom_title || '';
		const appliedEmails = getAppliedEmails(selectedEmail) || [];

		setCustomBlock(customBlock);
		setCustomTitle(customTitle);
		setAppliedEmails(appliedEmails);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedEmail]);

	useEffect(() => {
		setSelectedEmail(SellerEmailKeyEnum.BilletEmail);
	}, [currentSeller]);

	useEffect(() => {
		setCheckboxChecked(customBlock.includes('{{NUMERO_DO_PEDIDO}}'));
		setPayerNameCheckboxChecked(customBlock.includes('{{PAYER_NAME}}'));
		setSaleAmountCheckboxChecked(customBlock.includes('{{SALE_AMOUNT}}'));
		setSaleExpiresInCheckboxChecked(customBlock.includes('{{EXPIRES_IN}}'));
		setSaleDescriptionNumberCheckboxChecked(customBlock.includes('{{NUMERO_DO_PEDIDO_CUSTOM}}'));
	}, [customBlock]);

	const submitForm = async () => {
		if (currentSeller) {
			const requestParams = {
				editSellerPartialDto: {
					custom_emails: {
						...currentSeller.custom_emails,
						[selectedEmail]: {
							key: selectedEmail,
							custom_block: customBlock,
							custom_title: customTitle.trim(),
						},
					},
				},
				sellerId: currentSeller.id,
			};

			try {
				setIsSubmitting(true);

				const seller = await sellersApi.editSellerPartial((requestParams as unknown) as EditSellerPartialRequest);

				updateCurrentSeller(seller);

				addToast('Personalização salva com sucesso', {
					appearance: 'success',
					autoDismiss: true,
				});

				setIsSubmitting(false);
			} catch (e) {
				addToast('Não foi possível salvar a personalização.', {
					appearance: 'error',
					autoDismiss: true,
				});

				setIsSubmitting(false);
			}
		}
	};

	const handleCheckboxChange = (e) => {
		const isChecked = e.target.checked;
		const name = e.target.name;

		const fieldMappings = {
			is_active_protocol: '{{NUMERO_DO_PEDIDO}}',
			is_active_name: '{{PAYER_NAME}}',
			is_active_amount: '{{SALE_AMOUNT}}',
			is_active_expires_in: '{{EXPIRES_IN}}',
			is_active_number_protocol: '{{NUMERO_DO_PEDIDO_CUSTOM}}',
		};

		if (fieldMappings[name]) {
			const fieldValue = fieldMappings[name];

			if (isChecked) {
				setCustomBlock((prevCustomBlock) => prevCustomBlock + `<strong>${fieldValue}<strong>`);
			} else {
				setCustomBlock((prevCustomBlock) => prevCustomBlock.replace(new RegExp(`${fieldValue}`, 'g'), ''));
			}
		}
	};

	const handleInputChange = (e) => {
		setCustomTitle(e.target.value);
	};

	if (!currentSeller) {
		return <BackofficeAlertContainer />;
	}

	return (
		<Flex flexDirection={`column`} w='100%'>
			<Flex pb='8'>
				<Breadcrumb separator={<ChevronRightIcon />} color={`darkGrey`}>
					<BreadcrumbItem>
						<BreadcrumbLink href='#' fontSize='md'>
							Resumo
						</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem>
						<BreadcrumbLink href='#' fontSize='md'>
							Minha Conta
						</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink href='#' fontSize='md' fontWeight={`semibold`}>
							Personalização de Emails
						</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>
			</Flex>

			<Formik
				enableReinitialize
				initialValues={customEmailsInitialValues}
				onSubmit={async (_, { resetForm }) => {
					await submitForm();

					resetForm();
				}}
			>
				{({ handleSubmit, isValid }) => {
					return (
						<form onSubmit={handleSubmit} style={{ width: '100%' }}>
							<Flex mb='4'>
								<Text color={`darkGrey`} fontSize={isMobile ? 'lg' : '2xl'} fontWeight={`bold`}>
									PERSONALIZAÇÃO DE EMAILS
								</Text>
							</Flex>

							<Flex flexDirection={`column`}>
								<Flex flexDir={`column`} p={10} bgColor='white' rounded={`md`} boxShadow={`sm`} mt='2' w='100%'>
									<Stack spacing={3} color={`primary`}>
										<Stack direction='column' spacing={4}>
											<Stack direction={isMobile ? 'column' : 'row'} spacing={4}>
												<FormControl>
													<FormLabel color={`darkGrey`} fontSize={`md`} fontWeight={`medium`}>
														Email
													</FormLabel>
													<FormSelect fontSize={`md`} name='templateKeys' onChange={(event) => setSelectedEmail(event.target.value)}>
														<option value={SellerEmailKeyEnum.BilletEmail} key={SellerEmailKeyEnum.BilletEmail}>
															Boleto
														</option>
														<option value={SellerEmailKeyEnum.CancelationEmail} key={SellerEmailKeyEnum.CancelationEmail}>
															Estorno de Venda
														</option>
														<option value={SellerEmailKeyEnum.PaymentLink} key={SellerEmailKeyEnum.PaymentLink}>
															Link de Pagamento
														</option>
														<option value={SellerEmailKeyEnum.ProtocolsGeneration} key={SellerEmailKeyEnum.ProtocolsGeneration}>
															Protocolo
														</option>
														<option value={SellerEmailKeyEnum.ReceiptEmail} key={SellerEmailKeyEnum.ReceiptEmail}>
															Recibo de Pagamento
														</option>
													</FormSelect>
												</FormControl>
												<FormControl>
													<FormLabel color={`darkGrey`} fontSize={`md`} fontWeight={`medium`}>
														Aplicado em
													</FormLabel>
													{appliedEmails.map((aplliedEmail: string) => (
														<Tag size='md' mr='2' mb='2'>
															{aplliedEmail}
														</Tag>
													))}
												</FormControl>
											</Stack>
											<Stack>
												<FormControl>
													<FormLabel color={`darkGrey`} fontSize={`md`} fontWeight={`medium`}>
														Titulo do e-mail
													</FormLabel>
													<Input value={customTitle} onChange={handleInputChange} marginBottom={2} />
													<FormLabel color={`darkGrey`} fontSize={`md`} fontWeight={`medium`}>
														Informação adicional
													</FormLabel>
													<ReactQuill
														theme='snow'
														value={customBlock}
														onChange={(value) => {
															setCustomBlock(value);
														}}
													/>
													<FormErrorMessage name='customBlock' />
												</FormControl>
											</Stack>
										</Stack>
									</Stack>
									<FormControl
										display='flex'
										justifyContent={isMobile ? 'center' : 'space-between'}
										flexDirection={isMobile ? 'column' : 'row'}
										alignItems={isMobile ? 'flex-start' : 'center'}
										mt='4'
									>
										<Flex flexDirection={`column`}>
											<Checkbox
												name='is_active_protocol'
												isChecked={checkboxChecked}
												color={`darkGrey`}
												fontSize={`md`}
												fontWeight={`medium`}
												onChange={handleCheckboxChange}
												paddingBottom={isMobile ? '4' : '1'}
												width={isMobile ? '100%' : ''}
												maxWidth={isMobile ? '300px' : ''}
												colorScheme='primary_checkbox'
											>
												Adicionar número do pedido (descrição completa)
											</Checkbox>
											<Checkbox
												name='is_active_number_protocol'
												isChecked={saledescriptionNumbercheckboxChecked}
												color={`darkGrey`}
												fontSize={`md`}
												fontWeight={`medium`}
												onChange={handleCheckboxChange}
												paddingBottom={isMobile ? '4' : '1'}
												width={isMobile ? '100%' : ''}
												maxWidth={isMobile ? '300px' : ''}
												colorScheme='primary_checkbox'
											>
												Adicionar número do pedido (somente números)
											</Checkbox>
											<Checkbox
												name='is_active_name'
												isChecked={payerNameCheckboxChecked}
												color={`darkGrey`}
												fontSize={`md`}
												fontWeight={`medium`}
												onChange={handleCheckboxChange}
												paddingBottom={isMobile ? '4' : '1'}
												width={isMobile ? '100%' : ''}
												maxWidth={isMobile ? '300px' : ''}
												colorScheme='primary_checkbox'
											>
												Adicionar nome do pagador
											</Checkbox>
											<Checkbox
												name='is_active_amount'
												isChecked={saleAmountCheckboxChecked}
												color={`darkGrey`}
												fontSize={`md`}
												fontWeight={`medium`}
												onChange={handleCheckboxChange}
												paddingBottom={isMobile ? '4' : '1'}
												width={isMobile ? '100%' : ''}
												maxWidth={isMobile ? '300px' : ''}
												colorScheme='primary_checkbox'
											>
												Adicionar valor da venda
											</Checkbox>

											{selectedEmail === SellerEmailKeyEnum.PaymentLink && (
												<Checkbox
													name='is_active_expires_in'
													isChecked={saleExpiresInCheckboxChecked}
													color={`darkGrey`}
													fontSize={`md`}
													fontWeight={`medium`}
													onChange={handleCheckboxChange}
													paddingBottom={isMobile ? '4' : '1'}
													width={isMobile ? '100%' : ''}
													maxWidth={isMobile ? '300px' : ''}
													colorScheme='primary_checkbox'
												>
													Adicionar data de vencimento
												</Checkbox>
											)}

										</Flex>
										<Flex justifyContent={isMobile ? 'center' : 'flex-end'} w={'60%'}>
											<Button
												size='lg'
												type='submit'
												disabled={!isValid || isSubmitting}
												bgColor={'primary'}
												isLoading={isSubmitting}
												width={isMobile ? '100%' : ''}
												maxWidth={isMobile ? '300px' : ''}
											>
												Salvar Personalização
											</Button>
										</Flex>
									</FormControl>
								</Flex>
							</Flex>
						</form>
					);
				}}
			</Formik>
		</Flex>
	);
};

export default EmailsCustom;
