import { BreadcrumbItem, BreadcrumbLink, Center, chakra, Flex, Heading, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { IoInformationCircleOutline } from 'react-icons/io5';
import { useToasts } from 'react-toast-notifications';

import { FailedTransfersDto, PaginatedListUploadsRequest, ShippingReturnApi } from 'clients';
import { format } from 'date-fns';
import IPeriod from 'interfaces/period.interface';
import { getApiAuthConfig } from 'services/api.service';

import FileUploadInput from 'containers/FileUploadInput';
import Loader from 'containers/Loader';
import Paper from 'containers/Paper';
import RangeDatePicker from 'containers/RangeDatePicker';

import Breadcrumb from 'components/Breadcrumb';
import Title from 'components/Title';

import Modal from '../../containers/NewModal';
import FailedTransfersList from './components/FailedTransfersList';
import UploadsTable from './components/UploadsTable';

const UploadRetFile: React.FC = () => {
	const { addToast } = useToasts();

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [pagination, setPagination] = React.useState({ currentPage: 1, limit: 10 });
	const [totalPages, setTotalPages] = React.useState(1);
	const [uploadedFiles, setUploadedFiles] = React.useState<Array<any>>([]);
	const [failedTransfers, setFailedTransfers] = React.useState<Array<FailedTransfersDto>>([]);
	const [failedTransfersModal, setFailedTransfersModal] = React.useState<boolean>(false);

	const [period, setPeriod] = React.useState<IPeriod[]>([
		{
			startDate: new Date(new Date().setHours(0, 0, 0, 0)),
			endDate: new Date(new Date().setHours(23, 59, 59, 999)),
			key: 'selection',
		},
	]);

	const fetchUploads = async () => {
		try {
			setIsLoading(!isLoading);

			const parameters: PaginatedListUploadsRequest = {
				currentPage: pagination.currentPage,
				limit: pagination.limit,
				startDate: format(period[0].startDate, 'yyyy-MM-dd'),
				endDate: format(period[0].endDate, 'yyyy-MM-dd'),
			};

			const apiConfig = getApiAuthConfig();
			const shippingReturnApi = new ShippingReturnApi(apiConfig);
			const apiResponse = await shippingReturnApi.paginatedListUploads(parameters);

			if (apiResponse) {
				setTotalPages(apiResponse.total_pages);
				setUploadedFiles(apiResponse.results);
			}
		} catch (error) {
			addToast('Erro ao carregar lista de arquivos', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	};

	React.useEffect(() => {
		fetchUploads();
		// eslint-disable-next-line
	}, [pagination]);

	const upload = async (file) => {
		try {
			setIsLoading(!isLoading);
			const apiConfig = getApiAuthConfig();
			const shippingReturnApi = new ShippingReturnApi(apiConfig);
			const response = await shippingReturnApi.uploadReturnFile({ file });
			if (response.failed_transfers && response.failed_transfers.length) {
				setFailedTransfers(response.failed_transfers);
				setFailedTransfersModal(true);
			}
			fetchUploads();
		} catch (error) {
			addToast('Arquivo Inválido', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Flex flexDirection='column' w='100%'>
			<Loader isOpen={isLoading} />
			<Flex pb='8'>
				<Breadcrumb>
					<BreadcrumbItem>
						<BreadcrumbLink href='#' fontSize='md'>
							Gestão Financeira
						</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink opacity='0.9' cursor='unset' href='#' fontSize='md' fontWeight={`semibold`}>
							Enviar Arquivo Retorno
						</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>
			</Flex>

			<Title>Enviar Arquivos de Retorno</Title>
			<Paper p={6}>
				<Flex justifyContent='center'>
					<FileUploadInput
						inputProps={{
							onChange: (e) => {
								if (e.target.files?.length) {
									upload(e.target.files[0]);
								}
							},
						}}
					/>
				</Flex>
				<Flex>
					<Center position='relative'>
						<RangeDatePicker
							period={period}
							setPeriod={setPeriod}
							handleSubmit={fetchUploads}
							backgroundColor='primary'
							borderWidth={1}
							borderColor='white'
							py='5'
						/>
					</Center>

					<Center position='relative' mr='4'>
						<Tooltip label='Data da Envio' placement='top' hasArrow>
							<chakra.span>
								<IoInformationCircleOutline size='1.3rem' color='primary' />
							</chakra.span>
						</Tooltip>
					</Center>
				</Flex>
				{uploadedFiles && uploadedFiles.length > 0 && (
					<UploadsTable data={uploadedFiles} pagination={pagination} setPagination={setPagination} totalPages={totalPages} />
				)}
				{failedTransfers && failedTransfers.length > 0 && (
					<Modal isOpen={failedTransfersModal} closeOnEsc showCloseButton onClose={() => setFailedTransfersModal(false)}>
						<Heading color='gray.700' as='h6' size='sm' py='1%'>
							Transferências com Falha
						</Heading>
						<Flex flexDirection='column'>
							{failedTransfers.map((transfer) => {
								return (
									<FailedTransfersList
										seller_name={transfer.seller_name}
										cnab_id={transfer.cnab_id}
										value={transfer.value}
										code={transfer.code}
									/>
								);
							})}
						</Flex>
					</Modal>
				)}
			</Paper>
		</Flex>
	);
};

export default UploadRetFile;
