import React from 'react';

import { HStack, Box } from '@chakra-ui/react';
import { maskCpfOrCnpj, maskPhoneNumber } from '../../../services/masks.service';

import Paper from 'containers/Paper';
import Text from 'components/Text';

export default (data) => {
	return (
		<>
			<Paper title='Dados Cadastrados do Pagador'>
				<HStack spacing='10' alignItems='flex-start' justifyContent='space-between'>
					<Box>
						<Text fontWeight='bold'>Nome Completo</Text>
						<Text>{data.payer_name || '-'}</Text>
					</Box>
					<Box>
						<Text fontWeight='bold'>CPF/CNPJ</Text>
						<Text>{data.payer_document ? maskCpfOrCnpj(data.payer_document) : 'N/A'}</Text>
					</Box>
					<Box>
						<Text fontWeight='bold'>Telefone</Text>
						<Text>{data.payer_phone ? maskPhoneNumber(data.payer_phone) : 'N/A'}</Text>
					</Box>
					<Box>
						<Text fontWeight='bold'>Email</Text>
						<Text>{data.payer_email || '-'}</Text>
					</Box>
				</HStack>
			</Paper>
			<Paper title='Endereço'>
				<HStack spacing='10' alignItems='flex-start' justifyContent='space-between'>
					<Box>
						<Text fontWeight='bold'>CEP</Text>
						<Text>{data?.payer_address?.zipcode || '-'}</Text>
					</Box>
					<Box>
						<Text fontWeight='bold'>Rua</Text>
						<Text>{data?.payer_address?.street || '-'}</Text>
					</Box>
					<Box>
						<Text fontWeight='bold'>Número</Text>
						<Text>{data?.payer_address?.number || '-'}</Text>
					</Box>
					<Box>
						<Text fontWeight='bold'>Complemento</Text>
						<Text>{data?.payer_address?.complement || 'N/A'}</Text>
					</Box>
					<Box>
						<Text fontWeight='bold'>Bairro</Text>
						<Text>{data?.payer_address?.district || '-'}</Text>
					</Box>
					<Box>
						<Text fontWeight='bold'>Cidade</Text>
						<Text>{data?.payer_address?.city || '-'}</Text>
					</Box>
					<Box>
						<Text fontWeight='bold'>Estado</Text>
						<Text>{data?.payer_address?.state || '-'}</Text>
					</Box>
				</HStack>
			</Paper>
		</>
	);
};
