import React from 'react';

import { Progress, Text } from '@chakra-ui/react';

import { HeaderTitle, CellContent, SimpleTable, HeaderTitleProps, CellContentProps } from 'containers/SimpleTable';
import { maskMoney } from 'services/masks.service';

const HeaderTitleStyled: React.FC<HeaderTitleProps> = (props) => {
	return (
		<HeaderTitle {...props} justifyContent='flex-start' fontSize='sm' fontWeight='bold'>
			{props.children}
		</HeaderTitle>
	);
};

const CellContentStyled: React.FC<CellContentProps> = (props) => {
	return (
		<CellContent {...props} justifyContent='flex-start' fontSize='sm' fontWeight='bold'>
			{props.children}
		</CellContent>
	);
};

export default ({ data }) => {
	const columns = React.useMemo(
		() => [
			{
				Header: ({ column }) => <HeaderTitleStyled column={column}>Tipo de Venda</HeaderTitleStyled>,
				accessor: 'type_name',
				Cell: ({ cell: { value } }) => <CellContentStyled>{value}</CellContentStyled>,
			},
			{
				Header: ({ column }) => <HeaderTitleStyled column={column}>Volume Financeiro</HeaderTitleStyled>,
				accessor: 'financial_volume',
				Cell: ({ cell: { value } }) => <CellContentStyled>R$ {maskMoney(value / 100)}</CellContentStyled>,
			},
			{
				Header: ({ column }) => <HeaderTitleStyled column={column}>Valor Bruto</HeaderTitleStyled>,
				accessor: 'gross_volume',
				Cell: ({ cell: { value } }) => <CellContentStyled>R$ {maskMoney(value / 100)}</CellContentStyled>,
			},
			{
				Header: ({ column }) => <HeaderTitleStyled column={column}>Núm. de Vendas</HeaderTitleStyled>,
				accessor: 'number_sales',
				Cell: ({ cell: { value } }) => <CellContentStyled>{value}</CellContentStyled>,
			},
			{
				Header: ({ column }) => <HeaderTitleStyled column={column}>Média</HeaderTitleStyled>,
				accessor: 'average',
				Cell: ({ cell: { value } }) => <CellContentStyled>R$ {maskMoney(value / 100)}</CellContentStyled>,
			},
			{
				Header: ({ column }) => <HeaderTitleStyled column={column}>Porcentagem</HeaderTitleStyled>,
				accessor: 'percentage',
				Cell: ({ cell: { value } }) => (
					<div>
						<Progress value={value} rounded='md' /> {value > 0 && <Text textAlign='center'>{value}%</Text>}
					</div>
				),
			},
		],
		[]
	);

	return <SimpleTable columns={columns} variant='unstyled' data={data} fillParent />;
};
