import React from 'react';
import {
	FormControl,
	SimpleGrid,
	FormLabel,
	GridItem,
	Input,
} from '@chakra-ui/react';

import { subYears } from 'date-fns';

import FormCpfInput from 'components/Form/FormCpfInput';
import FormErrorMessage from 'components/Form/FormErrorMessage';
import FormInput from 'components/Form/FormInput';

import FormDatePickerInput from '../../../components/Form/FormDatePickerInput';
import FormSelect from '../../../components/Form/FormSelect';

type Props = {
	errors: any;
};

const OwnerForm: React.FC<Props> = (props) => (
	<SimpleGrid
		columns={{base: 1, md: 6, xl: 12}}
		color='gray.700'
		gap={4}
	>
		<GridItem colStart={{base: 1}} colSpan={{base: 4, md: 4, xl: 6}}>
			<FormControl id='owner_name' isRequired>
				<FormLabel fontSize='md' fontWeight='bold'>
					Nome
				</FormLabel>
				<FormInput as={Input} name='owner_name' />
				<FormErrorMessage name='owner_name' />
			</FormControl>
		</GridItem>

		<GridItem colStart={{base: 1}} colSpan={{base: 4, md: 2, xl: 3}}>
			<FormControl id='owner_document'>
				<FormLabel fontSize='md' fontWeight='bold'>
					CPF
				</FormLabel>
				<FormCpfInput borderColor={`darkGrey`} name='owner_document' />
				<FormErrorMessage name='owner_document' />
			</FormControl>
		</GridItem>

		<GridItem colStart={{base: 1}} colSpan={{base: 4, md: 2, xl: 3}}>
			<FormControl>
				<FormLabel fontSize='md' fontWeight='bold'>
					Data de Nascimento
				</FormLabel>
				<FormDatePickerInput
					name='owner_birth'
					placeholder='Informe a data'
					maxDate={subYears(new Date(), 18)}
					minDate={subYears(new Date(), 100)}
					borderColor='darkGrey'
				/>
				<FormErrorMessage name='owner_birth' />
			</FormControl>
		</GridItem>

		<GridItem colStart={{base: 1}} colSpan={{base: 4, md: 2, xl: 2}}>
			<FormControl id='owner_document_ofac'>
				<FormLabel fontSize='md' fontWeight='bold'>
					CPF OFAC
				</FormLabel>
				<FormSelect name='owner_document_ofac'>
					<option value='' />
					<option value='true'>Sim</option>
					<option value='false'>Não</option>
				</FormSelect>
				<FormErrorMessage name='owner_document_ofac' />
			</FormControl>
		</GridItem>

		<GridItem colStart={{base: 1}} colSpan={{base: 4, md: 2, xl: 2}}>
			<FormControl id='owner_document_onu'>
				<FormLabel fontSize='md' fontWeight='bold'>
					CPF ONU
				</FormLabel>
				<FormSelect name='owner_document_onu'>
					<option value='' />
					<option value='true'>Sim</option>
					<option value='false'>Não</option>
				</FormSelect>
				<FormErrorMessage name='owner_document_onu' />
			</FormControl>
		</GridItem>

		<GridItem colStart={{base: 1}} colSpan={{base: 4, md: 2, xl: 2}}>
			<FormControl id='owner_document_pep'>
				<FormLabel fontSize='md' fontWeight='bold'>
					CPF PEP
				</FormLabel>
				<FormSelect name='owner_document_pep'>
					<option value='' />
					<option value='true'>Sim</option>
					<option value='false'>Não</option>
				</FormSelect>
				<FormErrorMessage name='owner_document_pep' />
			</FormControl>
		</GridItem>

		<GridItem colStart={{base: 1}} colSpan={{base: 4, md: 2, xl: 2}}>
			<FormControl id='owner_document_ref'>
				<FormLabel fontSize='md' fontWeight='bold'>
					CPF REF
				</FormLabel>
				<FormSelect name='owner_document_ref'>
					<option value='' />
					<option value='true'>Sim</option>
					<option value='false'>Não</option>
				</FormSelect>
				<FormErrorMessage name='owner_document_ref' />
			</FormControl>
		</GridItem>
	</SimpleGrid>
);

export default OwnerForm;
