import React from 'react';
import { SimpleGrid, FormControl, FormLabel, GridItem } from '@chakra-ui/react';

import FormSelect from 'components/Form/FormSelect';
import FormErrorMessage from 'components/Form/FormErrorMessage';
import FormCurrencyInput from 'components/Form/FormCurrencyInput';

type Props = {
	errors: any;
};

const ThreeDsForm: React.FC<Props> = (props) => (
	<SimpleGrid columns={{base: 1, md: 6, xl: 12}} color='gray.700' gap={4}>
		<GridItem colStart={{base: 1}} colSpan={{base: 3, md: 2, xl: 3}}>
			<FormControl id='three_ds_active'>
				<FormLabel fontSize='md' fontWeight='bold'>
					3Ds Ativo
				</FormLabel>
				<FormSelect name='three_ds_active'>
					<option value='' />
					<option value='true'>Sim</option>
					<option value='false'>Não</option>
				</FormSelect>
				<FormErrorMessage name='three_ds_active' />
			</FormControl>
		</GridItem>

		<GridItem colStart={{base: 1}} colSpan={{base: 3, md: 2, xl: 3}}>
			<FormControl id='min_three_ds'>
				<FormLabel fontSize='md' fontWeight='bold'>
					Valor mínimo 3Ds
				</FormLabel>
				<FormCurrencyInput borderColor='darkGrey' name='min_three_ds' />
				<FormErrorMessage name='min_three_ds' />
			</FormControl>
		</GridItem>

		<GridItem colStart={{base: 1}} colSpan={{base: 3, md: 2, xl: 3}}>
			<FormControl id='three_ds_value'>
				<FormLabel fontSize='md' fontWeight='bold'>
					Valor 3Ds
				</FormLabel>
				<FormCurrencyInput borderColor='darkGrey' name='three_ds_value' />
				<FormErrorMessage name='three_ds_value' />
			</FormControl>
		</GridItem>
	</SimpleGrid>
);

export default ThreeDsForm;
