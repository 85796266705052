import { Flex, Image, Stack, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import { AiFillCheckCircle, AiFillClockCircle, AiFillCloseCircle } from 'react-icons/ai';

import { Divider } from '@chakra-ui/layout';
import { cardBrandEnum, saleStatus } from 'services/enums.service';

import AmexIcon from '../../../assets/images/cards/amex.svg';
import AuraIcon from '../../../assets/images/cards/aura.svg';
import DinersIcon from '../../../assets/images/cards/diners.svg';
import DiscoverIcon from '../../../assets/images/cards/discover.svg';
import EloIcon from '../../../assets/images/cards/elo.svg';
import HipercardIcon from '../../../assets/images/cards/hipercard.svg';
import JcbIcon from '../../../assets/images/cards/jcb.svg';
import McIcon from '../../../assets/images/cards/mc.svg';
import VisaIcon from '../../../assets/images/cards/visa.svg';
import { GetSaleDto } from '../../../clients';
import Paper from '../../../containers/Paper';
import { maskMoney } from '../../../services/masks.service';
import Steps from './Steps';
import Text from 'components/Text';

type Props = {
	sale: GetSaleDto;
	showBackofficeInfos: Boolean;
};

const iconStatusLiteral = {
	succeeded: <AiFillCheckCircle color='#47FF62' size={16} />,
	waiting_confirmation: <AiFillClockCircle color='#FFA547' size={16} />,
	pending: <AiFillClockCircle color='#FFA547' size={16} />,
	pending_capture: <AiFillClockCircle color='#FFA547' size={16} />,
	in_progress_capture: <AiFillClockCircle color='#FFA547' size={16} />,
	pending_cancel: <AiFillClockCircle color='#FFA547' size={16} />,
	canceled: <AiFillCloseCircle color='tomato' size={16} />,
	failed: <AiFillCloseCircle color='tomato' size={16} />,
	expired: <AiFillCloseCircle color='tomato' size={16} />,
};

const iconCard = {
	visa: VisaIcon,
	elo: EloIcon,
	mc: McIcon,
	amex: AmexIcon,
	hipercard: HipercardIcon,
	aura: AuraIcon,
	diners: DinersIcon,
	discover: DiscoverIcon,
	jcb: JcbIcon,
};

const SaleData: React.FC<Props> = (props) => {
	const { sale, showBackofficeInfos } = props;
	const isMobile = useBreakpointValue({ base: true, lg: false });

	return (
		<Paper title='Dados da venda' p={isMobile ? 6 : 10}>
			<Flex direction='column'>
				<Flex direction={isMobile ? 'column' : 'row'} justifyContent='space-around' mb='3'>
					<Flex direction='column' width={isMobile ? '100%' : '50%'} mb={isMobile ? '3' : '0'}>
						<Text fontWeight='bold'>Identificador</Text>
						<Text>{sale.id}</Text>
					</Flex>

					<Flex textAlign='start' direction='column' width={isMobile ? '100%' : '50%'}>
						<Text fontWeight='bold'>Descrição</Text>
						<Text>{sale.protocol ? sale.protocol : 'N/A'}</Text>
					</Flex>
				</Flex>
				<Flex direction={isMobile ? 'column' : 'row'} justifyContent='space-around' mb='3'>
					<Flex direction='column' width={isMobile ? '100%' : '50%'} mb={isMobile ? '3' : '0'}>
						<Text fontWeight='bold'>Nº do pedido</Text>
						<Text>{sale.description}</Text>
					</Flex>

					<Flex direction='column' width={isMobile ? '100%' : '50%'}>
						<Text fontWeight='bold'>Descrição da Divisão</Text>
						<Text>{sale.split_description ? sale.split_description : 'N/A'}</Text>
					</Flex>
				</Flex>
				{showBackofficeInfos && (
					<Flex direction={isMobile ? 'column' : 'row'} justifyContent='start' mb='7'>
						<Flex direction='column' width={isMobile ? '100%' : '50%'}>
							<Text fontWeight='bold'>PSP</Text>
							<Text>{sale.acquirer_reference}</Text>
						</Flex>
					</Flex>
				)}
			</Flex>
			<Flex
				direction={isMobile ? 'column' : 'row'}
				justifyContent='space-between'
				alignItems={isMobile ? 'flex-start' : 'center'}
				borderWidth={1}
				borderColor='#A8A8A8'
				rounded='md'
				px='5'
				py={isMobile ? '2' : '7'}
				mb='7'
			>
				<Stack spacing={isMobile ? '0' : '2'} alignItems={isMobile ? 'flex-start' : 'center'} mb={isMobile ? '2' : '0'} direction='column'>
					<Text fontWeight='bold'>{sale.celcoin ? 'Valor Pago' : 'Valor da Venda'}</Text>
					<Text fontWeight='bold'>{sale.original_amount ? `R$ ${maskMoney(sale.original_amount / 100)}` : 'N/A'}</Text>
				</Stack>

				<Stack spacing={isMobile ? '0' : '2'} alignItems={isMobile ? 'flex-start' : 'center'} mb={isMobile ? '2' : '0'} direction='column'>
					<Text fontWeight='bold'>{sale.celcoin ? 'Valor de Acréscimo' : 'Taxa'}</Text>
					<Text fontWeight='bold'>
						{sale?.original_amount - sale?.amount ? `R$ ${maskMoney((sale?.original_amount - sale?.amount) / 100)}` : 'N/A'}
					</Text>
				</Stack>

				<Stack spacing={isMobile ? '0' : '2'} alignItems={isMobile ? 'flex-start' : 'center'} mb={isMobile ? '2' : '0'} direction='column'>
					<Text fontWeight='bold'>{sale.celcoin ? 'Valor do Título' : 'Valor Líquido'}</Text>
					<Text fontWeight='bold'>{sale.amount ? `R$ ${maskMoney(sale.amount / 100)}` : 'N/A'}</Text>
				</Stack>

				<Stack spacing={isMobile ? '0' : '2'} alignItems={isMobile ? 'flex-start' : 'center'} mb={isMobile ? '2' : '0'} direction='column'>
					<Text fontWeight='bold'>Tipo de Venda</Text>
					<Text fontWeight='bold'>{sale.payment_type_description ? sale.payment_type_description : 'N/A'}</Text>
				</Stack>

				<Stack spacing={isMobile ? '0' : '2'} alignItems={isMobile ? 'flex-start' : 'center'} mb={isMobile ? '2' : '0'} direction='column'>
					<Text fontWeight='bold'>Status</Text>
					<Flex fontWeight='bold' alignItems='center'>
						<Text mr='3'>{iconStatusLiteral[sale.status]}</Text>
						<Text>{saleStatus[sale.status] ?? 'N/A'}</Text>
					</Flex>
				</Stack>
			</Flex>

			{sale.card_number && (
				<>
					<Text fontSize='xl' fontWeight='bold' mb={3}>
						Dados do cartão
					</Text>

					<Divider mb='7' />

					<Flex direction={isMobile ? 'column' : 'row'} justifyContent='space-between' mb='7'>
						<Flex direction='column'>
							<Text fontWeight='bold'>Nome no cartão</Text>
							<Text>{sale.card_holder_name}</Text>
						</Flex>

						<Flex direction='column'>
							<Text fontWeight='bold'>Número do cartão</Text>
							<Text>{sale?.card_number}</Text>
						</Flex>

						<Flex direction='column'>
							<Text fontWeight='bold'>Bandeira</Text>
							<Flex direction='row'>
								{sale.card_brand && <Image mr={2} src={iconCard[sale.card_brand]} />}
								<Text>{cardBrandEnum[sale.card_brand!] ?? 'N/A'}</Text>
							</Flex>
						</Flex>
					</Flex>
				</>
			)}

			<Text fontSize='xl' fontWeight='bold' mb={3}>
				Histórico
			</Text>

			<Flex width='100%' justifyContent='center'>
				<Flex width={isMobile ? '100%' : '80%'} justifyContent='center'>
					<Steps {...sale} />
				</Flex>
			</Flex>
		</Paper>
	);
};

export default SaleData;
