/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateSaleDto
 */
export interface CreateSaleDto {
    /**
     * Descricão
     * @type {string}
     * @memberof CreateSaleDto
     */
    description: string;
    /**
     * Valor original
     * @type {number}
     * @memberof CreateSaleDto
     */
    original_amount: number;
    /**
     * Valor
     * @type {number}
     * @memberof CreateSaleDto
     */
    amount?: number;
    /**
     * Id na adquirente
     * @type {string}
     * @memberof CreateSaleDto
     */
    acquirer_reference?: string;
    /**
     * Forma de pagamento
     * @type {string}
     * @memberof CreateSaleDto
     */
    form_payment?: string;
    /**
     * Tipo de venda
     * @type {string}
     * @memberof CreateSaleDto
     */
    type: CreateSaleDtoTypeEnum;
    /**
     * Id da máquina de pagamento
     * @type {string}
     * @memberof CreateSaleDto
     */
    terminal_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSaleDto
     */
    status: CreateSaleDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateSaleDto
     */
    sale_plan_id: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSaleDto
     */
    payment_type_id: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSaleDto
     */
    payer_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSaleDto
     */
    metadata?: string;
}

/**
* @export
* @enum {string}
*/
export enum CreateSaleDtoTypeEnum {
    Boleto = 'boleto',
    Pos = 'pos',
    PaymentLink = 'payment_link',
    ProtestLink = 'protest_link',
    Online = 'online',
    AutoGeneratePaymentLink = 'auto_generate_payment_link',
    AutoGenerateProtestLink = 'auto_generate_protest_link',
    BilletCode = 'billet_code',
    BillPayment = 'bill_payment'
}/**
* @export
* @enum {string}
*/
export enum CreateSaleDtoStatusEnum {
    Pending = 'pending',
    PendingCapture = 'pending_capture',
    InProgressCapture = 'in_progress_capture',
    Succeeded = 'succeeded',
    Canceled = 'canceled',
    PendingCancel = 'pending_cancel',
    Abort = 'abort',
    Failed = 'failed',
    WaitingConfirmation = 'waiting_confirmation',
    Expired = 'expired',
    WaitingCashIn = 'waiting_cashIn'
}

export function CreateSaleDtoFromJSON(json: any): CreateSaleDto {
    return CreateSaleDtoFromJSONTyped(json, false);
}

export function CreateSaleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSaleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': json['description'],
        'original_amount': json['original_amount'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'acquirer_reference': !exists(json, 'acquirer_reference') ? undefined : json['acquirer_reference'],
        'form_payment': !exists(json, 'form_payment') ? undefined : json['form_payment'],
        'type': json['type'],
        'terminal_id': !exists(json, 'terminal_id') ? undefined : json['terminal_id'],
        'status': json['status'],
        'sale_plan_id': json['sale_plan_id'],
        'payment_type_id': json['payment_type_id'],
        'payer_id': !exists(json, 'payer_id') ? undefined : json['payer_id'],
        'metadata': !exists(json, 'metadata') ? undefined : json['metadata'],
    };
}

export function CreateSaleDtoToJSON(value?: CreateSaleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'original_amount': value.original_amount,
        'amount': value.amount,
        'acquirer_reference': value.acquirer_reference,
        'form_payment': value.form_payment,
        'type': value.type,
        'terminal_id': value.terminal_id,
        'status': value.status,
        'sale_plan_id': value.sale_plan_id,
        'payment_type_id': value.payment_type_id,
        'payer_id': value.payer_id,
        'metadata': value.metadata,
    };
}


