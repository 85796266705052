import React from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import {
  Flex,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
  Box,
  GridItem,
  Grid,
  SimpleGrid,
  Heading,
} from '@chakra-ui/react';

import {
  AssignmentTransactionAlertsApi,
  GetAssignmentTransactionAlertDtoRequest,
  GetAssignmentTransactionAlertsDto,
} from 'clients';

import Paper from 'containers/Paper';
import Loader from 'containers/Loader';
import { getApiAuthConfig } from 'services/api.service';
import Breadcrumb from 'components/Breadcrumb';
import { sellerAssignments } from 'services/enums.service';
import {
  maskCns,
  maskCpfOrCnpj,
  maskFullDate,
  maskMoney
} from 'services/masks.service';


const AssignmentTransactionAlertDetails: React.FC = () => {
  const { id } = useParams();
  const { addToast } = useToasts();
  const history = useHistory();

  const apiConfig = getApiAuthConfig();
  const assignmentTransactionAlertApi = new AssignmentTransactionAlertsApi(apiConfig);
  const [isLoading, setIsLoading] = React.useState(false);
  const [alert, setAlert] = React.useState<GetAssignmentTransactionAlertsDto>();
  const [filters, setFilters] = React.useState();


  function setLastFilters(): void {
    const { location } = history || {};
    const { state } = location || {};
    const { filters } = state || {};

    setFilters(filters);
  }

  const getAlert = async () => {
    setIsLoading(true);

    try {
      const getByIdParams: GetAssignmentTransactionAlertDtoRequest = {
        alertId: id
      };

      const alert = await assignmentTransactionAlertApi
        .getAssignmentTransactionAlertDto(getByIdParams);

      setAlert(alert);
      setIsLoading(false);
    } catch (error) {
      addToast('Erro ao buscar Alerta', {
        appearance: 'error',
        autoDismiss: true,
      });

      setIsLoading(false);
    }
  };


  React.useEffect(() => {
    getAlert();
    setLastFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex flexDirection='column'>
      <Loader isOpen={isLoading} />

      <Breadcrumb>
        <BreadcrumbItem>
          <BreadcrumbLink>Resumo</BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink
            as={Link}
            to={{pathname: '/admin/alertas-atribuicao', state: {filters}}}
          >
            Alertas de Transações Suspeitas (Atribuição)
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink fontWeight='semibold'>Detalhes</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Paper title='Detalhes'>
        <Box>
          <Grid templateColumns='repeat(3, 1fr)' templateRows='repeat(1, 1fr)' gap={6} mb={10}>
            <GridItem colSpan={{ base: 3, sm: 2 }}>
              <Text fontWeight='bold'>Identificador</Text>
              <Text>{alert?.id}</Text>
            </GridItem>
            <GridItem colSpan={{ base: 3, sm: 1 }}>
              <Text fontWeight='bold'>Data da Criação</Text>
              <Text>{maskFullDate(alert?.created_at)}</Text>
            </GridItem>
          </Grid>
          <SimpleGrid mb={5}>
            <Heading color='gray.700' as='h6' size='md' py='1%'>
              Dados da Venda
            </Heading>
          </SimpleGrid>
          <Grid templateColumns='repeat(3, 1fr)' templateRows='repeat(2, 1fr)' gap={6} mb={10}>
            <GridItem colSpan={{ base: 3, sm: 2 }}>
              <Text fontWeight='bold'>Identificador</Text>
              <Text>{alert?.sale_id}</Text>
            </GridItem>
            <GridItem colSpan={{ base: 3, sm: 1 }}>
              <Text fontWeight='bold'>PSP</Text>
              <Text>{alert?.sale_acquirer_reference || '-'}</Text>
            </GridItem>
            <GridItem colSpan={{ base: 3, sm: 1 }}>
              <Text fontWeight='bold'>Data do Pagamento</Text>
              <Text>
                {
                  alert?.sale_payment_day
                    ? maskFullDate(alert?.sale_payment_day)
                    : '-'
                }
              </Text>
            </GridItem>
            <GridItem colSpan={{ base: 3, sm: 1 }}>
              <Text fontWeight='bold'>Valor da Dívida</Text>
              <Text>
                {'R$ '}
                {
                  maskMoney(
                    alert?.sale_original_amount
                      ? alert?.sale_original_amount/100
                      : 0
                  )
                }
              </Text>
            </GridItem>
            <GridItem colSpan={{ base: 3, sm: 1 }}>
              <Text fontWeight='bold'>Valor Líquido</Text>
              <Text>
                {'R$ '}
                  {
                    maskMoney(
                      alert?.sale_amount
                        ? alert?.sale_amount/100
                        : 0
                    )
                  }
              </Text>
            </GridItem>
            <GridItem colSpan={{ base: 3, sm: 1 }}>
              <Text fontWeight='bold'>Descrição</Text>
              <Text>{alert?.sale_description}</Text>
            </GridItem>
          </Grid>

          <SimpleGrid mb={5}>
            <Heading color='gray.700' as='h6' size='md' py='1%'>
              Dados do Estabelecimento
            </Heading>
          </SimpleGrid>
          <Grid templateColumns='repeat(3, 1fr)' templateRows='repeat(2, 1fr)' gap={6} mb={10}>
            <GridItem colSpan={{ base: 3, sm: 2 }}>
              <Text fontWeight='bold'>Identificador</Text>
              <Text>{alert?.seller_id}</Text>
            </GridItem>
            <GridItem colSpan={{ base: 3, sm: 1 }}>
              <Text fontWeight='bold'>CNS</Text>
              <Text>{maskCns(alert?.seller_cns || '')}</Text>
            </GridItem>
            <GridItem colSpan={{ base: 3, sm: 2 }}>
              <Text fontWeight='bold'>Nome</Text>
              <Text>{alert?.seller_name}</Text>
            </GridItem>
            <GridItem colSpan={{ base: 3, sm: 1 }}>
              <Text fontWeight='bold'>Documento</Text>
              <Text>{maskCpfOrCnpj(alert?.seller_document)}</Text>
            </GridItem>
            <GridItem colSpan={{ base: 3, sm: 1 }}>
              <Text fontWeight='bold'>Código</Text>
              <Text>{alert?.seller_code}</Text>
            </GridItem>
            <GridItem colSpan={{ base: 3, sm: 1 }}>
              <Text fontWeight='bold'>Atribuição</Text>
              <Text>{sellerAssignments[alert?.seller_assignment||''] || '-'}</Text>
            </GridItem>
            <GridItem colSpan={{ base: 3, sm: 1 }}>
              <Text fontWeight='bold'>Ticket Médio</Text>
              <Text>
                {'R$ '}
                {
                  maskMoney(
                    alert?.average_assignment_ticket
                      ? alert?.average_assignment_ticket/100
                      : 0
                  )
                }
              </Text>
            </GridItem>
          </Grid>
        </Box>
      </Paper>
    </Flex>
  );
};

export default AssignmentTransactionAlertDetails;
