import { BreadcrumbItem, BreadcrumbLink, Flex, HStack } from '@chakra-ui/react';
import Breadcrumb from 'components/Breadcrumb';
import { Formik } from 'formik';
import React from 'react';
import { useToasts } from 'react-toast-notifications';
import * as yup from 'yup';
import { Billet, BilletsApi } from '../../clients';
import Paper from '../../containers/Paper';
import { getApiAuthConfig } from '../../services/api.service';
import SampleTable from './components/SampleTable';
import { SearchForm } from './components/SearchForm';

export type FormValues = {
	key: string;
	value: string;
	provider: string | undefined;
};

const FindDda: React.FC = () => {
	const { addToast } = useToasts();
	const apiConfig = getApiAuthConfig();
	const billetsApi = new BilletsApi(apiConfig);

	const [isLoading, setIsLoading] = React.useState(false);
	const [data, setData] = React.useState<Partial<Billet>[]>([]);
	const [pagination, setPagination] = React.useState({ currentPage: 1, limit: 10 });
	const [totalPages, setTotalPages] = React.useState(1);
	const [firstRender, setFirstRender] = React.useState<boolean>(true);
	const initialValues: FormValues = {
		key: '',
		value: '',
		provider: '',
	};
	const [formValues, setFormValues] = React.useState<FormValues>(initialValues);

	const findDdaSchema = yup.object().shape({
		key: yup.string().required('Campo obrigatório'),
		value: yup.string().required('Campo obrigatório'),
		provider: yup.string().when('key', {
			is: 'barcode',
			then: yup.string().required('Campo obrigatório'),
			otherwise: yup.string().notRequired(),
		}),
	});

	async function findDda(values) {
		const { key, provider, value } = values;
		setIsLoading(true);
		try {
			const response = await billetsApi.getDdaByBillet({
				limit: pagination.limit,
				currentPage: pagination.currentPage,
				key: key,
				value: value,
				provider: provider !== '' ? provider: undefined,
			});
			setData((response.results as unknown) as Partial<Billet>[]);
			setTotalPages(response.total_pages);

			if (response.results.length === 0) {
				addToast('Nenhum boleto foi localizado.', {
					appearance: 'error',
					autoDismiss: true,
				});
			}
		} catch (error) {
			addToast('Erro ao localizar o DDA.', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	}

	const handleFormSubmit = async (values) => {
		setFormValues(values);
		findDda(values);
	};

	React.useEffect(() => {
		if (!firstRender) {
			findDda(formValues);
		} else {
			setFirstRender(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pagination]);

	return (
		<Flex flexDirection='column'>
			<HStack justify='space-between'>
				<Breadcrumb>
					<BreadcrumbItem>
						<BreadcrumbLink fontSize='md'>Resumo</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink fontSize='md' fontWeight='semibold'>
							Localizar DDA
						</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>
			</HStack>

			<Formik
				enableReinitialize
				initialValues={initialValues}
				validationSchema={findDdaSchema}
				onSubmit={async (values, { resetForm }) => {
					await handleFormSubmit(values);
					resetForm({ values: initialValues });
				}}
			>
				{({ handleSubmit, values }) => {
					return (
						<form onSubmit={handleSubmit}>
							<Paper p={6}>
								<SearchForm isLoading={isLoading} values={values}/>
							</Paper>
							<Paper p={6}>
								<Flex overflowX='auto' overflowY='hidden' flexDirection='column'>
									{data && <SampleTable data={data} setPagination={setPagination} pagination={pagination} totalPages={totalPages} />}
								</Flex>
							</Paper>
						</form>
					);
				}}
			</Formik>
		</Flex>
	);
};

export default FindDda;
