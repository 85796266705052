import React from 'react';

import { HStack, Text } from '@chakra-ui/react';

import { HeaderTitle, CellContent, SimpleTable, HeaderTitleProps, CellContentProps } from '../../../containers/SimpleTable';
import { AiFillCloseCircle, AiFillCheckCircle, AiFillClockCircle } from 'react-icons/ai';

import { maskDate, maskMoney, maskPhoneNumber } from '../../../services/masks.service';
import { paymentTypeEnum, paymentMethodEnum, saleStatus } from '../../../services/enums.service';
import { useAuth } from '../../../contexts/AuthProvider';

const iconStatusLiteral = {
	succeeded: <AiFillCheckCircle color='#47FF62' size={16} />,
	pending: <AiFillClockCircle color='#FFA547' size={16} />,
	pending_capture: <AiFillClockCircle color='#FFA547' size={16} />,
	in_progress_capture: <AiFillClockCircle color='#FFA547' size={16} />,
	pending_cancel: <AiFillClockCircle color='#FFA547' size={16} />,
	canceled: <AiFillCloseCircle color='tomato' size={16} />,
	failed: <AiFillCloseCircle color='tomato' size={16} />,
	waiting_confirmation: <AiFillClockCircle color='#FFA547' size={16} />,
	expired: <AiFillCloseCircle color='tomato' size={16} />,
};

const HeaderTitleStyled: React.FC<HeaderTitleProps> = (props) => {
	return (
		<HeaderTitle {...props} color='black' justifyContent='flex-start' fontSize='sm' fontWeight='bold'>
			{props.children}
		</HeaderTitle>
	);
};

const CellContentStyled: React.FC<CellContentProps> = (props) => {
	return (
		<CellContent {...props} color='darkGrey' justifyContent='flex-start' fontSize='sm' fontWeight='bold'>
			{props.children}
		</CellContent>
	);
};

export default ({ data, setPagination, pagination, totalPages, onRowClick, showSellerColumn }) => {
	const { isBackoffice, user } = useAuth();

	let deniedFields: string[] = [];

	if (!isBackoffice) {
		deniedFields = user.merchant?.tables_permissions?.sales_history?.denied_fields || [];
	}

	const sales = data?.results?.map((sale) => {
		const date = sale?.created_at && new Date(sale?.created_at)?.toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })?.split(' ');
		const updateDate =
			sale?.updated_at && new Date(sale?.updated_at)?.toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })?.split(' ');

		const paymentDay =
			sale?.payment_day && new Date(sale?.payment_day)?.toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })?.split(' ');
		const confirmationDay =
			sale?.confirmation_day && new Date(sale?.confirmation_day)?.toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })?.split(' ');
		const billetIsNotVisible = sale?.form_payment === 'billet' && sale?.status !== 'pending';

		if ((billetIsNotVisible || sale?.form_payment !== 'billet') && updateDate && updateDate.length > 0) {
		}

		return {
			id: sale?.id,
			status: sale?.status ? sale?.status : 'pending',
			payment_method: paymentMethodEnum[sale?.type],
			formatted_description: sale?.formatted_description,
			formatted_protocol: sale?.protocol,
			generate_payer: sale?.type === 'auto_generate_payment_link' ? 'Sim' : 'Não',
			formatted_pre_capture: sale?.formatted_pre_capture,
			payer: sale?.payer_name,
			created_at: date && `${maskDate(date[0].split('-').reverse().join('-'))} às ${date[1] ? date[1]?.substr(0, 5) : '-:-'}`,
			payment_day:
				paymentDay && `${maskDate(paymentDay[0].split('-').reverse().join('-'))} às ${paymentDay[1] ? paymentDay[1]?.substr(0, 5) : '-:-'}`,
			financial: (sale?.original_amount - sale?.amount) / 100,
			expiration_day: sale?.expiration_day,
			settlement_day: sale?.settlement_day?.split('-').reverse().join('/'),
			form_payment: sale?.payment_type_description,
			original_amount: sale?.original_amount / 100,
			amount: sale?.amount / 100,
			seller_name: sale?.seller_name,
			seller_description: sale?.seller_description,
			has_split_rules: sale?.has_split_rules ? 'Sim' : 'Não',
			payer_email: sale?.payer_email,
			payer_phone: sale?.payer_phone,
			confirmation_day:
				confirmationDay &&
				`${maskDate(confirmationDay[0].split('-').reverse().join('-'))} às ${
					confirmationDay[1] ? confirmationDay[1]?.substr(0, 5) : '-:-'
				}`,
			formatted_split_description: sale?.formatted_split_description,
			created_by_user_name: sale?.created_by_user_name,
			acquirer_reference: sale?.acquirer_reference
		};
	});

	const columns = React.useMemo(
		() => {
			let cols: any = [];

			if (showSellerColumn) {
				cols.push({
					Header: ({ column }) => <HeaderTitleStyled column={column}>Estabelecimento</HeaderTitleStyled>,
					accessor: 'seller_name',
					Cell: ({ cell: { value } }) => <CellContentStyled>{value}</CellContentStyled>,
				});
				cols.push({
					Header: ({ column }) => <HeaderTitleStyled column={column}>Descrição do Estabelecimento</HeaderTitleStyled>,
					accessor: 'seller_description',
					Cell: ({ cell: { value } }) => <CellContentStyled>{value}</CellContentStyled>,
				});
				cols.push({
					Header: ({ column }) => <HeaderTitleStyled column={column}>PSP</HeaderTitleStyled>,
					accessor: 'acquirer_reference',
					Cell: ({ cell: { value } }) => <CellContentStyled>{value}</CellContentStyled>,
				})
			}
			return cols.concat([
				{
					Header: ({ column }) => <HeaderTitleStyled column={column}>Data da Venda</HeaderTitleStyled>,
					accessor: 'created_at',
					Cell: ({ cell: { value } }) => <CellContentStyled>{value}</CellContentStyled>,
				},
				{
					Header: ({ column }) => <HeaderTitleStyled column={column}>Status</HeaderTitleStyled>,
					accessor: 'status',
					Cell: ({ cell: { value } }) => (
						<CellContentStyled onClick={() => {}}>
							<HStack>
								<Text>{iconStatusLiteral[value]}</Text>
								<Text>{saleStatus[value] ?? 'N/A'}</Text>
							</HStack>
						</CellContentStyled>
					),
				},
				{
					Header: ({ column }) => <HeaderTitleStyled column={column}>Nº do Pedido</HeaderTitleStyled>,
					accessor: 'formatted_description',
					Cell: ({ cell: { value } }) => <CellContentStyled>{value}</CellContentStyled>,
				},
				{
					Header: ({ column }) => <HeaderTitle column={column}>Tem Pré-Captura</HeaderTitle>,
					accessor: 'formatted_pre_capture',
					Cell: ({ cell: { value } }) => <CellContent color={`darkGrey`} className='cell-content__pre-capture'>{value}</CellContent>,
				},
				{
					Header: ({ column }) => <HeaderTitleStyled column={column}>Descrição</HeaderTitleStyled>,
					accessor: 'formatted_protocol',
					Cell: ({ cell: { value } }) => <CellContentStyled>{value}</CellContentStyled>,
				},
				{
					Header: ({ column }) => <HeaderTitleStyled column={column}>Descrição da Divisão</HeaderTitleStyled>,
					accessor: 'formatted_split_description',
					Cell: ({ cell: { value } }) => <CellContentStyled>{value}</CellContentStyled>,
				},
				{
					Header: ({ column }) => <HeaderTitleStyled column={column}>Forma de Pagamento</HeaderTitleStyled>,
					accessor: 'form_payment',
					Cell: ({ cell: { value } }) => <CellContentStyled>{value}</CellContentStyled>,
				},
				{
					Header: ({ column }) => <HeaderTitleStyled column={column}>Meio de Pagamento</HeaderTitleStyled>,
					accessor: 'payment_method',
					Cell: ({ cell: { value } }) => <CellContentStyled>{value || 'Pendente'}</CellContentStyled>,
				},
				{
					Header: ({ column }) => <HeaderTitleStyled column={column}>Valor Bruto</HeaderTitleStyled>,
					accessor: 'original_amount',
					Cell: ({ cell: { value } }) => <CellContentStyled>R$ {maskMoney(value)}</CellContentStyled>,
				},
				{
					Header: ({ column }) => <HeaderTitleStyled column={column}>Custo Financeiro</HeaderTitleStyled>,
					accessor: 'financial',
					Cell: ({ cell: { value } }) => <CellContentStyled>R$ {maskMoney(value)}</CellContentStyled>,
				},
				{
					Header: ({ column }) => <HeaderTitleStyled column={column}>Valor Líquido</HeaderTitleStyled>,
					accessor: 'amount',
					Cell: ({ cell: { value } }) => <CellContentStyled>R$ {maskMoney(value)}</CellContentStyled>,
				},
				{
					Header: ({ column }) => <HeaderTitleStyled column={column}>Pagador</HeaderTitleStyled>,
					accessor: 'payer',
					Cell: ({ cell: { value } }) => (
						<CellContentStyled>
							<Text isTruncated>{value}</Text>
						</CellContentStyled>
					),
				},
				{
					Header: ({ column }) => <HeaderTitleStyled column={column}>Data do Pagamento</HeaderTitleStyled>,
					accessor: 'payment_day',
					Cell: ({
						cell: {
							row: {
								values: { form_payment },
							},
							value,
						},
					}) => <CellContentStyled>{!paymentTypeEnum[form_payment] && value}</CellContentStyled>,
				},
				{
					Header: ({ column }) => <HeaderTitleStyled column={column}>Data da Confirmação do Serviço</HeaderTitleStyled>,
					accessor: 'confirmation_day',
					Cell: ({ cell: { value } }) => <CellContentStyled>{value}</CellContentStyled>,
				},
				{
					Header: ({ column }) => <HeaderTitleStyled column={column}>Data de Liquidação</HeaderTitleStyled>,
					accessor: 'settlement_day',
					Cell: ({ cell: { value } }) => <CellContentStyled>{value}</CellContentStyled>,
				},
				{
					Header: ({ column }) => <HeaderTitleStyled column={column}>Valor Dividido</HeaderTitleStyled>,
					accessor: 'has_split_rules',
					Cell: ({ cell: { value } }) => <CellContentStyled>{value}</CellContentStyled>,
				},
				{
					Header: ({ column }) => <HeaderTitleStyled column={column}>Gerado pelo Pagador</HeaderTitleStyled>,
					accessor: 'generate_payer',
					Cell: ({ cell: { value } }) => <CellContentStyled>{value}</CellContentStyled>,
				},
				{
					Header: ({ column }) => <HeaderTitleStyled column={column}>ID</HeaderTitleStyled>,
					accessor: 'id',
					Cell: ({ cell: { value } }) => <CellContentStyled>{value.toString().slice(0, 7)}...</CellContentStyled>,
				},
				{
					Header: ({ column }) => <HeaderTitleStyled column={column}>Email do Pagador</HeaderTitleStyled>,
					accessor: 'payer_email',
					Cell: ({ cell: { value } }) => <CellContentStyled>{value}</CellContentStyled>,
				},
				{
					Header: ({ column }) => <HeaderTitleStyled column={column}>Telefone do Pagador</HeaderTitleStyled>,
					accessor: 'payer_phone',
					Cell: ({ cell: { value } }) => <CellContentStyled>{value ? maskPhoneNumber(value) : ''}</CellContentStyled>,
				},
				{
					Header: ({ column }) => <HeaderTitleStyled column={column}>Usuário</HeaderTitleStyled>,
					accessor: 'created_by_user_name',
					Cell: ({ cell: { value } }) => <CellContentStyled>{value || ''}</CellContentStyled>,
				},
			]);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const allowedColumns = columns.filter((col) => !deniedFields?.includes(col?.accessor));

	return (
		<SimpleTable
			columns={allowedColumns}
			onRowClick={onRowClick}
			data={sales}
			setPagination={setPagination}
			pagination={pagination}
			totalPages={totalPages}
			pl={2}
			maxHeightTableWrapper='450px'
		/>
	);
};
