import { Flex, Stack } from '@chakra-ui/react';
import { Formik } from 'formik';
import React from 'react';
import { GetOrdersStatusEnum } from '../../../clients';
import FormControl from '../../../components/Form/FormControl';
import FormLabel from '../../../components/Form/FormLabel';
import FormSelect from '../../../components/Form/FormSelect';
import RangeDatePicker from '../../../containers/RangeDatePicker';
import { usePaperRollOrder } from '../context/PaperRollOrderContext';
import { statusText } from './StatusText';

const SearchForm: React.FC = () => {
	const { period, setPeriod, getOrders, isLoading, setStatus, isMobile } = usePaperRollOrder();

	const statusArray = Object.keys(GetOrdersStatusEnum).map((key) => GetOrdersStatusEnum[key]);

	const initialValues = {
		startDate: period[0].startDate,
		endDate: period[0].endDate,
		status: undefined,
	};
	return (
		<Formik enableReinitialize initialValues={initialValues} onSubmit={() => {}}>
			{() => {
				return (
					<form>
						<Flex mb={3} flexDirection='row'>
							<Stack direction={isMobile ? 'column' : 'row'} justifyContent={isMobile ? 'center' : 'space-between'} w='200%' spacing={4}>
								<Stack direction={['column', 'column', 'row']}>
									<Flex position='relative'>
										<FormControl id='date'>
											<FormLabel fontSize='md' fontWeight='bold'>
												Período
											</FormLabel>
											<RangeDatePicker
												name='date'
												period={period}
												setPeriod={setPeriod}
												handleSubmit={getOrders}
												backgroundColor='primary'
												py='5'
												isDisabled={isLoading}
											/>
										</FormControl>
									</Flex>
									<Flex>
										<FormControl id='status'>
											<FormLabel fontSize='md' fontWeight='bold'>
												Status
											</FormLabel>
											<FormSelect
												name='status'
												isDisabled={isLoading}
												onChange={(e) => {
													setStatus(e.target.value === '' ? undefined : ((e.target.value as unknown) as GetOrdersStatusEnum));
												}}
											>
												<option value='' />
												{statusArray.map((status, index) => (
													<option value={status} key={index}>
														{statusText[status as GetOrdersStatusEnum].text}
													</option>
												))}
											</FormSelect>
										</FormControl>
									</Flex>
								</Stack>
							</Stack>
						</Flex>
					</form>
				);
			}}
		</Formik>
	);
};

export default SearchForm;
