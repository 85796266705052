import React from 'react';
import { Flex, HStack } from '@chakra-ui/react';

import FileUploadInput from '../../../containers/FileUploadInput';
import Text from '../../../components/Text';

type UploadImageFormProps = {
	file: File | undefined;
	setFile: React.Dispatch<React.SetStateAction<File | undefined>>;
	isLoading: boolean;
};

export const UploadImageForm = (props: UploadImageFormProps) => {
	return (
		<HStack spacing={4} mb={4} alignItems='flex-end'>
			<Flex alignItems='center' border='1px solid lightgray' borderRadius='md' justifyContent='center' p={2}>
				<FileUploadInput
					containerProps={{ m: 0 }}
					inputProps={{
						onChange: (e) => {
							if (e.target.files?.length) {
								props.setFile(e.target.files[0]);
							}
						},
						accept: 'image/png, image/jpg, image/jpeg',
					}}
				/>
				<Text id='select-file-text' mx={2} whiteSpace='nowrap'>
					{props.file ? props.file.name : 'Selecione uma imagem.'}
				</Text>
			</Flex>
		</HStack>
	);
};
