import { BreadcrumbItem, BreadcrumbLink, Flex, useBreakpointValue } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';

import { useAuth } from 'contexts/AuthProvider';
import { useTheme } from 'contexts/ThemeProvider';
import { useCurrentSeller } from 'contexts/SellerProvider';
import { getApiAuthConfig } from 'services/api.service';
import Breadcrumb from 'components/Breadcrumb';
import BackofficeAlertContainer from 'containers/BackofficeAlertContainer';

import { GetSimulationDto, PaginatedDto, InternalListSellerSalesTypeEnum, SellersApi, InternalListSellerSalesRequest, GetUserDtoTypeEnum, SalesApi, CreateExportSalesListDtoExtensionEnum } from '../../clients';
import BillSaleBarCode, { BillSaleInitialValuesInterface } from './components/BillSaleBarcode';
import BillSalePos from './components/BillSalePos';
import BillSaleFilters from './components/BillSaleFilters';
import BillSaleTable from './components/BillSaleTable';
import Title from 'components/Title';
import Button from 'components/Button';
import { BiExport } from 'react-icons/bi';
import ExportFileSuccessModal from 'containers/ExportFileSuccessModal';

const BillSale: React.FC = () => {
	const billSaleInitialValues: BillSaleInitialValuesInterface = {
		description: '',
		createPayer: false,
		payer: {
			name: '',
			document: '',
			email: '',
			phone: '',
			address: { zipcode: '', street: '', district: '', city: '', state: '', complement: '', number: '' },
		},
		search: {
			name: '',
			document: '',
		},
	};

	const [isLoading, setIsLoading] = useState(false);
	const [barCode, setBarCode] = useState('');
	const [amount, setAmount] = useState('');
	const [showAmount, setShowAmount] = useState('');
	const [barCodeDisable, setBarCodeDisable] = useState(true);
	const [celcoinResponse, setCelcoinResponse] = useState({ celcoinId: '', documentPayer: '' });
	const [radioValue, setRadioValue] = useState('');
	const [installmentsSimulation, setInstallmentsSimulation] = useState<Array<GetSimulationDto>>([]);
	const [simulator, setSimulator] = useState({ installment: '', totalAmount: 0, installmentAmount: 0, type: '', cet: 0 });
	const [paymentType, setPaymentType] = useState('');
	const [selectedFilters, setSelectedFilters] = useState({});
	const [lastSales, setLastSales] = React.useState<PaginatedDto>({} as PaginatedDto);
	const [pagination, setPagination] = React.useState({ currentPage: 1, limit: 100 });
	const [lastSalesTotalPages, setLastSalesTotalPages] = React.useState(1);
	const [updateTable, setUpdateTable] = React.useState(true);
	const [dueDate, setDueDate] = useState('');
	const [exportIsLoading, setExportIsLoading] = React.useState<boolean>(false);
	const [openExportSuccessModal, setOpenExportSuccessModal] = React.useState<boolean>(false);

	const { theme } = useTheme();
	const { addToast } = useToasts();
	const { currentSeller } = useCurrentSeller();
	const { user } = useAuth();
	const isMobile = useBreakpointValue({ base: true, lg: false });
	const history = useHistory();

	const apiConfig = getApiAuthConfig();
	const sellerApi = new SellersApi(apiConfig);
	const salesApi = new SalesApi(apiConfig);

	const fetchSales = async (filters: any = {}) => {
		const { currentPage, limit, ...parsedFilters } = filters;

		const listSellerPayersRequest: InternalListSellerSalesRequest = {
			currentPage: pagination.currentPage,
			limit: pagination.limit,
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
			sellerId: currentSeller?.id!,
			type: [InternalListSellerSalesTypeEnum.BillPayment],
			startDate: filters['startDate'] || format(new Date().setHours(0, 0, 0, 0), 'yyyy-MM-dd'),
			endDate: filters['endDate'] || format(new Date().setHours(23, 59, 59, 999), 'yyyy-MM-dd'),
			...parsedFilters,
		};

		try {
			setIsLoading(true);

			const response = await sellerApi.internalListSellerSales(listSellerPayersRequest);

			setLastSales(response);
			setLastSalesTotalPages(response.total_pages);
		} catch (e) {
			addToast('Não foi possível listar os pagamentos', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	};

	function navigateToDetails(id: string) {
		history.push(`/admin/sales-history/${id}`, { route: history.location.pathname });
	}

	React.useEffect(() => {
		(async function () {
			if (currentSeller) {
				setIsLoading(true);

				const listSellerPayersRequest: InternalListSellerSalesRequest = {
					currentPage: pagination.currentPage,
					limit: pagination.limit,
					sellerId: currentSeller?.id!,
					type: [InternalListSellerSalesTypeEnum.BillPayment],
					startDate: selectedFilters['startDate'] || format(new Date().setHours(0, 0, 0, 0), 'yyyy-MM-dd'),
					endDate: selectedFilters['endDate'] || format(new Date().setHours(23, 59, 59, 999), 'yyyy-MM-dd'),
				};

				if (user.type !== GetUserDtoTypeEnum.Backoffice) {
					listSellerPayersRequest.user = user.id;
				}
				const response = await sellerApi.internalListSellerSales(listSellerPayersRequest);

				if (response) {
					setLastSales(response);
					setLastSalesTotalPages(response.total_pages);
				}
				setIsLoading(false);
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateTable, currentSeller, pagination]);

	const handleExportReportFile = async (params) => {
		setExportIsLoading(true);

		try {
			let { status, formPayment, type, payer_name, ...parsedParams } = params;

			if (parsedParams.search && parsedParams.search.length > 0) {
				const status = [{ value: '', label: '' }];

				parsedParams = {
					start_date: '',
					end_date: '',
					status: status?.map((v) => v.value),
					formPayment: [],
					type: [],
					search: parsedParams.search,
				};
			}

			await salesApi.createSalesBillPaymentListExportRequest({
				createExportSalesListDto: {
					start_date: params['startDate'] || format(new Date().setHours(0, 0, 0, 0), 'yyyy-MM-dd'),
					end_date: params['endDate'] || format(new Date().setHours(0, 0, 0, 0), 'yyyy-MM-dd'),
					status: status?.map((v) => v.value),
					form_payment: formPayment?.map((v) => v.value),
					type: type?.map((v) => v.value),
					extension: CreateExportSalesListDtoExtensionEnum.Xls,
					seller_id: currentSeller?.id,
					payer_name,
					...parsedParams,
				},
			});
			setOpenExportSuccessModal(true);
		} catch (e) {
			addToast('Ocorreu um erro no processamento', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setExportIsLoading(false);
		}
	};

	if (!currentSeller) {
		return <BackofficeAlertContainer />;
	}

	return (
		<>
			<Flex pb='8'>
				<Breadcrumb>
					<BreadcrumbItem>
						<BreadcrumbLink href='#' fontSize='md'>
							Resumo
						</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem>
						<BreadcrumbLink href='#' fontSize='md'>
							Venda
						</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink opacity='0.9' cursor='unset' href='#' fontSize='md' fontWeight='semibold'>
							Pagamento de contas
						</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>
			</Flex>

			<ExportFileSuccessModal
				showCloseButton
				isOpen={openExportSuccessModal}
				onOkClick={() => {
					setOpenExportSuccessModal(false);
				}}
				onClose={() => {
					setOpenExportSuccessModal(false);
				}}
			/>

			<BillSaleBarCode
				billSaleInitialValues={billSaleInitialValues}
				isLoading={isLoading}
				isMobile={isMobile}
				theme={theme}
				barCode={barCode}
				setBarCode={setBarCode}
				amount={amount}
				setAmount={setAmount}
				setShowAmount={setShowAmount}
				addToast={addToast}
				setIsLoading={setIsLoading}
				currentSeller={currentSeller}
				barCodeDisable={barCodeDisable}
				setBarCodeDisable={setBarCodeDisable}
				setCelcoinResponse={setCelcoinResponse}
				setInstallmentsSimulation={setInstallmentsSimulation}
				setRadioValue={setRadioValue}
				setSimulator={setSimulator}
				setPaymentType={setPaymentType}
				setDueDate={setDueDate}
			/>

			{paymentType && paymentType === 'pos' ? (
				<BillSalePos
					amount={amount}
					addToast={addToast}
					apiConfig={apiConfig}
					celcoinResponse={celcoinResponse}
					currentSeller={currentSeller}
					fetchSales={fetchSales}
					installmentsSimulation={installmentsSimulation}
					isLoading={isLoading}
					radioValue={radioValue}
					selectedFilters={selectedFilters}
					setAmount={setAmount}
					setBarCodeDisable={setBarCodeDisable}
					setBarcode={setBarCode}
					setInstallmentsSimulation={setInstallmentsSimulation}
					setIsLoading={setIsLoading}
					setPaymentType={setPaymentType}
					setRadioValue={setRadioValue}
					setShowAmount={setShowAmount}
					setSimulator={setSimulator}
					showAmount={showAmount}
					simulator={simulator}
					isMobile={isMobile}
					theme={theme}
					setUpdateTable={setUpdateTable}
					updateTable={updateTable}
					dueDate={dueDate}
					setDueDate={setDueDate}
				/>
			) : (
				paymentType === 'online' && <h1>online</h1>
			)}

			<Flex direction={isMobile ? 'column' : 'row'} justify='space-between' mb={1}>
				<Flex mt='8'>
					<Title>Pagamentos de Contas Gerados</Title>
				</Flex>
				<Flex direction={isMobile ? 'column' : 'row'}>
					<Button
						{...(lastSales?.results?.length === 0 && { disabled: true })}
						bgColor='primary'
						isDisabled={exportIsLoading}
						isLoading={exportIsLoading}
						onClick={() => handleExportReportFile(selectedFilters)}
						leftIcon={<BiExport size={20} />}
						mb={3}
						mt='8'
					>
						Exportar para Excel
					</Button>
				</Flex>
			</Flex>

			<Flex flexDir='column' p={10} bgColor='white' rounded='md' boxShadow='sm' mt='2' w='100%'>
				<BillSaleFilters
					onChangeFilters={async (filters) => {
						setSelectedFilters(filters);

						await fetchSales(filters);
					}}
				/>

				<Flex overflowX='auto'>
					<BillSaleTable
						data={lastSales}
						setPagination={setPagination}
						pagination={pagination}
						totalPages={lastSalesTotalPages}
						onRowClick={(row) => navigateToDetails(row.original.id)}
						seller={currentSeller}
						afterCancel={fetchSales}
					/>
				</Flex>
			</Flex>
		</>
	);
};

export default BillSale;
