import { Flex, Heading, Box, Spacer, Text } from '@chakra-ui/react';
import React from 'react';

import Modal from 'containers/Modal';

import Button from './Button';

type OmieConfirmModalProps = {
	confirmExportOmie: boolean;
	isLoading: boolean;
	handleSubmit: () => void;
};
const OmieConfirmModal: React.FC<OmieConfirmModalProps> = ({ confirmExportOmie, isLoading, handleSubmit }) => {
	return (
		<Modal isOpen={confirmExportOmie}>
			<Box bg='gray.100' borderWidth='1px' borderRadius='md'>
				<Flex p={8} flexDirection='column'>
					<Heading fontSize='lg' paddingBottom={8}>
						Confirmação de exportação
					</Heading>

					<Box mt={2} mb={5}>
						<Text align='center'>
							Ao confirmar será iniciado o processo de exportação para OMIE.
						</Text>
					</Box>

					<Flex flexDirection='row' alignItems='center' fontSize='md' px={3} pb={2}>
						<Spacer />

						<Button size='lg' color='white' backgroundColor='primary' isFullWidth onClick={() => handleSubmit()} isLoading={isLoading}>
							Confirmar Exportação!
						</Button>
					</Flex>
				</Flex>
			</Box>
		</Modal>
	);
};

export default OmieConfirmModal;
