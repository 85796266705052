import React from 'react';
import { Flex, Stack, Heading, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';

import { useToasts } from 'react-toast-notifications';
import { Center } from '@chakra-ui/layout';
import { Formik } from 'formik';

import Loader from 'containers/Loader';

import { CostsPlansApi, CreateCostPlanRequest } from '../../clients';

import Button from '../../components/Button';
import Paper from '../../containers/Paper';
import { getApiAuthConfig } from '../../services/api.service';
import createCostPlanSchema from './components/Validation';
import CostPlanForm from './components/CostPlanForm';
import initialValues from './components/Values';
import OptionsForm from './components/OptionsForm';
import Breadcrumb from 'components/Breadcrumb';

const CreateCostPlan: React.FC = () => {
	const apiConfig = getApiAuthConfig();
	const costsPlansApi = new CostsPlansApi(apiConfig);

	const [isLoading, setIsLoading] = React.useState(false);

	const { addToast } = useToasts();

	const createCostPlanInitialValues = initialValues;

	const createCostPlan = async (values, { resetForm }) => {
		const { name, description, options } = values;
		setIsLoading(true);

		try {
			const requestParams: CreateCostPlanRequest = {
				createCostPlanDto: {
					name,
					description,
					options,
				},
			};

			await costsPlansApi.createCostPlan(requestParams);
			addToast('Plano de custo Criado!', {
				appearance: 'success',
				autoDismiss: true,
			});
		} catch (error) {
			console.log(error);
			addToast('Erro ao criar Plano de custo', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
			resetForm();
		}
	};

	const renderOptions = (errors, values) => {
		const paymentTypeToRender = Array.from(Array(createCostPlanInitialValues.options.length).keys());

		return paymentTypeToRender.map((i, index) => <OptionsForm errors={errors} index={index} values={values} />);
	};

	return (
		<Flex flexDirection='column'>
			<Loader isOpen={isLoading} />

			<Breadcrumb>
				<BreadcrumbItem>
					<BreadcrumbLink>Resumo</BreadcrumbLink>
				</BreadcrumbItem>

				<BreadcrumbItem>
					<BreadcrumbLink href='/admin/planos-de-custo'>Planos de Custo</BreadcrumbLink>
				</BreadcrumbItem>

				<BreadcrumbItem isCurrentPage>
					<BreadcrumbLink fontWeight='semibold'>Criar Plano de Custo</BreadcrumbLink>
				</BreadcrumbItem>
			</Breadcrumb>

			<Paper p={4}>
				<Flex flexDirection='column'>
					<>
						<Formik initialValues={createCostPlanInitialValues} validationSchema={createCostPlanSchema} onSubmit={createCostPlan}>
							{({ handleSubmit, isValid, setFieldValue, errors, values }) => {
								return (
									<form onSubmit={handleSubmit} style={{ width: '100%' }}>
										<Stack px={4} spacing={6}>
											<CostPlanForm errors={errors} />

											<Heading color='gray.700' as='h6' size='md' py='1%'>
												Opções
											</Heading>

											{renderOptions(errors, values)}
										</Stack>

										<Center pt='6'>
											<Button size='md' bgColor='primary' disabled={!isValid} type='submit' isLoading={isLoading}>
												Criar Plano de Custo
											</Button>
										</Center>
									</form>
								);
							}}
						</Formik>
					</>
				</Flex>
			</Paper>
		</Flex>
	);
};

export default CreateCostPlan;
