import React, { useEffect, useState } from 'react';
import { Image } from '@chakra-ui/react';

import NewModal from 'containers/NewModal';
import { getApiAuthConfig } from '../../services/api.service';
import { NotificationApi, GetNotificationDto } from '../../clients';
import { getKey, persistKey } from '../../services/local-storage.service';


type NotificationModalProps = {};

const NotificationModal: React.FC<NotificationModalProps> = () => {
  const apiConfig = getApiAuthConfig();
  const notificationApi = new NotificationApi(apiConfig);

  const [notification, setNotification] = useState<GetNotificationDto>();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(()=> {
    verifyNotification();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const verifyNotification = async () => {
    const notificationViewed = getKey('notificationViewed');

    if (!notificationViewed?.isClosed) {
      await getNotification()
    }
  }

  const getNotification = async () => {
    try {
      const response = await notificationApi.getActiveNotification();

      setNotification(response);
      setIsOpen(true);
    } catch(error) {
      closeNotification();
      return;
    }
  }

  const closeNotification = () => {
    persistKey('notificationViewed', {isClosed: true});
    setIsOpen(false);
  }

  const openLink = () => {
    window.open(notification?.action_link, '_blank');
  }

  return (
    <NewModal
      isOpen={isOpen}
      onClose={()=>{closeNotification()}}
      showCloseButton={true}
      p={0}
      maxWidth={{base: '90vw', md: '45vw', lg: '40vw', xl: '30vw', '2xl': '25vw'}}
    >
      <Image
        src={notification?.url}
        minW='100%'
        maxH='70vh'
        rounded='md'
        {
          ...(
            notification?.action_link ? { onClick: () => openLink() } : {}
          )
        }
      />
    </NewModal>
  );
};

export default NotificationModal;
