import React from 'react';

import { Button, Icon } from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';

import { HeaderTitle, CellContent, SimpleTable } from '../../../containers/SimpleTable';
import { ImBlocked } from 'react-icons/im';

import { FiEdit } from 'react-icons/fi';

export default ({
	data,
	seller,
	setPagination,
	pagination,
	totalPages,
	handleOpenUserModal,
	showSellerColumn,
	currentUser,
	handleOpenUserPermissionModal,
}) => {
	const columns = React.useMemo(
		() => {
			let cols: any = [];

			if (showSellerColumn) {
				cols.push({
					Header: ({ column }) => <HeaderTitle column={column}>Estabelecimento</HeaderTitle>,
					accessor: 'seller',
					Cell: ({ cell: { row } }) => {
						return (
							<CellContent textAlign={`center`} color={`darkGrey`}>
								{(row.original.seller && row.original.seller.name && row.original.seller.name) || (seller && seller.name)}
							</CellContent>
						);
					},
				});
			}
			return cols.concat([
				{
					Header: ({ column }) => <HeaderTitle column={column}>Editar</HeaderTitle>,
					accessor: 'id',
					Cell: ({ cell: { row } }) => (
						<CellContent>
							<Button
								isDisabled={currentUser.id === row.original.id}
								background={'transparent'}
								onClick={() => {
									handleOpenUserModal(row.original);
								}}
							>
								<Icon as={FiEdit} boxSize={5} />
							</Button>
						</CellContent>
					),
				},
				{
					Header: ({ column }) => <HeaderTitle column={column}>Nome</HeaderTitle>,
					accessor: 'name',
					Cell: ({ cell: { value } }) => (
						<CellContent textAlign={`center`} color={`darkGrey`}>
							{value}
						</CellContent>
					),
				},
				{
					Header: ({ column }) => <HeaderTitle column={column}>E-mail</HeaderTitle>,
					accessor: 'email',
					Cell: ({ cell: { value } }) => (
						<CellContent textAlign={`center`} color={`darkGrey`}>
							{value}
						</CellContent>
					),
				},
				{
					Header: ({ column }) => <HeaderTitle column={column}>Perfil</HeaderTitle>,
					accessor: 'role.name',
					Cell: ({ cell: { row } }) => (
						<CellContent
							onClick={() => {
								handleOpenUserPermissionModal(row.original.role);
							}}
							cursor='pointer'
							textAlign={`center`}
							color={`darkGrey`}
						>
							{row.original?.role?.name}
							<Icon ml='2' as={InfoOutlineIcon} />
						</CellContent>
					),
				},
				{
					Header: ({ column }) => <HeaderTitle column={column}>Desabilitado</HeaderTitle>,
					accessor: 'status',
					Cell: ({ cell: { value } }) => <CellContent>{value !== 'active' ? <ImBlocked fontSize='20' /> : ''}</CellContent>,
				},
			]);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[seller]
	);

	return <SimpleTable columns={columns} data={data} setPagination={setPagination} pagination={pagination} totalPages={totalPages} pl={2} />;
};
