import React from 'react';

import { Button as DefaultButton, ButtonProps as DefaultButtonProps } from '@chakra-ui/react';

type ButtonProps = DefaultButtonProps & {
	children: string;
};

const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
	return (
		<DefaultButton
			rounded={props.rounded || `md`}
			bgColor={props.bgColor || `secondary`}
			color={props.color || `#fff`}
			fontSize={props.fontSize || `md`}
			_hover={{ opacity: 0.8 }}
			isLoading={props.isLoading || false}
			px={props.px || 6}
			{...props}
		>
			{props.children}
		</DefaultButton>
	);
};

export default Button;
