/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PaginatedDto,
    PaginatedDtoFromJSON,
    PaginatedDtoToJSON,
    UpdateSellerPosDto,
    UpdateSellerPosDtoFromJSON,
    UpdateSellerPosDtoToJSON,
} from '../models';

export interface ListPosRequest {
    limit: number;
    currentPage: number;
    status: ListPosStatusEnum;
    sort?: ListPosSortEnum;
}

export interface UpdateBackofficePosRequest {
    posId: string;
    updateSellerPosDto: UpdateSellerPosDto;
}

/**
 * 
 */
export class PosApi extends runtime.BaseAPI {

    /**
     * Listar as máquinas de todos os estabelecimentos
     */
    async listPosRaw(requestParameters: ListPosRequest): Promise<runtime.ApiResponse<PaginatedDto>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling listPos.');
        }

        if (requestParameters.currentPage === null || requestParameters.currentPage === undefined) {
            throw new runtime.RequiredError('currentPage','Required parameter requestParameters.currentPage was null or undefined when calling listPos.');
        }

        if (requestParameters.status === null || requestParameters.status === undefined) {
            throw new runtime.RequiredError('status','Required parameter requestParameters.status was null or undefined when calling listPos.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.currentPage !== undefined) {
            queryParameters['current_page'] = requestParameters.currentPage;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/pos`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedDtoFromJSON(jsonValue));
    }

    /**
     * Listar as máquinas de todos os estabelecimentos
     */
    async listPos(requestParameters: ListPosRequest): Promise<PaginatedDto> {
        const response = await this.listPosRaw(requestParameters);
        return await response.value();
    }

    /**
     * Atualizar pos via backoffice
     */
    async updateBackofficePosRaw(requestParameters: UpdateBackofficePosRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.posId === null || requestParameters.posId === undefined) {
            throw new runtime.RequiredError('posId','Required parameter requestParameters.posId was null or undefined when calling updateBackofficePos.');
        }

        if (requestParameters.updateSellerPosDto === null || requestParameters.updateSellerPosDto === undefined) {
            throw new runtime.RequiredError('updateSellerPosDto','Required parameter requestParameters.updateSellerPosDto was null or undefined when calling updateBackofficePos.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/pos/{posId}`.replace(`{${"posId"}}`, encodeURIComponent(String(requestParameters.posId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSellerPosDtoToJSON(requestParameters.updateSellerPosDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Atualizar pos via backoffice
     */
    async updateBackofficePos(requestParameters: UpdateBackofficePosRequest): Promise<void> {
        await this.updateBackofficePosRaw(requestParameters);
    }

}

/**
    * @export
    * @enum {string}
    */
export enum ListPosStatusEnum {
    Active = 'active',
    Broken = 'broken',
    Canceled = 'canceled'
}
/**
    * @export
    * @enum {string}
    */
export enum ListPosSortEnum {
    Desc = 'desc',
    Asc = 'asc'
}
