import React from 'react';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex, Link, Text, SimpleGrid, Heading, Grid, GridItem, Box } from '@chakra-ui/react';
import { paymentTypeEnum, costOptionFeeTypeEnum, cardBrandEnum, costPlanTypeStatusEnum } from '../../../services/enums.service';
import { maskMoney } from 'services/masks.service';
import Paper from '../../../containers/Paper';
import { ChevronRightIcon } from '@chakra-ui/icons';
import Loader from 'containers/Loader';
import { CostsPlansApi, GetCostPlanRequest } from '../../../clients';
import { useParams } from 'react-router-dom';
import { getApiAuthConfig } from 'services/api.service';
import { useToasts } from 'react-toast-notifications';

const CostPlanDetails: React.FC = () => {
	const [isLoading, setIsLoading] = React.useState(true);
	const [cost, setCost] = React.useState<any>();

	const { id } = useParams();
	const { addToast } = useToasts();

	const apiConfig = getApiAuthConfig();
	const costsApi = new CostsPlansApi(apiConfig);

	async function fetchGetCostRequest() {
		setIsLoading(true);

		try {
			const requestParams: GetCostPlanRequest = {
				costPlanId: id,
			};

			const response = await costsApi.getCostPlan(requestParams);

			setCost(response);
		} catch (error) {
			addToast('Erro ao buscar os dados', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	}

	React.useEffect(() => {
		fetchGetCostRequest();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Flex flexDirection='column' color='primary'>
			<Loader isOpen={isLoading} />

			<Breadcrumb fontSize='md' color='darkGrey' mb='4' separator={<ChevronRightIcon />}>
				<BreadcrumbItem>
					<BreadcrumbLink>Resumo</BreadcrumbLink>
				</BreadcrumbItem>

				<BreadcrumbItem>
					<BreadcrumbLink as={Link} href='/admin/planos-de-custo'>
						Plano de Custo
					</BreadcrumbLink>
				</BreadcrumbItem>

				<BreadcrumbItem isCurrentPage fontWeight='semibold'>
					<BreadcrumbLink>Detalhes do Plano de Custo</BreadcrumbLink>
				</BreadcrumbItem>
			</Breadcrumb>
			<Flex direction='column'>
				{cost && (
					<Paper title='Dados da venda'>
						<Flex justifyContent='space-between' mb={7}>
							<Flex direction='column'>
								<Text fontWeight='bold'>Nome</Text>
								<Text>{cost.name}</Text>
							</Flex>
							<Flex direction='column'>
								<Text fontWeight='bold'>Descrição</Text>
								<Text>{cost.description}</Text>
							</Flex>
						</Flex>
						<SimpleGrid>
							<Heading color='gray.700' as='h6' size='md' py='1%'>
								Opções
							</Heading>
						</SimpleGrid>
						<SimpleGrid>
							{cost?.options?.map((option, i) => (
								<Box color='black' borderWidth={1} borderColor='darkGrey' rounded='md' p='4' mb={7}>
									<Grid templateColumns='repeat(5, 1fr)' templateRows='repeat(2, 1fr)' gap={6}>
										<GridItem>
											<Text fontWeight='bold'>Descrição</Text>
											<Text>{option.description}</Text>
										</GridItem>
										<GridItem>
											<Text fontWeight='bold'>Tipo</Text>
											<Text>{paymentTypeEnum[option.type]}</Text>
										</GridItem>
										{option.type_status && (
											<GridItem>
												<Text fontWeight='bold'>Status</Text>
												<Text>{costPlanTypeStatusEnum[option.type_status]}</Text>
											</GridItem>
										)}
										{option.type !== 'pix' && option.type !== 'billet' && (
											<>
												{option.brand && (
													<GridItem>
														<Text fontWeight='bold'>Bandeira</Text>
														<Text>{cardBrandEnum[option.brand]}</Text>
													</GridItem>
												)}
												{option.brand_fee_type && (
													<GridItem>
														<Text fontWeight='bold'>Tipo de taxa da bandeira</Text>
														<Text>{costOptionFeeTypeEnum[option.brand_fee_type]}</Text>
													</GridItem>
												)}
												<GridItem>
													<Text fontWeight='bold'>Taxa da bandeira</Text>
													<Text>{option.brand_fee_type === 'percent' ? option.brand_fee : `R$ ${maskMoney(option.brand_fee)}`}</Text>
												</GridItem>
												{option.installments && (
													<GridItem>
														<Text fontWeight='bold'>Parcelas</Text>
														<Text>R$ {maskMoney(option.installments)}</Text>
													</GridItem>
												)}
												{option.installments && (
													<GridItem>
														<Text fontWeight='bold'>Tipo de taxa de câmbio</Text>
														<Text>{costOptionFeeTypeEnum[option.exchange_fee_type]}</Text>
													</GridItem>
												)}
												<GridItem>
													<Text fontWeight='bold'>Taxa de câmbio</Text>
													<Text>
														{option.exchange_fee_type === 'percent' ? option.exchange_fee : `R$ ${maskMoney(option.exchange_fee)}`}
													</Text>
												</GridItem>
												{option.anticipation_fee_type && (
													<GridItem>
														<Text fontWeight='bold'>Tipo de taxa de antecipação</Text>
														<Text>{costOptionFeeTypeEnum[option.anticipation_fee_type]}</Text>
													</GridItem>
												)}
												{option.markup_fee_type && (
													<GridItem>
														<Text fontWeight='bold'>Tipo de taxa de marcação</Text>
														<Text>{costOptionFeeTypeEnum[option.markup_fee_type]}</Text>
													</GridItem>
												)}
												<GridItem>
													<Text fontWeight='bold'>Taxa de marcação</Text>
													<Text>{option.markup_fee_type === 'percent' ? option.markup_fee : `R$ ${maskMoney(option.markup_fee)}`}</Text>
												</GridItem>

												{option.fixed_amount && (
													<GridItem>
														<Text fontWeight='bold'>Quantia fixa</Text>
														<Text>R$ {maskMoney(option.fixed_amount)}</Text>
													</GridItem>
												)}
												{option.type !== 'credit' && option.type !== 'debit' && (
													<GridItem>
														<Text fontWeight='bold'>Taxa do banco</Text>
														<Text>R$ {maskMoney(option.bank_fixed_amount)}</Text>
													</GridItem>
												)}
												{option.is_cap && (
													<GridItem>
														<Text fontWeight='bold'>Troca</Text>
														<Text>{option.is_cap ? 'Sim' : 'Não'}</Text>
													</GridItem>
												)}
												{option.exchange_cap && (
													<GridItem>
														<Text fontWeight='bold'>Limite de troca</Text>
														<Text>R$ {maskMoney(option.exchange_cap)}</Text>
													</GridItem>
												)}
											</>
										)}
									</Grid>
								</Box>
							))}
						</SimpleGrid>
					</Paper>
				)}
			</Flex>
		</Flex>
	);
};

export default CostPlanDetails;
