import React from 'react';
import Icon from '@chakra-ui/icon';
import { BsEyeFill } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import { HeaderTitle, CellContent, SimpleTable, HeaderTitleProps, CellContentProps } from '../../../containers/SimpleTable';
import { Switch, Tooltip, Flex } from '@chakra-ui/react';
import { CustomImage } from './CustomImage';

const HeaderTitleStyled: React.FC<HeaderTitleProps> = (props) => {
	return (
		<HeaderTitle {...props} color='black' justifyContent='flex-start' fontSize='sm' fontWeight='bold'>
			{props.children}
		</HeaderTitle>
	);
};

const CellContentStyled: React.FC<CellContentProps> = (props) => {
	return (
		<CellContent {...props} color='primary' justifyContent='flex-start' fontSize='sm' fontWeight='semibold'>
			{props.children}
		</CellContent>
	);
};

export default ({ data, setPagination, pagination, totalPages, updateActive, isLoading }) => {
	const history = useHistory();
	const columns = React.useMemo(() => {
		let cols: any = [];

		return cols.concat([
			{
				Header: ({ column }) => <HeaderTitleStyled column={column}>ID</HeaderTitleStyled>,
				accessor: 'id',
				id: 'id',
				Cell: ({ cell: { value } }) => <CellContentStyled textAlign={`center`}>{value}</CellContentStyled>,
			},
			{
				Header: ({ column }) => <HeaderTitleStyled column={column}>Nome</HeaderTitleStyled>,
				accessor: 'name',
				Cell: ({ cell: { value } }) => <CellContentStyled>{value}</CellContentStyled>,
			},
			{
				Header: ({ column }) => <HeaderTitleStyled column={column}>Ativo</HeaderTitleStyled>,
				accessor: 'is_active',
				Cell: ({ cell: { row, value } }) => (
					<Switch
						defaultChecked={value}
						isChecked={value}
						onChange={(e) => {
							updateActive(row.original.id, e.target.checked);
						}}
					></Switch>
				),
			},
			{
				Header: ({ column }) => <HeaderTitleStyled column={column}></HeaderTitleStyled>,
				accessor: 'url',
				id: 'view',
				Cell: ({ cell: { value } }) => {
					return (
						<Tooltip 
							label={
								<CustomImage src={value} />
							} 
							placement='left-start'
						>
							<Flex>
								<Icon as={BsEyeFill} boxSize={5} _hover={{ cursor: 'pointer' }} />
							</Flex>
						</Tooltip>
					);
				},
			},
		]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history]);

	return (
		<SimpleTable
			columns={columns}
			data={data}
			setPagination={setPagination}
			pagination={pagination}
			totalPages={totalPages}
			pl={2}
			pointerEvents={isLoading ? 'none' : 'auto'}
			opacity={isLoading ? 0.5 : 1}
			variant='unstyled'
			maxHeightTableWrapper='42vh'
		/>
	);
};