import React, { useEffect } from 'react';

import {
  Flex,
  FormControl,
  FormLabel,
  Text,
  Stack,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  useBreakpointValue,
} from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { useToasts } from 'react-toast-notifications';
import 'react-quill/dist/quill.snow.css';

import Button from 'components/Button';
import MultiSelect from 'components/MultiSelect';
import BackofficeAlertContainer from 'containers/BackofficeAlertContainer';
import { useCurrentSeller } from '../../contexts/SellerProvider';
import { getApiAuthConfig } from '../../services/api.service';
import {
  salesHistoryReport,
  settlementBatchTransactionReport,
  settlementBatchSaleReport,
} from 'services/enums.service';

import {
  EditSellerPartialRequest,
  SalesHistoryReportPermissionsFieldsEnum,
  SellersApi,
  SettlementBatchTransactionsReportFieldsEnum,
  SettlementBatchSalesReportFieldsEnum,
} from '../../clients';


const ReportCustomization: React.FC = () => {
  const { currentSeller, updateCurrentSeller } = useCurrentSeller();
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const apiConfig = getApiAuthConfig();
  const { addToast } = useToasts();

  const sellersApi = new SellersApi(apiConfig);

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const [reports, setReports] = React.useState<any>({
    sales_history_report: [],
    settlement_batch_transaction_report: [],
    settlement_batch_sales_report: []
  });

  const [reportsPermission, setReportsPermission] = React.useState<any>({
    sales_history: [],
    settlement_batch_transaction: [],
    settlement_batch_sale: []
  });

  useEffect(() => {
    if (currentSeller) {
      const { reports_permissions } = currentSeller.merchant;
      const reportsPermission = {
        sales_history: reports_permissions?.sales_history?.denied_fields || [],
        settlement_batch_transaction: reports_permissions?.settlement_batch_transaction?.denied_fields || [],
        settlement_batch_sale: reports_permissions?.settlement_batch_sale?.denied_fields || [],
      };

      setReportsPermission(reportsPermission);
      getReportCustomization();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSeller]);

  const getReportCustomization = async () => {
    if (currentSeller) {
      const requestParams = {
        sellerId: currentSeller.id,
      };

      try {
        setIsSubmitting(true);

        const reportCustomization = await sellersApi
          .getReportCustomizationBySellerId(requestParams);

        setReports(reportCustomization)

        setIsSubmitting(false);
      } catch (e) {
        addToast('Não foi possível buscar a customização de relatórios.', {
          appearance: 'error',
          autoDismiss: true,
        });

        setIsSubmitting(false);
      }
    }
  }

  const submitForm = async () => {
    if (currentSeller) {
      const requestParams = {
        editSellerPartialDto: {
          report_customization: {
            ...reports,
          }
        },
        sellerId: currentSeller.id,
      };

      try {
        setIsSubmitting(true);

        const seller = await sellersApi.editSellerPartial(requestParams as unknown as EditSellerPartialRequest);

        updateCurrentSeller(seller);

        addToast('Personalização salva com sucesso', {
          appearance: 'success',
          autoDismiss: true,
        });

        setIsSubmitting(false);
      } catch (e) {
        addToast('Não foi possível salvar a personalização.', {
          appearance: 'error',
          autoDismiss: true,
        });

        setIsSubmitting(false);
      }
    }
  };

  if (!currentSeller) {
    return <BackofficeAlertContainer />;
  }

  return (
    <Flex flexDirection={`column`} w='100%'>
      <Flex pb='8'>
        <Breadcrumb separator={<ChevronRightIcon />} color={`darkGrey`}>
          <BreadcrumbItem>
            <BreadcrumbLink href='#' fontSize='md'>
              Resumo
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink href='#' fontSize='md'>
              Minha Conta
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink href='#' fontSize='md' fontWeight={`semibold`}>
              Personalização de Relatórios
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Flex>
      <Flex  flexDirection={'column'}>
        <Flex  mb='4' w='100%'>
          <Text color={`darkGrey`} fontSize={isMobile ? 'lg' : '2xl'} fontWeight={`bold`}>
            PERSONALIZAÇÃO DE RELATÓRIOS
          </Text>
        </Flex>


        <Flex flexDirection={`column`} w='100%'>
          <Flex flexDir={`column`} p={10} bgColor='white' rounded={`md`} boxShadow={`sm`} mt='2' w='100%'>
            <Stack spacing={3} color={`primary`}>
              <Stack direction='column' spacing={4}>
                <Stack isInline spacing={4}>
                  <FormControl my={2}>
                    <FormLabel fontSize={`md`} fontWeight='bold'>
                      Relatório de Histórico Vendas/Pagamentos
                    </FormLabel>
                    <MultiSelect
                      id='sales_history_report'
                      placeholder='Campos de Histórico Vendas/Pagamento'
                      options={
                        Object.values(SalesHistoryReportPermissionsFieldsEnum)
                          ?.filter((field) => !reportsPermission.sales_history.includes(field))
                          .map((value) => ({
                            label: salesHistoryReport[value],
                            value: value,
                          }))}
                      value={reports.sales_history_report?.map((value: string) => ({
                        label: salesHistoryReport[value],
                        value: value,
                      }))}
                      onChange={(values) => {
                        setReports(
                          {...reports, sales_history_report: values.map((item) => item.value)}
                        );
                      }}
                    />
                  </FormControl>
                </Stack>
                <Stack isInline spacing={4}>
                  <FormControl my={2}>
                    <FormLabel fontSize={`md`} fontWeight='bold'>
                      Relatório Histórico de Liquidação
                    </FormLabel>
                    <MultiSelect
                      id='settlement_batch_transaction'
                      placeholder='Campos do Histórico de Liquidação'
                      options={Object.values(SettlementBatchTransactionsReportFieldsEnum)
                                ?.filter((field) => !reportsPermission.settlement_batch_transaction.includes(field))
                                .map((value) => ({
                                  label: settlementBatchTransactionReport[value],
                                  value: value,
                              }))}
                      value={reports.settlement_batch_transaction_report?.map((value: string) => ({
                        label: settlementBatchTransactionReport[value],
                        value: value,
                      }))}
                      onChange={(values) => {
                        setReports(
                          {...reports, settlement_batch_transaction_report: values.map((item) => item.value)}
                        );
                      }}
                    />
                  </FormControl>
                </Stack>
                <Stack isInline spacing={4}>
                  <FormControl my={2}>
                    <FormLabel fontSize={`md`} fontWeight='bold'>
                      Relatório Detalhes de Liquidação
                    </FormLabel>
                    <MultiSelect
                      id='settlement_batch_sales'
                      placeholder='Campos do Detalhes de Liquidação'
                      options={Object.values(SettlementBatchSalesReportFieldsEnum)
                                ?.filter((field) => !reportsPermission.settlement_batch_sale.includes(field))
                                .map((value) => ({
                                  label: settlementBatchSaleReport[value],
                                  value: value,
                                }))}
                      value={reports.settlement_batch_sales_report?.map((value: string) => ({
                        label: settlementBatchSaleReport[value],
                        value: value,
                      }))}
                      onChange={(values) => {
                        setReports(
                          {...reports, settlement_batch_sales_report: values.map((item) => item.value)}
                        );
                      }}
                    />
                  </FormControl>
                </Stack>
              </Stack>
            </Stack>
            <FormControl display='flex' justifyContent={isMobile ? 'center' : 'flex-end'} mt='4'>
              <Button size='lg' type='submit' disabled={isSubmitting} bgColor={'primary'} isLoading={isSubmitting} onClick={submitForm}>
                Salvar Personalização
              </Button>
            </FormControl>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ReportCustomization;
