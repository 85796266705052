import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	GridItem,
	SimpleGrid,
} from '@chakra-ui/react';
import React from 'react';

import FormSelect from 'components/Form/FormSelect';

const CompanyComplianceForm: React.FC = () => {
	return (
		<SimpleGrid
			columns={{base: 1, md: 6, xl: 6}}
			color='gray.700'
			gap={4}
		>
			<GridItem colStart={{base: 1}} colSpan={{base: 4, md: 2, xl: 1}}>
				<FormControl id='document_ofac'>
					<FormLabel fontSize='md' fontWeight='bold'>
						CNPJ OFAC
					</FormLabel>
					<FormSelect name='document_ofac'>
						<option value='' />
						<option value='true'>Sim</option>
						<option value='false'>Não</option>
					</FormSelect>
					<FormErrorMessage name='document_ofac' />
				</FormControl>
			</GridItem>

			<GridItem colStart={{base: 1}} colSpan={{base: 4, md: 2, xl: 1}}>
				<FormControl id='document_onu'>
					<FormLabel fontSize='md' fontWeight='bold'>
						CNPJ ONU
					</FormLabel>
					<FormSelect name='document_onu'>
						<option value='' />
						<option value='true'>Sim</option>
						<option value='false'>Não</option>
					</FormSelect>
					<FormErrorMessage name='document_onu' />
				</FormControl>
			</GridItem>

			<GridItem colStart={{base: 1}} colSpan={{base: 4, md: 2, xl: 1}}>
				<FormControl id='document_rf'>
					<FormLabel fontSize='md' fontWeight='bold'>
						CNPJ RF
					</FormLabel>
					<FormSelect name='document_rf'>
						<option value='' />
						<option value='true'>Sim</option>
						<option value='false'>Não</option>
					</FormSelect>
					<FormErrorMessage name='document_rf' />
				</FormControl>
			</GridItem>
		</SimpleGrid>
	);
};

export default CompanyComplianceForm;
