import { InputProps, InputGroup, InputRightElement, Spinner } from '@chakra-ui/react';
import * as React from 'react';
import InputMask from 'react-input-mask';
import { useToasts } from 'react-toast-notifications';

import { Field } from 'formik';

import { SellersApi } from '../../clients';
import { getApiAuthConfig } from '../../services/api.service';
import Input from '../Input';

type FormCnsInputType = InputProps & {
	search?: (data: any) => any;
};

const FormCnsInput: React.FC<FormCnsInputType> = (props: FormCnsInputType) => {
	const apiConfig = getApiAuthConfig();
	const sellersApi = new SellersApi(apiConfig);
	const { addToast } = useToasts();

	const [isLoading, setIsLoading] = React.useState(false);

	const searchDataByCns = async (cns: string) => {
		setIsLoading(true);

		try {
			const response = await sellersApi.getNotarysOfficesByCns({
				cns: cns.replace(/\D+/gi, ''),
			});

			const {
				assignment,
				invoicing,
				phone,
				secondary_phone,
				zipcode,
				street,
				address_number,
				district,
				city,
				state,
				responsible,
			} = response;

			return {
				cns,
				assignment: parseAssignment(assignment.toLowerCase()),
				monthlyBilling: invoicing / 6 / 100,
				phone,
				street,
				secondary_phone,
				zipcode,
				address_number,
				district,
				city,
				state,
				responsible,
			};
		} catch (error) {
			addToast('Erro ao buscar informações a partir do CNS', {
				appearance: 'error',
				autoDismiss: true,
			});
			return {};
		} finally {
			setIsLoading(false);
		}
	};

	const parseAssignment = (assignment: string) => {
		const assignments = {
			notas: 'note',
			imóveis: 'properties',
			civil: 'civil',
			'pessoa jurídica': 'legal_entity',
			protesto: 'protest',
		};

		return assignments[assignment];
	};

	return (
		<Field {...props}>
			{({ form, field }) => {
				return (
					<InputMask
						borderColor='darkGrey'
						mask='99.999-9'
						maskChar={null}
						value={field.value}
						onBlur={form.handleBlur}
						onChange={async (e) => {
							props.onChange && props.onChange(e);
							form.setFieldValue(field.name, e.target.value);

							if (e.target.value.replace(/\D+/gi, '').length !== 6) {
								return;
							}

							if (props.search) {
								const data = await searchDataByCns(e.target.value);
								props.search(data);
							}
						}}
						{...props}
					>
						{(inputProps) => {
							return (
								<InputGroup rounded='lg'>
									<Input type='text' {...inputProps} />
									{isLoading && (
										<InputRightElement
											pb={2}
											children={
												<Spinner thickness='1px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='sm' p={2} mt={4} mb={2} />
											}
										/>
									)}
								</InputGroup>
							);
						}}
					</InputMask>
				);
			}}
		</Field>
	);
};

export default FormCnsInput;
