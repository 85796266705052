import React from 'react';

import { useToasts } from 'react-toast-notifications';

import { FormControl, Icon, Button, Checkbox } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Box, Stack, Text, VStack } from '@chakra-ui/layout';

import { Formik } from 'formik';

import yup from '../../../services/yup.service';

import {
	BilletsApi,
	GetPaymentLinkSellerDto,
	GetSimulationDto,
	CreateBilletPaymentInternalRequest
} from '../../../clients';

import FormErrorMessage from '../../../components/Form/FormErrorMessage';
import FormInput from '../../../components/Form/FormInput';
import FormZipcodeInput from '../../../components/Form/FormZipcodeInput';
import getzipcode from '../../../services/getzipcode.service';

import { getApiDefaultConfig } from '../../../services/api.service';
import { maskMoney } from '../../../services/masks.service';
import { CheckoutSaleInformationType } from '../types/checkout.type';
import { states } from '../../../config/constants';

import BilletModal from './BilletModal';
import FormCpfCnpjInput from '../../../components/Form/FormCpfCnpjInput';
import FormSelect from '../../../components/Form/FormSelect';

type BilletProps = {
	seller: GetPaymentLinkSellerDto;
	saleInformation: CheckoutSaleInformationType | undefined;
	simulationValues: Array<GetSimulationDto>;
	setLinkPayed(payed: boolean): void;
};

const Billet: React.FC<BilletProps> = ({ seller, saleInformation, simulationValues, setLinkPayed }) => {
	const { addToast } = useToasts();

	const [value, setValue] = React.useState({
		simulated: 0,
		original: 0,
	});

	const [url, setUrl] = React.useState('');

	const [confirmPayment, setConfirmPayment] = React.useState(false);
	const [checked, setChecked] = React.useState(false);

	const [isLoading, setIsLoading] = React.useState(false);
	const initialValues = {
		cpf: '',
		cep: '',
		city: '',
		state: '',
		street: '',
		district: '',
		number: '',		
		complement: '',
	};

	const schema = yup.object().shape({
		cpf: yup.string().trim().isCpfOrCnpjOptional('Documento inválido').required('Campo obrigatório'),
		cep: yup.string().trim().required('Campo obrigatório'),
		city: yup.string().trim().required('Campo obrigatório'),
		state: yup.string().trim().required('Campo obrigatório'),
		street: yup.string().trim().required('Campo obrigatório'),
		district: yup.string().trim().required('Campo obrigatório'),
		number: yup.string().trim().required('Campo obrigatório'),		
		complement: yup.string(),
	});

	const handlePayment = async (values) => {
		try {
			if (url !== '') {
				setConfirmPayment(!confirmPayment);
				return;
			}
			if (!value.simulated) return;

			setIsLoading(true);

			const apiConfig = getApiDefaultConfig();

			const paymentPayloadRequest: CreateBilletPaymentInternalRequest = {
				sellerId: seller.id,
				createBilletInternalDto: [{
					value: value.simulated,
					social_security_number: values.cpf.trim(),
					shopper_statement: saleInformation!.description,
					description: saleInformation!.description,
					delivery_date: new Date(),
					shopper: {
						first_name: saleInformation!.first_name,
						last_name: saleInformation!.last_name,
						email: saleInformation!.email,
						billing_address: {
							city: values.city.trim(),							
							house_number_or_name: values.number.trim(),
							postal_code: values.cep.replace('-', '').trim(),
							state_or_province: values.state.trim(),
							street: values.street,
							complement: values.complement !== '' ? values.complement.trim() : 'N/A',
							district: values.district.trim(),
						},
					},
					sale_id: saleInformation!.id,
					protest_link: true,
					amount_fee: saleInformation!.fee
				}],
			};

			const sellerApi = new BilletsApi(apiConfig);
			const response = await sellerApi.createBilletPaymentInternal(paymentPayloadRequest);

			if (!response && !response[0]) return;

			setUrl(response[0].url);
			setConfirmPayment(!confirmPayment);
			addToast('Boleto gerado com sucesso', {
				appearance: 'success',
				autoDismiss: true,
			});
		} catch (e) {
			addToast('Dados Inválidos', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	};

	React.useEffect(() => {
		(async function () {
			const amount = simulationValues.find((payment) => payment.type === 'billet');

			if (!amount) return;

			setValue({
				simulated: amount.total_amount_cents,
				original: amount.original_amount_cents,
			});
		})();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Formik enableReinitialize initialValues={initialValues} validationSchema={schema} onSubmit={handlePayment}>
			{({ handleSubmit, setFieldValue, isValid, resetForm, values }) => {
				return (
					<form onSubmit={handleSubmit}>
						{!checked && (
							<>
								<Text align='center' mt={3}>
									Valor original da dívida destinado ao cartório: R$ <span style={{ fontWeight: 'bold' }}>{maskMoney((value.original ? value.original - saleInformation?.fee! : 0) / 100)}</span>
								</Text>
								<Text align='center'>
									* Acréscimo sobre a dívida do cartório: R$ <span style={{ fontWeight: 'bold' }}>{maskMoney((value.simulated - value.original) / 100)}</span>
								</Text>
								{saleInformation && <Text align='center'>
									Valor do Emolumento: R$ <span
									style={{ fontWeight: 'bold' }}>{maskMoney((saleInformation.fee) / 100)}</span>
								</Text>}
								<Text align='center'>
									Acréscimo sobre a dívida de emolumento: R$ <span style={{ fontWeight: 'bold' }}>{maskMoney(0)}</span>
								</Text>
								<Text align='center'>
									Valor a ser pago com acréscimo: R$ <span style={{ fontWeight: 'bold' }}>{maskMoney(value.simulated / 100)}</span>
								</Text>
								<Box border='1px solid #EE7D00' p={2} mt={2}>
									<Text align='center' fontSize='0.75rem'>
										Atenção: A solicitação do seu serviço somente será liberada após o prazo de compensação do boleto que poderá ser de até
										três dias úteis pela instituição financeira.
									</Text>

									<Checkbox
										size='sm'
										colorScheme='orange'
										isChecked={checked}
										border='#EE7D00'
										onClick={() => {
											setChecked(!checked);
										}}
										mt={3}
									>
										Estou ciente
									</Checkbox>
								</Box>
							</>
						)}
						{checked && (
							<VStack spacing={4} mt={5}>
								<Stack direction={['column', 'row']} w='100%'>
									<FormControl>
										<FormCpfCnpjInput
											isSearchPayer={true}
											variant='flushed'
											placeholder='CPF/CNPJ'
											size='lg'
											autoFocus
											name='cpf'
											borderTop='none'
											borderLeft='none'
											borderRight='none'
											setSelectedPayer={() => {}}
										/>
										<FormErrorMessage name='cpf' />
									</FormControl>
									<FormControl>
										<FormZipcodeInput
											variant='flushed'
											placeholder='CEP'
											size='lg'
											autoFocus
											name='cep'
											borderTop='none'
											borderLeft='none'
											borderRight='none'
											performSearch={getzipcode}
											onSearchResults={(data) => {
												setFieldValue('street', data.logradouro);
												setFieldValue('district', data.bairro);
												setFieldValue('city', data.localidade);
												setFieldValue('state', data.uf);
												setFieldValue('complement', data.complemento);												
											}}
										/>
										<FormErrorMessage name='cep' />
									</FormControl>
									<FormControl>
										<FormInput
											variant='flushed'
											placeholder='Cidade'
											size='lg'
											autoFocus
											name='city'
											borderTop='none'
											borderLeft='none'
											borderRight='none'
										/>
										<FormErrorMessage name='city' />
									</FormControl>
								</Stack>
								<Stack direction={['column', 'row']} w='100%'>
									<FormControl>
										<FormSelect
											textColor={!!values.state ? `black` : `gray.400`}
											variant='flushed'
											size='lg'
											fontSize='md'
											autoFocus
											name='state'
											borderTop='none'
											borderLeft='none'
											borderRight='none'>
											<option value="">Estado</option>
											{states.map((state) => (
												<option value={state.value} key={state.value}>
													{state.text}
												</option>
											))}
										</FormSelect>
										<FormErrorMessage name='state' />
									</FormControl>
									<FormControl>
										<FormInput
											variant='flushed'
											placeholder='Rua'
											size='lg'
											autoFocus
											name='street'
											borderTop='none'
											borderLeft='none'
											borderRight='none'
										/>
										<FormErrorMessage name='street' />
									</FormControl>
								</Stack>
								<Stack direction={['column', 'row']} w='100%'>
									<FormControl>
										<FormInput
											variant='flushed'
											placeholder='Bairro'
											size='lg'
											autoFocus
											name='district'
											borderTop='none'
											borderLeft='none'
											borderRight='none'
										/>
										<FormErrorMessage name='district' />
									</FormControl>
									<FormControl>
										<FormInput
											variant='flushed'
											placeholder='Número'
											size='lg'
											autoFocus
											name='number'
											borderTop='none'
											borderLeft='none'
											borderRight='none'
										/>
										<FormErrorMessage name='number' />
									</FormControl>
								</Stack>
								<Stack direction={['column', 'row']} w='100%'>								
									<FormControl>
										<FormInput
											variant='flushed'
											placeholder='Complemento'
											size='lg'
											autoFocus
											name='complement'
											borderTop='none'
											borderLeft='none'
											borderRight='none'
										/>
										<FormErrorMessage name='complement' />
									</FormControl>
								</Stack>

								<Button isLoading={isLoading} size='lg' w='100%' disabled={!isValid} color='white' bgColor='secondary' type='submit'>
									Gerar boleto <Icon as={ArrowForwardIcon} boxSize={25} />
								</Button>
							</VStack>
						)}
						<BilletModal
							confirmPayment={confirmPayment}
							url={url}
							setConfirmPayment={setConfirmPayment}
							resetForm={resetForm}
							isLoading={isLoading}
							setLinkPayed={setLinkPayed}
						/>
					</form>
				);
			}}
		</Formik>
	);
};

export default Billet;
