import React from 'react';

import {
	Center,
	Flex,
	FormControl,
	Text,
	HStack,
	Stack,
	BreadcrumbItem,
	BreadcrumbLink,
	useBreakpointValue,
	Tooltip,
	chakra,
	IconButton,
} from '@chakra-ui/react';

import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { format } from 'date-fns';
import { Formik } from 'formik';

import Loader from '../../containers/Loader';
import RangeDatePicker, { IPeriod } from '../../containers/RangeDatePicker';
import Button from '../../components/Button';
import MultiSelect from '../../components/MultiSelect';

import {
	SettlementsBatchesApi,
	PaginatedDto,
	CreateExportSettlementListDtoExtensionEnum,
	ListAllSettlementRequest,
	ListSellerSettlementRequest,
	OmieApi,
	SalesApi,
	CreateExportSalesListDtoExtensionEnum,
	CreateExportSalesListDtoSourceEnum
} from '../../clients';

import PaymentTable from './components/PaymentTable';
import Paper from '../../containers/Paper';

import { getApiAuthConfig } from '../../services/api.service';
import { useAuth } from '../../contexts/AuthProvider';
import { useCurrentSeller } from '../../contexts/SellerProvider';

import ExportFileSuccessModal from '../../containers/ExportFileSuccessModal';
import { maskMoney } from '../../services/masks.service';
import { BiExport } from 'react-icons/bi';
import { IoInformationCircleOutline } from 'react-icons/io5';
import Breadcrumb from 'components/Breadcrumb';
import { RepeatIcon } from '@chakra-ui/icons';
import OmieConfirmModal from '../../components/OmieConfirmModal';

const PaymentHistory: React.FC = () => {
	const history = useHistory();
	const isMobile = useBreakpointValue({ base: true, lg: false });
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [exportIsLoading, setExportIsLoading] = React.useState<boolean>(false);
	const [exportSalesIsLoading, setExportSalesIsLoading] = React.useState<boolean>(false);
	const [consolidateReportLoading, setConsolidateReportLoading] = React.useState<boolean>(false);
	const [settlementBatchOmieLoading, setSettlementBatchOmieLoading] = React.useState<boolean>(false);
	const [confirmExportOmieModalOpen, setConfirmExportOmieModalOpen] = React.useState<boolean>(false);
	const [openExportSuccessModal, setOpenExportSuccessModal] = React.useState<boolean>(false);
	const [period, setPeriod] = React.useState<IPeriod[]>([
		{
			startDate: new Date(new Date().setHours(0, 0, 0, 0)),
			endDate: new Date(new Date().setHours(23, 59, 59, 999)),
			key: 'selection',
		},
	]);

	const [batches, setBatches] = React.useState<PaginatedDto>();
	const [filters, setFilters] = React.useState({});
	const [pagination, setPagination] = React.useState({ currentPage: 1, limit: 100 });
	const [totalPages, setTotalPages] = React.useState(1);

	const { addToast } = useToasts();

	const apiConfig = getApiAuthConfig();
	const settlementBatchApi = new SettlementsBatchesApi(apiConfig);
	const omieApi = new OmieApi(apiConfig);
	const salesApi = new SalesApi(apiConfig);

	const { isBackoffice, isMerchant } = useAuth();
	const { currentSeller } = useCurrentSeller();

	const initialValues = {
		id: '',
		status: [],
		type: [],
		startDate: format(period[0].startDate, 'yyyy-MM-dd'),
		endDate: format(period[0].endDate, 'yyyy-MM-dd'),
	};

	async function fetchSettlementBatches(props) {
		const { status, type, ...parsedProps } = props;

		setIsLoading(true);

		if (currentSeller) {
			try {
				const requestParams: ListSellerSettlementRequest = {
					sellerId: currentSeller.id,
					currentPage: pagination.currentPage,
					limit: pagination.limit,
					startDate: format(period[0].startDate, 'yyyy-MM-dd'),
					endDate: format(period[0].endDate, 'yyyy-MM-dd'),
					status: status?.map((v) => v.value),
					type: type?.map((v) => v.value),
					...parsedProps,
				};

				const response = await settlementBatchApi.listSellerSettlement(requestParams);

				setBatches(response);
				setTotalPages(response.total_pages);
			} catch (error) {
				addToast('Erro ao buscar os dados', {
					appearance: 'error',
					autoDismiss: true,
				});
			} finally {
				setIsLoading(false);
			}
		} else {
			try {
				const requestParams: ListAllSettlementRequest = {
					currentPage: pagination.currentPage,
					limit: pagination.limit,
					startDate: format(period[0].startDate, 'yyyy-MM-dd'),
					endDate: format(period[0].endDate, 'yyyy-MM-dd'),
					status: status?.map((v) => v.value),
					type: type?.map((v) => v.value),
					...parsedProps,
				};

				const response = await settlementBatchApi.listAllSettlement(requestParams);

				setBatches(response);
				setTotalPages(response.total_pages);
			} catch (error) {
				addToast('Erro ao buscar os dados', {
					appearance: 'error',
					autoDismiss: true,
				});
			} finally {
				setIsLoading(false);
			}
		}
	}

	const handleFormSubmit = async (values) => {
		setFilters(values);

		await fetchSettlementBatches(values);
	};

	const persistFilters = (filters) => {
		const { status, type, startDate, endDate } = filters;

		handleFormSubmit(filters);

		if (startDate && endDate) {
			setPeriod([
				{
					startDate: new Date(`${startDate} 00:00:00`),
					endDate: new Date(`${endDate} 00:00:00`),
					key: 'selection',
				},
			]);
			initialValues.startDate = startDate;
			initialValues.endDate = endDate;
		}

		initialValues.status = status || [];
		initialValues.type = type || [];
	};

	React.useEffect(() => {
		const { location } = history;
		const { state } = location || {};
		const { previousFilters } = state || {};

		if (previousFilters) {
			persistFilters(previousFilters);
		} else {
			fetchSettlementBatches(filters);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pagination, currentSeller]);

	const handleExportReportFile = async (props) => {
		setExportIsLoading(true);

		try {
			const { status, type, ...parsedProps } = props;

			await settlementBatchApi.createSettlementBatchListExportRequest({
				createExportSettlementListDto: {
					start_date: format(period[0].startDate, 'yyyy-MM-dd'),
					end_date: format(period[0].endDate, 'yyyy-MM-dd'),
					status: status?.map((v) => v.value),
					type: type?.map((v) => v.value),
					extension: CreateExportSettlementListDtoExtensionEnum.Xls,
					seller_id: currentSeller?.id,
					...parsedProps,
				},
			});

			setOpenExportSuccessModal(true);
		} catch (e) {
			addToast('Ocorreu um erro no processamento', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setExportIsLoading(false);
		}
	};

	const handleExportConsolidateReport = async (params) => {
		setConsolidateReportLoading(true);

		try {
			let { status, original_amount, ...parsedParams } = params;

			await salesApi.createSalesConsolidateExportRequest({
				createExportSalesListDto: {
					start_date: format(period[0].startDate, 'yyyy-MM-dd'),
					end_date: format(period[0].endDate, 'yyyy-MM-dd'),
					status: status?.map((v) => v.value),
					extension: CreateExportSalesListDtoExtensionEnum.Pdf,
					seller_id: currentSeller?.id,
					original_amount: original_amount > 0 ? parseInt((original_amount * 100).toFixed(0), 10) : undefined,
					source: CreateExportSalesListDtoSourceEnum.SettlementSalesHistory,
					...parsedParams,
				},
			});
			setOpenExportSuccessModal(true);
		} catch (e) {
			addToast('Ocorreu um erro no processamento', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setConsolidateReportLoading(false);
		}
	};

	const handleExportSalesReportFile = async (props) => {
		setExportSalesIsLoading(true);

		try {
			const { status, type, ...parsedProps } = props;

			await settlementBatchApi.createTransactionsListExportRequest({
				createExportSettlementListDto: {
					start_date: format(period[0].startDate, 'yyyy-MM-dd'),
					end_date: format(period[0].endDate, 'yyyy-MM-dd'),
					status: status?.map((v) => v.value),
					type: type?.map((v) => v.value),
					extension: CreateExportSettlementListDtoExtensionEnum.Xls,
					seller_id: currentSeller?.id,
					...parsedProps,
				},
			});

			setOpenExportSuccessModal(true);
		} catch (e) {
			addToast('Ocorreu um erro no processamento', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setExportSalesIsLoading(false);
		}
	};

	const handleSettlementBatchOmie = async () => {
		setSettlementBatchOmieLoading(true);

		try {
			await omieApi.omieControllerIncludeAccountsPayable();

			addToast('Processo iniciado!', {
				appearance: 'success',
				autoDismiss: true,
			});
		} catch (e) {
			addToast('Ocorreu um erro no processamento', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setSettlementBatchOmieLoading(false);
			setConfirmExportOmieModalOpen(false);
		}
	};

	function navigateToDetails(id: string, date) {
		history.push(`/admin/payments-details/${id}`, { date, filters });
	}

	return (
		<Flex flexDirection='column'>
			<Loader isOpen={isLoading} />

			<Breadcrumb>
				<BreadcrumbItem>
					<BreadcrumbLink>Resumo</BreadcrumbLink>
				</BreadcrumbItem>

				<BreadcrumbItem>
					<BreadcrumbLink>Gestão Financeira</BreadcrumbLink>
				</BreadcrumbItem>

				<BreadcrumbItem isCurrentPage>
					<BreadcrumbLink fontWeight='semibold' opacity='0.9' cursor='unset'>
						Histórico de liquidação
					</BreadcrumbLink>
				</BreadcrumbItem>
			</Breadcrumb>

			<ExportFileSuccessModal
				showCloseButton
				isOpen={openExportSuccessModal}
				onOkClick={() => {
					setOpenExportSuccessModal(false);
				}}
				onClose={() => {
					setOpenExportSuccessModal(false);
				}}
			/>

			<Formik initialValues={initialValues} onSubmit={handleFormSubmit}>
				{({ handleSubmit, handleChange, setFieldValue, values, resetForm }) => {
					return (
						<form onSubmit={handleSubmit}>
							<Stack direction={['column', 'column', 'column', 'column', 'row']} justify='flex-end' mb={4}>
								{isBackoffice && (
									<Button
										{...(batches?.results.length === 0 && { disabled: true })}
										bgColor='primary'
										isDisabled={settlementBatchOmieLoading}
										isLoading={settlementBatchOmieLoading}
										onClick={() => setConfirmExportOmieModalOpen(true)}
										leftIcon={<BiExport size={20} />}
									>
										Exportar liquidações para OMIE
									</Button>
								)}

								<Button
									{...(batches?.results.length === 0 && { disabled: true })}
									bgColor='black'
									id='payment-history-consolidated-button'
									isDisabled={consolidateReportLoading}
									isLoading={consolidateReportLoading}
									onClick={() => handleExportConsolidateReport(values)}
									leftIcon={<BiExport size={20} />}
									order={[1, 0]}
								>
									Exportar Consolidado para PDF
								</Button>

								<Button
									{...(batches?.results.length === 0 && { disabled: true })}
									bgColor='black'
									isDisabled={exportSalesIsLoading}
									isLoading={exportSalesIsLoading}
									onClick={() => handleExportSalesReportFile(values)}
									leftIcon={<BiExport size={20} />}
								>
									Exportar transações para Excel
								</Button>

								<Button
									{...(batches?.results.length === 0 && { disabled: true })}
									bgColor='primary'
									isDisabled={exportIsLoading}
									isLoading={exportIsLoading}
									onClick={() => handleExportReportFile(values)}
									leftIcon={<BiExport size={20} />}
								>
									Exportar liquidações para Excel
								</Button>
							</Stack>

							<Paper p={4}>
								{batches && batches.additional_data && (
									<HStack spacing={4} mb={5} justifyContent={isMobile ? 'center' : 'flex-end'}>
										<Text color='primary' fontSize='lg' fontWeight='semibold'>
											Total Liquidações: R$ {maskMoney(batches?.additional_data['total_settlement_batch'] / 100 || 0)}
										</Text>
									</HStack>
								)}

								<Flex flexDirection='row'>
									<Stack
										direction={isMobile ? 'column' : 'row'}
										justifyContent={isMobile ? 'center' : 'space-between'}
										w='200%'
										spacing={4}
									>
										<Stack direction={['column', 'column', 'row']}>
											<Center position='relative'>
												<RangeDatePicker
													period={period}
													setPeriod={setPeriod}
													setFieldValue={setFieldValue}
													handleSubmit={handleSubmit}
													backgroundColor='primary'
													py='5'
												/>
												<Tooltip label={'Data da Liquidação'} placement='top' hasArrow>
													<chakra.span>
														<IoInformationCircleOutline size='1.3rem' color='primary' />
													</chakra.span>
												</Tooltip>
											</Center>

											<Center pt={0} minWidth='150px' mr='2'>
												<FormControl>
													<MultiSelect
														id='status'
														placeholder='Status'
														options={[
															{ value: 'paid', label: 'Pago' },
															{ value: 'pending', label: 'Pendente' },
															{ value: 'canceled', label: 'Cancelado' },
															{ value: 'failed', label: 'Falho' },
															{ value: 'sent', label: 'Enviado' },
														]}
														value={values.status}
														onChange={(value) => {
															setFieldValue('status', value);
															handleSubmit();
														}}
													/>
												</FormControl>
											</Center>
											<Center pt={0} minWidth='200px' mr='2'>
												<FormControl>
													<MultiSelect
														id='type'
														placeholder='Método'
														options={[
															{ value: 'TED', label: 'Ted' },
															{ value: 'PIX', label: 'Pix' },
														]}
														value={values.type}
														onChange={(value) => {
															setFieldValue('type', value);
															handleSubmit();
														}}
													/>
												</FormControl>
											</Center>
										</Stack>

										<Center>
											<Tooltip label={'Refazer a busca'} placement='top' hasArrow>
												<IconButton
													_hover={{ backgroundColor: 'transparent' }}
													_focus={{ outline: 'none' }}
													aria-label='Refazer a busca'
													icon={<RepeatIcon />}
													color='primary'
													backgroundColor='transparent'
													size='lg'
													onClick={() => handleSubmit()}
												/>
											</Tooltip>
											{(values.type.length > 0 || values.status.length > 0) && (
												<Button
													onClick={() => {
														resetForm();
														handleSubmit();
													}}
													paddingInline='10px'
													_focus={{ outline: 'none' }}
													color='secondary'
													bgColor='transparent'
												>
													Limpar Filtros
												</Button>
											)}
										</Center>
									</Stack>
								</Flex>

								<Flex overflowX='auto' overflowY='hidden' flexDirection='column'>
									{batches ? (
										<Flex mt={6} width='100%'>
											<PaymentTable
												showSellerColumn={isBackoffice || isMerchant}
												onRowClick={(row) => navigateToDetails(row.values.id, row.values.settlement_day)}
												data={batches.results}
												setPagination={setPagination}
												pagination={pagination}
												totalPages={totalPages}
											/>
										</Flex>
									) : (
										<Loader isOpen={isLoading} />
									)}
								</Flex>
							</Paper>
							<OmieConfirmModal
								confirmExportOmie={confirmExportOmieModalOpen}
								isLoading={settlementBatchOmieLoading}
								handleSubmit={handleSettlementBatchOmie}
							/>
						</form>
					);
				}}
			</Formik>
		</Flex>
	);
};

export default PaymentHistory;
