import React from 'react';

import { Box, Stack, Text } from '@chakra-ui/react';
import { AiFillWarning } from 'react-icons/ai';

const NoBilletContainer: React.FC = () => {
	return (
		<Box border='1px solid #EE7D00' p={2} mt={2} bgColor={`white`}>
			<Stack isInline spacing={2} color={`gray.700`} justifyContent={`center`} alignItems={`center`}>
				<AiFillWarning color={`orange`} />
				<Text align='center' fontWeight={`bold`}>Atenção:</Text>
				<Text>Você não oferece este tipo de pagamento</Text>
			</Stack>
		</Box>
	)
}

export default NoBilletContainer;