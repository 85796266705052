import React, { Dispatch, SetStateAction } from 'react';
import { Box, BoxProps, Flex, Text } from '@chakra-ui/react';
import { format, subDays, subMonths, endOfMonth, endOfToday, endOfYesterday, startOfMonth, startOfToday, startOfYesterday } from 'date-fns';
import { IPeriod } from '../../../containers/RangeDatePicker';

const FilterBadge: React.FC<BoxProps & { description: string; selected: boolean }> = (props) => {
	const { description, selected, ...rest } = props;

	return (
		<Box
			{...rest}
			px='4'
			py='2'
			color={selected ? 'white' : 'primary'}
			borderWidth={1}
			borderColor='primary'
			rounded='0.5rem'
			bgColor={selected ? 'primary' : 'white'}
			_hover={{ cursor: 'pointer', bgColor: 'primary', color: 'white' }}
		>
			<Text fontWeight='semibold'>{description}</Text>
		</Box>
	);
};

export type QuickFiltersData = {
	id: number;
	description: string;
	period: IPeriod;
};

type Props = {
	selectedFilter: QuickFiltersData;
	setSelectedQuickFilter: Dispatch<SetStateAction<QuickFiltersData>>;
	setPeriod: Dispatch<SetStateAction<IPeriod[]>>;
	filterAction: (filter: QuickFiltersData) => void;
	showSummaryChartPeriod: boolean;
};

const QuickFilters: React.FC<Props> = (props) => {
	const { selectedFilter, setPeriod, filterAction, setSelectedQuickFilter, showSummaryChartPeriod } = props;

	const date = new Date();
	const startDate = subMonths(new Date(date.getFullYear(), date.getMonth(), 1), 5);
	const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);

	const filters: QuickFiltersData[] = [
		{
			id: 1,
			description: 'Hoje',
			period: {
				startDate: startOfToday(),
				endDate: endOfToday(),
				key: 'selection',
			},
		},
		{
			id: 2,
			description: 'Ontem',
			period: {
				startDate: startOfYesterday(),
				endDate: endOfYesterday(),
				key: 'selection',
			},
		},
		{
			id: 3,
			description: 'Últimos 7 dias',
			period: {
				startDate: subDays(startOfToday(), 7),
				endDate: endOfToday(),
				key: 'selection',
			},
		},
		{
			id: 4,
			description: 'Este mês',
			period: {
				startDate: startOfMonth(new Date()),
				endDate: endOfMonth(new Date()),
				key: 'selection',
			},
		},
		{
			id: 5,
			description: 'Últimos 30 dias',
			period: {
				startDate: subDays(startOfToday(), 30),
				endDate: endOfToday(),
				key: 'selection',
			},
		},
	];

	const handleClick = (filter: QuickFiltersData) => {
		setSelectedQuickFilter(filter);
		setPeriod([filter.period]);
		filterAction(filter);
	};

	return (
		<Flex direction='column'>
			<Flex justifyContent='space-between'>
				<Flex>
					{filters.map((filter, index) => {
						return (
							<FilterBadge
								key={filter.id}
								selected={filter.id === selectedFilter.id}
								description={filter.description}
								mr={index !== filters.length - 1 ? '0.3125rem' : '0'}
								onClick={() => handleClick(filter)}
							/>
						);
					})}
				</Flex>

				{ showSummaryChartPeriod && (
					<Box px='4' py='2' bgColor='primary' color='white' rounded='md' fontWeight='semibold'>
						{format(startDate, 'dd/MM/yyyy')} a {format(endDate, 'dd/MM/yyyy')}
					</Box>
				)}
			</Flex>
		</Flex>
	);
};

export default QuickFilters;
