import * as React from 'react';

import { InputProps } from '@chakra-ui/react';
import { Field } from 'formik';

import InputMask from 'react-input-mask';

import Input from '../Input';

const FormPhoneInput: React.FC<InputProps> = (props: InputProps) => {
	return (
		<Field {...props}>
			{({ form, field }) => {
				return (
					<InputMask
						borderColor={`darkGrey`}
						mask={field.value && field.value.toString().length === 15 ? '(99) 99999-9999' : '(99) 9999-99999'}
						formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
						maskChar={null}
						value={field.value}
						onBlur={form.handleBlur}
						onChange={(e) => {
							props.onChange && props.onChange(e);
							form.setFieldValue(field.name, e.target.value);
						}}
						{...props}
					>
						{(inputProps) => {
							return <Input type='text' {...inputProps} />;
						}}
					</InputMask>
				);
			}}
		</Field>
	);
};

export default FormPhoneInput;
