import React from 'react';

import { useBreakpointValue, Flex } from '@chakra-ui/react';
import { maskCpfOrCnpj, maskMoney } from '../../../services/masks.service';

import Paper from 'containers/Paper';
import Text from 'components/Text';
import { dateToString } from 'services/date.service';

export default (data) => {
	const isMobile = useBreakpointValue({ base: true, lg: false });
	return (
		<>
			<Paper title='Dados Pagamento de Conta'>
			<Flex direction='column'>
				<Flex direction={isMobile ? 'column' : 'row'} justifyContent='space-around' mb='3'>
					<Flex direction='column' width={isMobile ? '100%' : '50%'} mb={isMobile ? '3' : '0'}>
						<Text fontWeight='bold'>Código de Barras</Text>
						<Text>{data.digitable || '-'}</Text>
					</Flex>
					<Flex textAlign='start' direction='column' width={isMobile ? '100%' : '50%'}>
						<Text fontWeight='bold'>CPF/CNPJ</Text>
						<Text>{data.document_payer ? maskCpfOrCnpj(data.document_payer) : 'N/A'}</Text>
					</Flex>
				</Flex>
				<Flex direction={isMobile ? 'column' : 'row'} justifyContent='space-around' mb='3'>
					<Flex direction='column' width={isMobile ? '100%' : '50%'} mb={isMobile ? '3' : '0'}>
						<Text fontWeight='bold'>Celcoin PSP</Text>
						<Text>{data.transaction_id ? data.transaction_id : 'N/A'}</Text>
					</Flex>
					<Flex direction='column' width={isMobile ? '100%' : '50%'}>
						<Text fontWeight='bold'>Valor do Título</Text>
						<Text>{data.original_value ? `R$ ${maskMoney(data.original_value)}` : 'N/A'}</Text>
					</Flex>
				</Flex>
					<Flex direction={isMobile ? 'column' : 'row'} justifyContent='start' mb='7'>
						<Flex direction='column' width={isMobile ? '100%' : '50%'}>
							<Text fontWeight='bold'>Data de Vencimento</Text>
							<Text>{data?.due_date ? dateToString(new Date(data?.due_date)) : '-'}</Text>
						</Flex>
					</Flex>
			</Flex>
			</Paper>
		</>
	);
};
