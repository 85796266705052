import React from 'react';
import { Flex, HStack, Text } from '@chakra-ui/react';
import { MonthlyCalendar } from '@zach.codes/react-calendar';
import { AiFillCloseCircle, AiFillCheckCircle, AiFillClockCircle, AiFillDollarCircle, AiFillMinusCircle, AiOutlineIssuesClose } from 'react-icons/ai';
import { maskMoney } from 'services/masks.service';

import MonthlyBody from './MonthlyBody';
import MonthlyNav from './MonthlyNav';
import Paper from '../../../containers/Paper';
import Legend from './Legend';
import PaymentDetailsModal from './PaymentDetailsModal';

export const IconStatus = {
	paid: <AiFillCheckCircle color='#47FF62' size={18} />,
	pending: <AiFillClockCircle color='#FFA547' size={18} />,
	canceled: <AiFillMinusCircle color='tomato' size={18} />,
	sent: <AiFillDollarCircle color='#47FF62' size={18} />,
	failed: <AiFillCloseCircle color='tomato' size={18} />,
	resent: <AiOutlineIssuesClose color='#63c75b' size={16} />,
};

type Props = {
	events: Events[];
	currentMonth: Date;
	setCurrentMonth: React.Dispatch<React.SetStateAction<Date>>;
	sellerId?: string;
};

export type Events = {
	id: string;
	title: string;
	date: Date;
	status: string;
};

type DefaultEventItemProps = {
	title: string;
	status: string;
	sellerId?: string;
	onClick: () => void
};

export const Event = ({ title, status, sellerId, onClick }: DefaultEventItemProps): JSX.Element => {
	return sellerId ? (
		<Flex cursor='pointer' onClick={onClick}>
			<Flex direction={['column', 'column', 'row']} w='100%' maxW='100%' alignItems={['center', 'center', 'flex-start']}>
				{IconStatus[status]}
				<Text fontWeight='bold' fontSize={['xs', 'sm', 'md']} isTruncated>{title}</Text>
			</Flex>
		</Flex>
	) : (
		<HStack w='100%' maxW='100%'>
			<Text fontWeight='bold' fontSize={['xs', 'xs', 'md']} isTruncated>
				{title}
			</Text>
		</HStack>
	);
};

const CalendarComponent: React.FC<Props> = (props: Props) => {
	const { events, currentMonth, setCurrentMonth } = props;
	const [isDetailsModalOpen, setDetailsModalOpen] = React.useState(false);
	const [currentPaymentOpen, setCurrentPaymentOpen] = React.useState<Events | undefined>(undefined);

	const getFormattedTotalMonth = () => {
		let total = 0;

		events.forEach(e => {
			total += Number(e.title) * 100;
		});

		return `R$${maskMoney(total/100)}`
	};

	return (
		<MonthlyCalendar currentMonth={currentMonth} onCurrentMonthChange={(date) => setCurrentMonth(date)}>
			<Flex direction='column'>
				<MonthlyNav />

				<Paper p={4}>
					<MonthlyBody
						events={events}
						renderDay={(data) =>
							data.map((item) => (
								<Event
									status={item.status}
									key={item.id}
									title={`R$${maskMoney(item.title)}`}
									sellerId={props.sellerId}
									onClick={() => {
										setCurrentPaymentOpen(item);
										setDetailsModalOpen(true);
									}}
								/>
							))
						}
					/>
				</Paper>

				<Legend totalMonth={getFormattedTotalMonth()}/>
			</Flex>

			<PaymentDetailsModal isOpen={isDetailsModalOpen} paymentData={currentPaymentOpen} onClose={() => setDetailsModalOpen(false)}/>
		</MonthlyCalendar>
	);
};

export default CalendarComponent;
