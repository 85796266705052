import { FormControl, FormLabel, Stack, VStack } from '@chakra-ui/react';
import FormSelect from 'components/Form/FormSelect';
import React from 'react';

import FormCpfCnpjInput from '../../../components/Form/FormCpfCnpjInput';
import FormErrorMessage from '../../../components/Form/FormErrorMessage';
import FormInput from '../../../components/Form/FormInput';
import FormZipcodeInput from '../../../components/Form/FormZipcodeInput';
import getzipcode from '../../../services/getzipcode.service';
import { states } from '../../../config/constants';
import FormPhoneInput from 'components/Form/FormPhoneInput';
import FormDatePickerInput from 'components/Form/FormDatePickerInput';

type PayerFormProps = {
	setFieldValue: any;
	values: any;
	formExtended: boolean | undefined;
};

const PayerForm: React.FC<PayerFormProps> = ({ setFieldValue, values, formExtended }) => {
	return (
		<VStack my={4} w='100%' fontSize='2sm' border='1px' borderColor='gray.400' rounded='md' p='5' className='data__billet'>
			<Stack direction={['column', 'row']} w='100%' spacing={4}>
				<FormControl>
					<FormLabel color={`black`} fontWeight={`semibold`} fontSize={`md`}>
						Nome Completo
					</FormLabel>
					<FormInput
						variant='flushed'
						placeholder='Digite aqui'
						size='lg'
						autoFocus
						name='name'
						borderColor='gray.500'
						rounded='md'
						p='5'
					/>
					<FormErrorMessage name='name' />
				</FormControl>

				<FormControl>
					<FormLabel color={`black`} fontWeight={`semibold`} fontSize={`md`}>
						CPF/CNPJ
					</FormLabel>
					<FormCpfCnpjInput
						isSearchPayer={true}
						variant='flushed'
						placeholder='Digite aqui'
						size='lg'
						name='cpf'
						borderColor='gray.500'
						rounded='md'
						p='5'
						setSelectedPayer={() => { }}
						className='form-data__document'
					/>
					<FormErrorMessage name='cpf' />
				</FormControl>
			</Stack>
			{formExtended && (
				<Stack direction={['column', 'row']} w='100%' spacing={4}>
				<FormControl>
					<FormLabel color={`black`} fontWeight={`semibold`} fontSize={`md`}>
						Data de Nascimento
					</FormLabel>
					<FormDatePickerInput
						variant='flushed'
						placeholder='Digite aqui'
						size='lg'
						name='birth_date'
						borderColor='gray.500'
						rounded='md'
						p='5'
					/>
					<FormErrorMessage name='birth_date' />
				</FormControl>
				<FormControl>
					<FormLabel color={`black`} fontWeight={`semibold`} fontSize={`md`}>
						Email
					</FormLabel>
					<FormInput
						variant='flushed'
						placeholder='Digite aqui'
						size='lg'
						name='email'
						borderColor='gray.500'
						rounded='md'
						p='5'
					/>
					<FormErrorMessage name='email' />
				</FormControl>
			</Stack>
			)}
			{formExtended && (
				<Stack direction={['column', 'row']} w='100%' spacing={4}>
				<FormControl>
					<FormLabel color={`black`} fontWeight={`semibold`} fontSize={`md`}>
						Telefone
					</FormLabel>
					<FormPhoneInput
						variant='flushed'
						placeholder='Digite aqui'
						size='lg'
						name='phone'
						borderColor='gray.500'
						rounded='md'
						p='5'
						className='form-data__phone'
					/>
					<FormErrorMessage name='phone' />
				</FormControl>
				<FormControl>
					<FormLabel color={`black`} fontWeight={`semibold`} fontSize={`md`}>
						CEP
					</FormLabel>
					<FormZipcodeInput
						variant='flushed'
						placeholder='Digite aqui'
						size='lg'
						name='cep'
						borderColor='gray.500'
						rounded='md'
						p='5'
						performSearch={getzipcode}
						onSearchResults={(data) => {
							setFieldValue('street', data.logradouro);
							setFieldValue('district', data.bairro);
							setFieldValue('city', data.localidade);
							setFieldValue('state', data.uf);
							setFieldValue('complement', data.complemento);
						}}
						className='form-data__zipcode'
					/>
					<FormErrorMessage name='cep' />
				</FormControl>
			</Stack>
			)}
			{formExtended && (
				<Stack direction={['column', 'row']} w='100%'>
				<FormControl>
					<FormLabel color={`black`} fontWeight={`semibold`} fontSize={`md`}>
						Cidade
					</FormLabel>
					<FormInput
						variant='flushed'
						placeholder='Digite aqui'
						size='lg'
						name='city'
						borderColor='gray.500'
						rounded='md'
						p='5'
					/>
					<FormErrorMessage name='city' />
				</FormControl>
				<FormControl rounded='md'>
					<FormLabel color={`black`} fontWeight={`semibold`} fontSize={`md`}>
						Estado
					</FormLabel>
					<FormSelect
						textColor={!!values.state ? `black` : `gray.500`}
						variant='outline'
						size='lg'
						fontSize='md'
						name='state'
						border='1px'
						borderColor='gray.500'
					>
						<option value=''>Selecione</option>
						{states.map((state) => (
							<option value={state.value} key={state.value}>
								{state.text}
							</option>
						))}
					</FormSelect>
					<FormErrorMessage name='state' />
				</FormControl>
			</Stack>
			)}
			{formExtended && (
				<Stack direction={['column', 'row']} w='100%'>
				<FormControl>
					<FormLabel color={`black`} fontWeight={`semibold`} fontSize={`md`}>
						Rua
					</FormLabel>
					<FormInput
						variant='flushed'
						placeholder='Rua'
						size='lg'
						name='street'
						borderColor='gray.500'
						rounded='md'
						p='5'
					/>
					<FormErrorMessage name='street' />
				</FormControl>
				<FormControl>
					<FormLabel color={`black`} fontWeight={`semibold`} fontSize={`md`}>
						Bairro
					</FormLabel>
					<FormInput
						variant='flushed'
						placeholder='Bairro'
						size='lg'
						name='district'
						borderColor='gray.500'
						rounded='md'
						p='5'
					/>
					<FormErrorMessage name='district' />
				</FormControl>
			</Stack>
			)}
			{formExtended && (
				<Stack direction={['column', 'row']} w='100%'>
				<FormControl>
					<FormLabel color={`black`} fontWeight={`semibold`} fontSize={`md`}>
						Número
					</FormLabel>
					<FormInput
						variant='flushed'
						placeholder='Número'
						size='lg'
						name='number'
						borderColor='gray.500'
						rounded='md'
						p='5'
						className='form-data__house-number'
					/>
					<FormErrorMessage name='number' />
				</FormControl>
				<FormControl>
					<FormLabel color={`black`} fontWeight={`semibold`} fontSize={`md`}>
						Complemento
					</FormLabel>
					<FormInput
						variant='flushed'
						placeholder='Complemento'
						size='lg'
						name='complement'
						borderColor='gray.500'
						rounded='md'
						p='5'
					/>
					<FormErrorMessage name='complement' />
				</FormControl>
			</Stack>
			)}
		</VStack>
	);
};

export default PayerForm;
